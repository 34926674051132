<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        automation_id="tbl_recording_information_grid"
        :config="gridConfig"
        :data-source="gridDataSource"
        :actions="gridActions"
        export-file-name="recording-information-data"
        :rq-editable="!isReadOnly"
        @delete="onDeleteItem"
        hide-search
    />
</template>
<script>

    import { mapState, mapGetters } from "vuex";
    import { RecordingInformationItem } from '../../../models';
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { useGridInvokerMethods } from "@/shared/composables/";

    export default {
        name: "RecordingInformationGridCalculator",
        mixins: [GridCompanyPickerMixin],
        props: {
            loanId:{ type: Number, default: 0 },
            displayMode: {type: String, default: "route"}
        },
        setup() {
            return useGridInvokerMethods();
        },
        data(){
            return{
                originalItems:[],
                items:[],
                deleteditems: [],
                gridDataSource: {},
                gridConfig: {},
                documents:[],
                lineTypes:[],
                availableLines:[]
            }
        },
        created() {
            this.initGridConfig();
        },
        watch:{
            loanId(newVal, oldVal){
                if(newVal === oldVal || newVal <= 0) return;
                this.fetchData();
            }
        },
        computed:{
            ...mapState({
                readOnly: state => state.isPageReadOnly,
                orderId: state => state.orders.orderId
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            localSecurity(){
                return this.securitySettings.findValues(["AllowEditRecordingInformation"]);
            },
            isReadOnly() { return this.readOnly || !this.localSecurity.AllowEditRecordingInformation },
            gridActions() {
                if (this.isReadOnly) return [];
                return [{
                    name: "delete",
                    text: "Delete",
                    eventName: "delete",
                    allowMultiSelection: true,
                    tooltip: "Delete"
                    }
                    ];
            },
            documentOptions(){
                return _.map(this.documents, (d) => { return {id:d.recordingDocumentID, name: d.description}; });
            },
            isDisplayModal() {
                return this.displayMode === "modal";
            }
        },
        methods:{
            async fetchData(){
                const self = this;
                try{
                    let promise = self.$api.RecordingInformationApi.getList(self.loanId);
                    let result = await self.$rqBusy.wait(promise);
                    self.mapData(result);
                }
                catch(err) {
                    console.error(err);
                    self.$toast.error("An issue occurred retrieving recording information.");
                }
            },
            async save(userInitiated){
                const self = this;
                if (self.isReadOnly) return; 
                await self.invokeGridMethod("saveEditData");

                if(!self.isDirty()) {
                    if(userInitiated)
                        self.$toast.info("No changes detected.");
                    return true;
                }

                let changes = _.filter(self.items, item => {
                    if(item.recordingInformationID === 0) return true;
                    let original = _.find(self.originalItems, o => o.recordingInformationID === item.recordingInformationID);
                    let changed = self.getAuditChanges(original, item);
                    return changed.length > 0;
                });

                let data = { orderID: self.orderId, loanID: self.loanId, items: _.concat(changes, self.deleteditems)};

                try {
                    let apiPromise = self.$api.RecordingInformationApi.save(data)
                    let result = await self.$rqBusy.wait(apiPromise);
                    self.mapItems(result.items);
                    self.$toast.success("Saved Recording Information Successfully.");
                    return true;

                }
                catch(err) {
                    console.error(err);
                    self.$toast.error("An issue occurred while saving recording information.");
                    return false;
                }
            },
            cancel(e){
                const self = this;
                if(!self.isDirty()) {
                    self.$toast.info("No changes detected.");
                    return;
                }
                let okHandler = () => {
                    self.deleteditems = [];
                    self.fetchData();
                };
                self.$dialog.confirm("Cancel Changes", "Are you sure you want to cancel and undo the current recording information changes?", okHandler);
            },
            isDirty() {
                const self = this;
                if(_.some(self.items, i => i.recordingInformationID === 0)
                    || self.items.length !== self.originalItems.length) return true;

                let itemChanges = self.getAuditChanges(self.items, self.originalItems);
                return itemChanges.length > 0;
            },
            add(){
                this.invokeGridComponentMethod("setAddRow");
            },
            mapData(data){
                if(_.isNil(data)) return;
                const self = this;
                self.lineTypes = _.map(data.lineTypes, d => ({ id: _.parseNumber(d.id, null), name: d.name }));
                self.availableLines = _.map(data.availableLines, d => ({ id: _.parseNumber(d.id, null), name: d.name, lineType: _.parseNumber(d.lineType, null) }));
                self.documents = data.recordingDocuments;
                self.mapItems(data.items);
            },
            mapItems(items){
                const self = this;
                self.items = _.map(items, i => new RecordingInformationItem(i));
                self.originalItems = _.cloneDeep(self.items);
                self.deleteditems = [];
                self.invokeGridMethod("refresh");
            },
            initGridConfig(){
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr:"companyID",
                    companyNameExpr:"company",
                    contactIDExpr:"conatactID",
                    contactNameExpr:"contactName",
                    showContactPicker: true
                };

                self.gridConfig = {
                    scrolling: { useNative: self.isDisplayModal },
                    columns: [
                        {
                            dataField: "lineType",
                            dataType: "number",
                            minWidth: 75,
                            lookup: {
                                dataSource: () => ({ store: self.lineTypes }),
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            caption: "Statement",
                            setCellValue: function (rowData, value) {
                                rowData.lineTypeID = null;
                                this.defaultSetCellValue(rowData, value);
                            }
                        },
                        {
                            dataField: "lineTypeID",
                            dataType: "number",
                            minWidth: 75,
                            lookup: {
                                dataSource: e => ({
                                    store: this.availableLines,
                                    filter: e.data ? ["lineType", "=", e.data.lineType] : null
                                }),
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            caption: "Line"
                        },
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "company",
                                dataType: "string",
                                caption: "Payee"
                            },
                            ...payeePickerInfo
                        }),
                        {
                            dataField: "recordingFee",
                            caption: "Actual Fees",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        {
                            dataField: "amount",
                            caption: "Collected Fees",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                        },
                        DxGridUtils.dateColumn({
                            dataField: "recordingDate",
                            caption: "Date",
                        }),
                        {
                            dataField: "recordingDocumentID",
                            dataType: "number",
                            minWidth: 75,
                            lookup: {
                                dataSource: () => ({ store: self.documentOptions }),
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            caption: "Document"
                        },
                        {
                            dataField: "notes",
                            caption: "Recording Information",
                            dataType: "string",
                        }
                    ],
                    onEditorPreparing(e) {
                        if(e.dataField !== "amount") return;
                        let data = e.row.data;
                        e.editorOptions.readOnly = data.lineType !== 2; //lineType 2 = Other, allow amount editing for those lines
                    },
                };

                self.gridDataSource = {
                    key: "clientKey",
                    load: async () => self.items,
                    update: self.onGridUpdate,
                    insert: self.onGridInsert
                };

                self.fetchData();
            },
            onDeleteItem(e){
                const self = this;
                let data = e.data;
                let okHandler = function (ee) {
                    _.forEach(data, selectedItem => {
                        selectedItem.isDeleted = true;
                        if(selectedItem.recordingInformationID > 0) self.deleteditems.push(selectedItem);
                        let itemIndex = _.findIndex(self.items, item => item.clientKey === selectedItem.clientKey);
                        if(itemIndex < 0) return;
                        self.items.splice(itemIndex, 1);
                    });
                    self.invokeGridMethod("refresh");
                    return true;
                }
                self.$dialog.confirm("Confirm Delete", "Are you sure you want to delete the selected recording information?", okHandler);
            },

            async onGridUpdate(key, values) {
                const self = this;
                let item = _.find(self.items, i => i.clientKey === key);
                _.assign(item, values);
                return item;
            },

            async onGridInsert(values){
                const self = this;

                let lineType = values["lineType"];
                let lineTypeID = values["lineTypeID"];

                if(_.isNil(lineType) || _.isNil(lineTypeID)){
                    let newItem = new RecordingInformationItem({
                        ordersID: self.orderId,
                        loanID: self.loanId,
                        recordingInformationID: 0,
                        ...values
                    });
                    self.items.push(newItem);
                    return newItem;
                }

                try {
                    let promise = self.$api.RecordingInformationApi.getFee(self.loanId, lineType, lineTypeID);
                    let result = await self.$rqBusy.wait(promise);

                    let newItem = new RecordingInformationItem({
                        ordersID: self.orderId,
                        loanID: self.loanId,
                        recordingInformationID: 0,
                        companyID: _.get(result, "companyID", null),
                        company: _.get(result, "company", null),
                        recordingFee: _.get(result, "fee", 0),
                        ...values
                    });
                    self.items.push(newItem);
                    return newItem;
                }
                catch(err) {
                    console.error(err);
                    self.$toast.error("An issue occurred while adding the entered recording information.")
                }
            },

            updateDimensions() {
                this.invokeGridMethod("updateDimensions");
                this.invokeGridMethod("repaint");
            }
        }
    }
</script>