import accounting from "accounting";
import { AssignedLanguageListType, AppliesToType } from "../file/title-policy/enums";
import { DateTimeHelper } from "@/shared/utilities";

export class AssignmentFilterModel {
    constructor(options) {
        let opt = options || {};
        this.status = _.parseNumber(opt.status, 0);
        this.assignee = _.parseNumber(opt.assignee, 2);
    }
    static create(status=null, assignee=null) {
        return new AssignmentFilterModel({ status, assignee });
    }
}

export const TaskGridActions = {
    MARK_COMPLETE: {
        automation_id: "btn_mark_task_complete",
        key: "mark-task-complete",
        text: "Mark Complete",
        tooltip: "Mark selected Tasks Complete",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: ["ctrl+m"],
        disableWhenReadOnly: true,
        allowMultiSelection: true
    },
    UPDATE_DUEDATE: {
        automation_id: "btn_update_due_date",
        key: "update-due-date",
        text: "Due Date",
        tooltip: "Update Due Date on selected Tasks",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        allowMultiSelection: true
    },
    UPDATE_STARTDATE: {
        automation_id: "btn_update_start_date",
        key: "update-start-date",
        text: "Start Date",
        tooltip: "Update Start Date on selected Tasks",
        permissionKeys: ["EditTaskStartDate"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        allowMultiSelection: true
    },
    MARK_NA: {
        automation_id: "btn_mark_task_na",
        key: "mark-task-na",
        text: "Toggle N/A",
        tooltip: "Mark selected Tasks N/A",
        permissionKeys: ["AllowMaskTaskNA"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        allowMultiSelection: true
    },
    EDIT: {
        automation_id: "btn_edit_task",
        key: "edit-task",
        text: "Edit",
        tooltip: "Edit selected Task",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        requireSelection: true
    },
    COPY: {
        automation_id: 'btn_copy_tasks',
        key: "copy-tasks",
        text: "Copy",
        tooltip: "Copy selected Task(s)",
        permissionKeys: ["AllowAddWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        allowMultiSelection: true
    },
    DELETE: {
        automation_id: "btn_delete_task",
        key: "delete-task",
        text: "Delete",
        tooltip: "Delete selected Task",
        permissionKeys: ["AllowDeleteWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        allowMultiSelection: true
    },
    EMAIL: {
        automation_id: "btn_email_task",
        key: "email-task",
        text: "Email",
        tooltip: "Email selected Task",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        requireSelection: true
    },
    ASSIGN_ME: {
        automation_id: "btn_assign_tasks_me",
        key: "assign-tasks-me",
        text: "Assign to Me",
        tooltip: "Assign selected Tasks to me",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        allowMultiSelection: true
    },
    ASSIGN: {
        automation_id: "btn_assign_tasks",
        key: "assign-tasks",
        text: "Assign",
        tooltip: "Assign selected Tasks to Department and/or Department User",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        allowMultiSelection: true
    },
    ADD_NOTE: {
        automation_id: 'btn_add_note_to_task',
        key: "add-note-to-tasks",
        text: "Add Note",
        tooltip: "Add Note to selected task",
        permissionKeys: ["AllowEditWorkflowTask"],
        accessKey: "UpdateWorkflow_ScreenAccess",
        shortcutKeys: [],
        disableWhenReadOnly: true,
        requireSelection: true
    },
    GO_TO_FILE: {
        automation_id: 'btn_go_to_file',
        key: "go-to-file",
        text: "Go to File",
        tooltip: "Go to File for selected task",
        permissionKeys: [],
        accessKey: "",
        shortcutKeys: [],
        allowMultiSelection: true
    }
};

export class AlertDataMapper{
    constructor(options){
        options = options || {};
        this.processOrder = _.parseBool(options.processOrder);
        this.processBuyers = _.parseBool(options.processBuyers);
        this.processSellers = _.parseBool(options.processSellers);
        this.processLoans = _.parseBool(options.processLoans);
        this.processProperties = _.parseBool(options.processProperties);
        this.processCompanies = _.parseBool(options.processCompanies);
        this.processNotes = _.parseBool(options.processNotes);
        this.processCommitmentPolicy = _.parseBool(options.processCommitmentPolicy);

        this.orderMapping = new OrderDataMapper(options.orderMapping);
        this.loanMappings = _.map(options.loanMappings, m => new LoanDataMapper(m));
        this.propertyMappings = _.map(options.propertyMappings, m => new PropertyDataMapper(m));
        this.buyerMappings = _.map(options.buyerMappings, m => new BuyerSellerDataMapper(m));
        this.sellerMappings = _.map(options.sellerMappings, m => new BuyerSellerDataMapper(m));
        this.titleProductionMapping = new TitleProductionDataMapper(options.titleProductionMapping);
        this.companyMapping = _.map(options.companyMapping, companyData => new CompanyDataMapper(companyData));
    }
}

export class OrderDataMapper{
    constructor(options){
        options = options || {};
        this.pcLogID = _.parseNumber(options.pcLogID, 0);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.stageOrdersID = _.parseNumber(options.stageOrdersID, 0);
        this.status = _.parseNumber(options.status, null);
        this.stageStatus = _.parseNumber(options.stageStatus, null);
        this.acceptStageStatus = _.parseBool(options.acceptStageStatus, false);
        this.statusDescription = options.statusDescription || null;
        this.stageStatusDescription = options.stageStatusDescription || null;
        this.otherCompaniesFileNumber = options.otherCompaniesFileNumber || null;
        this.stageOtherCompaniesFileNumber = options.stageOtherCompaniesFileNumber || null;
        this.acceptStageOtherCompaniesFileNumber = _.parseBool(options.acceptStageOtherCompaniesFileNumber, false);
        this.openDate = options.openDate || null;
        this.stageOpenDate = options.stageOpenDate || null;
        this.acceptStageOpenDate = _.parseBool(options.acceptStageOpenDate, false);
        this.closeDate = options.closeDate || null;
        this.stageCloseDate = options.stageCloseDate || null;
        this.acceptStageCloseDate = _.parseBool(options.acceptStageCloseDate, false);
        this.amountDown = _.parseNumber(options.amountDown, null);
        this.stageAmountDown = _.parseNumber(options.stageAmountDown, null);
        this.acceptStageAmountDown = _.parseBool(options.acceptStageAmountDown, false);
        this.earnestMoney = _.parseNumber(options.earnestMoney, null);
        this.stageEarnestMoney = _.parseNumber(options.stageEarnestMoney, null);
        this.acceptStageEarnestMoney = _.parseBool(options.acceptStageEarnestMoney, false);
        this.salesPrice = _.parseNumber(options.salesPrice, null);
        this.stageSalesPrice = _.parseNumber(options.stageSalesPrice, null);
        this.acceptStageSalesPrice = _.parseBool(options.acceptStageSalesPrice, false);
        this.regionID = _.parseNumber(options.regionID, null);
        this.stageRegionID = _.parseNumber(options.stageRegionID, null);
        this.acceptStageRegionID = _.parseBool(options.acceptStageRegionID, false);
        this.regionDescription = options.regionDescription || null;
        this.stageRegionDescription = options.stageRegionDescription || null;
        this.branchID = _.parseNumber(options.branchID, null);
        this.stageBranchID = _.parseNumber(options.stageBranchID, null);
        this.acceptStageBranchID = _.parseBool(options.acceptStageBranchID, false);
        this.branchDescription = options.branchDescription || null;
        this.stageBranchDescription = options.stageBranchDescription || null;
    }

    get finalStatus(){
        return this.acceptStageStatus ? this.stageStatusDescription : this.statusDescription;
    }

    get finalOtherCompaniesFileNumber(){
        return this.acceptStageOtherCompaniesFileNumber ? this.stageOtherCompaniesFileNumber : this.otherCompaniesFileNumber;
    }

    get finalOpenDate(){
        let date = this.acceptStageOpenDate ? this.stageOpenDate : this.openDate;
        return this.formatDate(date);
    }

    get displayStageOpenDate(){
        return this.formatDate(this.stageOpenDate);
    }

    get finalCloseDate(){
        let date = this.acceptStageCloseDate ? this.stageCloseDate : this.closeDate;
        return this.formatDate(date);
    }

    get displayStageCloseDate(){
        return this.formatDate(this.stageCloseDate);
    }

    get finalAmountDown(){
        let amount = this.acceptStageAmountDown ? this.stageAmountDown : this.amountDown;
        return accounting.formatMoney(amount);
    }

    get displayStageAmountDown(){
        return accounting.formatMoney(this.stageAmountDown);
    }

    get finalEarnestMoney(){
        let amount = this.acceptStageEarnestMoney ? this.stageEarnestMoney : this.earnestMoney;
        return accounting.formatMoney(amount);
    }

    get displayStageEarnestMoney(){
        return accounting.formatMoney(this.stageEarnestMoney);
    }

    get finalSalesPrice(){
        let amount = this.acceptStageSalesPrice ? this.stageSalesPrice : this.salesPrice;
        return accounting.formatMoney(amount);
    }

    get displayStageSalesPrice(){
        return accounting.formatMoney(this.stageSalesPrice);
    }

    get finalRegion(){
        return this.acceptStageRegionID ? this.stageRegionDescription : this.regionDescription;
    }

    get finalBranch(){
        return this.acceptStageBranchID ? this.stageBranchDescription : this.branchDescription;
    }

    formatDate(date) {
        if(_.isEmpty(date)) {
            return "";
        }

        try {
            let dt = DateTimeHelper.getDateFromString(date);
            return dt.isValid ? dt.toFormat('MM/dd/yyyy') : "";
        } 
        catch (error) {
            return "";
        }
    }
}

export class LoanDataMapper {
    constructor(options){
        options = options || {};
        this.pcLogID = _.parseNumber(options.pcLogID, 0);
        this.loanID = _.parseNumber(options.loanID, 0);
        this.lenderID  = _.parseNumber(options.lenderID, 0);
        this.lenderName = options.lenderName || null;
        this.stageLoanID = _.parseNumber(options.stageLoanID, 0);
        this.number = options.number || null;
        this.stageNumber= options.stageNumber || null;
        this.acceptStageNumber = _.parseBool(options.acceptStageNumber, false);
        this.loanOrder = _.parseNumber(options.loanOrder, 0);
        this.stageLoanOrder = _.parseNumber(options.stageLoanOrder, 0);
        this.acceptStageLoanOrder = _.parseBool(options.acceptStageLoanOrder, false);
        this.amount = _.parseNumber(options.amount, 0);
        this.stageAmount = _.parseNumber(options.stageAmount, 0);
        this.acceptStageAmount = _.parseBool(options.acceptStageAmount, false);
        this.interestRate = _.parseNumber(options.interestRate, 0);
        this.stageInterestRate = _.parseNumber(options.stageInterestRate, 0);
        this.acceptStageInterestRate = _.parseBool(options.acceptStageInterestRate, false);
        this.mortInsCaseNumber = options.mortInsCaseNumber || null;
        this.stageMortInsCaseNumber = options.stageMortInsCaseNumber || null;
        this.acceptStageMortInsCaseNumber = _.parseBool(options.acceptStageMortInsCaseNumber, false);

        this.loanPolicyLiability = _.parseNumber(options.oanPolicyLiability, 0);
        this.stageLoanPolicyLiability = _.parseNumber(options.stageLoanPolicyLiability, 0);
        this.acceptStageLoanPolicyLiability = _.parseBool(options.acceptStageLoanPolicyLiability, false);

        this.term = _.parseNumber(options.term, 0);
        this.stageTerm = _.parseNumber(options.stageTerm, 0);
        this.acceptStageTerm = _.parseBool(options.acceptStageTerm, false);

        this.loanTermPeriod = _.parseNumber(options.loanTermPeriod, 0);
        this.stageLoanTermPeriod = _.parseNumber(options.stageLoanTermPeriod, 0);
        this.acceptStageLoanTermPeriod = _.parseBool(options.acceptStageLoanTermPeriod, false);

        this.monthlyPrincipal = _.parseNumber(options.monthlyPrincipal, 0);
        this.stageMonthlyPrincipal = _.parseNumber(options.stageMonthlyPrincipal, 0);
        this.acceptStageMonthlyPrincipal = _.parseBool(options.acceptStageMonthlyPrincipal, false);

        this.monthlyInterest = _.parseNumber(options.monthlyInterest, 0);
        this.stageMonthlyInterest = _.parseNumber(options.stageMonthlyInterest, 0);
        this.acceptStageMonthlyInterest = _.parseBool(options.acceptStageMonthlyInterest, false);

        this.isNewLoan = _.parseBool(options.isNewLoan, false);
    }

    get finalNumber(){
        return this.acceptStageNumber ? this.stageNumber : this.number;
    }
    
    get finalLoanOrder(){
        return this.acceptStageLoanOrder ? this.stageLoanOrder : this.loanOrder;
    }

    get finalAmount(){
        return this.acceptStageAmount ? this.stageAmount : this.amount;
    }

    get finalInterestRate(){
        return this.acceptStageInterestRate ? this.stageInterestRate : this.interestRate;
    }

    get finalMortInsCaseNumber(){
        return this.acceptStageMortInsCaseNumber ? this.stageMortInsCaseNumber : this.mortInsCaseNumber;
    }

    get finalLoanPolicyLiability(){
        return this.acceptStageLoanPolicyLiability ? this.stageLoanPolicyLiability : this.loanPolicyLiability;
    }

    get finalTerm(){
        return this.acceptStageTerm ? this.stageTerm : this.term;
    }

    get finalLoanTermPeriod(){
        return this.acceptStageLoanTermPeriod ? this.stageLoanTermPeriod : this.loanTermPeriod;
    }

    get finalMonthlyPrincipal(){
        return this.acceptStageMonthlyPrincipal ? this.stageMonthlyPrincipal : this.monthlyPrincipal;
    }

    get finalMonthlyInterest(){
        return this.acceptStageMonthlyInterest ? this.stageMonthlyInterest : this.monthlyInterest;
    }
}

export class PropertyDataMapper {
    constructor(options){
        options = options || {};

        this.pcLogID = _.parseNumber(options.pcLogID, 0);
        this.propertyID = _.parseNumber(options.propertyID, 0);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.loanID = _.parseNumber(options.loanID, 0);
        this.stagePropertyID = _.parseNumber(options.stagePropertyID, 0);
        this.stageOrdersID = _.parseNumber(options.stageOrdersID, 0);
        this.stageLoanID = _.parseNumber(options.stageLoanID, 0);
        this.legalDescription = options.legalDescription || null;
        this.stageLegalDescription = options.stageLegalDescription || null;
        this.acceptStageLegalDescription = _.parseBool(options.acceptStageLegalDescription, false);
        this.acreage = _.parseNumber(options.acreage, 0);
        this.stageAcreage = _.parseNumber(options.stageAcreage, 0);
        this.acceptStageAcreage = _.parseBool(options.acceptStageAcreage, false);
        this.address = options.address || null;
        this.stageAddress = options.stageAddress || null;
        this.acceptStageAddress = _.parseBool(options.acceptStageAddress, false);
        this.address1 = options.address1 || null;
        this.stageAddress1 = options.stageAddress1 || null;
        this.acceptStageAddress1 = _.parseBool(options.acceptStageAddress1, false);
        this.address2 = options.address2 || null;
        this.stageAddress2 = options.stageAddress2 || null;
        this.acceptStageAddress2 = _.parseBool(options.acceptStageAddress, false);
        this.city = options.city || null;
        this.stageCity = options.stageCity || null;
        this.acceptStageCity = _.parseBool(options.acceptStageCity, false);
        this.countyID = _.parseNumber(options.countyID, 0);
        this.stageCountyID = _.parseNumber(options.stageCountyID, 0);
        this.acceptStageCountyID = _.parseBool(options.acceptStageCountyID, false);
        this.countyName = options.countyName || null;
        this.stageCountyName = options.stageCountyName || null;
        this.acceptStageCountyName = _.parseBool(options.acceptStageCountyN, false);
        this.state = options.state || null;
        this.stageState = options.stageState || null;
        this.acceptStageState = _.parseBool(options.acceptStageState, false);
        this.zip = options.zip || null;
        this.stageZip = options.stageZip || null;
        this.acceptStageZip = _.parseBool(options.acceptStageZip, false);
        this.sequence = _.parseNumber(options.sequence, 0);
        this.stageSequence = _.parseNumber(options.stageSequence, 0);
        this.acceptStageSequence = _.parseBool(options.acceptStageSequence, false);                

        this.isNewProperty = _.parseBool(options.isNewProperty, false);
    }

    get finalLegalDescription(){
        return this.acceptStageLegalDescription ? this.stageLegalDescription : this.legalDescription;
    }
    
    get finalAcreage(){
        return this.acceptStageAcreage ? this.stageAcreage : this.acreage;
    }
    
    get finalAddress(){
        return this.acceptStageAddress ? this.stageAddress : this.address;
    }
    
    get finalAddress1(){
        return this.acceptStageAddress1 ? this.stageAddress1 : this.address1;
    }

    get finalAddress2(){
        return this.acceptStageAddress2 ? this.stageAddress2 : this.address2;
    }
    
    get finalCity(){
        return this.acceptStageCity ? this.stageCity : this.city;
    }
    
    get finalCountyID(){
        return this.acceptStageCountyID ? this.stageCountyID : this.countyID;
    }
    
    get finalCountyName(){
        return this.acceptStageCountyName ? this.stageCountyName : this.countyName;
    }
    
    get finalState(){
        return this.acceptStageState ? this.stageState : this.state;
    }
    
    get finalZip(){
        return this.acceptStageZip ? this.stageZip : this.zip;
    }
    
    get finalSequence(){
        return this.acceptStageSequence ? this.stageSequence : this.sequence;
    }    
}

export class TitleProductionDataMapper{
    constructor(options){
        options = options || {};

        this.processHeader = false;
        this.processDetail = false;
        this.processAssignedLanguage = false;
        this.commitmentPolicyHeaderDataMapping = new CommitmentPolicyHeaderDataMapper(options.commitmentPolicyHeaderDataMapping);
        this.commitmentPolicyDetailDataMapping = _.map(options.commitmentPolicyDetailDataMapping, m => new CommitmentPolicyDetailDataMapper(m));
        this.pendingPolicyAssignedLanguageDataMapping = options.pendingPolicyAssignedLanguageDataMapping;
        this.originalAssignedLanguage = options.originalAssignedLanguage;
    }
}

export class CommitmentPolicyHeaderDataMapper {
    constructor(options){
        options = options || {};
        this.pcLogID = _.parseNumber(options.pcLogID, 0);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.stageOrdersID = _.parseNumber(options.stageOrdersID, 0);
        
        this.commitmentPolicyHeaderID = _.parseNumber(options.commitmentPolicyHeaderID, 0);
	    this.pcStageCommitmentPolicyHeaderID = _.parseNumber(options.pcStageCommitmentPolicyHeaderID, 0);

        this.commitmentNumber = _.parseNumber(options.commitmentNumber, 0);
        this.stageCommitmentNumber = _.parseNumber(options.stageCommitmentNumber, 0);
        this.acceptStageCommitmentNumber = _.parseBool(options.acceptStageCommitmentNumber, false);

        this.binderStrike1A  = options.binderStrike1A || null;
        this.stagebinderStrike1A = options.stagebinderStrike1A || null;
        this.acceptStagebinderStrike1A = _.parseBool(options.acceptStagebinderStrike1A, false);

        this.effectiveDate = options.effectiveDate || null;
        this.stageEffectiveDate = options.stageEffectiveDate || null;
        this.acceptStageEffectiveDate = _.parseBool(options.acceptStageEffectiveDate, false);

        this.issueDate = options.issueDate || null;
        this.stageIssueDate = options.stageIssueDate || null;
        this.acceptStageIssueDate = _.parseBool(options.acceptStageIssueDate, false);

        this.binderDate = options.binderDate || null;
        this.stageBinderDate = options.stageBinderDate || null;
        this.acceptStageBinderDate = _.parseBool(options.acceptStageBinderDate, false);

        this.binderNumber = _.parseNumber(options.binderNumber, 0);
        this.stageBinderNumber = _.parseNumber(options.stageBinderNumber, 0);
        this.acceptStageBinderNumber = _.parseBool(options.acceptStageBinderNumber, false);

        this.ownerInsured = options.ownerInsured || null;
        this.ownerInsuredText = options.ownerInsuredText || null;
        this.stageOwnerInsured = options.stageOwnerInsured || null;
        this.stageOwnerInsuredText = options.stageOwnerInsuredText || null;
        this.acceptStageOwnerInsured = _.parseBool(options.acceptStageOwnerInsured, false);

        this.ownerInsuredOverride = _.parseBool(options.ownerInsuredOverride, false);
        this.stageOwnerInsuredOverride = _.parseBool(options.stageOwnerInsuredOverride, false);
        this.acceptStageOwnerInsuredOverride = _.parseBool(options.acceptStageOwnerInsuredOverride, false);

        this.currentHolders = options.currentHolders || null;
        this.currentHoldersText = options.currentHoldersText || null;
        this.stageCurrentHolders = options.stageCurrentHolders || null;
        this.stageCurrentHoldersText = options.stageCurrentHoldersText || null;
        this.acceptStageCurrentHolders = _.parseBool(options.acceptStageCurrentHolders, false);

        this.currentHoldersOverride = _.parseBool(options.currentHoldersOverride, false);
        this.stageCurrentHoldersOverride = _.parseBool(options.stageCurrentHoldersOverride, false);
        this.acceptStageCurrentHoldersOverride = _.parseBool(options.acceptStageCurrentHoldersOverride, false);

        this.interestInLand = options.interestInLand || null;
        this.interestInLandText = options.interestInLandText || null;
        this.stageInterestInLand = options.stageInterestInLand || null;
        this.stageInterestInLandText = options.stageInterestInLandText || null
        this.acceptStageInterestInLand = _.parseBool(options.acceptStageInterestInLand, false);

        this.interestInLandOverride = _.parseBool(options.interestInLandOverride, false);
        this.stageInterestInLandOverride = _.parseBool(options.stageInterestInLandOverride, false);
        this.acceptStageInterestInLandOverride = _.parseBool(options.acceptStageInterestInLandOverride, false);

        this.countyID = _.parseNumber(options.countyID, 0);
        this.stageCountyID = _.parseNumber(options.stageCountyID, 0);
        this.acceptStageCountyID = _.parseBool(options.acceptStageCountyID, false);

        this.propertyTypeID = _.parseNumber(options.propertyTypeID, 0);
        this.stagePropertyTypeID  = _.parseNumber(options.stagePropertyTypeID, 0);
        this.acceptStagePropertyTypeID = _.parseBool(options.acceptStagePropertyTypeID, false);

        this.signatory = _.parseNumber(options.signatory, 0);
        this.stageSignatory = _.parseNumber(options.stageSignatory, 0);
        this.acceptStageSignatory = _.parseBool(options.acceptStageSignatory, false);

        this.standbyYear = _.parseNumber(options.standbyYear, 0);
        this.stageStandbyYear = _.parseNumber(options.stageStandbyYear, 0);
        this.acceptStageStandbyYear = _.parseBool(options.acceptStageStandbyYear, false);

        this.exceptions = options.exceptions || null;
        this.stageExceptions = options.stageExceptions || null;
        this.acceptStageExceptions = _.parseBool(options.acceptStageExceptions, false);

        this.ownerPolicyNumber = options.ownerPolicyNumber || null;
        this.stageOwnerPolicyNumber = options.stageOwnerPolicyNumber || null;
        this.acceptStageOwnerPolicyNumber = _.parseBool(options.acceptStageOwnerPolicyNumber, false);

        this.ownerPolicyNumberPoolID = _.parseNumber(options.ownerPolicyNumberPoolID, 0);
        this.stageOwnerPolicyNumberPoolID = _.parseNumber(options.stageOwnerPolicyNumberPoolID, 0);
        this.acceptStageOwnerPolicyNumberPoolID = _.parseBool(options.acceptStageOwnerPolicyNumberPoolID, false);

        this.ownerPolicyDate = options.ownerPolicyDate || null;
        this.stageOwnerPolicyDate = options.stageOwnerPolicyDate || null;
        this.acceptStageOwnerPolicyDate = _.parseBool(options.acceptStageOwnerPolicyDate, false);

        this.ownerRemitDate = options.ownerRemitDate || null;
        this.stageOwnerRemitDate = options.stageOwnerRemitDate || null;
        this.acceptStageOwnerRemitDate = _.parseBool(options.acceptStageOwnerRemitDate, false);

        this.ownerRemitDateLock = _.parseBool(options.ownerRemitDateLock, false);
        this.stageOwnerRemitDateLock = _.parseBool(options.stageOwnerRemitDateLock, false);
        this.acceptStageOwnerRemitDateLock = _.parseBool(options.acceptStageOwnerRemitDateLock, false);

        this.ownerScheduleB = options.ownerScheduleB || null;
        this.stageOwnerScheduleB = options.stageOwnerScheduleB || null;
        this.acceptStageOwnerScheduleB = _.parseBool(options.acceptStageOwnerScheduleB, false);

        this.ownerScheduleBOverride = _.parseBool(options.ownerScheduleBOverride, false);
        this.stageOwnerScheduleBOverride = _.parseBool(options.stageOwnerScheduleBOverride, false);
        this.acceptStageOwnerScheduleBOverride = _.parseBool(options.acceptStageOwnerScheduleBOverride, false);

        this.ownerStrikeException2 = _.parseNumber(options.ownerStrikeException2, 0);
        this.stageOwnerStrikeException2 =  _.parseNumber(options.stageOwnerStrikeException2, 0);
        this.acceptStageOwnerStrikeException2 = _.parseBool(options.acceptStageOwnerStrikeException2, false);

        this.strikeO1 = _.parseBool(options.strikeO1, false);
        this.stageStrikeO1 = _.parseBool(options.stageStrikeO1, false);
        this.acceptStageStrikeO1 = _.parseBool(options.acceptStageStrikeO1, false);

        this.strikeO2 = _.parseBool(options.strikeO2, false);
        this.stageStrikeO2 = _.parseBool(options.stageStrikeO2, false);
        this.acceptStageStrikeO2 = _.parseBool(options.acceptStageStrikeO2, false);

        this.strikeO3 = _.parseBool(options.strikeO3, false);
        this.stageStrikeO3 = _.parseBool(options.stageStrikeO3, false);
        this.acceptStageStrikeO3 = _.parseBool(options.acceptStageStrikeO3, false);

        this.strikeO4 = _.parseBool(options.strikeO4, false);
        this.stageStrikeO4 = _.parseBool(options.stageStrike4, false);
        this.acceptStageStrikeO4 = _.parseBool(options.acceptStageStrikeO4, false);

        this.strikeO5 = _.parseBool(options.strikeO5, false);
        this.stageStrikeO5 = _.parseBool(options.stageStrikeO5, false);
        this.acceptStageStrikeO5 = _.parseBool(options.acceptStageStrikeO5, false);

        this.strikeO6 = _.parseBool(options.strikeO6, false);
        this.stageStrikeO6 = _.parseBool(options.stageStrikeO6, false);
        this.acceptStageStrikeO6 = _.parseBool(options.acceptStageStrke06, false);

        this.strikeO7 = _.parseBool(options.strikeO7, false);
        this.stageStrikeO7 = _.parseBool(options.stageStrikeO7, false);
        this.acceptStageStrikeO7 = _.parseBool(options.acceptStageStrikeO7, false);

        this.strikeO8 = _.parseBool(options.strikeO8, false);
        this.stageStrikeO8 = _.parseBool(options.stageStrikeO8, false);
        this.acceptStageStrikeO8 = _.parseBool(options.acceptStageStrikeO8, false);

        this.strikeO9 = _.parseBool(options.strikeO9, false);
        this.stageStrikeO9 = _.parseBool(options.stageStrikeO9, false);
        this.acceptStageStrikeO9 = _.parseBool(options.acceptStageStrikeO9, false);
    }

    get finalCommitmentNumber(){
        return this.acceptStageCommitmentNumber ? this.stageCommitmentNumber : this.commitmentNumber;
    }

    get finalbinderStrike1A(){
        let date = this.acceptStagebinderStrike1A ? this.stagebinderStrike1A : this.binderStrike1A;
        return this.formatDate(date);
    }

    get displayStageEffectiveDate(){
        return this.formatDate(this.stageEffectiveDate);
    }
    
    get finalEffectiveDate(){
        let date = this.acceptStageEffectiveDate ? this.stageEffectiveDate : this.effectiveDate;
        return this.formatDate(date);
    }

    get finalIssueDate(){
        let date = this.acceptStageIssueDate ? this.stageIssueDate : this.issueDate;
        return this.formatDate(date);
    }

    get finalBinderDate(){
        let date = this.acceptStageBinderDate ? this.stageBinderDate : this.binderDate;
        return this.formatDate(date);
    }

    get finalBinderNumber(){
        return this.acceptStageBinderNumber ? this.stageBinderNumber : this.binderNumber;
    }

    get finalOwnerInsured(){
        return this.acceptStageOwnerInsured ? this.stageOwnerInsuredText : this.ownerInsuredText;
    }

    get finalOwnerInsuredOverride(){
        return this.acceptStageOwnerInsuredOverride ? this.stageOwnerInsuredOverride : this.ownerInsuredOverride;
    }

    get finalCurrentHolders(){
        return this.acceptStageCurrentHolders ? this.stageCurrentHoldersText : this.currentHoldersText;
    }

    get finalCurrentHoldersOverride(){
        return this.acceptStageCurrentHoldersOverride ? this.stageCurrentHoldersOverride : this.currentHoldersOverride;
    }

    get finalInterestInLand(){
        return this.acceptStageInterestInLand ? this.stageInterestInLandText : this.interestInLandText;
    }

    get finalInterestInLandOverride(){
        return this.acceptStageInterestInLandOverride ? this.stageInterestInLandOverride : this.interestInLandOverride;
    }

    get finalCountyID(){
        return this.acceptStageCountyID ? this.stageCountyID : this.countyID;
    }

    get finalPropertyTypeID(){
        return this.acceptStagePropertyTypeID ? this.stagePropertyTypeID : this.propertyTypeID;
    }
    
    get finalSignatory(){
        return this.acceptStageSignatory ? this.stageSignatory : this.signatory;
    }

    get finalStandbyYear(){
        return this.acceptStageStandbyYear ? this.stageStandbyYear : this.standbyYear;
    }

    get finalExceptions(){
        return this.acceptStageExceptions ? this.stageExceptions : this.exceptions;
    }

    get finalOwnerPolicyNumber(){
        return this.acceptStageOwnerPolicyNumber ? this.stageOwnerPolicyNumber : this.ownerPolicyNumber;
    }

    get finalOwnerPolicyNumberPoolID(){
        return this.acceptStageOwnerPolicyNumberPoolID ? this.stageOwnerPolicyNumberPoolID : this.ownerPolicyNumberPoolID;
    }

    get finalOwnerPolicyDate(){
        let date = this.acceptStageOwnerPolicyDate ? this.stageOwnerPolicyDate : this.ownerPolicyDate;
        return this.formatDate(date);
    }

    get finalOwnerRemitDate(){
        let date = this.acceptStageOwnerRemitDate ? this.stageOwnerRemitDate : this.ownerRemitDate;
        return this.formatDate(date);
    }

    get finalOwnerRemitDateLock(){
        return this.acceptStageOwnerRemitDateLock ? this.stageOwnerRemitDateLock : this.ownerRemitDateLock;
    }

    get finalOwnerScheduleB(){
        return this.acceptStageOwnerScheduleB ? this.stageOwnerScheduleB : this.ownerScheduleB;
    }

    get finalOwnerScheduleBOverride(){
        return this.acceptStageOwnerScheduleBOverride ? this.stageOwnerScheduleBOverride : this.ownerScheduleBOverride;
    }

    get finalStrikeException2(){
        return this.acceptStageOwnerStrikeException2 ? this.stageOwnerStrikeException2 : this.ownerStrikeException2;
    }

    get finalStrikeO1(){
        return this.acceptStageStrikeO1 ? this.stageStrikeO1 : this.strikeO1;
    }
    
    get finalStrikeO2(){
        return this.acceptStageStrikeO2 ? this.stageStrikeO2 : this.strikeO2;
    }

    get finalStrikeO3(){
        return this.acceptStageStrikeO3 ? this.stageStrikeO3 : this.strikeO3;
    }

    get finalStrikeO4(){
        return this.acceptStageStrikeO4 ? this.stageStrikeO4 : this.strikeO4;
    }

    get finalStrikeO5(){
        return this.acceptStageStrikeO5 ? this.stageStrikeO5 : this.strikeO5;
    }

    get finalStrikeO6(){
        return this.acceptStageStrikeO6 ? this.stageStrikeO6 : this.strikeO6;
    }

    get finalStrikeO7(){
        return this.acceptStageStrikeO7 ? this.stageStrikeO7 : this.strikeO7;
    }

    get finalStrikeO8(){
        return this.acceptStageStrikeO8 ? this.stageStrikeO8 : this.strikeO8;
    }

    get finalStrikeO9(){
        return this.acceptStageStrikeO9 ? this.stageStrikeO9 : this.strikeO9;
    }

    formatDate(date) {
        if(_.isEmpty(date)) {
            return "";
        }

        try {
            let dt = DateTimeHelper.getDateFromString(date);
            return dt.isValid ? dt.toFormat('MM/dd/yyyy') : "";
        } 
        catch (error) {
            return "";
        }
    }
}

export class CommitmentPolicyDetailDataMapper {
    constructor(options) { 
        options = options || {};
        this.pcStageCommitmentPolicyDetailID = _.parseNumber(options.pcStageCommitmentPolicyDetailID, null);
	    this.pcStageCommitmentPolicyHeaderID = _.parseNumber(options.pcStageCommitmentPolicyHeaderID, null);
        this.pcStageLoanID = _.parseNumber(options.pcStageLoanID, null);
        this.commitmentPolicyDetailID = _.parseNumber(options.commitmentPolicyDetailID, null);

        this.remitDateLock = _.parseBool(options.remitDateLock, false);
        this.stageRemitDateLock = _.parseBool(options.stageRemitDateLock, false);
        this.acceptStageRemitDateLock = _.parseBool(options.acceptStageRemitDateLock, false);

        this.binderStrike1A  = _.parseBool(options.binderStrike1A, false);
        this.stageBinderStrike1A = _.parseBool(options.stageBinderStrike1A, false);
        this.acceptStageBinderStrike1A = _.parseBool(options.acceptStageBinderStrike1A, false);

        this.binderB13Strike = _.parseBool(options.binderB13Strike, false);
        this.stageBinderB13Strike = _.parseBool(options.stageBinderB13Strike, false);
        this.acceptStageBinderB13Strike = _.parseBool(options.acceptStageBinderB13Strike, false);

        this.binderB13TaxLanguage = _.parseBool(options.binderB13TaxLanguage, false);
        this.stageBinderB13TaxLanguage = _.parseBool(options.stageBinderB13TaxLanguage, false);
        this.acceptStageBinderB13TaxLanguage = _.parseBool(options.acceptStageBinderB13TaxLanguage, false);

        this.mortgageInsured = options.mortgageInsured || null;
        this.mortgageInsuredText = options.mortgageInsuredText || null;
        this.stageMortgageInsured = options.stageMortgageInsured || null;
        this.stageMortgageInsuredText = options.stageMortgageInsuredText || null;
        this.acceptStageMortgageInsured = _.parseBool(options.acceptStageMortgageInsured, false);

        this.mortgageInsuredOverride = _.parseBool(options.mortgageInsuredOverride, false);
        this.stageMortgageInsuredOverride = _.parseBool(options.stageMortgageInsuredOverride, false);
        this.acceptStageMortgageInsuredOverride = _.parseBool(options.acceptStageMortgageInsuredOverride, false);

        this.mortgageBorrower = options.mortgageBorrower || null;
        this.mortgageBorrowerText = options.mortgageBorrowerText || null;
        this.stageMortgageBorrower = options.stageMortgageBorrower || null;
        this.stageMortgageBorrowerText = options.stageMortgageBorrowerText || null;
        this.acceptStageMortgageBorrower = _.parseBool(options.acceptStageMortgageBorrower, false);

        this.mortgageBorrowerOverride = _.parseBool(options.mortgageBorrowerOverride, false);
        this.stageMortgageBorrowerOverride = _.parseBool(options.stageMortgageBorrowerOverride, false);
        this.acceptStageMortgageBorrowerOverride = _.parseBool(options.acceptStageMortgageBorrowerOverride, false);

        this.constructionInsured = options.constructionInsured || null;
        this.constructionInsuredText = options.constructionInsuredText || null;
        this.stageConstructionInsured = options.stageConstructionInsured || null;
        this.stageConstructionInsuredText = options.stageConstructionInsuredText || null;
        this.acceptStageConstructionInsured = _.parseBool(options.acceptStageConstructionInsured, false);

        this.constructionInsuredOverride = _.parseBool(options.constructionInsuredOverride, false);
        this.stageConstructionInsuredOverride = _.parseBool(options.stageConstructionInsuredOverride, false);
        this.acceptStageConstructionInsuredOverride = _.parseBool(options.acceptStageConstructionInsuredOverride, false);

        this.constructionBorrower = options.constructionBorrower || null;
        this.constructionBorrowerText = options.constructionBorrowerText || null;
        this.stageConstructionBorrower = options.stageConstructionBorrower || null;
        this.stageConstructionBorrowerText = options.stageConstructionBorrowerText || null;
        this.acceptStageConstructionBorrower = _.parseBool(options.acceptStageConstructionBorrower, false);

        this.constructionBorrowerOverride = _.parseBool(options.constructionBorrowerOverride, false);
        this.stageConstructionBorrowerOverride = _.parseBool(options.stageConstructionBorrowerOverride, false);
        this.acceptStageConstructionBorrowerOverride = _.parseBool(options.acceptStageConstructionBorrowerOverride, false);

        this.mortgageScheduleB = options.mortgageScheduleB || null;
        this.stageMortgageScheduleB = options.stageMortgageScheduleB || null;
        this.acceptStageMortgageScheduleB = _.parseBool(options.acceptStageMortgageScheduleB, false);

        this.mortgageScheduleBOverride = _.parseBool(options.mortgageScheduleBOverride, false);
        this.stageMortgageScheduleBOverride = _.parseBool(options.stageMortgageScheduleBOverride, false);
        this.acceptStageMortgageScheduleBOverride = _.parseBool(options.acceptStageMortgageScheduleBOverride, false);

        this.mortgageScheduleB2Override = _.parseBool(options.mortgageScheduleB2Override, false);
        this.stageMortgageScheduleB2Override = _.parseBool(options.stageMortgageScheduleB2Override, false);
        this.acceptStageMortgageScheduleB2Override = _.parseBool(options.acceptStageMortgageScheduleB2Override, false);

        this.insuredMortgageOverride = _.parseBool(options.insuredMortgageOverride, false);
        this.stageInsuredMortgageOverride = _.parseBool(options.stageInsuredMortgageOverride, false);
        this.acceptStageInsuredMortgageOverride = _.parseBool(options.acceptStageInsuredMortgageOverride, false);

        this.titleVestedIn = options.titleVestedIn || null;
        this.titleVestedInText = options.titleVestedInText || null;
        this.stageTitleVestedIn = options.stageTitleVestedIn || null;
        this.stageTitleVestedInText = options.stageTitleVestedInText || null;
        this.acceptStageTitleVestedIn = _.parseBool(options.acceptStageTitleVestedIn, false);

        this.titleVestedInOverride = _.parseBool(options.titleVestedInOverride, false);
        this.stageTitleVestedInOverride = _.parseBool(options.stageTitleVestedInOverride, false);
        this.acceptStageTitleVestedInOverride = _.parseBool(options.acceptStageTitleVestedInOverride, false);

        this.legalDescription = options.legalDescription || null;
        this.legalDescriptionText = options.legalDescriptionText || null;
        this.stageLegalDescription = options.stageLegalDescription || null;
        this.stageLegalDescriptionText = options.stageLegalDescriptionText || null
        this.acceptStageLegalDescription = _.parseBool(options.acceptStageLegalDescription, false);

        this.strikeMortgageB2 = _.parseBool(options.strikeMortgageB2, false);
        this.stageStrikeMortgageB2 = _.parseBool(options.stageStrikeMortgageB2, false);
        this.acceptStageStrikeMortgageB2 = _.parseBool(options.acceptStageStrikeMortgageB2, false);

        this.strikeEOMB3 = _.parseBool(options.strikeEOMB3, false);
        this.stageStrikeEOMB3 = _.parseBool(options.stageStrikeEOMB3, false);
        this.acceptStageStrikeEOMB3 = _.parseBool(options.acceptStageStrikeEOMB3, false);

        this.addStandyB3 = _.parseBool(options.addStandyB3, false);
        this.stageAddStandyB3 = _.parseBool(options.stageAddStandyB3, false);
        this.acceptStageAddStandyB3 = _.parseBool(options.acceptStageAddStandyB3, false);

        this.strikeMortgageB4 = _.parseBool(options.strikeMortgageB4, false);
        this.stageStrikeMortgageB4 = _.parseBool(options.stageStrikeMortgageB4, false);
        this.acceptStageStrikeMortgageB4 = _.parseBool(options.acceptStageStrikeMortgageB4, false);

        this.noSecondLien = _.parseBool(options.noSecondLien, false);
        this.stageNoSecondLien = _.parseBool(options.stageNoSecondLien, false);
        this.acceptStageNoSecondLien = _.parseBool(options.acceptStageNoSecondLien, false);

        this.strikeMortgageB42 = _.parseBool(options.strikeMortgageB42, false);
        this.stageStrikeMortgageB42 = _.parseBool(options.stageStrikeMortgageB42, false);
        this.acceptStageStrikeMortgageB42 = _.parseBool(options.acceptStageStrikeMortgageB42, false);

        this.strikeMortgagee1 = _.parseBool(options.strikeMortgagee1, false);
        this.stageStrikeMortgagee1 = _.parseBool(options.stageStrikeMortgagee1, false);
        this.acceptStageStrikeMortgagee1 = _.parseBool(options.acceptStageStrikeMortgagee1, false);

        this.strikeMortgagee2 = _.parseBool(options.strikeMortgagee2, false);
        this.stageStrikeMortgagee2 = _.parseBool(options.stageStrikeMortgagee2, false);
        this.acceptStageStrikeMortgagee2 = _.parseBool(options.acceptStageStrikeMortgagee2, false);

        this.strikeMortgagee3 = _.parseBool(options.strikeMortgagee3, false);
        this.stageStrikeMortgagee3 = _.parseBool(options.stageStrikeMortgagee3, false);
        this.acceptStageStrikeMortgagee3 = _.parseBool(options.acceptStageStrikeMortgagee3, false);

        this.strikeMortgagee4 = _.parseBool(options.strikeMortgagee4, false);
        this.stageStrikeMortgagee4 = _.parseBool(options.stageStrikeMortgagee4, false);
        this.acceptStageStrikeMortgagee4 = _.parseBool(options.acceptStageStrikeMortgagee4, false);

        this.strikeMortgagee5 = _.parseBool(options.strikeMortgagee5, false);
        this.stageStrikeMortgagee5 = _.parseBool(options.stageStrikeMortgagee5, false);
        this.acceptStageStrikeMortgagee5 = _.parseBool(options.acceptStageStrikeMortgagee5, false);

        this.strikeMortgagee6 = _.parseBool(options.strikeMortgagee6, false);
        this.stageStrikeMortgagee6 = _.parseBool(options.stageStrikeMortgage6, false);
        this.acceptStageStrikeMortgagee6 = _.parseBool(options.acceptStageStrikeMortgagee6, false);

        this.strikeMortgagee7 = _.parseBool(options.strikeMortgagee7, false);
        this.stageStrikeMortgagee7 = _.parseBool(options.stageStrikeMortgagee7, false);
        this.acceptStageStrikeMortgagee7 = _.parseBool(options.acceptStageStrikeMortgagee7, false);

        this.strikeMortgagee8 = _.parseBool(options.strikeMortgagee8, false);
        this.stageStrikeMortgagee8 = _.parseBool(options.stageStrikeMortgagee8, false);
        this.acceptStageStrikeMortgagee8 = _.parseBool(options.acceptStageStrikeMortgagee8, false);

        this.strikeMortgagee9 = _.parseBool(options.strikeMortgagee9, false);
        this.stageStrikeMortgagee9 = _.parseBool(options.stageStrikeMortgagee9, false);
        this.acceptStageStrikeMortgagee9 = _.parseBool(options.acceptStageStrikeMortgagee9, false);
    }

    get finalRemitDateLock(){
        return this.acceptStageRemitDateLock ? this.stageRemitDateLock : this.remitDateLock;
    }

    get finalbinderStrike1A(){
        return this.acceptStagebinderStrike1A ? this.stagebinderStrike1A : this.binderStrike1A;
    }

    get finalBinderB13Strike(){
        return this.acceptStageBinderB13Strike ? this.stageBinderB13Strike : this.binderB13Strike;
    }

    get finalBinderB13TaxLanguage(){
        return this.acceptStageBinderB13TaxLanguage ? this.stageBinderB13TaxLanguage : this.binderB13TaxLanguage;
    }

    get finalMortgageInsured(){
        return this.acceptStageMortgageInsured ? this.stageMortgageInsuredText : this.mortgageInsuredText;
    }

    get finalMortgageInsuredOverride(){
        return this.acceptStageMortgageInsuredOverride ? this.stageMortgageInsuredOverride : this.mortgageInsuredOverride;
    }

    get finalMortgageBorrower(){
        return this.acceptStageMortgageBorrower ? this.stageMortgageBorrowerText : this.mortgageBorrowerText;
    }

    get finalMortgageBorrowerOverride(){
        return this.acceptStageMortgageBorrowerOverride ? this.stageMortgageBorrowerOverride : this.mortgageBorrowerOverride;
    }

    get finalConstructionInsured(){
        return this.acceptStageConstructionInsured ? this.stageConstructionInsuredText : this.constructionInsuredText;
    }

    get finalConstructionInsuredOverride(){
        return this.acceptStageConstructionInsuredOverride ? this.stageConstructionInsuredOverride : this.constructionInsuredOverride;
    }

    get finalConstructionBorrower(){
        return this.acceptStageConstructionBorrower ? this.stageConstructionBorrowerText : this.constructionBorrowerText;
    }

    get finalConstructionBorrowerOverride(){
        return this.acceptStageConstructionBorrowerOverride ? this.stageConstructionBorrowerOverride : this.constructionBorrowerOverride;
    }

    get finalMortgageScheduleB(){
        return this.acceptStageMortgageScheduleB ? this.stageMortgageScheduleB : this.mortgageScheduleB;
    }

    get finalMortgageScheduleBOverride(){
        return this.acceptStageMortgageScheduleBOverride ? this.stageMortgageScheduleBOverride : this.mortgageScheduleBOverride;
    }

    get finalMortgageScheduleB2Override(){
        return this.acceptStageMortgageScheduleB2Override ? this.stageMortgageScheduleB2Override : this.mortgageScheduleB2Override;
    }

    get finalInsuredMortgageOverride(){
        return this.acceptStageInsuredMortgageOverride ? this.stageInsuredMortgageOverride : this.insuredMortgageOverride;
    }

    get finalTitleVestedIn(){
        return this.acceptStageTitleVestedIn ? this.stageTitleVestedInText : this.titleVestedInText;
    }

    get finalTitleVestedInOverride(){
        return this.acceptStageTitleVestedInOverride ? this.stageTitleVestedInOverride : this.titleVestedInOverride;
    }

    get finalLegalDescription(){
        return this.acceptStageLegalDescription ? this.stageLegalDescriptionText : this.legalDescriptionText;
    }

    get finalStrikeMortgageB2(){
        return this.acceptStageStrikeMortgageB2 ? this.stageStrikeMortgageB2 : this.strikeMortgageB2;
    }

    get finalStrikeEOMB3(){
        return this.acceptStageStrikeEOMB3 ? this.stageStrikeEOMB3 : this.strikeEOMB3;
    }
    
    get finalAddStandyB3(){
        return this.acceptStageAddStandyB3 ? this.stageAddStandyB3 : this.addStandyB3;
    }

    get finalStrikeMortgageB4(){
        return this.acceptStageStrikeMortgageB4 ? this.stageStrikeMortgageB4 : this.strikeMortgageB4;
    }

    get finalNoSecondLien(){
        return this.acceptStageNoSecondLien ? this.stageNoSecondLien : this.noSecondLien;
    }

    get finalStrikeMortgageB42(){
        return this.acceptStageStrikeMortgageB42 ? this.stageStrikeMortgageB42 : this.strikeMortgageB42;
    }

    get finalStrikeMortgagee1(){
        return this.acceptStageStrikeMortgagee1 ? this.stageStrikeMortgagee1 : this.strikeMortgagee1;
    }

    get finalStrikeMortgagee2(){
        return this.acceptStageStrikeMortgagee2 ? this.stageStrikeMortgagee2 : this.strikeMortgagee2;
    }

    get finalStrikeMortgagee3(){
        return this.acceptStageStrikeMortgagee3 ? this.stageStrikeMortgagee3 : this.strikeMortgagee3;
    }

    get finalStrikeMortgagee4(){
        return this.acceptStageStrikeMortgagee4 ? this.stageStrikeMortgagee4 : this.strikeMortgagee4;
    }

    get finalStrikeMortgagee5(){
        return this.acceptStageStrikeMortgagee5 ? this.stageStrikeMortgagee5 : this.strikeMortgagee5;
    }

    get finalStrikeMortgagee6(){
        return this.acceptStageStrikeMortgagee6 ? this.stageStrikeMortgagee6 : this.strikeMortgagee6;
    }

    get finalStrikeMortgagee7(){
        return this.acceptStageStrikeMortgagee7 ? this.stageStrikeMortgagee7 : this.strikeMortgagee7;
    }

    get finalStrikeMortgagee8(){
        return this.acceptStageStrikeMortgagee8 ? this.stageStrikeMortgagee8 : this.strikeMortgagee8;
    }

    get finalStrikeMortgagee9(){
        return this.acceptStageStrikeMortgagee9 ? this.stageStrikeMortgagee9 : this.strikeMortgagee9;
    }    
}

export class AssignedLanguageDataMapper {
    constructor(options, parent = null) {
        options = options || { };
        this.pcStageAssignedLanguageID = _.parseNumber(options.pcStageAssignedLanguageID, null)
        this.pcStageCommitmentPolicyHeaderID = _.parseNumber(options.pcStageCommitmentPolicyHeaderID, null)
        this.pcStageCommitmentPolicyDetailID = _.parseNumber(options.pcStageCommitmentPolicyDetailID, null)
        this.assignedLanguageID = _.parseNumber(options.assignedLanguageID, null)
        this.isSection = _.parseBool(options.isSection, false);
        this.isPendingItem = !_.isNil(this.pcStageAssignedLanguageID);
        this.id = _.parseNumber(_.uniqueId());
        this.parentId = _.isNil(parent) ? null : parent.id;
        this.originalParentId = this.parentId;
        this.sectionId = _.parseNumber(options.sectionID, null);
        this.originalSectionId = _.parseNumber(options.sectionID, null);

        this.asssignedLanguageParentId = _.parseNumber(options.assignedLanguageParentID, null);
        this.code = _.isNil(parent) ? null : options.code || null;
        this.description = options.description || null;
        this.appliesToType = _.isNil(parent) ? null : options.appliesToType;
        this.listType = _.parseNumber(options.listType, null);
        this.originalListType = _.parseNumber(options.listType, null);
        this.ordinal = _.parseNumber(options.ordinal, null);
        this.rtfText = options.rtfText || null;
        this.bulletCharacter = options.bulletCharacter || null;
        this.bulletSize = _.parseNumber(options.bulletSize, null);
        this.charBeforeNumber = options.charBeforeNumber || null;
        this.charAfterNumber = options.charAfterNumber || null;
        this.firstNumber = _.parseNumber(options.firstNumber, null);
        this.formatCharacter = _.parseNumber(options.formatCharacter, null);
        this.hangingIndent = _.parseNumber(options.hangingIndent, null);
        this.leftIndent = _.parseNumber(options.leftIndent, null);
        this.level = _.parseNumber(options.level, null);
        this.numberFormat = _.parseNumber(options.numberFormat, null);
        this.restartNumbering = _.parseBool(options.restartNumbering, false);
        this.textAfterNumber = options.textAfterNumber || null;
        this.textBeforeNumber = options.textBeforeNumber || null;
        this.textControlListType = _.parseNumber(options.textControlListType, null);
        this.overrideListFormat = _.parseBool(options.overrideListFormat, false);
    }

    get descriptionDisplay() {
        if (this.isSection) {
            return AssignedLanguageListType.displayValue(this.listType);
        }
        else return this.description;
    }

    get appliesToDisplay(){
        return AppliesToType.displayValue(this.appliesToType);
    }
}

export class BuyerSellerDataMapper {
    constructor(options){
        options = options || {};

        this.pcLogID = _.parseNumber(options.pcLogID, 0);
        this.buyerSellerID = _.parseNumber(options.buyerSellerID, 0);
        this.stageBuyerSellerID = _.parseNumber(options.stageBuyerSellerID, 0);
        this.stageOrdersID = _.parseNumber(options.stageOrdersID, 0);
        this.buyerSellerType = _.parseNumber(options.buyerSellerType, 0);            
        this.formalName = options.formalName || null;

        // this.attorneyAddress = options.attorneyAddress || "";
        // this.stageAttorneyAddress = options.stageAttorneyAddress || "";
        // this.acceptStageAttorneyAddress = _.parseBool(options.acceptStageAttorneyAddress, false);
        
        // this.attorneyCity = options.attorneyCity || "";
        // this.stageAttorneyCity = options.stageAttorneyCity || "";
        // this.acceptStageAttorneyCity = _.parseBool(options.acceptStageAttorneyCity, false);
        
        // this.attorneyCompanyID	= _.parseNumber(options.attorneyCompanyID, 0);
        // this.stageAttorneyCompanyID	= _.parseNumber(stageOptions.attorneyCompanyID, 0);
        // this.acceptStageAttorneyCompanyID = _.parseBool(options.acceptStageAttorneyCompanyID, false);
        
        // this.attorneyCompanyName = options.attorneyCompanyName || "";
        // this.stageAttorneyCompanyName = options.stageAttorneyCompanyName || "";
        // this.acceptStageAttorneyCompanyName = _.parseBool(options.acceptStageAttorneyCompanyName, false);
        
        // this.attorneyContactID	= _.parseNumber(options.attorneyContactID, 0);
        // this.stageAttorneyContactID	= _.parseNumber(stageOptions.attorneyContactID, 0);
        // this.acceptStageAttorneyContactID = _.parseBool(options.acceptStageAttorneyContactID, false);
        
        // this.attorneyContactName = options.attorneyContactName || "";
        // this.stageAttorneyContactName = options.stageAttorneyContactName || "";
        // this.acceptStageAttorneyContactName = _.parseBool(options.acceptStageAttorneyContactName, false);
        
        // this.attorneyEmail = options.attorneyEmail || "";
        // this.stageAttorneyEmail = options.stageAttorneyEmail || "";
        // this.acceptStageAttorneyEmail = _.parseBool(options.acceptStageAttorneyEmail, false);
        
        // this.attorneyFax = options.attorneyFax || "";
        // this.stageAttorneyFax = options.stageAttorneyFax || "";
        // this.acceptStageAttorneyFax = _.parseBool(options.acceptStageAttorneyFax, false);
        
        // this.attorneyPhone = options.attorneyPhone || "";
        // this.stageAttorneyPhone = options.stageAttorneyPhone || "";
        // this.acceptStageAttorneyPhone = _.parseBool(options.acceptStageAttorneyPhone, false);
        
        // this.attorneyState = options.attorneyState || "";
        // this.stageAttorneyState = options.stageAttorneyState || "";
        // this.acceptStageAttorneyState = _.parseBool(options.acceptStageAttorneyState, false);
        
        // this.attorneyZip = options.attorneyZip || "";
        // this.stageAttorneyZip = options.stageAttorneyZip || "";
        // this.acceptStageAttorneyZip = _.parseBool(options.acceptStageAttorneyZip, false);
        
        this.businessName = options.businessName || null;
        this.stageBusinessName = options.stageBusinessName || null;
        this.acceptStageBusinessName = _.parseBool(options.acceptStageBusinessName, false);
        
        // this.businessPhone = options.businessPhone || "";
        // this.stageBusinessPhone = options.stageBusinessPhone || "";
        // this.acceptStageBusinessPhone = _.parseBool(options.acceptStageBusinessPhone, false);
        
        this.buyerSellerRelationshipID	= _.parseNumber(options.buyerSellerRelationshipID, 0);
        this.stageBuyerSellerRelationshipID	= _.parseNumber(options.stageBuyerSellerRelationshipID, 0);
        this.acceptStageBuyerSellerRelationshipID = _.parseBool(options.acceptStageBuyerSellerRelationshipID, false);        
        
        this.cellPhone1 = options.cellPhone1 || null;
        this.stageCellPhone1 = options.stageCellPhone1 || null;
        this.acceptStageCellPhone1 = _.parseBool(options.acceptStageCellPhone1, false);
        
        this.cellPhone2 = options.cellPhone2 || null;
        this.stageCellPhone2 = options.stageCellPhone2 || null;
        this.acceptStageCellPhone2 = _.parseBool(options.acceptStageCellPhone2, false);
        
        this.currentAddress1 = options.currentAddress1 || null;
        this.stageCurrentAddress1 = options.stageCurrentAddress1 || null;
        this.acceptStageCurrentAddress1 = _.parseBool(options.acceptStageCurrentAddress1, false);
        
        this.currentCity = options.currentCity || null;
        this.stageCurrentCity = options.stageCurrentCity || null;
        this.acceptStageCurrentCity = _.parseBool(options.acceptStageCurrentCity, false);
        
        this.currentState = options.currentState || null;
        this.stageCurrentState = options.stageCurrentState || null;
        this.acceptStageCurrentState = _.parseBool(options.acceptStageCurrentState, false);
        
        this.currentZip = options.currentZip || null;
        this.stageCurrentZip = options.stageCurrentZip || null;
        this.acceptStageCurrentZip = _.parseBool(options.acceptStageCurrentZip, false);
        
        this.emailAddress1 = options.emailAddress1 || null;
        this.stageEmailAddress1 = options.stageEmailAddress1 || null;
        this.acceptStageEmailAddress1 = _.parseBool(options.acceptStageEmailAddress1, false);
        
        this.emailAddress2 = options.emailAddress2 || null;
        this.stageEmailAddress2 = options.stageEmailAddress2 || null;
        this.acceptStageEmailAddress2 = _.parseBool(options.acceptStageEmailAddress2, false);
        
        this.extension1 = options.extension1 || null;
        this.stageExtension1 = options.stageExtension1 || null;
        this.acceptStageExtension1 = _.parseBool(options.acceptStageExtension1, false);
        
        this.extension2 = options.extension2 || null;
        this.stageExtension2 = options.stageExtension2 || null;
        this.acceptStageExtension2 = _.parseBool(options.acceptStageExtension2, false);
        
        this.fax1 = options.fax1 || null;
        this.stageFax1 = options.stageFax1 || null;
        this.acceptStageFax1 = _.parseBool(options.acceptStageFax1, false);
        
        this.fax2 = options.fax2 || null;
        this.stageFax2 = options.stageFax2 || null;
        this.acceptStageFax2 = _.parseBool(options.acceptStageFax2, false);
        
        this.firstName1 = options.firstName1 || null;
        this.stageFirstName1 = options.stageFirstName1 || null;
        this.acceptStageFirstName1 = _.parseBool(options.acceptStageFirstName1, false);
        
        this.firstName2 = options.firstName2 || null;
        this.stageFirstName2 = options.stageFirstName2 || null;
        this.acceptStageFirstName2 = _.parseBool(options.acceptStageFirstName2, false);
        
        this.gender1 = _.parseNumber(options.gender1, null);
        this.stageGender1 = _.parseNumber(options.stageGender1, null);
        this.acceptStageGender1 = _.parseBool(options.acceptStageGender1, false);
        
        this.gender2 = _.parseNumber(options.gender2, 0);
        this.stageGender2 = _.parseNumber(options.stageGender2, 0);
        this.acceptStageGender2 = _.parseBool(options.acceptStageGender2, false);
        
        this.homePhone = options.homePhone || null;
        this.stageHomePhone = options.stageHomePhone || null;
        this.acceptStageHomePhone = _.parseBool(options.acceptStageHomePhone, false);
        
        this.homePhone2 = options.homePhone2 || null;
        this.stageHomePhone2 = options.stageHomePhone2 || null;
        this.acceptStageHomePhone2 = _.parseBool(options.acceptStageHomePhone2, false);
        
        this.lastName1 = options.lastName1 || null;
        this.stageLastName1 = options.stageLastName1 || null;
        this.acceptStageLastName1 = _.parseBool(options.acceptStageLastName1, false);
        
        this.lastName2 = options.lastName2 || null;
        this.stageLastName2 = options.stageLastName2 || null;
        this.acceptStageLastName2 = _.parseBool(options.acceptStageLastName2, false);
        
        this.middleInitial1 = options.middleInitial1 || null;
        this.stageMiddleInitial1 = options.stageMiddleInitial1 || null;
        this.acceptStageMiddleInitial1 = _.parseBool(options.acceptStageMiddleInitial1, false);
        
        this.middleInitial2 = options.middleInitial2 || null;
        this.stageMiddleInitial2 = options.stageMiddleInitial2 || null;
        this.acceptStageMiddleInitial2 = _.parseBool(options.acceptStageMiddleInitial2, false);
        
        this.name2Name1Relation	= _.parseNumber(options.name2Name1Relation, 0);
        this.stageName2Name1Relation	= _.parseNumber(options.stageName2Name1Relation, 0);
        this.acceptStageName2Name1Relation = _.parseBool(options.acceptStageName2Name1Relation, false);
        
        // this.realtorAddress = options.realtorAddress || null;
        // this.stageRealtorAddress = options.stageRealtorAddress || null;
        // this.acceptStageRealtorAddress = _.parseBool(options.acceptStageRealtorAddress, false);
        
        // this.realtorCity = options.realtorCity || null;
        // this.stageRealtorCity = options.stageRealtorCity || null;
        // this.acceptStageRealtorCity = _.parseBool(options.acceptStageRealtorCity, false);
        
        // this.realtorCompanyID	= _.parseNumber(options.realtorCompanyID, 0);
        // this.stageRealtorCompanyID	= _.parseNumber(stageOptions.realtorCompanyID, 0);
        // this.acceptStageRealtorCompanyID = _.parseBool(options.acceptStageRealtorCompanyID, false);
        
        // this.realtorCompanyName = options.realtorCompanyName || null;
        // this.stageRealtorCompanyName = options.stageRealtorCompanyName || null;
        // this.acceptStageRealtorCompanyName = _.parseBool(options.acceptStageRealtorCompanyName, false);
        
        // this.realtorContactID	= _.parseNumber(options.realtorContactID, 0);
        // this.stageRealtorContactID	= _.parseNumber(stageOptions.realtorContactID, 0);
        // this.acceptStageRealtorContactID = _.parseBool(options.acceptStageRealtorContactID, false);
        
        // this.realtorContactName = options.realtorContactName || null;
        // this.stageRealtorContactName = options.stageRealtorContactName || null;
        // this.acceptStageRealtorContactName = _.parseBool(options.acceptStageRealtorContactName, false);
        
        // this.realtorEmail = options.realtorEmail || null;
        // this.stageRealtorEmail = options.stageRealtorEmail || null;
        // this.acceptStageRealtorEmail = _.parseBool(options.acceptStageRealtorEmail, false);
        
        // this.realtorFax = options.realtorFax || null;
        // this.stageRealtorFax = options.stageRealtorFax || null;
        // this.acceptStageRealtorFax = _.parseBool(options.acceptStageRealtorFax, false);
        
        // this.realtorPhone = options.realtorPhone || null;
        // this.stageRealtorPhone = options.stageRealtorPhone || null;
        // this.acceptStageRealtorPhone = _.parseBool(options.acceptStageRealtorPhone, false);
        
        // this.realtorState = options.realtorState || null;
        // this.stageRealtorState = options.stageRealtorState || null;
        // this.acceptStageRealtorState = _.parseBool(options.acceptStageRealtorState, false);
        
        // this.realtorZip = options.realtorZip || null;
        // this.stageRealtorZip = options.stageRealtorZip || null;
        // this.acceptStageRealtorZip = _.parseBool(options.acceptStageRealtorZip, false);
        
        // this.socialSecurity2 = options.socialSecurity2 || null;
        // this.stageSocialSecurity2 = options.stageSocialSecurity2 || null;
        // this.acceptStageSocialSecurity2 = _.parseBool(options.acceptStageSocialSecurity2, false);
        
        this.socialSecurityNumber1 = options.socialSecurityNumber1 || null;
        this.stageSocialSecurityNumber1 = options.stageSocialSecurityNumber1 || null;
        this.acceptStageSocialSecurityNumber1 = _.parseBool(options.acceptStageSocialSecurityNumber1, false);
        
        this.socialSecurityNumber2 = options.socialSecurityNumber2 || null;
        this.stageSocialSecurityNumber2 = options.stageSocialSecurityNumber2 || null;
        this.acceptStageSocialSecurityNumber2 = _.parseBool(options.acceptStageSocialSecurityNumber2, false);
        
        this.workPhone2 = options.workPhone2 || null;
        this.stageWorkPhone2 = options.stageWorkPhone2 || null;
        this.acceptStageWorkPhone2 = _.parseBool(options.acceptStageWorkPhone2, false);
                
        this.isNewBuyerSeller = _.parseBool(options.isNewBuyerSeller, false);
    }

    get finalBusinessName(){
        return this.acceptBusinessName ? this.stageBusinessName : this.businessName;
    }
    
    get finalBuyerSellerRelationshipID(){
        return this.acceptStageBuyerSellerRelationshipID ? this.stageBuyerSellerRelationshipID : this.buyerSellerRelationshipID;
    }
    
    get finalCellPhone1(){
        return this.acceptStageCellPhone1 ? this.stageCellPhone1 : this.cellPhone1;
    }
    
    get finalCellPhone2(){
        return this.acceptStageCellPhone2 ? this.stageCellPhone2 : this.cellPhone2;
    }
    
    get finalCurrentAddress1(){
        return this.acceptStageCurrentAddress1 ? this.stageCurrentAddress1 : this.currentAddress1;
    }
    
    get finalCurrentCity(){
        return this.acceptStageCurrentCity ? this.stageCurrentCity : this.currentCity;
    }
    
    get finalCurrentState(){
        return this.acceptStageCurrentState ? this.stageCurrentState : this.currentState;
    }
    
    get finalCurrentZip(){
        return this.acceptStageCurrentZip ? this.stageCurrentZip : this.currentZip;
    }
    
    get finalEmailAddress1(){
        return this.acceptStageEmailAddress1 ? this.stageEmailAddress1 : this.emailAddress1;
    }
    
    get finalEmailAddress2(){
        return this.acceptStageEmailAddress2 ? this.stageEmailAddress2 : this.emailAddress2;
    }
    
    get finalExtension1(){
        return this.acceptStageExtension1 ? this.stageExtension1 : this.extension1;
    }
    
    get finalExtension2(){
        return this.acceptStageExtension2 ? this.stageExtension2 : this.extension2;
    }
    
    get finalFax1(){
        return this.acceptStageFax1 ? this.stageFax1 : this.fax1;
    }
    
    get finalFax2(){
        return this.acceptStageFax2 ? this.stageFax2 : this.fax2;
    }
    
    get finalFirstName1(){
        return this.acceptStageFirstName1 ? this.stageFirstName1 : this.firstName1;
    }
    
    get finalFirstName2(){
        return this.acceptStageFirstName2 ? this.stageFirstName2 : this.firstName2;
    }
    
    get finalGender1(){
        return this.acceptStageGender1 ? this.stageGender1 : this.gender1;
    }
    
    get finalGender2(){
        return this.acceptStageGender2 ? this.stageGender2 : this.gender2;
    }
    
    get finalHomePhone(){
        return this.acceptStageHomePhone ? this.stageHomePhone : this.homePhone;
    }
    
    get finalHomePhone2(){
        return this.acceptStageHomePhone2 ? this.stageHomePhone2 : this.homePhone2;
    }
    
    get finalLastName1(){
        return this.acceptStageLastName1 ? this.stageLastName1 : this.lastName1;
    }
    
    get finalLastName2(){
        return this.acceptStageLastName2 ? this.stageLastName2 : this.lastName2;
    }
    
    get finalMiddleInitial1(){
        return this.acceptStageMiddleInitial1 ? this.stageMiddleInitial1 : this.middleInitial1;
    }
    
    get finalMiddleInitial2(){
        return this.acceptStageMiddleInitial2 ? this.stageMiddleInitial2 : this.middleInitial2;
    }
    
    get finalName2Name1Relation                              (){
        return this.acceptStageName2Name1Relation ? this.stageName2Name1Relation : this.name2Name1Relation;
    }
    
    get finalSocialSecurityNumber1(){
        return this.acceptStageSocialSecurityNumber1 ? this.stageSocialSecurityNumber1 : this.socialSecurityNumber1;
    }
    
    get finalSocialSecurityNumber2(){
        return this.acceptStageSocialSecurityNumber2 ? this.stageSocialSecurityNumber2 : this.socialSecurityNumber2;
    }
    
    get finalWorkPhone2(){
        return this.acceptStageWorkPhone2 ? this.stageWorkPhone2 : this.workPhone2;
    }
    
}
export class CompanyDataMapper{
    constructor(options){
        this.pcStageCompanyID = _.parseNumber(options.pcStageCompanyID, 0);
        this.pcStageOrdersID = _.parseNumber(options.pcStageOrdersID, 0);
        this.companyID = _.parseNumber(options.companyID, null);
        this.roleTypeID = _.parseNumber(options.roleTypeID, null);
        this.pcStageBuyerSellerID = _.parseNumber(options.pcStageBuyerSellerID, null);
        this.createNew = _.parseBool(options.createNew, false);
        this.mapToExisting = _.parseBool(options.mapToExisting, false);
        this.buyerSellerTypeCode = options.buyerSellerTypeCode || null;
        this.pcStageLoanID = _.parseNumber(options.pcStageLoanID, 0);
        this.roleTypeName = options.roleTypeName || null;
        this.name = options.name || null;
        this.stageName = options.stageName || null;
        this.acceptStageName = _.parseBool(options.acceptStageName);
        this.address = options.address || null;
        this.stageAddress  = options.stageAddress || null;
        this.acceptStageAddress = _.parseBool(options.acceptStageAddress, false);
        this.city = options.city || null;
        this.stageCity = options.stageCity || null;
        this.acceptStageCity = _.parseBool(options.acceptStageCity, false);
        this.state = options.state || null;
        this.stageState = options.stageState || null;
        this.acceptStageState = _.parseBool(options.acceptStageState, false);
        this.zip = options.zip || null;
        this.stageZip = options.stageZip || null;
        this.acceptStageZip = _.parseBool(options.acceptStageZip, false);
        this.loanNumber = options.loanNumber || null;
        this.buyerSellerName = options.buyerSellerName || null;
        this.phone = options.phone || null;
        this.stagePhone = options.stagePhone || null,
        this.acceptStagePhone = _.parseBool(options.acceptStagePhone, false);
        this.fax = options.fax || null;
        this.stageFax = options.stageFax || null;
        this.acceptStageFax = _.parseBool(options.acceptStageFax, false);
        this.email = options.email || null;
        this.stageEmail = options.stageEmail || null;
        this.acceptStageEmail = _.parseBool(options.acceptStageEmail, false);
        this.contactName = options.contactName || null;
        this.acceptContactName = false;
        this.relatedTo = options.relatedTo || null;
    }

    get finalName(){
        return this.acceptStageName ? this.stageName : this.name;
    }

    get finalAddress(){
        return this.acceptStageAddress ? this.stageAddress : this.address;
    }

    get finalCity(){
        return this.acceptStageCity ? this.stageCity : this.city;
    }

    get finalState(){
        return this.acceptStageState ? this.stageState : this.state;
    }

    get finalZip(){
        return this.acceptStageZip ? this.stageZip : this.zip;
    }

    get finalPhone(){
        return this.acceptStagePhone ? this.stagePhone : this.phone;
    }

    get finalFax(){
        return this.acceptStageFax ? this.stageFax : this.fax;
    }

    get finalEmail(){
        return this.acceptStageEmail ? this.stageEmail : this.email;
    }

    get finalContactName(){
        return this.acceptContactName ? this.contactName : "";
    }

    get companyLookup() {
        return { 
            companyID: this.companyID,
            companyName: this.name
        };
    }

    set companyLookup(val) {        
        this.companyID = _.get(val, "companyID", null);
        this.name = _.get(val, "companyName", null);
    }

   
    formatDate(date) {
        if(_.isEmpty(date)) {
            return "";
        }

        try {
            let dt = DateTimeHelper.getDateFromString(date);
            return dt.isValid ? dt.toFormat('MM/dd/yyyy') : "";
        } 
        catch (error) {
            return "";
        }
    }
}
