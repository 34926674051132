import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getCompanyLicensees(companyID) {
        return HttpWrapper.get(`${endpoints.COMPANY_LICENSES}/company/${companyID}`);
    },
    saveCompanyLicense(companyLicense, changes) {        
        return HttpWrapper.put(`${endpoints.COMPANY_LICENSES}`, { data: companyLicense, changes} );              
    },
    deleteCompanyLicense(companyLicenseID) {
        return HttpWrapper.delete(`${endpoints.COMPANY_LICENSES}/${companyLicenseID}`);              
    },
    deleteCompanyLicensees(companyLicenseIDs) {
        return HttpWrapper.post(`${endpoints.COMPANY_LICENSES}/delete`, companyLicenseIDs);
    },
};