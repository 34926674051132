<template>
    <div class="content-wrapper">
        <rq-page-section title="File Summary" header-size="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-loan-select-box
                            v-model="selectedLoanId"
                        />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <rq-page-section title="File Summary Information" collapsible>
            <template #header-actions>
                <ul class="nav ms-auto">
                    <li class="nav-item">
                        <rq-section-expand-collapse-all />
                    </li>
                </ul>
            </template>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_file_number">File #</label>
                        <input automation_id="txt_file_number" v-model="orderSummary.fileNumber" :readonly="true" type="text" class="form-control" id="txt_file_number" placeholder="File Number">
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_property">Property</label>
                        <input automation_id="txt_property" v-model="orderSummary.property" :readonly="true" type="text" class="form-control" id="txt_property" placeholder="Property">
                    </div>
                </div>
                <div class="row">
                    <div v-if="isCdf" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="dtp_issue_date">Date Issued</label>
                        <rqdx-date-box
                            id="dtp_issue_date"
                            ref="issueDate"
                            v-model="selectedSummary.dateIssued"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="dtp_close_date">Close Date</label>
                        <rqdx-date-box
                            id="dtp_close_date"
                            ref="closeDate"
                            v-model="selectedSummary.closingDate"
                            :disabled="readOnly"
                        />
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="dtp_disbursement_date">Fund/Disbursement Date</label>
                        <rqdx-date-box
                            id="dtp_disbursement_date"
                            @value-changed="onEditDisbursementDate"
                            v-model="selectedSummary.disbursementDate"
                            :disabled="readOnly"
                        />
                    </div>
                </div>
                <div class="row">
                    <div v-if="showSalesPriceType" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_loan_type">Sales Price Type</label>
                        <dx-select-box
                            id="drp_sale_price_type"
                            value-expr="id"
                            display-expr="name"
                            :items="salePriceTypeOptions"
                            v-model="selectedSummary.salePriceType">
                        </dx-select-box>
                    </div>
                    <div v-if="!isWithOutSeller || selectedSummary.salePriceType == 0" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_sales_sales_price">Sales Price</label>
                        <rqInputNumber
                            automation_id="txt_sales_sales_price"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            :disabled="isHud"
                            v-focus-select-all
                            @blur="onEditSalesPrice"
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedSummary.salesPrice" />
                    </div>
                    <div v-if="isCdf && selectedSummary.salePriceType == 1" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_appr_prop_value">Appraised Property Value</label>
                        <rqInputNumber
                            automation_id="txt_appr_prop_value"
                            formatType="money"
                            defaultValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            :disabled="readOnly"
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedSummary.appraisedPropertyValue" />
                    </div>
                    <div v-if="isCdf && selectedSummary.salePriceType == 2" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_est_prop_value">Estimated Property Value</label>
                        <rqInputNumber
                            automation_id="txt_est_prop_value"
                            formatType="money"
                            defaultValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            v-focus-select-all
                            :disabled="readOnly"
                            prependIcon="fas fa-dollar-sign"
                            v-model="selectedSummary.estimatedPropertyValue" />
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rq-page-section title="Parties" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_borrower">Borrower</label>
                        <input automation_id="txt_borrower" v-model="orderSummary.buyer" :readonly="true" type="text" class="form-control" id="txt_borrower" placeholder="Borrower">
                    </div>
                    <div v-if="!isWithOutSeller" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_seller">Seller</label>
                        <input automation_id="txt_seller" v-model="orderSummary.seller" :readonly="true" type="text" class="form-control" id="txt_seller" placeholder="Seller">
                    </div>
                    <!--settlement agent-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="settlement_agent">Settlement Agent</label>
                        <company-picker
                            ref="refSettlementAgent"
                            automation_id="pic_settlement_agent_company"
                            companyRoleName="Place of Closing"
                            :companyRoleId="28"
                            dialogTitle="Select Settlement Agent Company"
                            :disabled="readOnly || isHud"
                            v-model="selectedSummary.settlementAgent"
                            show-selection-summary
                        />
                    </div>
                    <div v-if="selectedSummary.hasSettlementAgentCompany && isCdf" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" :class="{'mb-0': isCdf}">
                        <label for="settlement_agent">Settlement Agent Contact</label>
                        <div class="input-group">
                            <contact-picker
                                automation_id="drp_settlement_agent_contact"
                                dialogTitle="Select Settlement Agent Contact"
                                :disabled="readOnly || isHud"
                                :company-id="selectedSummary.settlementAgentCompanyId"
                                v-model:contact-name="selectedSummary.settlementAgentContactName"
                                @change="onEditSettlementAgentContact"
                                v-model="selectedSummary.settlementAgentContactId">
                            </contact-picker>
                        </div>
                    </div>
                    <div v-if="isHud" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group mb-0">
                        <label for="place_of_closing">Place of Settlement</label>
                        <company-picker
                            ref="refPlaceOfClosing"
                            automation_id="pic_place_of_closing_company"
                            companyRoleName="Place of Closing"
                            :companyRoleId="9"
                            dialogTitle="Select Place of Closing"
                            :disabled="true"
                            v-model="selectedSummary.placeOfClosingCompany">
                        </company-picker>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rq-page-section title="Loan" collapsible>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_lender">Lender</label>
                        <input automation_id="txt_lender" v-model="selectedLoan.lender" :readonly="true" type="text" class="form-control" id="txt_lender" placeholder="Lender">
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_loan_number">Loan Number</label>
                        <input automation_id="txt_loan_number" v-model="selectedSummary.loanNumber" :readonly="readOnly || isHud" type="text" class="form-control" id="txt_loan_number" placeholder="Loan Number" maxlength="30">
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_mic_number">MIC #</label>
                        <input automation_id="txt_mic_number" v-model="selectedSummary.loanMicNumber" :readonly="readOnly || isHud" type="text" class="form-control" id="txt_mic_number" placeholder="MIC #" maxlength="20">
                    </div>
                </div>
                <!--Term Period -->
                <div class="row">
                    <!--Years-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_term_years">Term & Length</label>
                        <div class="input-group">
                            <rqInputNumber
                                id="txt_term_years"
                                automation_id="txt_term_years"
                                defaultValue="0"
                                decimals="0"
                                v-focus-select-all
                                @blur="onEditTermsLengthYears"
                                v-model="termsLengthYears">
                            </rqInputNumber>
                            <span class="input-group-text">Years</span>
                        </div>
                    </div>

                    <!--Months-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_term_months"></label>
                        <div class="input-group">
                            <rqInputNumber
                                id="txt_term_months"
                                automation_id="txt_term_months"
                                defaultValue="0"
                                decimals="0"
                                v-focus-select-all
                                @blur="onEditTermsLengthMonths"
                                v-model="termsLengthMonths">
                            </rqInputNumber>
                            <span class="input-group-text">Months</span>
                        </div>
                    </div>

                    <!--Days-->
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label class="form-control-label" for="txt_term_days"></label>
                        <div class="input-group">
                            <rqInputNumber
                                id="txt_term_days"
                                automation_id="txt_term_days"
                                defaultValue="0"
                                decimals="0"
                                v-focus-select-all
                                @blur="onEditTermsLengthDays"
                                v-model="termsLengthDays">
                            </rqInputNumber>
                            <span class="input-group-text">Days</span>
                        </div>
                    </div>
                </div>
                <!-- <loan-term-length v-if="isCdf"
                    :currentLoan="selectedSummary"
                    v-on:loan-term-change="updateTermPeriod"
                ></loan-term-length> -->
                <div class="row">
                    <div v-if="isCdf" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_loan_purpose">Loan Purpose</label>
                        <dx-select-box
                            id="drp_loan_purpose"
                            value-expr="id"
                            display-expr="name"
                            :items="loanPurposes"
                            v-model="selectedSummary.loanPurpose">
                        </dx-select-box>
                    </div>
                    <div v-if="isCdf" class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_loan_product">Loan Product</label>
                        <dx-select-box
                            id="drp_loan_product"
                            value-expr="id"
                            display-expr="name"
                            :items="loanProducts"
                            v-model="selectedSummary.loanProduct">
                        </dx-select-box>
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group">
                        <label for="drp_loan_type">Loan Type</label>
                        <dx-select-box
                            id="drp_loan_type"
                            value-expr="id"
                            display-expr="name"
                            :items="loanTypeOptions"
                            v-model="selectedSummary.loanType">
                        </dx-select-box>
                    </div>
                    <div class="col col-3 col-sm-12 col-md-6 col-lg-3 form-group" v-if="selectedSummary.loanType === 3">
                        <label for="txt_loan_type_other_description">Other Description</label>
                        <input automation_id="txt_loan_type_other_description" v-model="selectedSummary.loanTypeOtherDescription" :readonly="readOnly" type="text" class="form-control" id="txt_loan_type_other_description" placeholder="Description">
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
    </div>
</template>

<script>
    import { GlobalEventManager } from '@/app.events';
    import { CompanyPicker, ContactPicker } from '@/shared/components/rq';
    import LoanTermLengthMixin from "../../../order-entry/loans/LoanTermLengthMixin";
    import { SETTLEMENT_TYPE, SalePriceType } from '@settlement/models';
    import { SettlementOrderSummaryModel } from '../../models';
    import { ORDER_ACTIONS } from "@/store/actions";
    import BaseSettlementMixin from "../../BaseSettlementMixin";

    export default {
        name: "SettlementOrderSummary",
        components: {
            CompanyPicker,
            ContactPicker
        },
        mixins: [
            BaseSettlementMixin,
            LoanTermLengthMixin
        ],
        data(){
            return{
                selectedSummary: {},
                selectedOrderId: 0,
                summaries:[],
                originalSummaries:[]
            }
        },
        computed:{
            selectedLoan(){
                const self = this;
                let selected = _.find(self.loans, l => l.loanID === self.selectedLoanId);
                return selected;
            },
            selectedView: {
                get: function() {
                    return _.parseNumber(this.orderSummary.settlementStatementType, SETTLEMENT_TYPE.CDF);
                }
            },
            loanTypeOptions() {
                if (this.isCdf)
                    return this.lookupHelpers.getLookupItems(this.lookupItems.LOAN_TYPE);
                
                if (this.isHud1974) {
                    return _.map(this.lookupHelpers.getLookupItems(this.lookupItems.HUD_LOAN_TYPE), r => { return r.id === 1 
                        ? { ...r, ...{name: "FmHA"} } 
                        : r});
                }

                if (this.isHud2010) {
                    return _.filter(this.lookupHelpers.getLookupItems(this.lookupItems.HUD_LOAN_TYPE));
                }

                return this.lookupHelpers.getLookupItems(this.lookupItems.LOAN_TYPE); // CDF default
            },
            salePriceTypeOptions() { return SalePriceType.lookupItems; },
            loanPurposes() { return this.lookupHelpers.getLookupItems(this.lookupItems.LOAN_PURPOSE); },
            loanProducts() { return this.lookupHelpers.getLookupItems(this.lookupItems.LOAN_PRODUCT); },
            showSalesPriceType(){ return this.isCdf && this.isWithOutSeller},
        },
        watch:{
            orderId(newValue, oldValue){
                if(newValue !== oldValue)
                    this.fetchData();
            },
            selectedLoanId(newValue, oldValue){
                const self = this;
                if (newValue === oldValue || _.parseNumber(oldValue, 0) === 0 || self.selectedOrderId !== self.orderId) return;
                self.save(false).then((success)=>{
                    if(success)
                        self.selectedSummary = _.find(self.summaries, s => s.loanID === newValue);
                });
            },
            selectedSummary: {
                immediate: true,
                handler(val, oldVal){
                    this.loanTermLengthMixin_clearTermLength();
                    this.loanTermLengthMixin_setTimePeriod(val);
                }
            },

            "selectedSummary.closingDate": function(newValue, oldValue){
                const self = this;
                let origVal = self.originalSummaries[0].closingDate;

                if (newValue === origVal) return;

                const handleCancel = () => {
                    self.selectedSummary.closingDate = origVal;
                };

                self.$dialog.confirm(
                    "Confirm",
                    `This will update the Close Date on this file.`,
                    () => true,
                    handleCancel,
                    { cancelTitle: 'Cancel', okTitle: 'Ok'});
            },
            "selectedSummary.settlementAgent": function(newValue, oldValue){
                const self = this;
                let loan = self.selectedLoan;
                let origVal = self.originalSummaries[0].settlementAgent;

                if(newValue?.companyID === origVal.companyID) return;
                if(oldValue?.companyID !== origVal.companyID) return;

                const handleCancel = () => {
                    self.selectedSummary.settlementAgent = origVal;
                };

                self.$dialog.confirm(
                    "Confirm",
                    `This will update the Settlement Agent for Loan #${loan.loanOrder} for this file.`,
                    () => true,
                    handleCancel,
                    { cancelTitle: 'Cancel', okTitle: 'Ok'});
            },
            selectedView(newValue, oldValue) {
                const self = this;
                if (newValue === oldValue) return;
                self.fetchData();
            },
        },

        created() {
            this.baseInit();
            this.fetchData();
        },

        mounted() {
            this.resetFocus();
        },

        methods:{

            onSave(e){
                let userInitiated = _.get(e, "userInitiated", false);
                this.save(userInitiated);
            },

            onCancel(e) {
                if(!this.hasChanges()) {
                    this.$toast.info("No changes detected.");
                    return;
                }
                this.fetchData();
            },

            onEditDisbursementDate(e) {
                const self = this;
                let loan = self.selectedLoan;
                let newVal = e.value;
                let origVal = self.originalSummaries[0].disbursementDate;

                if (newVal === origVal) return;

                const handleCancel = () => {
                    self.selectedSummary.disbursementDate = origVal;
                };

                self.$dialog.confirm(
                    "Confirm",
                    `This will update the Fund/Disbursement Date on this file.`,
                    () => true,
                    handleCancel,
                    { cancelTitle: 'Cancel', okTitle: 'Ok'});
            },

            onEditSettlementAgentContact(e) {
                if (_.isNil(e.event)) return;
                const self = this;
                let loan = self.selectedLoan;
                let newVal = e.value;
                let origVal = e.previousValue;

                if (newVal === origVal) return;

                const handleCancel = () => {
                    self.selectedSummary.settlementAgentContactId = origVal;
                };

                self.$dialog.confirm(
                    "Confirm",
                    `This will update the Settlement Agent Contact for Loan #${loan.loanOrder} for this file.`,
                    () => true,
                    handleCancel,
                    { cancelTitle: 'Cancel', okTitle: 'Ok'});
            },

            onEditSalesPrice(e) {
                const self = this;
                let loan = self.selectedLoan;
                let newVal = e.value;
                let origVal = self.selectedSummary.salesPrice;

                let handleOk = function(e) {
                    return true;
                }

                let handleCancel = function(e) {
                    self.selectedSummary.salesPrice = origVal;
                    return true;
                }

                if (newVal !== origVal) {
                    self.$dialog.confirm(
                        "Confirm",
                        `This will update the Sales Price for Loan #${loan.loanOrder} for this file.`,
                        handleOk,
                        handleCancel,
                        { cancelTitle: 'Cancel', okTitle: 'Ok'});
                }
            },

            onEditTermsLengthYears(e) {
                const self = this;
                let loan = self.selectedLoan;
                let newLengthYears = e.value;
                let origLengthYears = self.termsLengthYears;

                let handleOk = function(e) {
                    if(newLengthYears > 0 && self.termsLengthMonths > 11) {
                        self.termsLengthMonths = 0;
                    }
                    if(newLengthYears > 0 || self.termsLengthMonths > 0){
                        self.termsLengthDays = 0;
                    }

                    let data = self.loanTermLengthMixin_getTermPeriod();
                    self.updateTermPeriod(data);
                    return true;
                }

                let handleCancel = function(e) {
                    self.termsLengthYears = origLengthYears;
                    return true;
                }

                // values match so no need to prompt with confirmation dialog
                if (newLengthYears !== origLengthYears) {
                    self.$dialog.confirm(
                        "Confirm",
                        `This will update the Loan Terms for Loan #${loan.loanOrder} for this file.`,
                        handleOk, // handleOk
                        handleCancel, // handleCancel
                        { cancelTitle: 'Cancel', okTitle: 'Ok'});
                }
            },
            onEditTermsLengthMonths(e) {
                const self = this;
                let loan = self.selectedLoan;
                let newLengthMonths = e.value;
                let origLengthMonths = self.termsLengthMonths;

                let handleOk = function(e) {
                    if (newLengthMonths > 11) {
                        self.termsLengthYears = 0;
                    }
                    if (self.termsLengthYears > 0 || newLengthMonths > 0){
                        self.termsLengthDays = 0;
                    }

                    let data = self.loanTermLengthMixin_getTermPeriod();
                    self.updateTermPeriod(data);
                    return true;
                }

                let handleCancel = function(e) {
                    self.termsLengthMonths = origLengthMonths;
                    return true;
                }

                // values match so no need to prompt with confirmation dialog
                if (newLengthMonths !== origLengthMonths) {
                    self.$dialog.confirm(
                        "Confirm",
                        `This will update the Loan Terms for Loan #${loan.loanOrder} for this file.`,
                        handleOk, // handleOk
                        handleCancel, // handleCancel
                        { cancelTitle: 'Cancel', okTitle: 'Ok'});
                }
            },
            onEditTermsLengthDays(e) {
                const self = this;
                let loan = self.selectedLoan;
                let newLengthDays = e.value;
                let origLengthDays = self.termsLengthDays;

                let handleOk = function(e) {
                    if (newLengthDays > 0) {
                        self.termsLengthMonths = 0;
                        self.termsLengthYears = 0;
                    }

                    let data = self.loanTermLengthMixin_getTermPeriod();
                    self.updateTermPeriod(data);
                    return true;
                }

                let handleCancel = function(e) {
                    self.termsLengthDays = origLengthDays;
                    return true;
                }

                // values match so no need to prompt with confirmation dialog
                if (newLengthDays !== origLengthDays) {
                    self.$dialog.confirm(
                        "Confirm",
                        `This will update the Loan Terms for Loan #${loan.loanOrder} for this file.`,
                        handleOk, // handleOk
                        handleCancel, // handleCancel
                        { cancelTitle: 'Cancel', okTitle: 'Ok'});
                }
            },

            updateTermPeriod(data) {
                this.selectedSummary.loanTermPeriod = data.loanTermPeriod;
                this.selectedSummary.loanTerm = data.term;
                this.selectedSummary.loanMonths = data.loanMonths;
            },

            save(toastIfNoChanges=false){
                const self = this;

                let original = _.find(self.originalSummaries, s => s.settlementOrderSummaryID === self.selectedSummary.settlementOrderSummaryID);
                if(_.isNil(original)) return Promise.resolve(false);

                if(!self.hasChanges(original)){
                    if(toastIfNoChanges)
                        self.$toast.info("No changes detected");
                    GlobalEventManager.saveCompleted({success: true});
                    return Promise.resolve(true);
                }

                let promise = self.$api.SettlementOrderSummaryApi.updateWithDependencies(self.selectedSummary).then(()=> {

                    return self.syncUpdates().then(() => {
                        self.$toast.success("File Summary Saved.");
                        GlobalEventManager.saveCompleted({success: true});
                        return true;
                    });

                }).catch(err =>{
                    self.$toast.error(err.message);
                    GlobalEventManager.saveCompleted({success: false});
                    return false;
                });

                self.$rqBusy.wait(promise);
                return promise;

            },

            hasChanges(original=null) {
                const self = this;
                let originalSummary = _.isNil(original) ? _.find(self.originalSummaries, s => s.settlementOrderSummaryID === self.selectedSummary.settlementOrderSummaryID) : original;
                let changes = self.getAuditChanges(self.selectedSummary, originalSummary);
                return changes.length > 0;
            },

            fetchData(){
                const self = this;
                let promise = self.$api.SettlementOrderSummaryApi.getByOrderId(self.orderId);
                self.$rqBusy.wait(promise).then(result => {
                    self.summaries = _.map(result, i => new SettlementOrderSummaryModel(i));
                    self.originalSummaries = _.cloneDeep(self.summaries);
                    self.selectedSummary = _.find(self.summaries, s => s.loanID === self.selectedLoanId);
                    self.selectedOrderId = self.orderId;
                })
                .catch(err =>{
                    self.$toast.error(err.errorMessage);
                });
            },

            syncUpdates(){
                const self = this;
                //these values are the same for the whole order even though they come across as separate records
                _.forEach(self.summaries, s=>{
                    s.closingDate = self.selectedSummary.closingDate;
                    s.disbursementDate = self.selectedSummary.disbursementDate;
                    s.salesPrice = self.selectedSummary.salesPrice;
                    s.appraisedPropertyValue = self.selectedSummary.appraisedPropertyValue;
                    s.estimatedPropertyValue = self.selectedSummary.estimatedPropertyValue;
                });
                //reset original data
                self.originalSummaries = _.cloneDeep(self.summaries);
                //refresh the store
                let promise = self.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, {orderId: self.orderId, refresh:true});
                self.$rqBusy.wait(promise);
                return promise;
            },

            resetFocus(){
                if (this.loans.length === 1){
                    if (this.isCdf)
                        this.$refs.issueDate.focus()
                    if (this.isHud)
                        this.$refs.closeDate.focus()
                }
            }
        }
    }
</script>
