const priorityType = {
    High: 0,
    Medium: 1,
    Low: 2,
};

export class PriorityType {
    static get High() {
        return priorityType.High;
    }

    static get Medium() {
        return priorityType.Medium;
    }

    static get Low() {
        return priorityType.Low;
    }

    static getCssClass(id) {
        return `note-priority-${id}`;
    }

    static get lookupItems() {
        return [
            {
                id: this.High,
                name: "High",
                icon: "fas fa-chevrons-up", // Font Awesome icon for High
            },
            {
                id: this.Medium,
                name: "Medium",
                icon: "fas fa-equals", // Font Awesome icon for Medium
            },
            {
                id: this.Low,
                name: "Low",
                icon: "fas fa-chevrons-down", // Font Awesome icon for Low
            },
        ];
    }

    static getLookupItemById(priorityId) {
        return this.lookupItems.find(item => item.id === priorityId) || null;
    }
}
