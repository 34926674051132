import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {

    getAmortizationCalculator() {
        return HttpWrapper.get(`${endpoints.AMORTIZATION_CALCULATOR}`);
    },
    saveAmortizationCalculatorDetail(data) {
        return HttpWrapper.put(`${endpoints.AMORTIZATION_CALCULATOR}/save`, { data, changes: null} );
    },
};