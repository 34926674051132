import LookupNames from "../../store/lookupNames";

const linesBetweenClauses = { None: 0, One: 1, Two: 2 };
export class LinesBetweenClauses {
    static get None () {return linesBetweenClauses.None;}
    static get One () {return linesBetweenClauses.One;}
    static get Two () {return linesBetweenClauses.Two;}

    static get lookupItems () {
        return _.map(linesBetweenClauses, c => { return { id: c, name: LinesBetweenClauses.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(linesBetweenClauses, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}

const endorsementOptionList = { None: 0, BreakoutEndorsement: 1, CombineEndorsement: 2 };
export class EndorsementOptionList {
    static get None () {return endorsementOptionList.None;}
    static get BreakoutEndorsement () {return endorsementOptionList.BreakoutEndorsement;}
    static get CombineEndorsement () {return endorsementOptionList.CombineEndorsement;}

    static get lookupItems () {
        return _.map(endorsementOptionList, c => { return { id: c, name: EndorsementOptionList.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(endorsementOptionList, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}

const defaultFileType = { TogetherOnly: 1, SeparateOnly: 2, SeparateOrTogether: 3 };
export class DefaultFileType {
    static get TogetherOnly () {return defaultFileType.TogetherOnly;}
    static get SeparateOnly () {return defaultFileType.SeparateOnly;}
    static get SeparateOrTogether () {return defaultFileType.SeparateOrTogether;}

    static get lookupItems () {
        return _.map(defaultFileType, c => { return { id: c, name: DefaultFileType.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(defaultFileType, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}


const typesOfPolicies = {Owner: 1, Mortgage: 2, Both: 3 };
export class TypeOfPolicies {
    static get Both () {return typesOfPolicies.Both;}
    static get Mortgage () {return typesOfPolicies.Mortgage;}
    static get Owner () {return typesOfPolicies.Owner;}

    static get lookupItems () {
        return _.map(typesOfPolicies, c => { return { id: c, name: TypeOfPolicies.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(typesOfPolicies, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}

const fileOpenDateOptions = { CurrentYearToDate: 0, LastYearToDate: 1,  LastQuarterToDate: 2, LastMonthToDate: 3, Custom: 4 };
export class FileOpenDateOptions {
    static get CurrentYearToDate () {return fileOpenDateOptions.CurrentYearToDate;}
    static get LastYearToDate () {return fileOpenDateOptions.LastYearToDate;}
    static get LastQuarterToDate () {return fileOpenDateOptions.LastQuarterToDate;}
    static get LastMonthToDate () {return fileOpenDateOptions.LastMonthToDate;}
    static get Custom () {return fileOpenDateOptions.Custom;}

    static get lookupItems () {
        return _.map(fileOpenDateOptions, c => { return { id: c, name: FileOpenDateOptions.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(fileOpenDateOptions, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}

const commitmentPolicyTypes = {Texas : 1, ALTA : 2};
export class CommitmentPolicyTypes {
    static get Texas () {return commitmentPolicyTypes.Texas;}
    static get ALTA () {return commitmentPolicyTypes.ALTA;}

    static get lookupItems () {
        return _.map(commitmentPolicyTypes, c => { return { id: c, name: CommitmentPolicyTypes.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(commitmentPolicyTypes, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}

const checkPrintingOptions = { DisplayButDoNotAllowEdit: 2,  DisplayAndAllowEdit: 3 }; //DoNotDisplayBeforePrinting: 1, This option is not available in Horizon
export class CheckPrintingOptions {
    // static get DontDisplayBeforePrinting () {return checkPrintingOptions.DontDisplayBeforePrinting;}
    static get DisplayButDoNotAllowEdit () {return checkPrintingOptions.DisplayButDoNotAllowEdit;}
    static get DisplayAndAllowEdit () {return checkPrintingOptions.DisplayAndAllowEdit;}

    static get lookupItems () {
        return _.map(checkPrintingOptions, c => { return { id: c, name: CheckPrintingOptions.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(checkPrintingOptions, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}
const checkPrintingOptionsForUser = { UseSystemDefault: 1, DisplayButDoNotAllowEdit: 2,  DisplayAndAllowEdit: 3 };
export class CheckPrintingOptionsForUser {
    static get UseSystemDefault () {return checkPrintingOptionsForUser.UseSystemDefault;}
    static get DisplayButDoNotAllowEdit () {return checkPrintingOptionsForUser.DisplayButDoNotAllowEdit;}
    static get DisplayAndAllowEdit () {return checkPrintingOptionsForUser.DisplayAndAllowEdit;}

    static get lookupItems () {
        return _.map(checkPrintingOptionsForUser, c => { return { id: c, name: CheckPrintingOptionsForUser.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(checkPrintingOptionsForUser, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}
const moneyMovementBatchApprovalType = { None: 0, File: 1, Global: 2 };
export class MoneyMovementBatchApprovalType {
    static get None () {return moneyMovementBatchApprovalType.None;}
    static get File () {return moneyMovementBatchApprovalType.File;}
    static get Global () {return moneyMovementBatchApprovalType.Global;}

    static get lookupItems () {
        return _.map(moneyMovementBatchApprovalType, c => { return { id: c, name: MoneyMovementBatchApprovalType.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(moneyMovementBatchApprovalType, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}
const moneyMovementNotificationType = { Email: 1 }; // add back Alert once implemented
export class MoneyMovementNotificationType {
    static get Email () {return moneyMovementNotificationType.Email;}
    //static get Alert () {return moneyMovementNotificationType.Alert;}

    static get lookupItems () {
        return _.map(moneyMovementNotificationType, c => { return { id: c, name: MoneyMovementNotificationType.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(moneyMovementNotificationType, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}
const moneyMovementType = { ConsolidatedDisbursementAdjustment: 1, Disbursement: 2, Advance: 3, Loss: 4 };
const acpMoneyMovementType = { ConsolidatedDisbursement: 1, Advance: 3, Loss: 4 };
export class MoneyMovementType {
    static get ConsolidatedDisbursementAdjustment () {return moneyMovementType.ConsolidatedDisbursementAdjustment;}
    static get Disbursement () {return moneyMovementType.Disbursement;}
    static get Advance () {return moneyMovementType.Advance;}
    static get Loss () {return moneyMovementType.Loss;}

    static get acpLookupItems () {
        return _.map(acpMoneyMovementType, c => { return { id: c, name: MoneyMovementType.displayAcpValue(c) }; });
    }

    static get lookupItems () {
        return _.map(moneyMovementType, c => { return { id: c, name: MoneyMovementType.displayValue(c) }; });
    }

    static displayAcpValue (id) {
		let enumKey = _.findKey(acpMoneyMovementType, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }

    static displayValue (id) {
		let enumKey = _.findKey(moneyMovementType, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}
const moneyMovementApprovalStatus = { Pending: 0, AutoApproved: 1, Approved: 2, Rejected: 3 };
export class MoneyMovementApprovalStatus {
    static get Pending () {return moneyMovementApprovalStatus.Pending;}
    static get AutoApproved () {return moneyMovementApprovalStatus.AutoApproved;}
    static get Approved () {return moneyMovementApprovalStatus.Approved;}
    static get Rejected () {return moneyMovementApprovalStatus.Rejected;}

    static get lookupItems () {
        return _.map(moneyMovementApprovalStatus, c => { return { id: c, name: MoneyMovementApprovalStatus.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(moneyMovementApprovalStatus, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}
const goodFundDefinitionOptions = {   DoNotCheckForAvailableFunds: 0,  UseVerifiedDateOrReconDate: 1, UseDepositReconDateOnly: 2,  UseDepositVerifiedDateOnly: 3 };
export class GoodFundDefinitionOptions {
    static get All () {return goodFundDefinitionOptions.DoNotCheckForAvailableFunds;}
    static get UseReconDateAndVerifiedDate () {return goodFundDefinitionOptions.UseVerifiedDateOrReconDate;}
    static get UseReconDateOnly () {return goodFundDefinitionOptions.UseDepositReconDateOnly;}
    static get UseVerifiedDateOnly () {return goodFundDefinitionOptions.UseDepositVerifiedDateOnly;}

    static get lookupItems () {
        return _.map(goodFundDefinitionOptions, c => { return { id: c, name: GoodFundDefinitionOptions.displayValue(c) }; });
    }

    static displayValue (id) {
		let enumKey = _.findKey(goodFundDefinitionOptions, cm => cm === id);
		return (enumKey && _.startCase(enumKey)) || "";
    }
}

const templateType = { PlainText: 1, HTML: 0 };
export class TemplateTypes {
    static get PlainText () {return templateType.PlainText;}
    static get HTML () {return templateType.HTML;}

    static get lookupItems () {
        return _.map(templateType, c => { return { id: c, name: TemplateTypes.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(templateType, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}

//const deliveryAction = { PaperlessCloser: "P", Print: "R" };
const deliveryAction = { Print: "R" };
export class DeliveryAction {
    //static get PaperlessCloser () {return deliveryAction.PaperlessCloser;}
    static get Print () {return deliveryAction.Print;}

    static get lookupItems () {
        return _.map(deliveryAction, c => { return { id: c, name: DeliveryAction.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(deliveryAction, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}

const defaultSellersFor1099 = { UseSystemDefault: 0, Yes: 1, No: 2 };
export class DefaultSellersFor1099 {
    static get UseSystemDefault () {return defaultSellersFor1099.UseSystemDefault;}
    static get Yes () {return defaultSellersFor1099.Yes;}
    static get No () {return defaultSellersFor1099.No;}

    static get lookupItems () {
        return _.map(defaultSellersFor1099, c => { return { id: c, name: DefaultSellersFor1099.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(defaultSellersFor1099, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}

const taxType = { County: 1, City: 2 };
export class TaxType {
    static get County () {return taxType.County;}
    static get City () {return taxType.City;}

    static get lookupItems () {
        return _.map(taxType, c => { return { id: c, name: TaxType.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(taxType, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}
const autoReconFileType = { BAI: 1, QuickBooksMSMoney: 2 };
export class AutoReconFileType {
    static get BAI () {return autoReconFileType.BAI;}
    static get QuickBooksMSMoney () {return autoReconFileType.QuickBooksMSMoney;}

    static get lookupItems () {
        return _.map(autoReconFileType, c => { return { id: c, name: AutoReconFileType.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(autoReconFileType, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}

const specialFormat = { BAI: 0, QuickBooksMSMoney: 5 };
export class SpecialFormat {
    static get BAI () {return specialFormat.BAI;}
    static get QuickBooksMSMoney () {return specialFormat.QuickBooksMSMoney;}

    static get lookupItems () {
        return _.map(specialFormat, c => { return { id: c, name: SpecialFormat.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(specialFormat, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}
const unitCheckType = { autoGenerated: 0, manuallyGenerated: 1 };
export class UnitCheckType {
    static get AutoGenerated () {return unitCheckType.autoGenerated;}
    static get ManuallyGenerated () {return unitCheckType.manuallyGenerated;}

    static get lookupItems () {
        return _.map(unitCheckType, c => { return { id: c, name: UnitCheckType.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(unitCheckType, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}
const fileScanTaskAction = { Create: 1, Update: 0 };
export class FileScanTaskAction {
    static get Create () {return fileScanTaskAction.Create;}
    static get Update () {return fileScanTaskAction.Update;}

    static get lookupItems () {
        return _.map(fileScanTaskAction, c => { return { id: c, name: FileScanTaskAction.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(fileScanTaskAction, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}
const fileScanDescriptionTaskMarkAs = { None: 0, Open: 1, Complete: 2 };
export class FileScanDescriptionTaskMarkAs {
    static get None () {return fileScanDescriptionTaskMarkAs.None;}
    static get Open () {return fileScanDescriptionTaskMarkAs.Open;}
    static get Complete () {return fileScanDescriptionTaskMarkAs.Complete;}

    static get lookupItems () {
        return _.map(fileScanDescriptionTaskMarkAs, c => { return { id: c, name: FileScanDescriptionTaskMarkAs.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(fileScanDescriptionTaskMarkAs, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}

const workflowBasedOn = { None: 0, OpenDate: 1, CloseDate: 2, OtherTaskComplete : 3, FundDisbursementDate: 4 };
export class WorkflowBasedOnType {
    static get None () {return workflowBasedOn.None;}
    static get OpenDate () {return workflowBasedOn.OpenDate;}
    static get CloseDate () {return workflowBasedOn.CloseDate;}
    static get OtherTaskComplete () {return workflowBasedOn.OtherTaskComplete ;}
    static get FundDisbursementDate () {return workflowBasedOn.FundDisbursementDate;}

    static get lookupItems () {
        return _.map(workflowBasedOn, c => { return { id: c, name: WorkflowBasedOnType.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(workflowBasedOn, cm => cm === id);
              let displaVal = _.startCase(enumKey);
              if(displaVal === "None")
                displaVal = "[None]"
              return (enumKey && displaVal) || "";
    }
}

const importType = { Rates: 1, Endorsements: 2 };
export class ImportType {
    static get Rates () {return importType.Rates;}
    static get Endorsements () {return importType.Endorsements;}

    static get lookupItems () {
        return _.map(importType, c => { return { id: c, name: ImportType.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(importType, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}

// RQO-15175 : Undo changes from RQO-8106
const effectiveDateBasedOn = { OpenDate: 1, CloseDate: 2, FundDisbursementDate: 3};
export class EffectiveDateBasedOn {
    static get OpenDate () {return effectiveDateBasedOn.OpenDate;}
    static get CloseDate () {return effectiveDateBasedOn.CloseDate;}
    static get FundDisbursementDate () {return effectiveDateBasedOn.FundDisbursementDate ;}

    static get lookupItems () {
        return _.map(effectiveDateBasedOn, c => { return { id: c, name: EffectiveDateBasedOn.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(effectiveDateBasedOn, cm => cm === id);
              return _.replace((enumKey && _.startCase(enumKey)) || "", 'Fund Disbursement Date', 'Fund/Disbursement Date');
    }
}

const transferTaxEffectiveDateBasedOn = { OpenDate: 0, CloseDate: 1, FundDisbursementDate: 2};
export class TransferTaxEffectiveDateBasedOn {
    static get OpenDate () {return transferTaxEffectiveDateBasedOn.OpenDate;}
    static get CloseDate () {return transferTaxEffectiveDateBasedOn.CloseDate;}
    static get FundDisbursementDate () {return transferTaxEffectiveDateBasedOn.FundDisbursementDate ;}

    static get lookupItems () {
        return _.map(transferTaxEffectiveDateBasedOn, c => { return { id: c, name: TransferTaxEffectiveDateBasedOn.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(transferTaxEffectiveDateBasedOn, cm => cm === id);
              return _.replace((enumKey && _.startCase(enumKey)) || "", 'Fund Disbursement Date', 'Fund/Disbursement Date');
    }
}

const recordingFeeEffectiveDateBasedOn = { OpenDate: 0, CloseDate: 1, FundDisbursementDate: 2};
export class RecordingFeeEffectiveDateBasedOn {
    static get OpenDate () {return recordingFeeEffectiveDateBasedOn.OpenDate;}
    static get CloseDate () {return recordingFeeEffectiveDateBasedOn.CloseDate;}
    static get FundDisbursementDate () {return recordingFeeEffectiveDateBasedOn.FundDisbursementDate;}

    static get lookupItems () {
        return _.map(recordingFeeEffectiveDateBasedOn, c => { return { id: c, name: RecordingFeeEffectiveDateBasedOn.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(recordingFeeEffectiveDateBasedOn, cm => cm === id);
              return _.replace((enumKey && _.startCase(enumKey)) || "", 'Fund Disbursement Date', 'Fund/Disbursement Date');
    }
}

const recordingType = { County: 0, City: 1};
export class RecordingType {
    static get County () {return recordingType.County;}
    static get City () {return recordingType.City;}


    static get lookupItems () {
        return _.map(recordingType, c => { return { id: c, name: RecordingType.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(recordingType, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}

const whoPays = { Buyer: 0, Seller: 1, Lender: 2};
export class WhoPays {
    static get Buyer () {return whoPays.Buyer;}
    static get Seller () {return whoPays.Seller;}
    static get Lender() {return whoPays.Lender;}


    static get lookupItems () {
        return _.map(whoPays, c => { return { id: c, name: WhoPays.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(whoPays, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}

const closingParties = { Default: 0, Buyer: 1, Seller: 2};
export class ClosingParties {
    static get Default () {return closingParties.Default;}
    static get Buyer () {return closingParties.Buyer;}
    static get Seller () {return closingParties.Seller;}

    static get lookupItems () {
        return _.map(closingParties, c => { return { id: c, name: ClosingParties.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(closingParties, cp => cp === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const initialPageNumber = { PageOne: 1, Page12: 2, Page13: 3, Page14: 4};
export class InitialPageNumber {
    static get PageOne () {return initialPageNumber.PageOne;}
    static get Page12 () {return initialPageNumber.Page12;}
    static get Page13 () {return initialPageNumber.Page13;}
    static get Page14 () {return initialPageNumber.Page14;}

    static get lookupItems () {
        return _.map(initialPageNumber, c => { return { id: c, name: InitialPageNumber.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(initialPageNumber, cm => cm === id);
              return _.replace((enumKey && _.startCase(enumKey)) || "" ,'1','1-');
    }
}

const calculationBasis = { FlatFee: 0, PerPage: 1};
export class CalculationBasis {
    static get FlatFee () {return calculationBasis.FlatFee;}
    static get PerPage () {return calculationBasis.PerPage;}

    static get lookupItems () {
        return _.map(calculationBasis, c => { return { id: c, name: CalculationBasis.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(calculationBasis, cm => cm === id);
              return _.replace((enumKey && _.startCase(enumKey)) || "" ,'1','1-');
    }
}

const taxRateEffectiveBasis = { None: -1, OpenDate: 1, ClosingDate: 2, FundDisbursementDate: 3};
export class TaxRateEffectiveBasis {
    static get None () {return taxRateEffectiveBasis.None;}
    static get OpenDate () {return taxRateEffectiveBasis.OpenDate;}
    static get CloseDate () {return taxRateEffectiveBasis.CloseDate;}
    static get FundDisbursementDate () {return taxRateEffectiveBasis.FundDisbursementDate ;}

    static get lookupItems () {
        return _.map(taxRateEffectiveBasis, c => { return { id: c, name: TaxRateEffectiveBasis.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(taxRateEffectiveBasis, cm => cm === id);
              return _.replace((enumKey && _.startCase(enumKey)) || "", 'Fund Disbursement Date', 'Fund/Disbursement Date');
    }
}

const taxRateBasedOn = { Property: 1, OrderContact: 2};
export class TaxRateBasedOn {
    static get Property () {return taxRateBasedOn.Property;}
    static get OrderContact () {return taxRateBasedOn.OrderContact ;}

    static get lookupItems () {
        return _.map(taxRateBasedOn, c => { return { id: c, name: TaxRateBasedOn.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(taxRateBasedOn, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}

const simultaneousPolicyType = { None: 0, FlatFee: 1, FlatFeePlusExcess: 2, NoFee: 3, Percentage: 4, PercentagePlusExcess: 6, FlatFeePlusPercentage: 7, FlatFeePlusPercentagePlusExcess: 8};
export class SimultaneousPolicyType {
    static get None () {return simultaneousPolicyType.None;}
    static get FlatFee () {return simultaneousPolicyType.FlatFee ;}
    static get FlatFeePlusExcess () {return simultaneousPolicyType.FlatFeePlusExcess ;}
    static get NoFee () {return simultaneousPolicyType.NoFee ;}
    static get Percentage () {return simultaneousPolicyType.Percentage ;}
    static get PercentagePlusExcess () {return simultaneousPolicyType.PercentagePlusExcess ;}
    static get FlatFeePlusPercentage () {return simultaneousPolicyType.FlatFeePlusPercentage ;}
    static get FlatFeePlusPercentagePlusExcess () {return simultaneousPolicyType.FlatFeePlusPercentagePlusExcess ;}

    static get lookupItems () {
        return _.map(simultaneousPolicyType, c => { return { id: c, name: SimultaneousPolicyType.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(simultaneousPolicyType, cm => cm === id);
              return _.replace(_.replace((enumKey && _.startCase(enumKey)) || "",'Plus',' + '),'Plus',' + ');
    }
}

const rateSimultaneousPolicyType = { None: 0, ConcurrentPremium: 9, FlatFee: 1, FlatFeePlusDifference: 10, FlatFeePlusExcess: 2, FlatFeePlusPercentage: 7, FlatFeePlusPercentagePlusExcess: 8, NoFee: 3, Percentage: 4, PercentagePlusExcess: 6};
export class RateSimultaneousPolicyType {
    static get None () {return rateSimultaneousPolicyType.None;}
    static get FlatFee () {return rateSimultaneousPolicyType.FlatFee ;}
    static get FlatFeePlusExcess () {return rateSimultaneousPolicyType.FlatFeePlusExcess ;}
    static get NoFee () {return rateSimultaneousPolicyType.NoFee ;}
    static get Percentage () {return rateSimultaneousPolicyType.Percentage ;}
    static get PercentagePlusExcess () {return rateSimultaneousPolicyType.PercentagePlusExcess ;}
    static get FlatFeePlusPercentage () {return rateSimultaneousPolicyType.FlatFeePlusPercentage ;}
    static get FlatFeePlusPercentagePlusExcess () {return rateSimultaneousPolicyType.FlatFeePlusPercentagePlusExcess ;}
    static get ConcurrentPremium () {return rateSimultaneousPolicyType.ConcurrentPremium ;}
    static get FlatFeePlusDifference () {return rateSimultaneousPolicyType.FlatFeePlusDifference ;}

    static get lookupItems () {
        return _.map(rateSimultaneousPolicyType, c => { return { id: c, name: RateSimultaneousPolicyType.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(rateSimultaneousPolicyType, cm => cm === id);
        return _.replace(_.replace((enumKey && _.startCase(enumKey)) || "",'Plus',' + '),'Plus',' + ');
    }
}

const excessMethod = { UseDifference: 0, UsePercentDifference: 1};
export class ExcessMethod {
    static get UseDifference () {return excessMethod.UseDifference;}
    static get UsePercentDifference () {return excessMethod.UsePercentDifference ;}

    static get lookupItems () {
        return _.map(excessMethod, c => { return { id: c, name: ExcessMethod.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(excessMethod, cm => cm === id);
              return _.replace((enumKey && _.startCase(enumKey)) || "" ,'Percent',' % ');
    }
}

const coverageRoundingMethod = { None: 0, Round1000: 1, Round5000: 2, Round10000: 3, Custom: 4};
export class CoverageRoundingMethod {
    static get None () {return coverageRoundingMethod.None;}
    static get Round1000 () {return coverageRoundingMethod.Round1000 ;}
    static get Round5000 () {return coverageRoundingMethod.Round5000 ;}
    static get Round10000 () {return coverageRoundingMethod.Round10000 ;}
    static get Custom () {return coverageRoundingMethod.Custom ;}

    static get lookupItems () {
        return _.map(coverageRoundingMethod, c => { return { id: c, name: CoverageRoundingMethod.displayValue(c) }; });
    }

    static displayValue (id) {
        switch (id) {
            case 0:
                return "[None]";
            case 1:
                return "Round up to 1,000";
            case 2:
                return "Round up to 5,000";
            case 3:
                return "Round up to 10,000";
            case 4:
                return "Custom";
        }
        return "Unknown";
    }
}

const roundingMethod = { None: 0, Round01: 1, Round010: 2, Round10: 3, Custom: 4};
export class RoundingMethod {
    static get Round01 () {return roundingMethod.Round01;}
    static get Round010 () {return roundingMethod.Round010 ;}
    static get Round10 () {return roundingMethod.Round10 ;}
    static get Custom () {return roundingMethod.Custom ;}
    static get None () {return roundingMethod.None ;}

    static get lookupItems () {
        return _.map(roundingMethod, c => { return { id: c, name: RoundingMethod.displayValue(c) }; });
    }

    static displayValue (id) {
        switch (id) {
            case 0:
                return "[None]";
            case 1:
                return "Round up to .01";
            case 2:
                return "Round up to .10";
            case 3:
                return "Round up to 1.00";
            case 4:
                return "Custom";
        }
        return "Unknown";
    }
}

const transferTaxCalculationBasis = { FlatFee: 0, Percentage: 1, Scalable: 2};
export class TransferTaxCalculationBasis {
    static get FlatFee () {return transferTaxCalculationBasis.FlatFee;}
    static get Percentage () {return transferTaxCalculationBasis.Percentage;}
    static get Scalable () {return transferTaxCalculationBasis.Scalable;}

    static get lookupItems () {
        return _.map(transferTaxCalculationBasis, c => { return { id: c, name: TransferTaxCalculationBasis.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(transferTaxCalculationBasis, cm => cm === id);
              return _.replace((enumKey && _.startCase(enumKey)) || "" ,'1','1-');
    }
}

const chargePercentageBasis = { LoanAmount: 1, SalePrice: 2};
export class ChargePercentageBasis {
    static get FlatFee () {return chargePercentageBasis.FlatFee;}
    static get Percentage () {return chargePercentageBasis.Percentage;}

    static get lookupItems () {
        return _.map(chargePercentageBasis, c => { return { id: c, name: ChargePercentageBasis.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(chargePercentageBasis, cm => cm === id);
              return _.replace((enumKey && _.startCase(enumKey)) || "" ,'1','1-');
    }
}

// const coverageRoundingMethod = { None: 0, RoundUpTo1000: 1, RoundUpTo5000: 2, RoundUpTo10000: 3, Custom: 4};
// export class CoverageRoundingMethod {
//     static get None () {return coverageRoundingMethod.None;}
//     static get RoundUpTo1000 () {return coverageRoundingMethod.RoundUpTo1000;}
//     static get RoundUpTo5000 () {return coverageRoundingMethod.RoundUpTo5000;}
//     static get RoundUpTo10000 () {return coverageRoundingMethod.RoundUpTo10000;}
//     static get Custom () {return coverageRoundingMethod.Custom;}

//     static get lookupItems () {
//         return _.map(coverageRoundingMethod, c => { return { id: c, name: CoverageRoundingMethod.displayValue(c) }; });
//     }

//     static displayValue (id) {
//               let enumKey = _.findKey(coverageRoundingMethod, cm => cm === id);
//               if (_.indexOf((enumKey && _.startCase(enumKey)),'0000') > 0)
//                 return _.replace((enumKey && _.startCase(enumKey)) || "" ,'0000','0,000');
//               else
//                 return _.replace((enumKey && _.startCase(enumKey)) || "" ,'000',',000');
//     }
// }
const overrideBulletOption = { Empty: 0, NoBullet: 1};
export class OverrideBulletOption {
    static get Empty () {return overrideBulletOption.Empty;}
    static get NoBullet () {return overrideBulletOption.NoBullet;}

    static get lookupItems () {
        return _.map(overrideBulletOption, c => { return { id: c, name: OverrideBulletOption.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(overrideBulletOption, cm => cm === id);
              return _.replace((enumKey && _.startCase(enumKey)) || "" ,'Empty','');
    }
}



const calculationRoundingMethod = { None: 5, RoundUpTo01: 1, RoundUpTo10: 2, RoundUpTo100: 3, Custom: 4};
export class CalculationRoundingMethod {
    static get None () {return calculationRoundingMethod.None;}
    static get RoundUpTo01 () {return calculationRoundingMethod.RoundUpTo01;}
    static get RoundUpTo10 () {return calculationRoundingMethod.RoundUpTo10;}
    static get RoundUpTo100 () {return calculationRoundingMethod.RoundUpTo100;}
    static get Custom () {return calculationRoundingMethod.Custom;}

    static get lookupItems () {
        return _.map(calculationRoundingMethod, c => { return { id: c, name: CalculationRoundingMethod.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(calculationRoundingMethod, cm => cm === id);
        let value = _.replace((enumKey && _.startCase(enumKey)) || "" ,'100','1.00');
        value = _.replace(value ,'10','.10');
        value = _.replace(value ,'01','.01');
        return value;
    }
}

const transferTaxType = { County: 0, City: 1, State: 2};
export class TransferTaxType {
    static get County () {return transferTaxType.County;}
    static get City () {return transferTaxType.City;}
    static get State () {return transferTaxType.State;}

    static get lookupItems () {
        return _.map(transferTaxType, c => { return { id: c, name: TransferTaxType.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(transferTaxType, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}

const hud1974TaxType = { None: 0, Line1202CityCountyDeed: 1, Line1202CityCountyMortgage: 2, Line1203StateDeed: 3, Line1203StateMortgage: 4, AdditionalTransferTax: 5};
export class Hud1974TaxType {
    static get None () {return hud1974TaxType.None;}
    static get Line1202CityCountyDeed () {return hud1974TaxType.Line1202CityCountyDeed;}
    static get Line1202CityCountyMortgage () {return hud1974TaxType.Line1202CityCountyMortgage;}
    static get Line1203StateDeed () {return hud1974TaxType.Line1203StateDeed;}
    static get Line1203StateMortgage () {return hud1974TaxType.Line1203StateMortgage;}
    static get AdditionalTransferTax () {return hud1974TaxType.AdditionalTransferTax;}

    static get lookupItems () {
        return _.map(hud1974TaxType, c => { return { id: c, name: Hud1974TaxType.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(hud1974TaxType, cm => cm === id);
        let value = _.replace((enumKey && _.startCase(enumKey)) || "" ,'City County','City/County');
        return value;
    }
}

const hud2010TaxType = { None: 0, Line1204CityCountyDeed: 1, Line1204CityCountyMortgage: 2, Line1205StateDeed: 3, Line1205StateMortgage: 4, AdditionalTransferTax: 5};
export class Hud2010TaxType {
    static get None () {return hud2010TaxType.None;}
    static get Line1204CityCountyDeed () {return hud2010TaxType.Line1204CityCountyDeed;}
    static get Line1204CityCountyMortgage () {return hud2010TaxType.Line1204CityCountyMortgage;}
    static get Line1205StateDeed () {return hud2010TaxType.Line1205StateDeed;}
    static get Line1205StateMortgage () {return hud2010TaxType.Line1205StateMortgage;}
    static get AdditionalTransferTax () {return hud2010TaxType.AdditionalTransferTax;}

    static get lookupItems () {
        return _.map(hud2010TaxType, c => { return { id: c, name: Hud2010TaxType.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(hud2010TaxType, cm => cm === id);
        let value = _.replace((enumKey && _.startCase(enumKey)) || "" ,'City County','City/County');
        return value;
    }
}

const cdfTaxType = { None: 0, E02CityTransferTax: 1, E02CountyTransferTax: 2, E02StateTransferTax: 3, AdditionalTransferTax: 5};
export class CDFTaxType {
    static get None () {return cdfTaxType.None;}
    static get E02CityTransferTax () {return cdfTaxType.E02CityTransferTax;}
    static get E02CountyTransferTax () {return cdfTaxType.E02CountyTransferTax;}
    static get E02StateTransferTax () {return cdfTaxType.E02StateTransferTax;}
    static get AdditionalTransferTax () {return cdfTaxType.AdditionalTransferTax;}

    static get lookupItems () {

        return _.map(cdfTaxType, c => { return {
            id: c,
            name: CDFTaxType.displayValue(c)
        }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(cdfTaxType, cm => cm === id);
        let displayText = _.startCase(enumKey).split("E 02").join("E02");
        return (enumKey && displayText) || "";
    }
}

const cssTaxType = { None: 0, CityTransferTax: 1, CountyTransferTax: 2, StateTransferTax: 3, AdditionalTransferTax: 4};
export class CSSTaxType {
    static get None () {return cssTaxType.None;}
    static get CityTransferTax () {return cssTaxType.CityTransferTax;}
    static get CountyTransferTax () {return cssTaxType.CountyTransferTax;}
    static get StateTransferTax () {return cssTaxType.StateTransferTax;}
    static get AdditionalTransferTax () {return cssTaxType.AdditionalTransferTax;}

    static get lookupItems () {
        return _.map(cssTaxType, c => { return { id: c, name: CSSTaxType.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(cssTaxType, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const transferTaxPercentageBasedOn = { LoanAmount: 1, SalesPrice: 2};
export class TransferTaxPercentageBasedOn {
    static get LoanAmount () {return transferTaxPercentageBasedOn.LoanAmount;}
    static get SalesPrice () {return transferTaxPercentageBasedOn.SalesPrice;}

    static get lookupItems () {
        return _.map(transferTaxPercentageBasedOn, c => { return { id: c, name: TransferTaxPercentageBasedOn.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(transferTaxPercentageBasedOn, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}


const roundingRule = { BLANK: 5, RoundUp: 1, RoundOff: 2, DoNotRound: 3, Round: 4,  RoundUpTo01: 7, RoundUpTo10: 8, RoundUpTo100: 9};
export class RoundingRule {
    static get RoundUp () {return roundingRule.RoundUp;}
    static get RoundOff () {return roundingRule.RoundOff;}
    static get DoNotRound () {return roundingRule.DoNotRound;}
    static get Round () {return roundingRule.Round;}
    static get BLANK () {return roundingRule.BLANK;}
    static get RoundUpTo01 () {return roundingRule.RoundUpTo01;}
    static get RoundUpTo10 () {return roundingRule.RoundUpTo10;}
    static get RoundUpTo100 () {return roundingRule.RoundUpTo100;}

    static get lookupItems () {
        return _.map(roundingRule, c => { return { id: c, name: RoundingRule.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(roundingRule, cm => cm === id);
        let value = _.replace((enumKey && _.startCase(enumKey)) || "" ,'100','1.00');
        value = _.replace(value ,'10','.10');
        value = _.replace(value ,'01','.01');
        value = _.replace(value ,'BLANK','');
        return value;
    }
}
const roundingRowType = { None: 0, CoverageRounding: 1, EndorsementChargesSettlementFeeRounding: 2};
export class RoundingRowType {
    static get None () {return roundingRowType.None;}
    static get CoverageRounding () {return roundingRowType.CoverageRounding;}
    static get EndorsementChargesSettlementFeeRounding () {return roundingRowType.EndorsementChargesSettlementFeeRounding;}

    static get lookupItems () {
        return _.map(roundingRowType, c => { return { id: c, name: RoundingRowType.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(roundingRowType, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const rateEngineFileTypeName = { RamQuest: 1, RamQuestCustom: 2, UserDefined: 3 };
export class RateEngineFileTypeName {
    static get RamQuest () {return rateEngineFileTypeName.RamQuest;}
    static get RamQuestCustom () {return rateEngineFileTypeName.RamQuestCustom;}
    static get UserDefined () {return rateEngineFileTypeName.UserDefined;}

    static get lookupItems () {
        return _.map(rateEngineFileTypeName, c => { return { id: c, name: RateEngineFileTypeName.displayValue(c) }; });
    }

    static displayValue (id) {
              let enumKey = _.findKey(rateEngineFileTypeName, cm => cm === id);
              return (enumKey && _.startCase(enumKey)) || "";
    }
}

const rateEngineReissueCreditCalculationMethod = { Amount: 1, Percentage: 4, YearAndAmount: 3, YearAndPercentage: 2, YearAndRatePer: 5 };
export class RateEngineReissueCreditCalculationMethod {
    static get Amount () {return rateEngineReissueCreditCalculationMethod.Amount;}
    static get YearAndPercentage () {return rateEngineReissueCreditCalculationMethod.YearAndPercentage;}
    static get YearAndAmount () {return rateEngineReissueCreditCalculationMethod.YearAndAmount;}
    static get Percentage () {return rateEngineReissueCreditCalculationMethod.Percentage;}
    static get YearAndRatePer () {return rateEngineReissueCreditCalculationMethod.YearAndRatePer;}

    static get lookupItems () {
        return _.map(rateEngineReissueCreditCalculationMethod, c => { return { id: c, name: RateEngineReissueCreditCalculationMethod.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(rateEngineReissueCreditCalculationMethod, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const premiumPercentageExcessBasedOn = { LoanAmount: false, SalesPrice: true};
export class PremiumPercentageExcessBasedOn {
    static get LoanAmount () {return premiumPercentageExcessBasedOn.LoanAmount;}
    static get SalesPrice () {return premiumPercentageExcessBasedOn.SalesPrice;}

    static get lookupItems () {
        return _.map(premiumPercentageExcessBasedOn, c => { return { id: c, name: PremiumPercentageExcessBasedOn.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(premiumPercentageExcessBasedOn, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const effectiveRateCalculationBasis = { AmountPer: false, Percentage: true};
export class EffectiveRateCalculationBasis {
    static get AmountPer () {return effectiveRateCalculationBasis.AmountPer;}
    static get Percentage () {return effectiveRateCalculationBasis.Percentage ;}

    static get lookupItems () {
        return _.map(effectiveRateCalculationBasis, c => { return { id: c, name: EffectiveRateCalculationBasis.displayValue(c) }; });
    }

    static displayValue (id) {
        switch (id) {
            case false:
                return "Amount/Per";
            case true:
                return "Percentage";
        }
        return "Unknown";
    }
}

const rateCreateType = { NewRate: 0, NewEffectiveDate: 1, CopyRate: 2, LinkRate: 3, ReissueCreditsFromRate: 4, UnderwriterSplitsFromRate: 5 };
export class RateCreateType {
    static get NewRate () {return rateCreateType.NewRate;}
    static get NewEffectiveDate () {return rateCreateType.NewEffectiveDate;}
    static get CopyRate () {return rateCreateType.CopyRate;}
    static get LinkRate () {return rateCreateType.LinkRate;}
    static get ReissueCreditsFromRate () {return rateCreateType.ReissueCreditsFromRate;}
    static get UnderwriterSplitsFromRate () {return rateCreateType.UnderwriterSplitsFromRate;}
}

const invoiceEnding = { NewOnly: 0, UnpaidOnly: 1, EditUnrestricted: 2};
export class InvoiceEnding {
    static get None () {return invoiceEnding.NewOnly;}
    static get CoverageRounding () {return invoiceEnding.UnpaidOnly;}
    static get EndorsementChargesSettlementFeeRounding () {return invoiceEnding.EditUnrestricted;}

    static get lookupItems () {
        return _.map(invoiceEnding, c => { return { id: c, name: InvoiceEnding.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(invoiceEnding, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const invoiceEditing = { NewOnly: 0, UnpaidOnly: 1, EditUnrestricted: 2};
export class InvoiceEditing {
    static get NewOnly () {return invoiceEditing.NewOnly;}
    static get UnpaidOnly () {return invoiceEditing.UnpaidOnly;}
    static get EditUnrestricted () {return invoiceEditing.EditUnrestricted;}

    static get lookupItems () {
        return _.map(invoiceEditing, c => { return { id: c, name: InvoiceEditing.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(invoiceEditing, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const invoiceDeletion = { CannotDelete: 0, DeleteUnpaidOnly: 1, DeleteUnrestricted: 2};
export class InvoiceDeletion {
    static get CannotDelete () {return invoiceDeletion.CannotDelete;}
    static get DeleteUnpaidOnly () {return invoiceDeletion.DeleteUnpaidOnly;}
    static get DeleteUnrestricted () {return invoiceDeletion.DeleteUnrestricted;}

    static get lookupItems () {
        return _.map(invoiceDeletion, c => { return { id: c, name: InvoiceDeletion.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(invoiceDeletion, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const invoicePayment = { CannotEnter: 0, EnterNewOnly: 1, PaymentUnrestricted: 2};
export class InvoicePayment {
    static get CannotEnter () {return invoicePayment.CannotEnter;}
    static get EnterNewOnly () {return invoicePayment.EnterNewOnly;}
    static get PaymentUnrestricted () {return invoicePayment.PaymentUnrestricted;}

    static get lookupItems () {
        return _.map(invoicePayment, c => { return { id: c, name: InvoicePayment.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(invoicePayment, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const creditMemoEdit = { Create: 0, Restricted: 1, Unrestricted: 2};
export class CreditMemoEdit {
    static get Create () {return creditMemoEdit.Create;}
    static get Restricted () {return creditMemoEdit.Restricted;}
    static get Unrestricted () {return creditMemoEdit.Unrestricted;}

    static get lookupItems () {
        return _.map(creditMemoEdit, c => { return { id: c, name: CreditMemoEdit.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(creditMemoEdit, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const workflowDashboardIndicator = { Individual: 1, Department: 2, IndividualAndDepartment: 3};
export class WorkflowDashboardIndicator {
    static get Individual () {return workflowDashboardIndicator.Individual;}
    static get Department () {return workflowDashboardIndicator.Department;}
    static get IndividualAndDepartment () {return workflowDashboardIndicator.IndividualAndDepartment;}

    static get lookupItems () {
        return _.map(workflowDashboardIndicator, c => { return { id: c, name: WorkflowDashboardIndicator.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(workflowDashboardIndicator, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const policyEditing = { CannotEdit: 0, CanEditPriorToLockdown: 1, NoRestriction: 2};
export class PolicyEditing {
    static get CannotEdit () {return policyEditing.CannotEdit;}
    static get CanEditPriorToLockdown () {return policyEditing.CanEditPriorToLockdown;}
    static get NoRestriction () {return policyEditing.NoRestriction;}

    static get lookupItems () {
        return _.map(policyEditing, c => { return { id: c, name: PolicyEditing.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(policyEditing, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const policyCreation = { CannotGeneratePolicyNumbers: 0, MustGeneratePolicyNumber: 1, CanManuallyEnterValidated: 2, NoRestriction: 3};
export class PolicyCreation {
    static get CannotGeneratePolicyNumbers () {return policyCreation.CannotGeneratePolicyNumbers;}
    static get MustGeneratePolicyNumber () {return policyCreation.MustGeneratePolicyNumber;}
    static get CanManuallyEnterValidated () {return policyCreation.CanManuallyEnterValidated;}
    static get NoRestriction () {return policyCreation.NoRestriction;}

    static get lookupItems () {
        return _.map(policyCreation, c => { return { id: c, name: PolicyCreation.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(policyCreation, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const escrowLockOptions = { All: 0, LockOnly: 1, UnlockOnly: 2, None: 4};
export class EscrowLockOptions {
    static get All () {return escrowLockOptions.All;}
    static get LockOnly () {return escrowLockOptions.LockOnly;}
    static get UnlockOnly () {return escrowLockOptions.UnlockOnly;}
    static get None () {return escrowLockOptions.None;}

    static get lookupItems () {
        return _.map(escrowLockOptions, c => { return { id: c, name: EscrowLockOptions.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(escrowLockOptions, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const depositEditingOptions = { NoChange: 0, ChangeNonBooked: 1, ChangeUnverifiedUnreconciled: 2};
export class DepositEditingOptions {
    static get NoChange () {return depositEditingOptions.NoChange;}
    static get ChangeNonBooked () {return depositEditingOptions.ChangeNonBooked;}
    static get ChangeUnverifiedUnreconciled () {return depositEditingOptions.ChangeUnverifiedUnreconciled;}

    static get lookupItems () {
        return _.map(depositEditingOptions, c => { return { id: c, name: DepositEditingOptions.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(depositEditingOptions, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const wireOutOptions = { WireOutDate: 0, ExpectedWireOutDate: 1, CanUseEitherDate: 2};
export class WireOutOptions {
    static get ExpectedWireOutDate () {return wireOutOptions.ExpectedWireOutDate;}
    static get WireOutDate () {return wireOutOptions.WireOutDate;}
    static get CanUseEitherDate () {return wireOutOptions.CanUseEitherDate;}

    static get lookupItems () {
        return _.map(wireOutOptions, c => { return { id: c, name: WireOutOptions.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(wireOutOptions, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const displayAnticipatedDeposits = { None: 0, Full: 1, Display: 2};
export class DisplayAnticipatedDeposits {
    static get None () {return displayAnticipatedDeposits.None;}
    static get Full () {return displayAnticipatedDeposits.Full;}
    static get Display () {return displayAnticipatedDeposits.Display;}

    static get lookupItems () {
        return _.map(displayAnticipatedDeposits, c => { return { id: c, name: DisplayAnticipatedDeposits.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(displayAnticipatedDeposits, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const functionRestrict = { None: 0, TitleOnly: 1, EscrowOnly: 2};
export class FunctionRestrict {
    static get None () {return functionRestrict.None;}
    static get TitleOnly () {return functionRestrict.TitleOnly;}
    static get EscrowOnly () {return functionRestrict.EscrowOnly;}

    static get lookupItems () {
        return _.map(functionRestrict, c => { return { id: c, name: FunctionRestrict.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(functionRestrict, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const fileLockOptions = { All: 0, LockOnly: 1, UnlockOnly: 2, None: 3};
export class FileLockOptions {
    static get All () {return fileLockOptions.All;}
    static get LockOnly () {return fileLockOptions.LockOnly;}
    static get UnlockOnly () {return fileLockOptions.UnlockOnly;}
    static get None () {return fileLockOptions.None;}

    static get lookupItems () {
        return _.map(fileLockOptions, c => { return { id: c, name: FileLockOptions.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(fileLockOptions, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}


const defaultUserSecurity = { NoAccess: 0, ReadOnly: 1, FullAccess: 2};
export class DefaultUserSecurity {
    static get NoAccess () {return defaultUserSecurity.NoAccess;}
    static get ReadOnly () {return defaultUserSecurity.ReadOnly;}
    static get FullAccess () {return defaultUserSecurity.FullAccess;}

    static get lookupItems () {
        return _.map(defaultUserSecurity, c => { return { id: c, name: DefaultUserSecurity.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(defaultUserSecurity, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}
export const UserGroupSecurityEnum = { NoAccess: false, FullAccess: true};

const documentSource = { RamQuest: 1, User: 2, ARC: 3 };
export class DocumentSource {
    static get RamQuest () { return documentSource.RamQuest; }
    static get User () { return documentSource.User; }
    static get ARC () { return documentSource.ARC; }

    static get lookupItems () {
        console.warn("The DocumentSource enum only matches the base server-side enum values and is for conditional comparison only.  For lookup items, lookupHelper.getLookupItems(lookupItems.DOCUMENT_SOURCES) so any ListCategoryItems are included.")
        return [];
    }

    static displayValue (id) {
        let enumKey = _.findKey(documentSource, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}


const jobTypes = { ClosingAgent: -1, EscrowOfficer: -2, EscrowProcessor: -3, SalesRepresentative: -4, TitleOfficer: -5, TitleProcessor: -6, Notary: -7};
export class JobTypes {
    static get ClosingAgent () {return jobTypes.ClosingAgent;}
    static get EscrowOfficer () {return jobTypes.EscrowOfficer;}
    static get EscrowProcessor () {return jobTypes.EscrowProcessor;}
    static get SalesRepresentative () {return jobTypes.SalesRepresentative;}
    static get TitleOfficer () {return jobTypes.TitleOfficer;}
    static get TitleProcessor () {return jobTypes.TitleProcessor;}
    static get Notary () {return jobTypes.Notary;}

    static get lookupItems () {
        return _.map(jobTypes, c => { return { id: c, name: JobTypes.displayValue(c) }; });
    }

    static lookupName(id) {
        switch(id) {
            case jobTypes.ClosingAgent: return LookupNames.CLOSING_AGENTS;
            case jobTypes.EscrowOfficer: return LookupNames.ESCROW_OFFICERS;
            case jobTypes.EscrowProcessor: return LookupNames.ESCROW_PROCESSORS;
            case jobTypes.SalesRepresentative: return LookupNames.SALES_REPS;
            case jobTypes.TitleOfficer: return LookupNames.TITLE_OFFICERS;
            case jobTypes.TitleProcessor: return LookupNames.TITLE_PROCESSORS;
            case jobTypes.Notary: return LookupNames.NOTARIES;
        }
    }

    static displayValue (id) {
        let enumKey = _.findKey(jobTypes, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}



const onboardingRoles = { OrderProcessor: 1, TitleSearcher: 2, Commitment: 3, Closer: 4, PostClosing: 5, Accountant: 6, Manager: 7, Administrator: 8};
export class OnboardingRoles {
    static get OrderProcessor () {return OnboardingRoles.OrderProcessor;}
    static get TitleSearcher () {return OnboardingRoles.TitleSearcher;}
    static get Commitment () {return OnboardingRoles.Commitment;}
    static get Closer () {return OnboardingRoles.Closer;}
    static get PostClosing () {return OnboardingRoles.PostClosing;}
    static get Accountant () {return OnboardingRoles.Accountant;}
    static get Manager () {return OnboardingRoles.Manager;}
    static get Administrator () {return OnboardingRoles.Administrator;}

    static get lookupItems () {
        return _.map(onboardingRoles, c => { return { id: c, name: OnboardingRoles.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(onboardingRoles, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const intentionallyDeletedClausesOptions = { Restricted : 0, PrintStrikethrough: 1, DoNotPrint: 2};
export class IntentionallyDeletedClausesOptions {
    static get Restricted () {return intentionallyDeletedClausesOptions.Restricted;}
    static get PrintStrikethrough () {return intentionallyDeletedClausesOptions.PrintStrikethrough;}
    static get DoNotPrint () {return intentionallyDeletedClausesOptions.DoNotPrint;}

    static get lookupItems () {
        return _.map(intentionallyDeletedClausesOptions, c => { return { id: c, name: IntentionallyDeletedClausesOptions.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(intentionallyDeletedClausesOptions, cm => cm === id);
        return (enumKey && (id===2 ? "Don't Print Clause" : _.startCase(enumKey))) || "";
    }
}

const doNotDoBusinessWithTypes = { Name : 0, Address: 1, Entity: 2, Company: 3};
export class DoNotDoBusinessWithType {
    static get Name () {return doNotDoBusinessWithTypes.Name;}
    static get Address () {return doNotDoBusinessWithTypes.Address;}
    static get Entity () {return doNotDoBusinessWithTypes.Entity;}
    static get Company () {return doNotDoBusinessWithTypes.Company;}

    static get lookupItems () {
        return _.map(doNotDoBusinessWithTypes, t => { return { id: t, name: DoNotDoBusinessWithType.displayValue(t) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(doNotDoBusinessWithTypes, bwt => bwt === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

const multiFactorAuthTypes = { SMS : 1, Email: 2};
export class MultiFactorAuthTypes {
    static get SMS () {return multiFactorAuthTypes.SMS;}
    static get Email () {return multiFactorAuthTypes.Email;}

    static get lookupItems () {
        return _.map(multiFactorAuthTypes, t => { return { id: t, name: MultiFactorAuthTypes.displayValue(t) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(multiFactorAuthTypes, bwt => bwt === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}


const epicorStateList = { General: 0, Alabama: 1, Arizona: 2, Arkansas: 3, California: 4, Colorado: 5, Connecticut: 6, Delaware: 7, DistrictOfColumbia: 8,
                            Florida: 9, Georgia: 10, Idaho: 11, Illinois: 12, Indiana: 13, Iowa: 14, Kansas: 15, Kentucky: 16, Louisiana: 17, Maine: 18,
                            Maryland: 19, Massachusetts: 20, Michigan: 21, Minnesota: 22, Mississippi: 23, Missouri: 24, Montana: 25, Nebraska: 26,
                            Nevada: 27, NewHampshire: 28, NewJersey: 29, NewMexico: 30, NewYork: 31, NorthCarolina: 32, NorthDakota: 33, Ohio: 34,
                            Oklahoma: 35, Oregon: 36, Pennsylvania: 37, RhodeIsland: 38, SouthCarolina: 39, SouthDakota: 40, Tennessee: 41, Texas: 42,
                            Utah: 43, Vermont: 44, Virginia: 45, Washington: 46, WestVirginia: 47, Wisconsin: 48, Wyoming: 49, Hawaii: 50, PuertoRico: 51,
                            Mexico: 52, Alaska: 53, Guam: 54};
export class EpicorStateList {
    static get General () {return epicorStateList.General;}

    static get lookupItems () {
        return _.orderBy(_.map(epicorStateList, c => { return { id: c, name: EpicorStateList.displayValue(c) }; }), o => o.name.toLowerCase());
    }

    static displayValue (id) {
        switch (id) {
            case 0:
                return "General-0";
            case 1:
                return "Alabama-1";
            case 2:
                return "Arizona-2";
            case 3:
                return "Arkansas-3";
            case 4:
                return "California-4";
            case 5:
                return "Colorado-5";
            case 6:
                return "Connecticut-6";
            case 7:
                return "Delaware-7";
            case 8:
                return "District Of Columbia-8"
            case 9:
                return "Florida-9";
            case 10:
                return "Georgia-10";
            case 11:
                return "Idaho-11";
            case 12:
                return "Illinois-12";
            case 13:
                return "Indiana-13";
            case 14:
                return "Iowa-14";
            case 15:
                return "Kansas-15";
            case 16:
                return "Kentucky-16";
            case 17:
                return "Louisiana-17";
            case 18:
                return "Maine-18";
            case 19:
                return "Maryland-19";
            case 20:
                return "Massachusetts-20";
            case 21:
                return "Michigan-21";
            case 22:
                return "Minnesota-22";
            case 23:
                return "Mississippi-23";
            case 24:
                return "Missouri-24";
            case 25:
                return "Montana-25";
            case 26:
                return "Nebraska-26";
            case 27:
                return "Nevada-27";
            case 28:
                return "New Hampshire-28";
            case 29:
                return "New Jersey-29";
            case 30:
                return "New Mexico-30";
            case 31:
                return "New York-31";
            case 32:
                return "North Carolina-32";
            case 33:
                return "North Dakota-33";
            case 34:
                return "Ohio-34";
            case 35:
                return "Oklahoma-35";
            case 36:
                return "Oregon-36";
            case 37:
                return "Pennsylvania-37";
            case 38:
                return "Rhode Island-38";
            case 39:
                return "South Carolina-39";
            case 40:
                return "South Dakota-40";
            case 41:
                return "Tennessee-41";
            case 42:
                return "Texas-42";
            case 43:
                return "Utah-43";
            case 44:
                return "Vermont-44";
            case 45:
                return "Virginia-45";
            case 46:
                return "Washington-46";
            case 47:
                return "West Virginia-47";
            case 48:
                return "Wisconsin-48";
            case 49:
                return "Wyoming-49";
            case 50:
                return "Hawaii-50";
            case 51:
                return "Puerto Rico-51";
            case 52:
                return "Mexico-52";
            case 53:
                return "Alaska-53";
            case 54:
                return "Guam-54";
        }
        return "Unknown";
    }
}
export class EpicorStateListAbbr {
    static get General () {return epicorStateList.General;}

    static get lookupItems () {
        return _.orderBy(_.map(epicorStateList, c => { return { id:  EpicorStateListAbbr.idValue(c) , name: EpicorStateListAbbr.displayValue(c) }; }), o => o.name.toLowerCase());
    }

    static idValue (id) {
        switch (id) {
            case 0:
                return "GG";
            case 1:
                return "AL";
            case 2:
                return "AZ";
            case 3:
                return "AR";
            case 4:
                return "CA";
            case 5:
                return "CO";
            case 6:
                return "CT";
            case 7:
                return "DE";
            case 8:
                return "DC"
            case 9:
                return "FL";
            case 10:
                return "GA";
            case 11:
                return "ID";
            case 12:
                return "IL";
            case 13:
                return "IN";
            case 14:
                return "IA";
            case 15:
                return "KS";
            case 16:
                return "KY";
            case 17:
                return "LA";
            case 18:
                return "ME";
            case 19:
                return "MD";
            case 20:
                return "MA";
            case 21:
                return "MI";
            case 22:
                return "MN";
            case 23:
                return "MS";
            case 24:
                return "MO";
            case 25:
                return "MT";
            case 26:
                return "NE";
            case 27:
                return "NV";
            case 28:
                return "NH";
            case 29:
                return "NJ";
            case 30:
                return "NM";
            case 31:
                return "NY";
            case 32:
                return "NC";
            case 33:
                return "ND";
            case 34:
                return "OH";
            case 35:
                return "OK";
            case 36:
                return "OR";
            case 37:
                return "PA";
            case 38:
                return "RI";
            case 39:
                return "SC";
            case 40:
                return "SD";
            case 41:
                return "TN";
            case 42:
                return "TX";
            case 43:
                return "UT";
            case 44:
                return "VT";
            case 45:
                return "VA";
            case 46:
                return "WA";
            case 47:
                return "WV";
            case 48:
                return "WI";
            case 49:
                return "WY";
            case 50:
                return "HI";
            case 51:
                return "PR";
            case 52:
                return "MM";
            case 53:
                return "AK";
            case 54:
                return "GU";
        }
        return "None";
    }
    static displayValue (id) {
        switch (id) {
            case 0:
                return "GG - General";
            case 1:
                return "AL - Alabama";
            case 2:
                return "AZ - Arizona";
            case 3:
                return "AR - Arkansas";
            case 4:
                return "CA - California";
            case 5:
                return "CO - Colorado";
            case 6:
                return "CT - Connecticut";
            case 7:
                return "DE - Delaware";
            case 8:
                return "DC - District of Columbia"
            case 9:
                return "FL - Florida";
            case 10:
                return "GA - Georgia";
            case 11:
                return "ID - Idaho";
            case 12:
                return "IL - Illinois";
            case 13:
                return "IN - Indiana";
            case 14:
                return "IA - Iowa";
            case 15:
                return "KS - Kansas";
            case 16:
                return "KY - Kentucky";
            case 17:
                return "LA - Louisiana";
            case 18:
                return "ME - Maine";
            case 19:
                return "MD - Maryland";
            case 20:
                return "MA - Massachusetts";
            case 21:
                return "MI - Michigan";
            case 22:
                return "MN - Minnesota";
            case 23:
                return "MS - Mississippi";
            case 24:
                return "MO - Missouri";
            case 25:
                return "MT - Montana";
            case 26:
                return "NE - Nebraska";
            case 27:
                return "NV - Nevada";
            case 28:
                return "NH - New Hampshire";
            case 29:
                return "NJ - New Jersey";
            case 30:
                return "NM - New Mexico";
            case 31:
                return "NY - New York";
            case 32:
                return "NC - North Carolina";
            case 33:
                return "ND - North Dakota";
            case 34:
                return "OH - Ohio";
            case 35:
                return "OK - Oklahoma";
            case 36:
                return "OR - Oregon";
            case 37:
                return "PA - Pennsylvania";
            case 38:
                return "RI - Rhode Island";
            case 39:
                return "SC - South Carolina";
            case 40:
                return "SD - South Dakota";
            case 41:
                return "TN - Tennessee";
            case 42:
                return "TX - Texas";
            case 43:
                return "UT - Utah";
            case 44:
                return "VT - Vermont";
            case 45:
                return "VA - Virginia";
            case 46:
                return "WA - Washington";
            case 47:
                return "WV - West Virginia";
            case 48:
                return "WI - Wisconsin";
            case 49:
                return "WY - Wyoming";
            case 50:
                return "HI - Hawaii";
            case 51:
                return "PR - Puerto Rico";
            case 52:
                return "MM - Mexico";
            case 53:
                return "AK - Alaska";
            case 54:
                return "GU - Guam";
        }
        return "None";
    }
}

const standbyFeesAndTaxYearTypes = { LastYear : 0, CurrentYear: 1, NextYear: 2, YearAfterNextYear: 3};
export class StandbyFeesAndTaxYearType {
    static get LastYear () {return standbyFeesAndTaxYearTypes.LastYear;}
    static get CurrentYear () {return standbyFeesAndTaxYearTypes.CurrentYear;}
    static get NextYear () {return standbyFeesAndTaxYearTypes.NextYear;}
    static get YearAfterNextYear () {return standbyFeesAndTaxYearTypes.YearAfterNextYear;}

    static get lookupItems () {
        return _.map(standbyFeesAndTaxYearTypes, t => { return { id: t, name: StandbyFeesAndTaxYearType.displayValue(t) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(standbyFeesAndTaxYearTypes, bwt => bwt === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }

    static getYear(id) {
        const currentYear = new Date().getFullYear();

        switch(id){
            case this.LastYear:
                return currentYear - 1;
            case null:
            case this.CurrentYear:
                return currentYear;
            case this.NextYear:
                return currentYear + 1;
            case this.YearAfterNextYear:
                return currentYear + 2;
        }

        return null;
    }
}

const pavasoSigningTypes = { InPersonElectronicNotarization : 1, Paper: 2, RemoteOnlineNoterization: 3, MultiDeviceClosing: 4, RemoteInkNotarization: 5 };
export class PavasoSigningType {
    static get InPersonElectronicNotarization () {return pavasoSigningTypes.InPersonElectronicNotarization;}
    static get Paper () {return pavasoSigningTypes.Paper;}
    static get RemoteOnlineNoterization () {return pavasoSigningTypes.RemoteOnlineNoterization;}
    static get MultiDeviceClosing () {return pavasoSigningTypes.MultiDeviceClosing;}
    static get RemoteInkNotarization () {return pavasoSigningTypes.RemoteInkNotarization;}

    static get lookupItems () {
        return _.map(pavasoSigningTypes, t => { return { id: t, name: PavasoSigningType.displayValue(t) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(pavasoSigningTypes, bwt => bwt === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}
