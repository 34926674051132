import mitt from "mitt";

export const GLOBAL_EVENTS = {
    CREATE_NEW: "rqapp::CreateNew",
	SAVE: "rqapp::Save",
	DELETE: "rqapp::Delete",
	SAVE_COMPLETED: "rqapp::SaveCompleted",
	CANCEL: "rqapp::Cancel",
	CONFIRM_CANCEL: "rqapp::ConfirmCancel",
	PRINT: "rqapp::Print",
	BUSY_START: "rqapp::BusyStart",
	BUSY_END: "rqapp::BusyEnd",
	CONFIRM_DIALOG: "rqapp::ConfirmDialog",
	NAVIGATED: "rqapp::Navigated",
	EMAIL: "rqapp::Email",
	SHOW_DIALOG: "rqapp::ShowDialog",
	USER_AUTHENTICATED: "rqapp::UserAuthenticated",
	SHOW_MESSAGE_BOX: "rqapp::ShowMessageBox",
	NEW_ORDER: "rqapp::NewOrder",
	NEW_SECONDARY_ORDER: "rqapp::NewSecondaryOrder",
	FIRE_ACTION: "rqapp::FireAction",
	SAVE_DOCUMENTS: "rqapp::save-documents",
	FILE_NUMBER_PROMPT: "rqapp::file-number-prompt",
    REGISTER_TOOLTIP: "rqapp::register-tooltip",
    UPDATE_TOOLTIP: "rqapp::update-tooltip",
    REMOVE_TOOLTIP: "rqapp::remove-tooltip",
    FILE_AVAILABLE: "rqapp::file-available",
    AMORTIZATION_CALCULATOR: "rqapp::AmortizationCalculator",
};

const emitter = mitt();
class RqEventManager {
    constructor() {
        this.registeredEvents = [];
        this.save = _.debounce(this._save, 200, { 'leading': true, 'trailing': false });
    }

    getEventMethod(eventName) {
        switch(eventName) {
            case GLOBAL_EVENTS.CREATE_NEW: return "onCreateNew";
            case GLOBAL_EVENTS.SAVE: return "onSave";
            case GLOBAL_EVENTS.DELETE: return "onDelete";
            case GLOBAL_EVENTS.SAVE_COMPLETED: return "onSaveCompleted";
            case GLOBAL_EVENTS.CANCEL: return "onCancel";
            case GLOBAL_EVENTS.CONFIRM_CANCEL: return "onConfirmCancel";
            case GLOBAL_EVENTS.PRINT: return "onPrint";
            case GLOBAL_EVENTS.BUSY_START: return "onBusyStart";
            case GLOBAL_EVENTS.BUSY_END: return "onBusyEnd";
            case GLOBAL_EVENTS.NAVIGATED: return "onNavigated";
            case GLOBAL_EVENTS.NEW_ORDER: return "onNewOrder";
            case GLOBAL_EVENTS.NEW_SECONDARY_ORDER: return "onNewSecondaryOrder";
            case GLOBAL_EVENTS.AMORTIZATION_CALCULATOR: return "onShowAmortizationCalculator";
            case GLOBAL_EVENTS.FIRE_ACTION: return "onFireAction";
        }
        return null;
    }

    fireEvent (evt) {
        switch (evt.key) {
            case 'add':
                this.createNew();
                break;
            case 'add-file':
                this.newOrder();
                break;
            case 'add-secondary-file':
                this.newSecondaryOrder();
                break;
            case 'amortization-calculator':
                this.showAmortizationCalculator();
                break;
            case 'delete':
                this.delete(evt);
                break;
            case 'save':
                this.save(evt);
                break;
            case 'cancel':
                this.cancel({});
                break;
            case 'print':
                this.print({});
                break;
            case 'clear':
                this.clear({});
                break;
            default:
                this.fireAction(evt);
                break;
        }
    }

    emit(event, ...args) {
        emitter.emit(event, ...args);
    }

    emitReturn(event, ...args) {
        const returnEvent = `${event}--return`;
        return new Promise(resolve => {
            emitter.on(returnEvent, response => {
                emitter.off(returnEvent);
                resolve(response);
            });
            emitter.emit(event, ...args);
        });
    }

    on(event, handler, sourceKey=null) {
        let source = sourceKey || _.uniqueId("rq-event-source-");
        let builtInMethod = this.getEventMethod(event);
        if(_.isNil(builtInMethod))
            this.register(source, event, handler);
        else
            this[builtInMethod](source, handler);
        return source; //key can be used to unregister groups of events
    }

    off(event, handler) {
        emitter.off(event, handler);
        _.remove(this.registeredEvents, item => item.event === event && (_.isNil(handler) || item.handler === handler));
    }

    remove(event, callback) {
        this.off(event, callback);
    }

    /**
    * Responsible for setting up an event handler for an event
    * @param {any} source - reference to the component registering the event (used to unregister all events when the component unloads).
    * @param {string} event - name of the event for which the handler should listen.
    * @param {function} handler - function to run when the event fires.
    */
    register (source, event, handler) {
        let regIssue = _.isNil(source) ? 'Source component is null or undefined.' :
            _.isEmpty(event) ? 'Event name is empty.' :
                !_.isFunction(handler) ? 'Provided handler is not a function.' :
                    '';

        if (!_.isEmpty(regIssue)) {
            console.warn(`Event registration invalid: ${source?.$options?.name || source?._hzn_uid} :: ${event} :: ${regIssue}`);
            return;
        }

        this.registeredEvents.push({
            source: _.isObject(source)
                ? source._hzn_uid
                : source,
            event,
            handler
        });
        emitter.on(event, handler);
    }

    /**
    * Responsible for unregistering all events assocaited with the specified source parameter.
    * @param {any} source - reference to the component that registered the events
    */
    unregister (source) {
        _.remove(this.registeredEvents, item => {
            if (source === item.source || (_.isObject(source) && source?._hzn_uid === item.source)) {
                if (_.isFunction(item.handler)) emitter.off(item.event, item.handler);
                return true;
            }
            return false;
        });
    }

    sourceOff (source, event, handler) {
        _.remove(this.registeredEvents, item => {
            if ((item.source === source || item.source === source?._hzn_uid) && item.event === event && item.handler === handler) {
                if (_.isFunction(item.handler)) emitter.off(item.event, item.handler);
                return true;
            }
            return false;
        });
    }

    /// ///////////////////////////////////////////////////////
    /// Clear Event Methods
    /// ///////////////////////////////////////////////////////
    clear (eventArgs) {
        this.emit('GlobalEventManager.Clear', eventArgs || {});
    }
    onClear (source, handler) {
        this.register(source, 'GlobalEventManager.Clear', handler);
    }
    offClear (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.Clear', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// CreateNew Event Methods
    /// ///////////////////////////////////////////////////////
    createNew (eventArgs) {
        this.emit('GlobalEventManager.CreateNew', eventArgs || {});
    }
    onCreateNew (source, handler) {
        this.register(source, 'GlobalEventManager.CreateNew', handler);
    }
    offCreateNew (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.CreateNew', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// Delete Event Methods
    /// ///////////////////////////////////////////////////////
    delete (eventArgs) {
        this.emit('GlobalEventManager.Delete', eventArgs || {});
    }
    onDelete (source, handler) {
        this.register(source, 'GlobalEventManager.Delete', handler);
    }
    offDelete (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.Delete', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// Save Event Methods
    /// ///////////////////////////////////////////////////////
    _save(eventArgs) {
        this.emit('GlobalEventManager.Save', eventArgs || {});
    }
    onSave (source, handler) {
        this.register(source, 'GlobalEventManager.Save', handler);
    }
    offSave (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.Save', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// SaveCompleted Event Methods
    /// ///////////////////////////////////////////////////////
    saveCompleted (eventArgs) {
        this.emit('GlobalEventManager.SaveCompleted', eventArgs || {});
    }
    onSaveCompleted (source, handler) {
        this.register(source, 'GlobalEventManager.SaveCompleted', handler);
    }
    offSaveCompleted (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.SaveCompleted', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// Cancel Event Methods
    /// ///////////////////////////////////////////////////////
    cancel (eventArgs) {
        this.emit('GlobalEventManager.Cancel', eventArgs || {});
    }
    onCancel (source, handler) {
        this.register(source, 'GlobalEventManager.Cancel', handler);
    }
    offCancel (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.Cancel', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// ConfirmCancel Event Methods
    /// ///////////////////////////////////////////////////////
    confirmCancel (eventArgs) {
        this.emit('GlobalEventManager.ConfirmCancel', eventArgs || {});
    }
    onConfirmCancel (source, handler) {
        this.register(source, 'GlobalEventManager.ConfirmCancel', handler);
    }
    offConfirmCancel (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.ConfirmCancel', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// Print Event Methods
    /// ///////////////////////////////////////////////////////
    print (eventArgs) {
        this.emit('GlobalEventManager.Print', eventArgs || {});
    }
    onPrint (source, handler) {
        this.register(source, 'GlobalEventManager.Print', handler);
    }
    offPrint (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.Print', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// Navigated Event Methods
    /// ///////////////////////////////////////////////////////
    navigated (eventArgs) {
        this.emit('GlobalEventManager.Navigated', eventArgs || {});
    }
    onNavigated (source, handler) {
        this.register(source, 'GlobalEventManager.Navigated', handler);
    }
    offNavigated (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.Navigated', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// Email Event Methods
    /// ///////////////////////////////////////////////////////
    email (eventArgs) {
        this.emit('GlobalEventManager.Email', eventArgs || {});
    }
    onEmail (source, handler) {
        this.register(source, 'GlobalEventManager.Email', handler);
    }
    offEmail (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.Email', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// UserAuthenticated Event Methods
    /// ///////////////////////////////////////////////////////
    userAuthenticated (eventArgs) {
        this.emit('GlobalEventManager.UserAuthenticated', eventArgs || {});
    }
    onUserAuthenticated (source, handler) {
        this.register(source, 'GlobalEventManager.UserAuthenticated', handler);
    }
    offUserAuthenticated (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.UserAuthenticated', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// NewOrder Event Methods
    /// ///////////////////////////////////////////////////////
    newOrder (eventArgs) {
        this.emit('GlobalEventManager.NewOrder', eventArgs || {});
    }
    onNewOrder (source, handler) {
        this.register(source, 'GlobalEventManager.NewOrder', handler);
    }
    offNewOrder (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.NewOrder', handler);
    }
    newSecondaryOrder (eventArgs) {
        this.emit('GlobalEventManager.NewSecondaryOrder', eventArgs || {});
    }
    onNewSecondaryOrder (source, handler) {
        this.register(source, 'GlobalEventManager.NewSecondaryOrder', handler);
    }
    offNewSecondaryOrder (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.NewSecondaryOrder', handler);
    }
    showAmortizationCalculator (eventArgs) {
        this.emit('GlobalEventManager.ShowAmortizationCalculator', eventArgs || {});
    }
    onShowAmortizationCalculator (source, handler) {
        this.register(source, 'GlobalEventManager.ShowAmortizationCalculator', handler);
    }
    offShowAmortizationCalculator (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.ShowAmortizationCalculator', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// FireAction Methods
    /// ///////////////////////////////////////////////////////
    fireAction (eventArgs) {
        this.emit('GlobalEventManager.FireAction', eventArgs || {});
    }
    onFireAction (source, handler) {
        this.register(source, 'GlobalEventManager.FireAction', handler);
    }
    offFireAction (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.FireAction', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// Update Methods
    /// ///////////////////////////////////////////////////////
    updateOrder (eventArgs) {
        this.emit('GlobalEventManager.UpdateOrder', eventArgs || {});
    }
    onUpdateOrder (source, handler) {
        this.register(source, 'GlobalEventManager.UpdateOrder', handler);
    }
    offUpdateOrderCompleted (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.UpdateOrder', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// Modify File Methods
    /// ///////////////////////////////////////////////////////
    modifyFile (eventArgs) {
        this.emit('GlobalEventManager.ModifyFile', eventArgs);
    }
    onModifyFile (source, handler) {
        this.register(source, 'GlobalEventManager.ModifyFile', handler);
    }
    offModifyFile (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.ModifyFile', handler);
    }

    /// ///////////////////////////////////////////////////////
    /// Busy Methods
    /// ///////////////////////////////////////////////////////
    busyStart (eventArgs) {
        this.emit('GlobalEventManager.BusyStart', eventArgs);
    }
    onBusyStart (source, handler) {
        this.register(source, 'GlobalEventManager.BusyStart', handler);
    }
    offBusyStart (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.BusyStart', handler);
    }
    busyEnd (eventArgs) {
        this.emit('GlobalEventManager.BusyEnd', eventArgs);
    }
    onBusyEnd (source, handler) {
        this.register(source, 'GlobalEventManager.BusyEnd', handler);
    }
    offBusyEnd (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.BusyEnd', handler);
    }
    /// ///////////////////////////////////////////////////////
    /// Skip save call on the router
    /// ///////////////////////////////////////////////////////
    skipSave (eventArgs) {
        this.emit('GlobalEventManager.SkipSave', eventArgs);
    }
    onSkipSave (source, handler) {
        this.register(source, 'GlobalEventManager.SkipSave', handler);
    }
    offSkipSave (source, handler) {
        this.sourceOff(source, 'GlobalEventManager.SkipSave', handler);
    }
}

export const GlobalEventManager = new RqEventManager();

const DEFAULT_PLUGIN_KEY = "rq-events-plugin";
export const RqVueEvents = {
    install(app) {
        app.config.globalProperties.$events = {
            emit: (event, ...args) => GlobalEventManager.emit(event, ...args),
            $emit: (event, ...args) => GlobalEventManager.emit(event, ...args),
            on: (event, callback, sourceKey=DEFAULT_PLUGIN_KEY) => GlobalEventManager.on(event, callback, sourceKey),
            $on: (event, callback, sourceKey=DEFAULT_PLUGIN_KEY) => GlobalEventManager.on(event, callback, sourceKey),
            off: (event, callback) => GlobalEventManager.off(event, callback),
            $off: (event, callback) => GlobalEventManager.off(event, callback),
            remove: (event, callback) => GlobalEventManager.remove(event, callback)
        };
    }
};

import { onBeforeUnmount } from "vue";
export const useRqEvent = function(name, handler, sourceKey=null) {
    if(_.isEmpty(name) || !_.isFunction(handler)) {
        throw "Composable, useRqEvent, requires a valid event name string and event handler function.";
    }
    GlobalEventManager.on(name, handler, sourceKey);
    onBeforeUnmount(() => {
        GlobalEventManager.off(name, handler);
    });
}
