﻿<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
             :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['inactive']"
            target-inactive-column="inactive"
            focus-after-insert="none"
            v-model:search-value="searchText"
            @delete="onDeleteItem"
            @go-to-file="onGoToFile"
            @activate="onActivateItem"
            integrated-search
            rq-filters
            rq-editable
            show-include-inactive
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { AutoConsolidatePayeeDto }  from "../models";
    import { MoneyMovementType } from '@config/enums';
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { h } from "vue";

    export default {
        name:"AutoConsolidatePayeesList",
        mixins: [GridCompanyPickerMixin, GridSystemLookupMixin],
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                addEventName: "",
                validationContext: {},
                newOrdersID: null,
                searchText: ""
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                allBranches: state => _.get(state, "system.lookups.branches", [])
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            regions() { return this.lookupHelpers.getRegions(); },
            escrowBanks() { return this.lookupHelpers.getAllLookupItems(this.lookupItems.ESCROW_ACCOUNTS); },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
            lossAdvanceTypes(){ return MoneyMovementType.acpLookupItems; },
            selectionActions() {
                return [
                    { name: "go-to-file", text: "Go To File", eventName: "go-to-file", requireSelection: true, tooltip: "Go to Consolidated File" },
                    { name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', true]); } },
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', false]); }  }
                ];
            },
            parentRoute() {
                let lastMatched = _.findLast(this.$route.matched);
                return {path: lastMatched.parent.path || "", category: lastMatched.meta.category || "(Unknown)"};
            },
        },

        watch: {
            validationErrors(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$events.emit("update-config-error", { message: _.isEmpty(newValue) ? "" : "Please correct the highlighted errors on screen to continue.", hasError: !_.isEmpty(newValue) });
            }
        },

        created(){
            const self = this;
            self.initGridConfig();
            self.initListeners();
            self.fetchData();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {
            onActivateItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.AutoConsolidatePayeesApi.activateAutoConsolidatePayees(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initGridConfig(){
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr: "payeeCompanyID",
                    companyNameExpr: "payeeCompanyName",
                    showContactPicker: false,
                    activeOnly: true
                };
                let branchDataSource = _.map(self.allBranches, b => {
                    let branchItem = _.clone(b);
                    let branchRegion = self.lookupHelpers.getRegion(b.regionID);
                    branchItem.regionDisplay = _.isNil(branchRegion)
                        ? "Region not found"
                        : branchRegion.description;
                    return branchItem;
                });
                let payeeBankRegionBranchRule = {
                    type: "custom",
                    message: "The selected payee/bank combination already exists for the selected region & branch.",
                    validationCallback: params => !self.payeeBankRegionBranchExists(params.data)
                };
                self.gridConfig = {
                    sorting: { enabled: true },
                    columns: [
                        {
                            dataField: "regionID",
                            dataType: "number",
                            caption: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            setCellValue(rowData, value) {
                                rowData.regionID = value;
                                rowData.branchIDs = [];
                            },
                            lookup: {
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID",
                            },
                            validationRules: [{ type: "required" }, payeeBankRegionBranchRule]
                        },
                        {
                            dataField: "branchIDs",
                            dataType: "object",
                            caption: "Branch",
                            width: 200,
                            minWidth: 100,
                            calculateFilterExpression: (filterValue, operator) => {
                                if(_.isNil(operator)) return _.isString(filterValue)
                                    ? rowData => {
                                        let displayText = self.getBranchDisplayText(rowData);
                                        return _.includes(_.toLower(displayText), _.toLower(filterValue))
                                    }
                                    : () => true;
                                return rowData => {
                                    let ids = rowData.allBranches
                                        ? _.map(_.filter(self.allBranches, b => b.regionID === rowData.regionID), "id")
                                        : rowData.branchIDs;
                                    return _.includes(ids, filterValue);
                                };
                            },
                            rqFilter: {
                                valueExpr: "branchID",
                                displayExpr: "description",
                                dataSource: branchDataSource,
                                listOperator: "or",
                                valueOperator: "contains",
                                itemTemplate: {
                                    name: "BranchItemTemplate",
                                    props: { item: Object, index: Number },
                                    setup(props) {
                                        return () => h("div", { class: "d-inline-flex align-items-baseline" }, [
                                            h("span", {class:"text-truncate"}, props.item.name),
                                            h("span", { class: "text-muted font-italic font-xs font-weight-light ms-auto text-truncate" }, props.item.regionDisplay)
                                        ]);
                                    }
                                }
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                if((_.isEmpty(cellInfo.value) && !cellInfo.data.allBranches) || _.isNil(cellInfo.data.regionID)) return;
                                const searchHighlightTemplate = '<span class="dx-datagrid-search-text">$&</span>';

                                let displayText = self.getBranchDisplayText(cellInfo.data);

                                let searchHighlighted = _.isEmpty(_.trim(self.searchText)) ? displayText : _.replace(displayText, new RegExp(self.searchText, "ig"), searchHighlightTemplate);
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayText)
                                    .append(searchHighlighted)
                                    .appendTo(cellElement);
                            },
                            setCellValue(rowData, value) {
                                rowData.allBranches = value.allBranches;
                                rowData.branchIDs = value.branchIDs;
                                rowData.branchList = _.join(value.branchIDs, ",");
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                if(_.isNil(cellInfo.data.regionID)) {
                                    $("<div/>")
                                        .addClass("d-flex align-items-center text-muted ps-1")
                                        .height(cellElement[0].clientHeight)
                                        .text("Select a region first...")
                                        .appendTo(cellElement);
                                    return;
                                }
                                let branchItems = _.filter(branchDataSource, b => b.regionID === cellInfo.data.regionID);
                                let initialValue = (_.isEmpty(cellInfo.value) && cellInfo.data.allBranches) ? _.map(branchItems, "branchID") : cellInfo.value;
                                $("<div />").dxTagBox({
                                    dataSource: branchItems,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: initialValue,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    applyValueMode: "useButtons",
                                    maxDisplayedTags: 1,
                                    onValueChanged(e) {
                                        cellInfo.setValue({
                                            branchIDs: e.value,
                                            allBranches: e.value.length === branchItems.length
                                        });
                                    }
                                }).appendTo(cellElement);
                            },
                            validationRules: [{
                                type: "custom",
                                message: "Branch is required.",
                                validationCallback: e => _.parseBool(e.data.allBranches) || !_.isEmpty(e.data.branchIDs)
                            }]
                        },
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "payeeCompanyName",
                                dataType: "string",
                                caption: "Payee",
                                validationRules: [{ type: "required" }, payeeBankRegionBranchRule]
                            },
                            ...payeePickerInfo
                        }),
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "bankCompanyID",
                                dataType: "number",
                                caption: "Escrow Account",
                                validationRules: [{ type: "required" }, payeeBankRegionBranchRule],
                            },
                            lookupKey: self.lookupItems.ESCROW_ACCOUNTS
                        }),
                        {
                            dataField: "lossAdvanceType",
                            dataType: "number",
                            caption: "Type",
                            lookup: {
                                dataSource: self.lossAdvanceTypes,
                                displayExpr: "name",
                                valueExpr: "id",
                            },
                            validationRules: [{ type: "required" }, payeeBankRegionBranchRule]
                        },
                        {
                            dataField: "gfNo",
                            dataType: "string",
                            caption: "File Number",
                            editorOptions: { maxLength: 50 },
                            validationRules: [
                                { type: "required" },
                                { type: "async", validationCallback: params => self.validateFile(params.data) }
                            ]
                        },
                        {
                            dataField: "requireAccountCode",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        {
                            dataField: "inactive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                    ],
                    onRowUpdating: self.onRowUpdating,
                    onInitNewRow: e => {
                        e.data.inactive = false;
                        e.data.lossAdvanceType = MoneyMovementType.ConsolidatedDisbursementAdjustment;
                    },
                    onEditorPreparing: e => {
                        if (e.parentType !== "dataRow" || e.dataField !== "bankCompanyID" || e.dataField !== "gfNo") return;
                        e.editorOptions.disabled = !e.row.isNewRow;
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.AutoConsolidatePayeesApi.getAutoConsolidatePayees();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new AutoConsolidatePayeeDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        console.log(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            onRowUpdating(e) {
                const self = this;
                let oldOrdersID = _.getNumber(e.oldData, "ordersID", 0);
                let hasNewExistingFile = _.getNumber(self, "newOrdersID", 0) > 0;
                let msg = hasNewExistingFile ? `This action will <b>update the file</b> for this ${self.itemTypeNamePlural}; any previous disbursements will retain the prior file number and future disbursements will be associated with this file. Do you want to continue?`
                                             : `This action will <b>create a new file</b> for this ${self.itemTypeNamePlural}; any previous disbursements will retain the prior file number and future disbursements will be associated with this new file. Do you want to continue?`;
                //RQO-17959 - only prompt if they change the OrdersID
                if (!_.isEqual(self.newOrdersID, oldOrdersID)) {
                    e.cancel = new Promise((resolve, reject) => {
                        self.$dialog.confirm(
                            "Confirm Edit",
                            msg,
                            () => resolve(false), //continue with edit
                            () => resolve(true), //cancel edit
                            { cancelTitle: 'No', okTitle: 'Yes'});
                    });
                }
            },

            onGoToFile(e) {
                this.goToConsolidatedFile(e.data.ordersID);
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onGridInsert(values) {
                const self = this;
                let originalItem = new AutoConsolidatePayeeDto();
                let newItem = new AutoConsolidatePayeeDto(values);
                if (!_.isNull(self.newOrdersID)) {
                    newItem.ordersID = self.newOrdersID;
                }
                let changes = self.getAuditChanges(originalItem.toDataObject(), newItem.toDataObject(), ["branchIDs"]);
                return self.save(newItem, changes)
                    .then(result => {
                        self.fetchData();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.autoConsolidatePayeeID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new AutoConsolidatePayeeDto(_.assign({}, self.items[itemIndex], values));
                if (!_.isNull(self.newOrdersID)) {
                    updatedItem.ordersID = self.newOrdersID;
                }
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                let saveChanges = function() {
                    self.save(updatedItem, changes)
                        .then(result => {
                            self.fetchData();
                        });
                }

                if (updatedItem.payeeCompanyID !== originalItem.payeeCompanyID) {
                    self.$dialog.confirm(
                        "Confirm Edit",
                        "This will change the Payee for this consolidated check. Would you like to proceed?",
                        saveChanges,
                        null, { cancelTitle: 'No', okTitle: 'Yes' });
                }
                else saveChanges();
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.delete(keys);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    `Are you sure you want to delete the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            save(item, changes){
                const self = this;
                if (changes.length == 0) {
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.AutoConsolidatePayeesApi.saveAutoConsolidatePayee(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            delete(keys) {
                const self = this;
                let apiPromise = self.$api.AutoConsolidatePayeesApi.deleteAutoConsolidatePayees(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.removeItems(keys);
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
                            : `${self.itemTypeName} was deleted.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                            self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                        } else {
                            self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                        }
                        console.error(error);
                        return error;
                    });
            },

            validateFile(item) {
                const self = this;
                return new Promise((resolve, reject) => {
                    self.newOrdersID = null;
                    self.$api.OrdersApi.getOrderEntity(item.gfNo)
                        .then(result => {
                            let message = "";
                            if(item.gfNo.length < 3)
                                    message = "File Number must be at least 3 characters."
                            if(!_.isEmpty(result)) {
                                let fileBankCompanyID = _.getNumber(result, "bankCompanyID", 0);
                                if(_.getBool(result, "isLocked"))
                                    message = "The existing file matching this file number is locked.  Please enter a different file number.";
                                else if(!_.parseBool(result.isConsolidatedFile) && (_.parseNumber(result.dispursements) > 0 || _.parseNumber(result.receipts) > 0))
                                    message = "The existing file matching this file number has had check writing activity.  Please enter a different file number.";
                                else if(fileBankCompanyID !== 0 && fileBankCompanyID !== item.bankCompanyID)
                                    message = "The Escrow Account on an existing file must match the selected bank company.";
                            }
                            if(_.isEmpty(message)) {
                                self.newOrdersID = _.getNumber(result, "ordersID", null);
                                resolve();
                            }
                            else {
                                reject(message);
                            }
                        })
                        .catch(err => {
                            reject("File number validation failed");
                            console.error(err);
                            self.$toast.error("An problem occurred while validating the entered file number.");
                        });
                });
            },

            getBranchDisplayText(item) {
                const self = this;
                if((_.isEmpty(item.branchIDs) && !item.allBranches) || _.isNil(item.regionID)) return;
                let selectedBranches = _.filter(self.allBranches, b => item.allBranches
                    ? b.regionID === item.regionID
                    : _.includes(item.branchIDs, b.id));
                return _.joinParts(_.map(selectedBranches, "name"), ", ");
            },

            goToConsolidatedFile(orderId) {
                if(_.parseNumber(orderId, 0) === 0) {
                    this.$toast.error("Invalid consolidated file.");
                    return;
                }
                this.$router.push({ name: "oe:main", params: { orderId } });
            },

            payeeBankRegionBranchExists(targetItem) {
                const self = this;
                if (targetItem.lossAdvanceType === MoneyMovementType.Advance || targetItem.lossAdvanceType === MoneyMovementType.Loss) {
                    return _.some(self.items, item => item.autoConsolidatePayeeID !== targetItem.autoConsolidatePayeeID
                            && item.bankCompanyID === targetItem.bankCompanyID
                            && item.lossAdvanceType === targetItem.lossAdvanceType
                            && item.regionID === targetItem.regionID
                            && (_.size(_.intersection(item.branchIDs, targetItem.branchIDs)) > 0)
                )

                } else {
                    return _.some(self.items, item => item.autoConsolidatePayeeID !== targetItem.autoConsolidatePayeeID
                            && item.payeeCompanyID === targetItem.payeeCompanyID
                            && item.bankCompanyID === targetItem.bankCompanyID
                            && item.lossAdvanceType === targetItem.lossAdvanceType
                            && item.regionID === targetItem.regionID
                            && (_.size(_.intersection(item.branchIDs, targetItem.branchIDs)) > 0)
                        );
                }
            },

            // payeeBankRegionExists(targetItem) {
            //     const self = this;
            //     return _.some(self.items, item => item.autoConsolidatePayeeID !== targetItem.autoConsolidatePayeeID
            //         && item.payeeCompanyID === targetItem.payeeCompanyID
            //         && item.bankCompanyID === targetItem.bankCompanyID
            //         && item.regionID === targetItem.regionID);
            // },

            refresh() {
                if(_.isNull(this.gridInstance)) return;
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            removeItems(keys) {
                const self = this;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                    if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                });
                self.refresh();
            }
        }
    }
</script>