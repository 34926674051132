import { SETTLEMENT_TYPE } from '@settlement/models';
import { ReportOptionsDto } from "@reporting/exago-reports/report-models";

export class SettlementDashboardDto {
	constructor(options) {
		options = options || {};
		this.mainID = _.parseNumber(options.mainID, 0);
		this.hudStatuses = options.hudStatuses || [];
		this.cashToCloseResults = options.cashToCloseResults || [];
	}
}
export class SettlementMissingItemDto {
	constructor(options) {
		options = options || {};
		this.cdfLine = _.parseNumber(options.cdfLine, 0);
		this.cdfSection = options.cdfSection || "";
		this.hudLine = _.parseNumber(options.hudLine, 0);
		this.hudSection = options.hudSection || "";
		this.description = options.description || "";
		this.payeeCompanyId = _.parseNumber(options.payeeCompanyId, 0);
		this.accountCode = _.parseNumber(options.accountCode, 0);
		this.loanId = _.parseNumber(options.loanId, 0);
		this.defaultedSettlementType = _.parseNumber(options.defaultedSettlementType, 0); // Default CDF
		this.validate = _.parseBool(options.validate);
		this.isPayoff = _.parseBool(options.isPayoff);
	}

	get missingData() {
		let missingValues = [];

		if (this.payeeCompanyId === 0)
			missingValues.push("Payee");

		if (this.accountCode === 0)
			missingValues.push("Account Code");

		return _.joinParts(missingValues, ", ");
	}

	getLineNumber(settlementType) {
		switch(settlementType) {
			case 0:
				return this.cdfSection;
			case 1:
				return this.hudLine;
			case 2:
				return this.hudLine;
			default:
				return this.cdfSection;
		}
	}

	routeName(settlementType) {
		if (settlementType === SETTLEMENT_TYPE.CDF)
			return this.GetCdfRouteName();

		if (settlementType === SETTLEMENT_TYPE.HUD_1974)
			return this.GetHud1974RouteName();

		if (settlementType === SETTLEMENT_TYPE.HUD_2010)
			return this.GetHud2010RouteName();

		return '';
	}

	GetCdfRouteName() {
		switch (this.cdfSection) {
			case 'A':
				return 'settlement:origination-section';
			case 'B':
			case 'C':
				return 'settlement:title-section';
			case 'F':
				return 'settlement:prepaid-section';
			case 'G':
				return 'settlement:escrow-section';
			case 'H':
				return 'settlement:othercost-section';
			case 'K':
			case 'L':
			case 'M':
			case 'N':
				return 'settlement:debitcredits-section';
			default:
				return '';
		}
	}

	GetHud1974RouteName() {
		switch (this.hudSection) {
			case 100:
			case 200:
			case 400:
			case 500:
				if (this.payeeCompanyId === 0 && this.isPayoff)
					return 'settlement:payoffs:list';
				return 'settlement:debitcredits-section';
			case 800:
				return 'settlement:origination-section';
			case 900:
				return 'settlement:prepaid-section';
			case 1000:
				return 'settlement:escrow-section';
			case 1100:
				return 'settlement:title-section';
			case 1300:
				return 'settlement:othercost-section';
			default:
				return '';
		}
	}

	GetHud2010RouteName() {
		switch (this.hudSection) {
			case 100:
			case 200:
			case 400:
			case 500:
				if (this.payeeCompanyId === 0 && this.isPayoff)
					return 'settlement:payoffs:list';
				return 'settlement:debitcredits-section';
			case 800:
				return 'settlement:origination-section';
			case 900:
				return 'settlement:prepaid-section';
			case 1000:
				return 'settlement:escrow-section';
			case 1100:
				return 'settlement:title-section';
			case 1300:
				return 'settlement:othercost-section';
			default:
				return '';
		}
	}
}

export class SettlementDocTemplateDto {
	constructor(options) {
		options = options || {};
		this.column = options.column || "";
		this.disabled = _.parseBool(options.disabled);
		this.documentTemplateID = _.parseNumber(options.documentTemplateID, 0);
		this.settlementType = _.parseNumber(options.settlementType, null);
		this.name = options.name || "";
		this.isWithOutSeller = _.parseBool(options.isWithOutSeller);
		this.isAltaWithOutSeller = _.parseBool(options.isAltaWithOutSeller); // exclusive for alta without seller state
		this.includeAlta = _.parseBool(options.includeAlta);
		this.isP2AB = _.parseBool(options.isP2AB);
		this.sequence = _.parseNumber(options.sequence, 0); // Helps with sorting data
		this.isAltaCash = _.parseBool(options.isAltaCash);
		this.isAltaCombined = _.parseBool(options.isAltaCombined);
		this.documentDescription = options.documentDescription || "";
	}

	get isConfigured() { return _.parseNumber(this.documentTemplateID, 0) > 0; }

	getDocumentDescription(fileNumber) {
		return this.documentDescription + " - " + fileNumber;
	}

	static createConfigured({ documentTemplateID, systemDefaultColumn }) {
		let docInfo = _.find(SettlementDocTemplateDto.configuredTemplates, { column: systemDefaultColumn });
		return new SettlementDocTemplateDto({
			...docInfo,
			documentTemplateID
		});
	}

	// Configuration of document templates based on defined schema columns and configuration in application
	static configuredTemplates = [
		// CDF Docs
		{ sequence: 1, column: "CDFDocumentName", name: "CDF", documentDescription: "CDF", settlementType: SETTLEMENT_TYPE.CDF },
		{ sequence: 2, column: "BorrowerCDFDocumentName", name: "Buyer's CDF", documentDescription: "CDF - Buyer's",  settlementType: SETTLEMENT_TYPE.CDF },
		{ sequence: 3, column: "SellerCDFDocumentName", name: "Seller's CDF", documentDescription: "CDF - Seller's",  settlementType: SETTLEMENT_TYPE.CDF },
		{ sequence: 4, column: "TOSCDFDocumentName", name: "CDF Without Seller", documentDescription: "CDF - Without Seller",  isWithOutSeller: true, settlementType: SETTLEMENT_TYPE.CDF },

		// CDF P2AB Docs
		{ sequence: 5, column: "CDFDocumentName_P2ab", name: "CDF - P2A2B", documentDescription: "CDF", isP2AB: true, settlementType: SETTLEMENT_TYPE.CDF },
		{ sequence: 6, column: "BorrowerCDFDocumentName_P2ab", name: "Buyer's CDF - P2A2B", documentDescription: "CDF - Buyer's", isP2AB: true, settlementType: SETTLEMENT_TYPE.CDF },
		{ sequence: 7, column: "SellerCDFDocumentName_P2ab", name: "Seller's CDF - P2A2B", documentDescription: "CDF - Seller's", isP2AB: true, settlementType: SETTLEMENT_TYPE.CDF },
		{ sequence: 8, column: "TOSCDFDocumentName_P2ab", name: "CDF Without Seller - P2A2B", documentDescription: "CDF - Without Seller", isWithOutSeller: true, isP2AB: true, settlementType: SETTLEMENT_TYPE.CDF },

		// ALTA Docs
		{ sequence: 9, column: "ALTASSCombinedDocument", name: "ALTA SS", documentDescription: "ALTA SS", includeAlta: true, settlementType: SETTLEMENT_TYPE.CDF, isAltaCombined: true },
		{ sequence: 10, column: "ALTASSCashDocument", name: "ALTA Cash SS", documentDescription: "ALTA SS Cash", includeAlta: true, settlementType: SETTLEMENT_TYPE.CDF, isAltaCash: true },
		{ sequence: 11, column: "ALTASSBorrowerDocument", name: "Buyer's ALTA SS", documentDescription: "ALTA SS - Buyer's", isWithOutSeller: true, isAltaWithOutSeller: true, includeAlta: true, settlementType: SETTLEMENT_TYPE.CDF },
		{ sequence: 12, column: "ALTASSSellerDocument", name: "Seller's ALTA SS", documentDescription: "ALTA SS - Seller's", includeAlta: true, settlementType: SETTLEMENT_TYPE.CDF },

		// 1974 HUD
		{ sequence: 13, column: "HUD1974_1DocumentName", name: "HUD", documentDescription: "HUD", isWithOutSeller: false, settlementType: SETTLEMENT_TYPE.HUD_1974 },
		{ sequence: 14, column: "HUD1974_BorrowerDocumentName", name: "Buyer's HUD", documentDescription: "HUD - Buyer's", isWithOutSeller: false, settlementType: SETTLEMENT_TYPE.HUD_1974 },
		{ sequence: 15, column: "HUD1974_SellerDocumentName", name: "Seller's HUD", documentDescription: "HUD - Seller's", isWithOutSeller: false, settlementType: SETTLEMENT_TYPE.HUD_1974 },
		{ sequence: 16, column: "HUD1974_1ADocumentName", name: "HUD-1A", documentDescription: "HUD-1A", isWithOutSeller: true, settlementType: SETTLEMENT_TYPE.HUD_1974 },

		// 2010 HUD
		{ sequence: 17, column: "HUD1DocumentName", name: "HUD", documentDescription: "HUD", isWithOutSeller: false, settlementType: SETTLEMENT_TYPE.HUD_2010 },
		{ sequence: 18, column: "BorrowerHUDDocumentName", name: "Buyer's HUD", documentDescription: "HUD - Buyer's", isWithOutSeller: false, settlementType: SETTLEMENT_TYPE.HUD_2010 },
		{ sequence: 19, column: "SellerHUDDocumentName", name: "Seller's HUD", documentDescription: "HUD - Seller's", isWithOutSeller: false, settlementType: SETTLEMENT_TYPE.HUD_2010 },
		{ sequence: 20, column: "HUD1ADocumentName", name: "HUD-1A", documentDescription: "HUD-1A", isWithOutSeller: true, settlementType: SETTLEMENT_TYPE.HUD_2010 },

		// CSS Docs
		{ sequence: 21, column: "PurchaserSellerCSSDocumentName", name: "Combined Statement", documentDescription: "Combined Statement", isWithOutSeller: false, settlementType: SETTLEMENT_TYPE.CSS },
		{ sequence: 22, column: "BorrowerCSSDocumentName", name: "Borrower's Statement", documentDescription: "Borrower's Statement", isWithOutSeller: true, settlementType: SETTLEMENT_TYPE.CSS },
		{ sequence: 23, column: "PurchaserCSSDocumentName", name: "Purchaser's Statement", documentDescription: "Purchaser's Statement", isWithOutSeller: true, settlementType: SETTLEMENT_TYPE.CSS },
		{ sequence: 24, column: "SellerCSSDocumentName", name: "Seller's Statement", documentDescription: "Seller's Statement", isWithOutSeller: false, settlementType: SETTLEMENT_TYPE.CSS },		
	];
}

export class SettlementReport {
	static reportOptions801Breakout(mainID) {
		return new ReportOptionsDto({
			reportPath: "System Reports\\File Specific\\801 Breakout Charges",
			disabled: true,
			immediate: true,
			parameters: {
				p_HUDMainID: mainID
			},
		});
	}

	static reportOptionsAdditionalTitleServices(mainID) {
		return new ReportOptionsDto({
			reportPath: "System Reports\\File Specific\\Additional Title Services",
			disabled: true,
			immediate: true,
			parameters: {
				p_HUDMainID: mainID
			},
		});
	}

	static reportOptionsCDFAddendums(mainID) {
		return new ReportOptionsDto({
			reportPath: "System Reports\\File Specific\\CDF Addendum",
			disabled: true,
			immediate: true,
			parameters: {
				p_CDFMainID: mainID
			}
		});
	}

	static reportOptionsCommissionCheck(orderID, mapFunction) {
		return new ReportOptionsDto({
			reportPath: "System Reports\\File Specific\\Commission Check Report",
			parameters: {
				p_OrdersID: orderID
			},
			preExecuteHook: mapFunction
		});
	}

	static reportOptionsPaidByOthers(orderID, loanID) {
		return new ReportOptionsDto({
			reportPath: "System Reports\\File Specific\\Paid By Others",
			disabled: true,
			immediate: true,
			parameters: {
				p_OrdersID: orderID,
				p_LoanID: loanID
			},
		});
	}

	static reportOptionsRecordingItems(orderID, loanID) {
		return new ReportOptionsDto({
			reportPath: "System Reports\\File Specific\\Recording Itemization", // TO DO: Need to be updated when the report is moved to right folder
			parameters: {
				p_OrdersID: orderID,
				p_LoanID: loanID
			}
		});
	}

	static reportOptionsLLBreakdown(orderID, settlementStatementType, mapFunction = null) {

		return new ReportOptionsDto({
			title: 'Lender Loan Breakdown',
			reportPath: 'System Reports/File Specific/Lender Loan Breakdown',
			parameters: {
				p_OrdersID: orderID
				, p_SettlementType: settlementStatementType
			},
			preExecuteHook: mapFunction
		});
	}

	static reportOptionsHudAddendums(mainID) {
		return new ReportOptionsDto({
			reportPath: "System Reports\\File Specific\\HUD Addendum",
			disabled: true,
			immediate: true,
			parameters: {
				p_HUDMainID: mainID
			},
		});
	}

	static reportOptionsSettlementFees(orderID) {
		return new ReportOptionsDto({
			reportPath: "System Reports\\File Specific\\Settlement Fees",
			disabled: true,
			immediate: true,
			parameters: {
				p_OrdersID: orderID
			},
		});
	}

	static reportOptionsProrationAddendum(orderID) {
		return new ReportOptionsDto({
			title: 'Proration Addendum',
			reportPath: "System Reports\\File Specific\\Proration Addendum Report",
			disabled: true,
			immediate: true,
			parameters: {
				p_OrdersID: orderID
			},
		});
	}
}

