import { Categories } from "./categories"
import { Topics } from "../topics"
import { ComingSoon } from "@/pages";
import ClosingMarketIntegrationForm from "./components/ClosingMarketIntegrationForm";
import ClosingProtectionLetter from "./components/ClosingProtectionLetter";
import PolicyJacketIntegrationForm from "./components/PolicyJacketIntegrationForm";
import ready2closeForm from "./components/Ready2CloseForm";
import Ready2CloseSystemList from "./components/Ready2CloseSystemList";
import PavasoPartyRole from "./components/PavasoPartyRole";
import EpicorLocationsList from "./components/EpicorLocationsList";
import EpicorProductsList from "./components/EpicorProductsList";
import EpicorBankCodesList from "./components/EpicorBankCodesList";
import EpicorUnderwriterSplitRatesList from "./components/EpicorUnderwriterSplitRatesList";
import EpicorValidPayeesList from "./components/EpicorValidPayeesList";
const DataConversionWrapper = () => import(/* webpackChunkName: "data-conversion" */"@/integrations/wrappers/DataConversionWrapper.vue");
const WorkDayIntegrationWrapper = () => import(/* webpackChunkName: "data-conversion" */"@/integrations/wrappers/WorkDayIntegrationWrapper.vue");

let routes = [

    // {
    //     name: "cfg:integration:pcSecByRole",
    //     path: "pcSecByRole",
    //     component: ComingSoon,
    //     meta: {
    //         label: "Paperless Closer Security by Role",
    //         itemKey: "",
    //         itemTypeName: "Paperless Closer Security by Role",
    //         itemTypeDescription: "Select the security for PaperlessCloser for each role across all files",
    //         skipSaveOnLeave: true,
    //         topic: Topics.Integrations,
    //         category: Categories.Integrations
    //     }
    // },
    // {
    //     name: "cfg:integration:pcSecByDesc",
    //     path: "pcSecByDesc",
    //     component: ComingSoon,
    //     meta: {
    //         label: "Paperless Closer Security by Description",
    //         itemKey: "",
    //         itemTypeName: "Paperless Closer Security by Description",
    //         itemTypeDescription: "Used to automate the security of a user to published documents",
    //         skipSaveOnLeave: true,
    //         topic: Topics.Integrations,
    //         category: Categories.Integrations
    //     }
    // },
    // {
    //     name: "cfg:integration:pcSettings",
    //     path: "pcSettings",
    //     component: ComingSoon,
    //     meta: {
    //         label: "Paperless Closer Settings",
    //         itemKey: "",
    //         itemTypeName: "Paperless Closer Setting",
    //         itemTypeDescription: "The highest level of PaperlessCloser security that is inherited by all files",
    //         skipSaveOnLeave: true,
    //         topic: Topics.Integrations,
    //         category: Categories.Integrations
    //     }
    // },
    // {
    //     name: "cfg:integration:cdfSecByRole",
    //     path: "cdfSecByRole",
    //     component: ComingSoon,
    //     meta: {
    //         label: "CDF Security by Role",
    //         itemKey: "",
    //         itemTypeName: "CDF Security by Role",
    //         itemTypeDescription: "Select the security for PaperlessCloser for each role across all files",
    //         skipSaveOnLeave: true,
    //         topic: Topics.Integrations,
    //         category: Categories.Integrations
    //     }
    // },
    {
        name: "cfg:integration:cmIntegration",
        path: "cmIntegration",
        component: ClosingMarketIntegrationForm,
        meta: {
            label: "Closing Market Integrations",
            itemKey: "",
            itemTypeName: "Closing Market Integration",
            itemTypeDescription: "Configure Closing Market settings.",
            topic: Topics.Integrations,
            category: Categories.Integrations,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:integration:policyJacket",
        path: "policyJackets",
        component: PolicyJacketIntegrationForm,
        meta: {
            label: "Policy Jacket Integrations",
            itemKey: "",
            itemTypeName: "Policy Jacket Integration",
            itemTypeDescription: "Configure Policy Jacket Integration settings.",
            topic: Topics.Integrations,
            category: Categories.Integrations,
            skipSaveOnLeave: false,
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:integration:cpl",
        path: "cpl",
        component: ClosingProtectionLetter,
        meta: {
            label: "Closing Protection Letter",
            itemKey: "",
            itemTypeName: "Closing Protection Letter",
            itemTypeDescription: "Configure Closing Protection Letter settings.",
            topic: Topics.Integrations,
            category: Categories.Integrations,
            skipSaveOnLeave: false,
            sysAdminRequired: true,
            featureFlag: "cpl"
        }
    },
    {
        name: "cfg:integration:ready2close",
        path: "ready2close",
        component: ready2closeForm,
        meta: {
            label: "ready2close Configuration Settings",
            itemKey: "",
            itemTypeName: "ready2close Configuration Settings",
            itemTypeDescription: "Configure ready2close Settings",
            skipSaveOnLeave: true,
            topic: Topics.Integrations,
            category: Categories.Integrations,
            sysAdminRequired: true,
        }
    },
    {
        name: "cfg:integration:ready2closeSystem",
        path: "ready2closeSystem",
        component: Ready2CloseSystemList,
        meta: {
            label: "ready2close System Information",
            itemKey: "id",
            itemTypeName: "ready2close System",
            itemTypeDescription: "Configure ready2close System Information",
            skipSaveOnLeave: true,
            topic: Topics.Integrations,
            category: Categories.Integrations,
            sysAdminRequired: true,
        }
    },

    // REMOVED PAVASO OPTION ON RQO-34214
    // {
    //     name: "cfg:integration:pavaso",
    //     path: "pavaso",
    //     component: PavasoPartyRole,
    //     meta: {
    //         label: "Pavaso",
    //         itemKey: "",
    //         itemTypeName: "pavaso System",
    //         itemTypeDescription: "Configure your Party Role Identifier provided by Pavaso",
    //         topic: Topics.Integrations,
    //         category: Categories.Integrations,
    //         sysAdminRequired: true,
    //         featureFlag: "pavasoDigitalSignings",
    //     }
    // },

    // {
    //     name: "cfg:integration:routingRules",
    //     path: "routingRules",
    //     component: ComingSoon,
    //     meta: {
    //         label: "Routing Rules",
    //         itemKey: "",
    //         itemTypeName: "Routing Rule",
    //         itemTypeDescription: "Define rules for adding organizational routing data to incoming PaperlessCloser orders",
    //         skipSaveOnLeave: true,
    //         topic: Topics.Integrations,
    //         category: Categories.Integrations
    //     }
    // }
    {
        name: "cfg:integration:data-conversion",
        path: "data-conversion",
        component: DataConversionWrapper,
        meta: {
            label: "Data Conversion",
            itemKey: "",
            itemTypeName: "",
            itemTypeDescription: "Integration with Data Conversion service.",
            topic: Topics.Integrations,
            category: Categories.Integrations,
            featureFlag: "dataConversion",
            permissionKeys: ["AllowInitiateDataConversion"],
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:chk:workdayLocations",
        path: "workdayLocations",
        component: EpicorLocationsList,
        meta: {
            label: "WorkDay Locations",
            itemKey: "ortEpicorLocationID",
            itemTypeName: "Location",
            itemTypeDescription: "Configure WorkDay Locations.",
            skipSaveOnLeave: true,
            topic: Topics.Integrations,
            category: Categories.WorkDay,
            featureFlag: "workdayIntegration",
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:chk:workdayProducts",
        path: "workdayProducts",
        component: EpicorProductsList,
        meta: {
            label: "WorkDay Products",
            itemKey: "ortEpicorProductID",
            itemTypeName: "Product",
            itemTypeDescription: "Configure WorkDay Products.",
            skipSaveOnLeave: true,
            topic: Topics.Integrations,
            category: Categories.WorkDay,
            featureFlag: "workdayIntegration",
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:chk:workdayBankCodes",
        path: "workdayBankCodes",
        component: EpicorBankCodesList,
        meta: {
            label: "WorkDay Bank Codes",
            itemKey: "ortEpicorBankCodeID",
            itemTypeName: "Bank Code",
            itemTypeDescription: "Configure WorkDay Bank Codes.",
            skipSaveOnLeave: true,
            topic: Topics.Integrations,
            category: Categories.WorkDay,
            featureFlag: "workdayIntegration",
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:chk:workdayUnderwriterSplitRates",
        path: "workdayUnderwriterSplitRates",
        component: EpicorUnderwriterSplitRatesList,
        meta: {
            label: "WorkDay Underwriter Splits",
            itemKey: "ortEpicorUnderwriterSplitRateID",
            itemTypeName: "Underwriter Splits",
            itemTypeDescription: "Configure WorkDay Underwriter Splits.",
            skipSaveOnLeave: true,
            topic: Topics.Integrations,
            category: Categories.WorkDay,
            featureFlag: "workdayIntegration",
            sysAdminRequired: true
        }
    },
    {
        name: "cfg:chk:workdayValidPayees",
        path: "workdayValidPayees",
        component: EpicorValidPayeesList,
        meta: {
            label: "WorkDay Valid Payees",
            itemKey: "ortEpicorValidPayeeID",
            itemTypeName: "Valid Payee",
            itemTypeDescription: "Configure WorkDay Valid Payees.",
            skipSaveOnLeave: true,
            topic: Topics.Integrations,
            category: Categories.WorkDay,
            featureFlag: "workdayIntegration",
            sysAdminRequired: true
        }
    },
];

export default routes;

