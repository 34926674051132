<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="notesGrid"
            :title="notesTitle"
            title-size="lg"
            automation_id="dg_order_notes"
            :actions="selectionActions"
            :data-source="gridDataSource"
            :config="gridConfig"
            :export-file-name="exportFileName"
            :onSelectionChanged="onSelectionChanged"
            @view-edit="onEditNote"
            @delete="onDeleteNote"
            @resolve="onResolveNotes"
            @rowDoubleClick="onEditNote"
            hide-search
            rq-filters>
            <template #toolbar>
                <ul class="nav me-auto">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_add_note"
                            size="sm"
                            variant="theme"
                            @click="onAddNote"
                            v-focus
                            :disabled="disableAddNotes">Add
                        </b-btn>
                    </li>
                </ul>
                <ul class="nav navbar-nav me-3 ms-auto">
                    <li class="nav-item">
                        <b-form-checkbox
                            automation_id="exc_sys_notes"
                            id="exc_sys_notes"
                            v-model="excludeSysNotes"
                            @change="onChangeCheckbox">Exclude System Notes
                        </b-form-checkbox>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { OrderNoteCategory } from '@/shared/models/enums';
    import { SearchRequest } from "@/shared/models/models";
    import { OrderNote } from "@order-entry/models";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { ORDER_ACTIONS } from '@/store/actions';
    import {PriorityType} from "./enums.js";

    const EXCLUDE_SYS_NOTE_STORAGE_KEY = "rq_exclude_sys_note";

    export default {
        name: "OrderNotes",
        props: {
            category: { type: Number, default: 0 }
        },
        data () {
            return {
                searchTerm: "",
                notes: [],
                gridDataSource: {},
                selectedRowData: null,
                excludeSysNotes: false,
            };
        },

        computed: {
            ...mapState({
                order: state => state.orders.order,
                orderSummary: state => state.orders.orderSummary,
                isOrderLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                fileInUse: state => state.orders.orderSummary.isFileInUse,
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            filteredItems() {
                return this.excludeSysNotes ?  _.filter(_.clone(this.notes), n=> !n.isSystemGen) : _.clone(this.notes);
            },
            gridInstance() { return _.get(this, "$refs.notesGrid.gridInstance", null); },
            notesTitle() {
                if(this.category === 0) return "Notes";
                return "";
                // let categoryName = OrderNoteCategory.displayValue(this.category);
                // return `${categoryName} Notes`;
            },
            exportFileName() { return `${this.order.gfNo}_notes` },
            localSecurity(){
                let settings = this.securitySettings.findValues(["OrderNotesSecurity","Notes_ScreenAccess"]);
                let mapVals = (setting,keys) => _.zipObject(keys, _.map(keys, (val,idx) => idx === _.parseNumber(setting, 2)));
                return {
                    OrderNotesSecurity: mapVals(settings.OrderNotesSecurity, ["Limited","UserOnly","Any"]),
                    Notes_ScreenAccess: mapVals(settings.Notes_ScreenAccess, ["None","ReadOnly","Full"])
                };
            },
            fileLockedOrInUse() { return this.isOrderLocked || this.fileInUse},
            readOnly() { return this.isOrderLocked || this.fileInUse || !this.localSecurity.Notes_ScreenAccess.Full || this.localSecurity.OrderNotesSecurity.Limited || this.selectedRowData.isSystemGen || (this.selectedRowData.usersID !== this.user.usersID && this.localSecurity.OrderNotesSecurity.UserOnly) },
            disableAddNotes() { return (this.isOrderLocked || this.fileInUse || !this.localSecurity.Notes_ScreenAccess.Full)},
            selectionActions() {
                const self = this;
                if(!this.selectedRowData){
                    return;
                }
                return [
                    {
                        name: "edit",
                        text: self.readOnly && self.editWindowExpired(self.selectedRowData) ? "View" : "Edit",
                        eventName: "view-edit",
                        tooltip: self.readOnly && self.editWindowExpired(self.selectedRowData) ? "View Note" : "Edit Note",
                        requireSelection: true,
                        disabled: e => (
                                self.readOnly
                                ? false
                                : (self.getActionDisabled(e.data, "edit") || self.isReadOnly(e.data)
                                    ? "Access Restricted"
                                    : false
                            )) || self.isTask(e.data)
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        tooltip: "Delete Note(s)",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: e =>
                            (e.data.length === 1
                            ? self.getActionDisabled(e.data[0], "delete")
                            : _.some(e.data, item => self.isReadOnly(item))
                                ? "Access Restricted"
                                : false)
                            || _.some(e.data, item => self.isTask(item)
                            || (self.readOnly && self.editWindowExpired(self.selectedRowData)))
                    },
                    {
                        name: "resolve",
                        text: "Resolve",
                        eventName: "resolve",
                        tooltip: "Resolve Note(s)",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: e => (!self.hasEditPermission || _.some(e.data, item => self.isReadOnly(item) || !item.isCriticalNote)
                                ? "Access Restricted"
                                : false) || self.isTask(e.data)
                    },
                ];
            },
            noteCategories(){ return this.lookupHelpers.getAllLookupItems(this.lookupItems.NOTE_CATEGORIES); },
         },

        created () {
            this.$events.on("note-save-complete", this.onNoteSaveComplete);
            this.$events.on("refresh-order-notes", this.fetchData);
            this.fetchData();
            this.loadCheckboxStoreValue();
            this.loadGridConfig();
        },

       beforeUnmount() {
            this.$events.off("note-save-complete", this.onNoteSaveComplete);
            this.$events.off("refresh-order-notes", this.fetchData);
        },

        methods: {
            hasEditPermission(orderNote) {
                //let editWindowExpired = moment().diff(orderNote.nDate, "minutes") > 5;
                let editWindowExpired = this.editWindowExpired(orderNote);
                return this.localSecurity.OrderNotesSecurity.Any || (
                    orderNote.usersID === this.user.usersID && (
                        this.localSecurity.OrderNotesSecurity.UserOnly || !editWindowExpired
                    )
                );
            },
            isReadOnly(orderNote){ return this.readOnly || !this.hasEditPermission(orderNote) || orderNote.isSystemGen; },

            getActionDisabled(orderNote, actionName) {
                if(!this.isReadOnly(orderNote)) return false;
                //let editWindowExpired = moment().diff(orderNote.nDate, "minutes") > 5;
                let editWindowExpired = this.editWindowExpired(orderNote);
                if(this.localSecurity.OrderNotesSecurity.Any || (this.localSecurity.OrderNotesSecurity.UserOnly && orderNote.usersID === this.user.usersID) || !editWindowExpired)
                    return false;
                if(((this.localSecurity.OrderNotesSecurity.Limited && orderNote.usersID === this.user.usersID && editWindowExpired) && !this.fileLockedOrInUse) && !orderNote.isSystemGen)
                    return `Not authorized to ${actionName} note after 5 minutes`;
                else
                    return "Access Restricted";
            },

            //editWindowExpired(orderNote){ return moment().diff(orderNote.nDate, "minutes") > 5; },
            editWindowExpired(orderNote){
                let relativeDate = DateTimeHelper.toTenantTimeString(orderNote.nDate);
                let nowDate = DateTimeHelper.nowTenant();
                let diff = DateTimeHelper.diff(nowDate, relativeDate, "minutes");
                return diff.minutes > 5;
            },

            loadGridConfig() {
                const self = this;
                self.gridConfig = {
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [50,100,500], showInfo: true},
                    remoteOperations: false,
                    columns: [
                        {
                            dataField: "priority",
                            caption: "Priority",
                            width: 85,
                            minWidth: 85,
                            alignment: "center",
                            cellTemplate: DxGridUtils.priorityCellTemplate,
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: PriorityType.lookupItems
                            }
                        },
                        {
                            dataField: "isCriticalNote",
                            dataType: "boolean",
                            caption: "Critical Note",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            width: 120,
                            minWidth: 120
                        },
                        {
                            dataField: "nDate",
                            dataType: "datetime",
                            caption: "Date / Time",
                            width: 150,
                            minWidth: 100,
                        },
                        {
                            dataField: "category",
                            width: 180,
                            minWidth: 150,
                            alignment: "left",
                            lookup: {
                                dataSource: self.noteCategories,
                                valueExpr: "id",
                                displayExpr: "name"
                            }
                        },
                        {
                            dataField: "usersID",
                            caption: "Created By",
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: {
                                    loadMode: "raw",
                                    load: () => _.sortBy(_.uniqBy(_.map(self.notes, n => ({ id: n.usersID, name: n.fullName })), "id"), "name")
                                }
                            },
                            width: 150,
                            minWidth: 100
                        },
                        {
                            dataField: "notes",
                            caption: "Note",
                            cellTemplate: (cellElement, cellInfo) => DxGridUtils.truncatedCellTemplate(cellElement, cellInfo, 500, true)
                        },
                        {
                            dataField: "resolvedByUsersID",
                            dataType: "number",
                            caption: "Resolved By",
                            lookup: {
                                displayExpr: "resolvedByFullName",
                                valueExpr: "resolvedByUsersID",
                                dataSource: {
                                    loadMode: "raw",
                                    load: () => _.sortBy(_.filter(self.notes, r => _.includes(_.map(self.notes, "resolvedByUsersID"), r.resolvedByUsersID) && r.resolvedByUsersID > 0), "resolvedByFullName")
                                }
                            },
                            width: 150,
                            minWidth: 100
                        },
                        {
                            dataField: "resolvedDate",
                            dataType: "datetime",
                            caption: "Resolved Date",
                            width: 150,
                            minWidth: 100,
                        }
                    ],
                    noDataText: `No notes found for this ${_.gt(self.category, 0) ? "category" : "file"}.`,
                };
                self.gridDataSource = {
                    key: "orderNoteID",
                    loadMode: "raw",
                    load () {
                        return Promise.resolve(self.filteredItems);
                    },
                };
            },

            fetchData (loadOptions) {
                const self = this;
                let category = self.category > 0 ? self.category : null;
                
                let apiPromise = self.$api.OrdersApi.getOrderNotes(self.order.ordersID, category);
                self.$rqBusy.wait(apiPromise)
                    .then(results => {
                        let criticalNotes = _.sortBy(_.filter(results, n => n.isCriticalNote), "nDate");
                        self.$store.dispatch(ORDER_ACTIONS.UPDATE_ORDER_CRITICAL_NOTES, criticalNotes);
                        self.notes = _.map(results, n => new OrderNote(n));
                        self.refresh();
                    })
                    .catch(error => {
                        self.$toast.error("Failed to retrieve notes.");
                    })
            },

            onAddNote() {
                GlobalEventManager.fireAction({ key: "addNote" })
            },

            onChangeCheckbox(e) {
                this.excludeSysNotes = e;
                this.$rqStore.setItem(EXCLUDE_SYS_NOTE_STORAGE_KEY, this.excludeSysNotes);
                this.refresh();
            },

            onEditNote(e) {
                if(!e || !e.data || this.isTask(e.data)) return;
                GlobalEventManager.fireAction({ key: "editNote", data: e.data })
            },

            onNoteSaveComplete(e) {
                if(!e.refresh) return;
                this.fetchData();
            },

            onSelectionChanged(e){
                if(e.selectedRowsData.length) {
                    this.selectedRowData = e.selectedRowsData[0];
                }
                else {
                    this.selectedRowData = null;
                }
            },

            onDeleteNote(e) {
                const self = this;
                let isRestricted = e.data.length === 1
                    ? self.getActionDisabled(e.data[0], "delete")
                    : _.some(e.data, item => self.isReadOnly(item));

                if(isRestricted) return;

                self.deleteNotes(e.data);
            },

            deleteNotes (items) {
                const self = this;
                var okHandler = function (args) {
                    let toBeDeletedKeys = _.map(items, "orderNoteID");
                    let apiPromise = self.$api.OrdersApi.deleteOrderNotes(toBeDeletedKeys);
                    self.$rqBusy.wait(apiPromise)
                        .then(t => {
                            self.$toast.success({ message: `Note${items.length > 1 ? 's' : ''} deleted` });
                        })
                        .catch(errorInfo => {
                            self.$toast.error({ message: "Error deleting note: " + errorInfo.errorMessage });
                        })
                        .finally(() => {
                            self.refresh();
                            self.fetchData();
                        });
                    return true;
                };

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected note${items.length > 1 ? 's' : ''}?`, okHandler);
            },
            loadCheckboxStoreValue() {
                let storeValue = this.$rqStore.getItem(EXCLUDE_SYS_NOTE_STORAGE_KEY);
                this.excludeSysNotes = _.parseBool(storeValue, false);
            },

            onResolveNotes(e){
                this.resolveNotes(e.data);
            },

            resolveNotes(items){
                const self = this;
                let toBeResolvedKeys = _.map(items, "orderNoteID");
                let apiPromise = self.$api.OrdersApi.resolveOrderNotes(toBeResolvedKeys);
                self.$rqBusy.wait(apiPromise)
                    .then(t => {
                        self.$toast.success({ message: `Critical Note${items.length > 1 ? 's' : ''} resolved` });
                    })
                    .catch(errorInfo => {
                        self.$toast.error({ message: "Error resolving note(s): " + errorInfo.errorMessage });
                    })
                    .finally(() => {
                        self.refresh();
                        self.fetchData();
                    });
            },

            refresh() {
                _.invoke(this, "gridInstance.clearSelection");
                _.invoke(this, "gridInstance.refresh");
            },
            isTask(item) { return item.category == -2 }
            // hasEditPermission(orderNote) {
            //     let editWindowExpired = moment().diff(orderNote.nDate, "minutes") > 5;
            //     return orderNote.isNew || (
            //         this.localSecurity.OrderNotesSecurity === 2 || (
            //             orderNote.usersID === this.user.usersID && (
            //                 this.localSecurity.OrderNotesSecurity === 1 || !editWindowExpired
            //             )
            //         )
            //     );
            // },
        }
    };
</script>

<style lang="scss">
    .clear-search { width: 0; height: 25px; color: rgb(204, 204, 204); text-decoration: none; padding: 0; position: relative; right: 20px; z-index: 500;
        > i, svg { padding-top: 12px; }
    }
</style>
