import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
    getContactLicensees(contactID) {
        return HttpWrapper.get(`${endpoints.CONTACT_LICENSES}/contact/${contactID}`);
    },
    saveContactLicense(contactLicense, changes) {        
        return HttpWrapper.put(`${endpoints.CONTACT_LICENSES}`, { data: contactLicense, changes} );              
    },
    deleteContactLicense(contactLicenseID) {
        return HttpWrapper.delete(`${endpoints.CONTACT_LICENSES}/${contactLicenseID}`);              
    },
    deleteContactLicensees(contactLicenseIDs) {
        return HttpWrapper.post(`${endpoints.CONTACT_LICENSES}/delete`, contactLicenseIDs);
    },
};