import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
import { DateTime } from "luxon";

export const computedPropSync = propName => ({
    get() { return this[propName]; },
    set(val) { this.$emit(`update:${propName}`, val); }
});

export const computedObjPath = path => ({
    get() { return _.get(this , path, ""); },
    set(val) { _.set(this, path, val); }
});

export const watcherPropSync = props => {
    let result = {};
    _.forEach(props, (opts,propName) => {

        let dataFieldName = _.isString(opts) ? opts : opts.dataField;

        result[propName] = {
            handler(newVal, oldVal) {
                if(newVal === oldVal || newVal === this[dataFieldName]) return;
                this[dataFieldName] = newVal;
            },
            immediate: _.isString(opts) ? true : _.getBool(opts, "immediate", true)
        };

        result[dataFieldName] = function(newVal, oldVal) {
            if(newVal === oldVal || newVal === this[propName]) return;
            this.$emit(`update:${propName}`, newVal);
        };

    });
    return result;
};

export const mapObjPaths = paths => _.mapValues(paths, computedObjPath);

const luxonFormat = "yyyy-MM-dd'T'HH:mm:ss";
const parseDateTimeValue = dateTime => {
    if(_.isNullOrEmpty(dateTime) || (_.isObject(dateTime) && _.has(dateTime, "event")))
        return { value: null, startOfDay: null, isValid: false, isStartOfDay: false };

    let dt = _.isDate(dateTime)
        ? DateTime.fromJSDate(dateTime)
        : DateTime.fromISO(dateTime);

    let value = dt.isValid ? dt.toFormat(luxonFormat) : null;
    let startOfDay = dt.isValid ? dt.startOf("day").toFormat(luxonFormat) : null;
    let isStartOfDay = !_.isNil(value) && value === startOfDay;
    let isValid = dt.isValid;

    return { value, startOfDay, isValid, isStartOfDay };
};

/*================================================================================
    This is for showing a single control that supports DATE and TIME.
    NOTE: In the background, ONE database value is the underlying source.
    EX: Note Date, Workflow Task Start Date, etc.
================================================================================*/
export const dateTimeCalculated = ({field}) => {
    let calcTimeKey = _.first(_.keys(field));
    let timePath = field[calcTimeKey];

    return {
        [calcTimeKey]: {
            get() {
                let dtVal = _.get(this, timePath, null);
                if (_.isNil(dtVal)) return null;
                let dtResult = parseDateTimeValue(dtVal);
                return dtResult.value;
            },
            set(val) {
                let dtResult = parseDateTimeValue(val);
                if (!dtResult.isValid) {
                    _.set(this, timePath, null);
                    return;
                }
                _.set(this, timePath, dtResult.value);
            },
        }
    }
}

/*================================================================================
    This is for the scenario where we need two controls that are dependent and one
    displays DATE, the other displays the TIME.
    NOTE: In the background, TWO database values are the underlying source.
    EX: Close Date / Close Time.
================================================================================*/
export const dateTimeSplitDependent = ({fields}) => {

    let calcDateKey = _.first(_.keys(fields.date));
    let datePath = fields.date[calcDateKey];
    let calcTimeKey = _.first(_.keys(fields.time));
    let timePath = fields.time[calcTimeKey];

    return {
        [calcDateKey]: {
            get() {
                let dtVal = _.get(this, datePath, null);
                let dtResult = parseDateTimeValue(dtVal);
                return dtResult.startOfDay;
            },
            set(val) {
                let dtResult = parseDateTimeValue(val);
                _.set(this, datePath, dtResult.startOfDay);

                let timeVal = _.get(this, timePath, null) || null;
                if(_.isNil(timeVal)) return;

                let updatedTimeVal = null;
                if(dtResult.isValid) {
                    let combinedVal = DateTimeHelper.joinDateParts({date: dtResult.value, time: timeVal});
                    updatedTimeVal = dtResult.startOfDay === combinedVal ? null : combinedVal;
                }
                _.set(this, timePath, updatedTimeVal);
            },
        },
        [calcTimeKey]: {
            get() {
                let timeVal = _.get(this, timePath, null);
                let dtResult = parseDateTimeValue(timeVal);
                return dtResult.value;
            },
            set(val) {
                let dtResult = parseDateTimeValue(val);
                _.set(this, timePath, dtResult.value);
            },
        }
    }
}
/*================================================================================
    This is for showing two controls that are split from the same value
    and one displays DATE, the other displays the TIME.
    NOTE: In the background, ONE database value is the underlying source.
    EX: Policy Date/Time, Issue Date/Time.
================================================================================*/
const getFallbackTime = function({ timeValue, timeValuePath }) {
    let targetTime = `${DateTime.now().toFormat("yyyy-MM-dd")}T${timeValue}`;
    // This follows order of precedence
    let calcTimes = [
        timeValue ? targetTime : "",                        // Pure time value.
        timeValuePath ? _.get(this, timeValuePath) : "",    // Retrieve from some class data/computed value.
        DateTime.now().toFormat(luxonFormat),               // Just use current tenant time.
    ];

    return _.find(calcTimes, (dt) => !!dt);
}

export const dateTimeSplit = ({ fields, defaultTime, defaultTimePath }) => {
    defaultTime = fields.defaultTime || defaultTime;
    defaultTimePath = fields.defaultTimePath || defaultTimePath;

    return {
        [fields.date]: {
            get() {
                let val = _.get(this, fields.path) || null;
                return _.isNil(val) ? null : DateTime.fromISO(val).startOf("day").toFormat(luxonFormat);
            },
            set(newValue) {
                let timeString = getFallbackTime.call(this, { timeValue: defaultTime, timeValuePath: defaultTimePath });
                let fallback = DateTimeHelper.joinDateParts({ date: newValue, time: timeString });
                let currentVal = _.get(this, fields.path) || null;
                let timeVal = _.isEmpty(newValue) ? null : (currentVal || fallback);

                let combined = DateTimeHelper.joinDateParts({ date: newValue, time: timeVal });
                _.set(this, fields.path, combined);
            }
        },
        [fields.time]: {
            get() {
                let val = _.get(this, fields.path) || null;
                return _.isNil(val) ? null : DateTime.fromISO(val).toFormat(luxonFormat);
            },
            set(newValue) {
                let currentVal = _.get(this, fields.path) || null;

                let combined = DateTimeHelper.joinDateParts({ date: currentVal, time: newValue });
                _.set(this, fields.path, combined);
            }
        }
    }
};