<template>
    <div id="check-browser" class="content-wrapper escrow-browser">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section title="Amortization Calculator" headerSize="lg" class="browser-filter-section">
            <template #header-actions>
                <transition name="simple-fade">
                    <ul v-if="filtersExpanded" class="nav ms-auto">
                        <li class="nav-item">
                            <rq-select-box
                                v-if="showLoanDropDown"
                                id="drp_loan"
                                :display-template="item => `Loan ${item.name}`"
                                :items="loanOptions"
                                size="sm"
                                @change="onLoanChange"
                                v-model="selectedLoanId"
                                :default-item-enabled="false"
                            />
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_clear"
                                variant="theme"
                                @click="onClearResults">Reset</b-btn>
                        </li>
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_calculate"
                                variant="theme"
                                @click="onCalulate">Calulate</b-btn>
                        </li>
                    </ul>
                </transition>
            </template>
            <div class="row">
                <div :class="{ 'col col-3 form-group form-required': true, 'has-error': v$.request.amount.$error }">
                    <label for="txt_amount">Amount</label>
                    <rq-input-number
                        automation_id="txt_amount"
                        ref="txt_amount"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        v-model="v$.request.amount.$model"
                    />
                    <rq-validation-feedback
                        :messages="{ 'Amount is required': v$.request.amount.required.$invalid,
                                        'Amount must be greater than Zero': v$.request.amount.isGreaterThanZero.$invalid,
                                    }"
                    />
                </div>
                <div :class="{ 'col col-3 form-group form-required': true, 'has-error':v$.request.beginningOfLoan.$error }">
                    <label for="dtp_beginningOfLoan">Loan Start Date</label>
                    <rqdx-date-box
                        id="dtp_beginningOfLoan"
                        type="date"
                        display-format="MM/dd/yyyy"
                        v-model="v$.request.beginningOfLoan.$model"
                    />
                    <rq-validation-feedback
                        :messages="{
                            'Loan Start Date is required': v$.request.beginningOfLoan.required.$invalid,
                            'Loan Start Date is not within range': !v$.request.beginningOfLoan.required.$invalid && v$.request.beginningOfLoan.isInRange.$invalid,
                        }"
                    />
                </div>
                <div :class="{ 'col col-3 form-group form-required': true, 'has-error':v$.request.firstPayment.$error }">
                    <label for="dtp_firstPayment">First Payment Date</label>
                    <rqdx-date-box
                        id="dtp_firstPayment"
                        type="date"
                        display-format="MM/dd/yyyy"
                        v-model="v$.request.firstPayment.$model"
                    />
                    <rq-validation-feedback
                        :messages="{
                            'First Payment Date is required': v$.request.firstPayment.required.$invalid,
                            'First Payment Date is not within range': !v$.request.firstPayment.required.$invalid && v$.request.firstPayment.isInRange.$invalid,
                        }"
                    />
                </div>
                <div :class="{ 'col col-3 form-group form-required': true, 'has-error': v$.request.paymentsPerYear.$error }">
                    <label for="txt_amount">Payments per Year</label>
                    <rq-input-number
                        automation_id="txt_amount"
                        ref="txt_amount"
                        formatType="number"
                        defaultValue="0"
                        :minValue="0"
                        decimals="0"
                        input-group
                        no-prefix
                        v-model="v$.request.paymentsPerYear.$model"
                        />
                        <rq-validation-feedback
                            :messages="{ 'Payments per Year is required': v$.request.paymentsPerYear.required.$invalid,
                                         'Payments per Year must be greater than Zero': v$.request.paymentsPerYear.isGreaterThanZero.$invalid,
                                        }"
                        />
                </div>
            </div>
            <div class="row">
                <div :class="{ 'col col-3 form-group form-required': true, 'has-error': v$.request.annualInterestRate.$error }">
                    <label class="form-control-label" for="txt_annualInterestRate">Annual Interest Rate</label>
                    <div class="input-group">
                        <rq-input-number
                            v-model="v$.request.annualInterestRate.$model"
                            defaultValue="0"
                            decimals="3"
                            minValue="0"
                            maxValue="100"
                            cssClass="form-control"
                            automation_id="txt_annualInterestRate"
                        />
                        <span class="input-group-text">%</span>
                    </div>
                    <rq-validation-feedback>Annual Interest Rate is required</rq-validation-feedback>
                </div>
                <div :class="{ 'col col-3 form-group form-required': true, 'has-error': v$.request.lengthOfLoanYears.$error }">
                    <label for="txt_lengthOfLoanYears">Length of Loan</label>
                    <div class="input-group">
                        <rq-input-number
                            v-model="v$.request.lengthOfLoanYears.$model"
                            defaultValue="0"
                            decimals="0"
                            minValue="0"
                            cssClass="form-control"
                            automation_id="txt_lengthOfLoanYears" />
                        <span class="input-group-text">Years</span>
                    </div>
                    <rq-validation-feedback
                        :offset="28"
                        :messages="{ 'Length of Loan is required': v$.request.lengthOfLoanYears.required.$invalid,
                                     'Length of Loan must be greater than Zero': v$.request.lengthOfLoanYears.isGreaterThanZero.$invalid,
                                    }"
                    />
                </div>
                <div :class="{ 'col col-3 form-group': true, 'has-error': v$.request.enteredPayment.$error }">
                    <label for="txt_amount">Entered Payment</label>
                    <rq-input-number
                        automation_id="txt_amount"
                        ref="txt_amount"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        v-model="v$.request.enteredPayment.$model"
                        />
                        <rq-validation-feedback
                            :messages="{ 'Entered Payment must be greater than Zero': v$.request.enteredPayment.isGreaterThanZero.$invalid,
                                        }"
                        />
                </div>
            </div>
            <div class="row">
                <div class="col col-3 form-group">
                    <label for="txt_calculatedPayment">Calculated Payment</label>
                    <rq-input-number
                        automation_id="txt_calculatedPayment"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        disabled
                        v-model="request.calculatedPayment"
                        />
                </div>
                <div class="col col-3 form-group">
                    <label for="txt_totalPayment">Total Payments</label>
                    <rq-input-number
                        automation_id="txt_totalPayment"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        decimals="0"
                        input-group
                        no-prefix
                        disabled
                        v-model="request.totalPayments"
                        />
                </div>
                <div class="col col-3 form-group">
                    <label for="txt_totalPaid">Total Paid</label>
                    <rq-input-number
                        automation_id="txt_totalPaid"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        disabled
                        v-model="request.totalPaid"
                        />
                </div>
                <div class="col col-3 form-group">
                    <label for="txt_interestPaid">Interest Paid</label>
                    <rq-input-number
                        automation_id="txt_interestPaid"
                        formatType="money"
                        defaultValue="0"
                        :minValue="0"
                        decimals="2"
                        input-group
                        no-prefix
                        prependIcon="fas fa-dollar-sign"
                        disabled
                        v-model="request.interestPaid"
                        />
                </div>
            </div>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            automation_id="tbl_amortization_calculator"
            :config="gridConfig"
            title="Amortization Calculator"
            title-size="sm"
            class="grid-container"
            :data-source="gridDataSource"
            export-file-name="amortization_calculator_data"
            :hide-search="true"
            :persist-state="false"
            fixed-header
            >
            <template #toolbar>
                <ul class="nav ms-2 me-auto">
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_save"
                            class="btn btn-theme me-1"
                            :disabled="!hasItems || hasFilterChanged"
                            variant="theme"
                            @click="onSave"
                            >View Report
                        </b-button>
                        <rq-report-button
                            text="View Report"
                            ref="btn_view_report"
                            v-show="false"
                            :disabled="!hasItems || hasFilterChanged"
                            :path="reportOptions.reportPath"
                            :name="reportOptions.title"
                            :report-options="reportOptions"
                        />
                    </li>
                    <li class="nav-item">
                        <b-button
                            automation_id="btn_export"
                            class="btn btn-theme me-1"
                            :disabled="!hasItems"
                            variant="theme"
                            @click="onDownload"
                            >Export Schedule
                        </b-button>
                    </li>
                </ul>
            </template>
        </rqdx-action-data-grid>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { AmortizationCalculator, AmortizationCalculatorDetail, LoanModel, OrderMainDto } from '@order-entry/models';
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import { DateTime } from "luxon";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name: "AmortizationCalculator",
        props: {
        },
        data () {
            return {
                items: [],
                request: new AmortizationCalculator(),
                activeRequest: new AmortizationCalculator(),
                errorMessage: "",
                filtersExpanded: true,
                selectedLoanId: 0
            };
        },

        setup: () => ({ v$: useVuelidate() }),

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },

        validations() {
            const self = this;

            var result = {
                request: {
                    amount: {
                        required,
                        isGreaterThanZero(val) {
                            return _.gt(val, 0);
                        }
                    },
                    paymentsPerYear: {
                        required,
                        isGreaterThanZero(val) {
                            return _.gt(val, 0);
                        }
                    },
                    beginningOfLoan: {
                        required,
                        isInRange(val) {
                            return true;
                        }
                    },
                    firstPayment: {
                        required,
                        isInRange(val) {
                            return true;
                        }
                    },
                    annualInterestRate: {
                        required,
                        isGreaterThanZero(val) {
                            return _.gt(val, 0);
                        }
                    },
                    lengthOfLoanYears: {
                        required,
                        isGreaterThanZero(val) {
                            return _.gt(val, 0);
                        }
                    },
                    enteredPayment: {
                        isGreaterThanZero(val) {
                            return _.gte(val, 0) || _.isNil(val);
                        }
                    },
                }
            }
            return result;
        },

        computed: {
            ...mapGetters([ "lookupHelpers", "lookupItems" ]),
            ...mapState({
                loans: state => _.map(state.orders.loans, l => new LoanModel(l)),
                order: state => new OrderMainDto(state.orders.order),
                user: state => state.authentication.session.user
            }),
            // calcDisabled() { return this.request.$invalid || !this.hasFilterChanged;},
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            hasFilter(){
                return !_.isEqual(new AmortizationCalculator(this.request), new AmortizationCalculator());
            },
            hasActiveFilter(){
                return !_.isEqual(new AmortizationCalculator(this.activeRequest), new AmortizationCalculator());
            },
            hasItems(){
                return _.size(this.items) > 0;
            },
            hasFilterChanged(){
                return !_.isEqual(new AmortizationCalculator(this.activeRequest), new AmortizationCalculator(this.request));
            },
            loanOptions() { return this.lookupHelpers.getLoanOptions(); },
            reportOptions() {
                let reportOptions =
                    new ReportOptionsDto({
                        reportPath: "System Reports\\File Specific\\Amortization Calculator",
                        title: 'Amortization Calculator',
                        disabled: true,
                        immediate: true,
                        parameters: {
                            p_AmortizationCalculatorID: this.request.amortizationCalculatorID || 0,
                        },
                });

                return reportOptions;
            },
            showLoanDropDown() {
                return this.loans.length > 1;
            },
        },

        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.errorMessage = newValue ? 'Please correct the highlighted fields.' : "";
                },
                immediate: true
            },
        },

        methods: {
            calculateActualBalance(item) {
                let actualBalance = _.lte(item.actualBalance, item.scheduledPayment) ? 0 : Math.max(0, item.actualBalance - item.principlePortion - item.additionalPrinciple);

			    return this.parseFixed(actualBalance, 2);
            },

            calculateInterest(item) {
                let interest = 0;
                const annualInterestRate = this.request.annualInterestRate / 100;
                if (_.lt(item.actualBalance, 0.01) || _.lt(this.request.paymentsPerYear, 0.05) || _.lt(annualInterestRate, 0.001))
                    return interest;

                if (_.isEqual(item.paymentNumber, 1) && !_.isEqual(this.request.firstPayment, this.request.beginningOfLoan))
                {
                    const firstPaymentDate = _.parseLuxonDateTime(this.request.firstPayment);
                    const loanDate = _.parseLuxonDateTime(this.request.beginningOfLoan);
                    const numberOfDays = firstPaymentDate.diff(loanDate, "days").days;
                    const interestPerMonth = (annualInterestRate * item.actualBalance)/12;
                    const daysInMonth = firstPaymentDate.plus({ months: 1}).plus({ days: -1}).day;
                    const interestPerDay = interestPerMonth / daysInMonth;
                    interest = numberOfDays * interestPerDay;
                }
                else {
                    interest = (annualInterestRate / this.request.paymentsPerYear) * item.actualBalance;
                }
                return this.parseFixed(interest, 2);
            },

            calculateStartOfPeriod(dateStr) {
                const date = DateTime.fromFormat(dateStr, "MM/dd/yyyy");
                const paymentsPerYear = this.request.paymentsPerYear;
                switch (paymentsPerYear) {
                    case 1:
                        return date.plus({ months: 12 }).toFormat("MM/dd/yyyy");
                    case 2:
                        return date.plus({ months: 6 }).toFormat("MM/dd/yyyy");
                    case 3:
                        return date.plus({ months: 4 }).toFormat("MM/dd/yyyy");
                    case 4:
                        return date.plus({ months: 3 }).toFormat("MM/dd/yyyy");
                    case 5:
                        switch (date.month) {
                            case 5:
                            case 10:
                                return date.plus({ months: 3 }).toFormat("MM/dd/yyyy");
                            default:
                                return date.plus({ months: 2 }).toFormat("MM/dd/yyyy");
                        }
                    case 6:
                        return date.plus({ months: 2 }).toFormat("MM/dd/yyyy");
                    case 7:
                        switch (date.month) {
                            case 1:
                            case 6:
                                return date.plus({ months: 1 }).toFormat("MM/dd/yyyy");
                            default:
                                return date.plus({ months: 2 }).toFormat("MM/dd/yyyy");
                        }
                    case 8:
                        switch (date.month) {
                            case 1:
                            case 4:
                            case 7:
                            case 10:
                                return date.plus({ months: 1 }).toFormat("MM/dd/yyyy");
                            default:
                                return date.plus({ months: 2 }).toFormat("MM/dd/yyyy");
                        }
                    case 9:
                        switch (date.month) {
                            case 3:
                            case 7:
                            case 11:
                                return date.plus({ months: 2 }).toFormat("MM/dd/yyyy");
                            default:
                                return date.plus({ months: 1 }).toFormat("MM/dd/yyyy");
                        }
                    case 10:
                        switch (date.month) {
                            case 5:
                            case 11:
                                return date.plus({ months: 2 }).toFormat("MM/dd/yyyy");
                            default:
                                return date.plus({ months: 1 }).toFormat("MM/dd/yyyy");
                        }
                    case 11:
                        switch (date.month) {
                            case 11:
                                return date.plus({ months: 2 }).toFormat("MM/dd/yyyy");
                            default:
                                return date.plus({ months: 1 }).toFormat("MM/dd/yyyy");
                        }
                    case 12:
                        return date.plus({ months: 1 }).toFormat("MM/dd/yyyy");
                }
                return DateTime.now().toFormat("MM/dd/yyyy");
            },

            calculatePrinciple(item) {
                if (_.lt(item.actualBalance, .01)) return 0;

			    return this.parseFixed(Math.min(item.actualBalance, (item.scheduledPayment - item.interestPortion)), 2);
            },

            calculateScheduledBalance(item) {
                let scheduledBalance = _.lte(item.scheduledBalance, item.scheduledPayment) ? 0 : Math.max(0, item.scheduledBalance - item.principlePortion);

			    return this.parseFixed(scheduledBalance, 2);
            },

            calculatePayment() {
                let calculatedPayment;
                const annualInterestRate = this.request.annualInterestRate / 100;
                if (_.isEqual(this.request.annualInterestRate,0)) {
                    calculatedPayment = this.request.amount / (this.request.lengthOfLoanYears * this.request.paymentsPerYear);
                } else {
                    calculatedPayment = this.request.amount * (((annualInterestRate / this.request.paymentsPerYear) * Math.pow((1 + annualInterestRate / this.request.paymentsPerYear), (this.request.lengthOfLoanYears * this.request.paymentsPerYear))) / (Math.pow((1 + annualInterestRate / this.request.paymentsPerYear), (this.request.lengthOfLoanYears * this.request.paymentsPerYear)) - 1));
                }
                return this.parseFixed(calculatedPayment, 2);
            },

            clear() {
                if(!this.gridInstance) return;
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.AmortizationCalculatorApi.getAmortizationCalculator();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.request = new AmortizationCalculator(result);
                        self.activeRequest = new AmortizationCalculator(result);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Amortization Calculator.` });
                    })
                    .finally(() => {
                        self.setLoan();
                    });
            },

            initNonReactiveVariables() {
                this.itemKey = "paymentNumber";
                this.selectedLoanId = _.parseNumber(localStorage.getItem("selectedLoanId"), 0);
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columnHidingEnabled: false,
                    focusedRowEnabled: false,
                    selection: { mode: "none", showCheckBoxesMode: "never" },
                    paging: { enabled: false },
                    height: "100%",
                    columns: [
                        {
                            dataField: "paymentNumber",
                            dataType: "number",
                            caption: "Payment#",
                            allowFiltering: false,
                            allowSorting: false,
                        },
                        {
                            dataField: "startOfPeriod",
                            dataType: "date",
                            allowFiltering: false,
                            allowSorting: false,
                        },
                        {
                            dataField: "annualInterestRate",
                            dataType: "number",
                            format: {
                                type: "percent",
                                precision: 2
                            },
                            allowFiltering: false,
                            allowSorting: false,
                        },
                        {
                            dataField: "scheduledBalance",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            allowFiltering: false,
                            allowSorting: false,
                        },
                        {
                            dataField: "actualBalance",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            allowFiltering: false,
                            allowSorting: false,
                        },
                        {
                            dataField: "scheduledPayment",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            allowFiltering: false,
                            allowSorting: false,
                        },
                        {
                            dataField: "interestPortion",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            allowFiltering: false,
                            allowSorting: false,
                        },
                        {
                            dataField: "principlePortion",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            allowFiltering: false,
                            allowSorting: false,
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                };
            },

            onClearResults() {
                this.filtersExpanded = true;
                this.request = new AmortizationCalculator();
                this.activeRequest = new AmortizationCalculator();
                this.items = [];
                this.refresh();
            },

            onCalulate() {
                const self = this;
                self.v$.$touch();
                if (self.v$.$error) return;
                self.request.calculatedPayment = self.calculatePayment();
                self.request.totalPayments = self.request.lengthOfLoanYears * self.request.paymentsPerYear;
                const annualInterestRate = self.request.annualInterestRate / 100;
                let scheduledPayment = _.gt(self.request.enteredPayment, 0) ? self.request.enteredPayment : self.request.calculatedPayment;
                let options = {amortizationCalculatorID: self.request.amortizationCalculatorID, paymentNumber: 1, startOfPeriod: self.request.firstPayment, annualInterestRate, scheduledBalance: self.request.amount, actualBalance: self.request.amount, scheduledPayment, usersID: self.user.usersID};
                options.interestPortion = self.calculateInterest(options);
                options.principlePortion = self.calculatePrinciple(options);
                let firstPayment = new AmortizationCalculatorDetail(options);
                self.items.push(firstPayment);
                let startOfPeriod;
                let scheduledBalance;
                let actualBalance;
                for (let x = 1; x < self.request.totalPayments; x++) {
                    const priorPayment = self.items[x-1];
                    if (!priorPayment) continue;
                    startOfPeriod = self.calculateStartOfPeriod(priorPayment.startOfPeriod);
                    scheduledBalance = self.calculateScheduledBalance(priorPayment);
                    actualBalance = self.calculateActualBalance(priorPayment);
                    scheduledPayment = Math.min(priorPayment.scheduledPayment, actualBalance);
                    let options = {amortizationCalculatorID: self.request.amortizationCalculatorID, paymentNumber: x + 1, startOfPeriod, annualInterestRate, scheduledBalance, actualBalance, scheduledPayment, usersID: self.user.usersID};
                    options.interestPortion = self.calculateInterest(options);
                    options.principlePortion = self.calculatePrinciple(options);
                    let nextPayment = new AmortizationCalculatorDetail(options);
                    if (_.gt(scheduledBalance, 0.01) || _.gt(actualBalance, 0.01))
					    self.items.push(nextPayment);
                }

                self.request.totalPaid = _.sumBy(self.items, "scheduledPayment");
                self.request.interestPaid = _.sumBy(self.items, "interestPortion");
                self.request.totalPayments = _.size(self.items);
                self.refresh();
                self.activeRequest = new AmortizationCalculator(self.request);
            },

            onDownload() {
                const self = this;
                DxGridUtils.exportToCsv(self.gridInstance, `AmortizationCalculatorResults.csv`);
            },

            onLoanChange(e) {
                this.selectedLoanId = _.parseNumber(e.selectedValue, 0);
                this.setLoan();
            },

            onSave() {
                const self = this;
                self.save()
                    .then(() => {
                        let reportOptions =
                            new ReportOptionsDto({
                                reportPath: "System Reports\\File Specific\\Amortization Schedule Report",
                                title: 'Amortization Schedule Report',
                                disabled: true,
                                immediate: true,
                                parameters: {
                                    p_AmortizationCalculatorID: this.request.amortizationCalculatorID || 0,
                                },
                        });
                        _.invoke(this, "$rq.showReport", reportOptions.reportPath, reportOptions);

                    }).catch(error => {
                        self.$toast.error({ message: `${error.errorMessage}` });
                    });
            },

            parseFixed(v, d) { return accounting.parse(accounting.toFixed(v, d)); },

            refresh() {
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.refresh();
            },

            save(){
                const self = this;
                self.errorMessage = "";
                self.v$.$touch();
                if (self.v$.$error) { return Promise.reject({errorMessage: 'Please provide all required fields and correct errors.'}); }

                let saveRequest = {
                    AmortizationCalculator: self.request,
                    AmortizationCalculatorDetail: self.items
                };

                let apiPromise = self.$api.AmortizationCalculatorApi.saveAmortizationCalculatorDetail(saveRequest);
                return self.$rqBusy.wait(apiPromise);
            },

            setLoan() {
                const self = this;
                let loan = {};
                if (_.isEqual(self.selectedLoanId, 0)) {
                    loan = self.loans[0];
                } else {
                    loan = _.find(self.loans, {loanID: self.selectedLoanId});
                }
                const startDate = self.order.closeDate || DateTime.now();
                const beginningOfLoan = _.parseLuxonDateTime(startDate).plus({days: 28}).startOf('month').toFormat("MM/dd/yyyy");
                const firstPayment = beginningOfLoan;
                const years = _.isEqual(loan.loanTermPeriod, 2) ? loan.term : (_.isEqual(loan.loanTermPeriod, 1) ? loan.term/12 : loan.term/365);
                const options = {amount: loan.amount, annualInterestRate: loan.interestRate, beginningOfLoan, firstPayment, paymentsPerYear: 12, lengthOfLoanYears: years};
                _.assign(self.request, options);
                _.assign(self.activeRequest, options);
            },
        }
    }
</script>