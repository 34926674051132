
<template>
    <div class="logi-frame-container">
        <slot name="top" />
        <!-- <div>Reports Designer Frame</div> -->
        <!-- report designer - Logi Web Studio -->
        <div id="report1" style="position:relative;height:100%;overflow-x:auto;"></div>
        <slot />
    </div>
</template>

<script>
    import { computed, onMounted } from "vue";
    import { useStore } from "vuex";
    import { ReportIframeMode } from "@reporting/exago-reports/report-models";
    import { ReportAccessDto } from "@/shared/models/models";

    export default {
        name: "ReportDesignerIFrame",

        props: {
            reportPath: { type: String, default: null }
        },

        data() {
            return {
                frameUrl: "",
                frameLoaded: false,
                pathInvalid: false,
                hasError: false,
                showFrame: false,
                isBusy: true,
                actionsReady: false,
                app: {},
                designerUrl: "",
            };
        },

        watch: {
            definition: {
                handler(newValue, oldValue) {
                    //this.refresh();
                },
                deep: true,
                immediate: true,
            },
        },

        computed: {
            mode() {
                return !_.isNullOrEmpty(this.reportPath) || this.requireReportPath
                    ? ReportIframeMode.REPORT_VIEWER
                    : ReportIframeMode.STAND_ALONE;
            },
            isReportDesigner() { return this.mode == ReportIframeMode.STAND_ALONE; },
            displayErrorMessage() { return this.hasError || this.pathInvalid; },
            errorMessage() {
                return this.pathInvalid
                    ? "Report path invalid."
                    : this.hasError
                        ? `An issue occurred loading ${this.mode == ReportIframeMode.REPORT_VIEWER ? 'this report' : 'the Report Designer'}.`
                        : '';
            },
            definition() {
                return {
                    reportPath: this.reportPath,
                    reportAccess: this.reportAccess,
                }
            },
            showActions() { return !this.isReportDesigner && !this.isBusy && this.actionsReady; },
        },
        setup() {
            const vuexStore = useStore();
            const reportApiBaseUrl = computed({ get() { return vuexStore.state.exagoSession.reportAccess?.rsApi; }, });
            const reportAccess = computed({ get() { return vuexStore.state.exagoSession.reportAccess; }, });
            return {
                reportApiBaseUrl,
                reportAccess
            };
        },

        beforeUnmount() {
            this.endWait();
            window.removeEventListener("message", this.onPostMessageRecieved, false);
        },

        created() {
            let bAppFactoryLoaded = this.IsAppFactoryLoaded();

            if(bAppFactoryLoaded)
                this.refresh();
        },

        methods: {
            initExagoNavigationEvent() {
                const self = this;
                window.addEventListener("message", self.onPostMessageRecieved, false);
            },
            refresh() {
                const self =  this;
                self.showFrame = false;
                self.$nextTick()
                    .then(() => {
                        self.showFrame = true;
                        self.process();
                    });
            },
            process() {
                const self =  this;
                self.loadFrame(self.reportAccess);
            },
            refreshParent() {
                const self = this;
                this.$emit('report-designer-launcher', 1);
            },

            loadFrame(reportAccess) {
                const self = this;

                self.clearIframe();

                self.hasError = false;
                self.isBusy = true;

                if(!self.validatePath()) return;

                let entryId = "report1";

                // eslint-disable-next-line no-undef
                var Factory = com.jinfonet.api.AppFactory;
                let server = {
                    url: reportAccess.rsReportCreatorUrl,
                    user: reportAccess.rsUser,
                    pass: reportAccess.rsPwd,

                    jrd_prefer:{
                        pagereport:{
                            editMode:{
                                hasToolbar: true,
                                hasSideArea: true
                            },
                            feature_UserInfoBar:true,
                            feature_ToolBar: true,
                            feature_Toolbox: true,
                            feature_DSOTree: true,
                            feature_TOCTree: true,
                            feature_PopupMenu: true,
                            feature_ADHOC: true
                        },
                    },
                    jrd_studio_mode: "edit",
                    jrd_newRptset: true,
                },

                prptRes = {},
                catRes = {name: reportAccess.rsCustomCatalog};
                let params = {};

                self.app = Factory.runReport(
                    server, prptRes, catRes, params, entryId);

                self.app.attachEvent("jreportstatus", 4, this, function(e){
                    self.onjreportstatus(e);
                });

                self.isBusy = false;
                self.actionsReady = true;
                this.frameLoaded = true
                self.$emit('loaded')
            },

            onjreportstatus(e) {
                var msg = e.getData();
                console.log(msg);
            },

            getReportOptions() {
                const self = this;

                let opts = _.cloneDeep(self.reportOptions);

                if(_.isNullOrEmpty(opts.reportPath) && !_.isNullOrEmpty(self.reportPath))
                    opts.reportPath = self.reportPath;

                return opts;
            },
            clearIframe() {
                this.frameUrl = '';
                this.frameLoaded = false;
            },
            validatePath() {
                const self = this;
                if(!self.requireReportPath || !_.isEmpty(self.reportPath) || (this.mode === ReportIframeMode.REPORT_VIEWER && !_.isEmpty(self.reportOptions?.reportPath))) {
                    self.pathInvalid = false;
                    return true;
                }
                self.pathInvalid = true;
                self.endWait();
                return false;
            },
            // onIframeLoaded() {
            //    if (this.frameUrl) this.frameLoaded = true;
            //    if(!this.persist) return;
            //},
            startWait() { this.$rqBusy.startWait(false); },
            endWait() { this.$rqBusy.endWait(true); },
            onPostMessageRecieved(e) {
            },
            IsAppFactoryLoaded(){
                let bFactoryLoaded = false;
                if(typeof com !== 'undefined')
                {
                // eslint-disable-next-line no-undef
                if (com?.jinfonet?.api?.AppFactory)
                    bFactoryLoaded = true;
                }
                return (bFactoryLoaded);
            },
        }
    };
</script>
