<template>
    <div class="rq-container content-wrapper doc-mgmt-add">
        <div class="row">
            <div class="col col-2">
                <div class="category-list d-flex flex-column">
                    <div class="instruction-text" @click="onClick($event)" @drop="onDrop($event)"
                        @dragover="onDragOver($event)" style="width:100%; height:200px; border:2px dashed #ccc">
                        <p>Click or drop to upload files</p>
                    </div>
                </div>
            </div>
            <div class="col col-10">
                <rq-banner icon="fas fa-exclamation-circle" variant="error" :message="errorMessage"
                    :visible="errorMessage.length > 0" @dismiss="errorMessage = ''" dismissable />
                <rqdx-action-data-grid ref="dataGrid2" automation_id="tbl_buyer_seller_documents_add"
                    :actions="selectionActions" :config="gridConfig" :data-source="gridDataSource"
                    :export-file-name="elementName('', 'data')" @delete="onDeleteItem" fixed-header hide-search
                    hide-export hide-show-column-chooser rq-editable />
            </div>
        </div>
        <form enctype="multipart/form-data" novalidate ref="fileForm">
            <input type="file" ref="fileInput" id="fileInput" name="fileInput" @change="onFileInputChange($event)"
                :accept="acceptedAttr" class="input-file" multiple />
        </form>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { DocumentFileType } from "../../../documents/enums";
import { FileScanDocumentDuplicateAction } from "../../../document-mgmt/enums";
import { DateTime } from "luxon";
import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
import { BuyerSellerDocumentDto } from "@/modules/file/order-entry/buyer-seller/models.js";


export default {
    name: 'BuyerSellerDocumentsAdd',
    components: {},
    props: {
        buyerSellerID: { type: Number, default: 0 },
        existingItems: { type: Array, required: true, default: () => [] }
    },
    data() {
        return {
            acceptedFileTypes: [".docx", ".pdf"],
            items: [],
            itemData: [],
            isDragging: false,
            maxUploadSize: 100 * 1024 * 1024,//100Mb
            totalSizeDisplay: "",
            errorMessage: "",
            users: [],
        }
    },
    created() {
        this.initNonReactiveVariables();
        this.initGridConfig();
        this.setDialogOkButtonDisabled(true);
    },
    watch: {
        'items.length'(newLength) {
            if (newLength > 0) {
                this.setDialogOkButtonDisabled(false);
            }
            else {
                this.setDialogOkButtonDisabled(true);
            }
        },
    },
    computed: {
        ...mapGetters([
            "lookupHelpers",
            "lookupItems",
            "currentSession"
        ]),
        ...mapState({
            orderId: state => state.orders.orderId,
            order: state => state.orders.order,
            currentUser: state => state.authentication.session.user
        }),
        acceptedAttr() { return _.join(this.acceptedFileTypes, ","); },
        gridInstance() {
            return _.get(this.$refs, "dataGrid2.gridInstance", null);
        },
        fileForm() { return _.get(this.$refs, "fileForm", null) || {}; },
        fileInput() { return _.get(this.$refs, "fileInput", null) || {}; },
        currentUserID() { return this.currentSession.userId; },
    },
    methods: {
        clear() {
            if (!this.gridInstance) return;
            this.gridInstance.option("focusedRowIndex", -1);
            this.gridInstance.clearSelection();
        },

        clearForm() {
            if (!this.fileForm) return;
            this.fileForm.reset();
            if (!this.fileInput) return;
            this.fileInput.value = null;
        },

        deleteItemData(keys) {
            _.each(keys, k => {
                _.remove(this.itemData, (i) => { return _.get(i, "name", "") == k; });
            });
        },
        onClick(e, id) {
            if (!this.fileInput) return;
            this.fileInput.click();
        },

        onDragOver(e, id) {
            e.preventDefault();
        },

        onDrop(e, id) {
            const self = this;
            self.isDragging = true;
            e.preventDefault();
            let files = [];
            if (e.dataTransfer.items) {
                for (var i = 0; i < e.dataTransfer.items.length; i++) {
                    if (e.dataTransfer.items[i].kind === 'file') {
                        var file = e.dataTransfer.items[i].getAsFile();
                        if (self.isValidFileExt(file.name.substr(file.name.lastIndexOf('.')))) {
                            files.push(file);
                        } else {
                            self.errorMessage = `Invalid File ${file.name}, it will be excluded.`;
                        }
                    }
                }
            } else {
                for (var j = 0; j < e.dataTransfer.files.length; j++) {
                    var file2 = e.dataTransfer.files[j];
                    if (self.isValidFileExt(file2.name.substr(file2.name.lastIndexOf('.')))) {
                        files.push(file2);
                    } else {
                        self.errorMessage = `Invalid File ${file2.name}, it will be excluded.`;
                    }
                }
            }
            if (files.length == 0) return;
            this.processFiles(files);
            e.dataTransfer.clearData();
        },
        onFileInputChange(e) {
            if (!e.target.files.length) return;
            const self = this;
            let fileInput = e.target;
            _.forEach(fileInput.files, f => {
                if (!self.checkFile(f)) {
                    return self.$toast.error({ message: `Documents must be of type '.docx' or '.pdf'` });
                }
            })
            self.processFiles(e.target.files);
        },
        checkFile(file) {
            if (!file) return;
            const self = this;
            self.errorMessage = "";
            let fileExt = file.name.substr(file.name.lastIndexOf('.'));
            if (!self.isValidFileExt(fileExt)) {
                self.setDialogOkButtonDisabled(true);
                self.clearForm();
                return false;
            }
            self.setDialogOkButtonDisabled(false);
            return true;
        },

        setDialogOkButtonDisabled(val) {
            if (val)
                this.$emit("disable-ok");
            else
                this.$emit("enable-ok");
        },

        processFiles(files = []) {
            if (files.length == 0) return;
            const self = this;

            let buyerSellerDocumentID = 0;
            let maxBuyerSellerDocument = _.minBy(self.items, 'buyerSellerDocumentID') || {};
            if (!_.isEmpty(maxBuyerSellerDocument)) {
                buyerSellerDocumentID = maxBuyerSellerDocument.buyerSellerDocumentID;
            }
            _.each(files, f => {
                buyerSellerDocumentID--;
                let fileExt = f.name.substr(f.name.lastIndexOf('.'));
                let fileName = f.name.substr(0, f.name.lastIndexOf('.'));
                let fileType = DocumentFileType.fromFileExtension(fileExt.toLowerCase());
                let contentType = f.name.substring(f.name.lastIndexOf('.') + 1).toUpperCase();
                let doc = new BuyerSellerDocumentDto({
                    buyerSellerDocumentID: buyerSellerDocumentID,
                    buyerSellerID: self.buyerSellerID,
                    effectiveDate: DateTime.now(),
                    fileName: f.name,
                    contentType: contentType,
                    fileSizeBytes: f.size,
                    fileType: fileType,
                    modifiedBy: self.currentUserID,
                    modifiedByName: self.currentUser.displayName,
                    modifiedDate: DateTime.now(),
                });
                if (!self.isUniqueItemExisting({ fileName })) {
                    doc.requiresAttention = true;
                    doc.fileScanDocumentDuplicateAction = FileScanDocumentDuplicateAction.ReplaceExisting;
                } else {
                    doc.requiresAttention = false;
                    doc.fileScanDocumentDuplicateAction = 0;
                }
                if (self.isUniqueItem(doc)) {
                    self.items.splice(self.items.length, 0, doc);
                    self.itemData.push(f);
                } else {
                    self.errorMessage = `Document with file type [${doc.fileTypeDisplay}] and Description [${doc.fileName}] is already in queue.`;
                }
            });
            self.clearForm();
            self.refresh();
        },

        elementName(prefix = "", suffix = "") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

        isUniqueItemExisting(item) {
            let dup = {};
            dup = _.find(this.existingItems, (i) => {
                return _.isEqual(_.toLower(_.trim(i.fileName.substring(0, i.fileName.lastIndexOf('.')))), _.toLower(_.trim(item.fileName)));
            });
            return _.isEmpty(dup);
        },

        isUniqueItem(item) {
            let dup = {};
            dup = _.find(this.items, (i) => {
                return _.toLower(_.trim(i.fileName)) === _.toLower(_.trim(item.fileName))
                    && _.parseNumber(i.fileType, -1) === _.parseNumber(item.fileType, -1)
                    && _.parseNumber(i.buyerSellerDocumentID, -1) != _.parseNumber(item.buyerSellerDocumentID, -1)
                    ;
            });
            return _.isEmpty(dup);
        },

        isValidFileExt(fileExt) {
            return _.includes(this.acceptedFileTypes, _.toLower(fileExt));
        },

        initGridConfig() {
            const self = this;
            self.gridConfig = {
                focusedRowEnabled: false,
                cacheEnabled: false,
                onEditorPreparing: self.onEditorPreparing,
                onRowUpdating: self.onRowUpdating,
                paging: { enabled: false },
                pager: { showPageSizeSelector: true, allowedPageSizes: [50, 100, 500], showInfo: true },
                remoteOperations: { sorting: false, paging: false },
                columns: [
                    { dataField: self.itemKey, visible: false, showInColumnChooser: false },
                    {
                        dataField: "fileName",
                        dataType: "string",
                        caption: "File Name",
                        allowEditing: false
                    },
                    {
                        dataField: "contentType",
                        dataType: "string",
                        caption: "File Type",
                        allowEditing: false
                    },
                    {
                        dataField: "effectiveDate",
                        dataType: "date",
                        caption: "Effective Date",
                        validationRules:
                            [
                                {
                                    type: "custom",
                                    message: "Effective Date is Required.",
                                    validationCallback: (e) => {
                                        if(!_.isNullOrEmpty(e.data.effectiveDate)){
                                            self.setDialogOkButtonDisabled(false);
                                            return true;
                                        }
                                        else{
                                            self.setDialogOkButtonDisabled(true);
                                            return false;
                                        }
                                    }
                                },
                            ]
                    },
                    {
                        dataField: "expireOnDate",
                        dataType: "date",
                        caption: "Expiration Date",
                        allowEditing: false,
                    },
                    {
                        dataField: "fileScanDocumentDuplicateAction",
                        dataType: "string",
                        caption: "Action",
                        alignment: "left",
                        minWidth: 75,
                        lookup: {
                            dataSource: self.documentActions,
                            displayExpr: "name",
                            valueExpr: "id"
                        },
                        setCellValue: function (rowData, data) {
                            rowData.fileScanDocumentDuplicateAction = data.cellData.fileScanDocumentDuplicateAction = data.value;
                            if (data.value == FileScanDocumentDuplicateAction.CreateNew) {
                                rowData.fileName = data.cellData.fileName = `${data.cellData.fileName}_${DateTimeHelper.now("yyyy-MM-dd hh:mm:ss a")}`;
                            }
                            if (data.value == FileScanDocumentDuplicateAction.ReplaceExisting) {
                                rowData.fileName = data.cellData.fileName = data.cellData.fileName.substring(0, data.cellData.fileName.lastIndexOf('_'));
                                rowData.buyerSellerDocumentID = data.cellData.buyerSellerDocumentID;
                            }
                            self.setItemData(data.cellData);
                        },
                        editCellTemplate: function (cellElement, cellInfo) {
                            $("<div />").dxSelectBox({
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.documentActions;
                                    }
                                },
                                searchEnabled: true,
                                displayExpr: "name",
                                valueExpr: "id",
                                value: cellInfo.data.fileScanDocumentDuplicateAction,
                                disabled: !_.get(cellInfo.data, 'requiresAttention', false),
                                onValueChanged: function (e) {
                                    cellInfo.setValue({ value: e.value, cellData: cellInfo.data });
                                },
                            }).appendTo(cellElement);
                        }
                    },
                    {
                        dataField: "modifiedByName",
                        dataType: "string",
                        caption: "Modified By",
                        allowEditing: false
                    },
                    {
                        dataField: "modifiedDate",
                        dataType: "date",
                        caption: "Date Modified",
                        visible: true,
                        allowEditing: false
                    },
                    { type: "buttons", visible: false, showInColumnChooser: false }
                ],
                summary: {
                    totalItems: [{
                        name: "UploadSummary",
                        showInColumn: "fileName",
                        displayFormat: "Total Upload Size: {0}",
                        summaryType: "custom"
                    }
                    ],
                    calculateCustomSummary(options) {
                        if (options.name !== "UploadSummary") return;
                        options.totalValue = self.totalSizeDisplay;
                    }
                },
            };

            self.gridDataSource = {
                loadMode: "raw",
                key: self.itemKey,
                load() {
                    return Promise.resolve(self.items);
                },
                update: self.onGridUpdate
            };
        },
        initNonReactiveVariables() {
            const self = this;
            self.itemTypeName = "Document";
            self.itemTypeNamePlural = "Documents";
            self.itemKey = "buyerSellerDocumentID";
            self.documentActions = FileScanDocumentDuplicateAction.lookupItems;
            self.documentFileTypes = DocumentFileType.lookupItems;
            self.selectionActions = [
                { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` },
            ];
        },

        onDeleteItem(e) {
            if (!e || !e.data) return;
            const self = this;
            let items = e.data;
            _.forEach(items, i => {
                if(i.fileScanDocumentDuplicateAction === FileScanDocumentDuplicateAction.CreateNew){
                    i.fileName = i.fileName.substr(0, i.fileName.lastIndexOf('_'));
                }
            })

            let ok = function (args) {
                let toBeDeletedKeys = _.map(items, "fileName");
                _.pullAllBy(self.items, items, self.itemKey);
                self.deleteItemData(toBeDeletedKeys);
                let message = toBeDeletedKeys.length > 1 ? `${toBeDeletedKeys.length} ${self.itemTypeNamePlural} were removed from the queue.` : `${self.itemTypeName} was removed from the queue.`
                self.$toast.success({ message: message });
                self.refresh();
            }

            self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes' });
        },

        onGridUpdate(key, values) {
            return;
        },

        onEditorPreparing(e) {
            if (e.parentType !== "dataRow") return;
            if (e.dataField == "fileScanDocumentDuplicateAction" && _.getNumber(e, "row.data.fileScanDocumentDuplicateAction", 0) == 0) { e.editorOptions.disabled = true; return; }
        },

        onRowUpdating(e){
            const self = this;
            const {oldData, newData} = e;
            const itemIndex = self.items.findIndex((item) => item === oldData);
            if(itemIndex !== -1){
                self.items[itemIndex] = {...self.items[itemIndex], ...newData};
            }
        },

        refresh() {
            const self = this;
            self.clear();
            self.gridInstance.refresh();
            self.validate();
        },

        removeIgnored() {
            const self = this;
            let ignoredItems = _.filter(self.items, ["fileScanDocumentDuplicateAction", FileScanDocumentDuplicateAction.Ignore]);
            _.pullAllBy(self.items, ignoredItems, self.itemKey);
            let toBeDeletedKeys = _.map(ignoredItems, "fileName");
            self.deleteItemData(toBeDeletedKeys);
        },

        saveDocuments() {
            const self = this;
            self.removeIgnored();
            const formData = new FormData();
            _.each(self.itemData, f => {
                formData.append(`file-${f.name}`, f);
            });
            formData.append('data-', JSON.stringify(self.items));
            let apiPromise = self.$api.BuyerSellerDocumentApi.uploadBuyerSellerDocument(formData);
            return self.$rqBusy.wait(apiPromise);
        },

        setItemData(data) {
            const self = this;
            let itemIndex = _.findIndex(self.items, [self.itemKey, _.get(data, self.itemKey)]);
            self.errorMessage = "";
            let item = new BuyerSellerDocumentDto(_.assign({}, self.items[itemIndex], data));
            _.assign(self.items[itemIndex], item);
            self.validate();
        },

        validate() {
            const self = this;
            self.errorMessage = "";
            let totalUploadSize = _.sumBy(self.items, "fileSizeBytes");
            for (let aMultiples = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], nMultiple = 0, nApprox = totalUploadSize / 1024; nApprox > 1; nApprox /= 1024, nMultiple++) {
                self.totalSizeDisplay = nApprox.toFixed(3) + " " + aMultiples[nMultiple];
            }
            if (totalUploadSize > self.maxUploadSize) {
                self.errorMessage = "You have gone over the maximum upload size, please remove files.";
            }
            let invalidCount = _.size(_.filter(self.items, i => i.requiresAttention == true && i.fileScanDocumentDuplicateAction == 0));
            if (invalidCount > 0) {
                self.errorMessage = `You have ${invalidCount} record${invalidCount == 1 ? '' : 's'} that requires attention, please select an action.`;
                self.$emit("disable-ok");
            }
            self.$emit(`${self.errorMessage.length > 0 ? 'disable' : 'enable'}-ok`);
        },
    }
}
</script>
<style lang="scss">
.instruction-text {
    display: flex;
    align-items: center;
    height: 50px;
    color: gray;
    font-weight: 300;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
</style>