<template>
    <rqdx-action-data-grid
        id="workflow-tasks-list"
        ref="dataGrid"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        :strikethrough-if-true="['isInActive']"
        target-inactive-column="isInActive"
        @activate="onActivateItem($event, 'Activate')"
        @inactivate="onActivateItem($event, 'Inactivate')"
        @edit="onEditItem"
        @delete="onDeleteItem"
        @insertBefore="onInsertItemBefore"
        @insert="onInsertItem"
        @movedown="onMoveItemDown"
        @moveup="onMoveItemUp"
        @moveto="onMoveItemTo"
        @rowDoubleClick="onEditItem"
        @edit-note="onEditNote"
        integrated-search
        rq-filters
        show-include-inactive
    />
    <notes-popover
        container="#workflow-tasks-list"
        :popover="notesPopover"
        v-model:visible="notesPopover.visible"
    />

</template>

<script>
    import { GlobalEventManager } from "@/app.events";
    import { mapState, mapGetters } from "vuex";
    import { WorkflowTasksDto }  from "../models";
    import WorkflowTasksForm  from "./WorkflowTasksForm.vue";
    import WorkflowTasksSequenceForm from "./WorkflowTasksSequenceForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import NoteDialog from "@config/shared/NoteDialog.vue";
    import NotesPopover from "@/shared/components/rq/NotesPopover";


    export default {
        name:"WorkflowTasksList",
        components: { NotesPopover },
        data () {
            return {
                items: [],
                itemKey: "workflowTaskID",
                itemTypeName: "Workflow Task",
                itemTypeNamePlural: "Workflow Tasks",
                selectedItem: {},
                validationErrors: [],
                verifiedItem: {},
                maxSequence: 0,
                gridDataSource: {},
                gridConfig: {},
                addEventName: "",
                insertClick: false,
                skipChangeChecking: null,
                notesPopover: {
                    visible: false,
                    target: null,
                    notes: null,
                    description: null
                }
            };
        },
        created(){
            this.initGridConfig();
            this.initListeners();
        },
        beforeUnmount() {
            GlobalEventManager.unregister(this);
            this.$events.off(this.addEventName, this.onAddItem);
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "regionsLookup",
                "currentSession"
            ]),
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}`, disabled: function(e) { return (e.data.isSystem == true);} },
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}`, disabled: function(e) { return (_.some(e.data, ['isSystem', true]));} },
                        {   name: "insert",
                            text: "Insert",
                            children: [
                                { name: "insertBefore", text: "Insert Before", eventName: "insertBefore", requireSelection: true, tooltip: `Insert ${this.itemTypeName} Before`},
                                { name: "insertAfter", text: "Insert After", eventName: "insert", requireSelection: true, tooltip: `Insert ${this.itemTypeName} After`},
                            ]
                        },
                        {   name: "move",
                            text: "Move",
                            children: [
                                { name: "moveup", text: "Move Up", eventName: "moveup",  requireSelection: true, tooltip: `Move ${this.itemTypeName} Up` },
                                { name: "movedown", text: "Move Down", eventName: "movedown",  requireSelection: true, tooltip: `Move ${this.itemTypeName} Down` },
                            ]
                        },
                        { name: "moveto", text: "Change Sequence", eventName: "moveto",  requireSelection: true, tooltip: `Change ${this.itemTypeName} Sequence` },
                        { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['isInActive', false]); } },
                        { name: "Inactivate", text: "Inactivate", eventName: "inactivate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['isInActive', true]); } },
                        {
                            name: "edit-note",
                            text: "Add Notes",
                            tooltip: `Add ${ this.itemTypeName } Notes`,
                            eventName: "edit-note",
                            requireSelection: true,
                            visible: function(e) { return _.isNullOrEmpty(_.get(e.data, "notes"));}
                        },
                        {
                            name: "edit-note",
                            text: "Edit Notes",
                            tooltip: `Edit ${ this.itemTypeName } Notes`,
                            eventName: "edit-note",
                            requireSelection: true,
                            visible: function(e) { return !_.isNullOrEmpty(_.get(e.data, "notes"));}
                        },

                    ];
            },
        },

        methods: {
            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.ConfigWorkflowTasksApi.activate(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.toggleInactiveFlag(keys);
                        self.refresh();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            onActivateItem(e, verb) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            addNewItem(nextSeq) {
                const self = this;
                self.gridInstance.deselectAll();
                self.selectedItem = new WorkflowTasksDto();
                let regionID = _.get(this.currentSession, "user.regionID", 0);
                self.selectedItem.regionID = regionID;    // Set RegionID to default (Admin Region) for New Workflow Task.
                ++self.maxSequence;
                self.selectedItem.sequence = nextSeq;
                self.loadItem(self.selectedItem);
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onAddItem() {
                const self = this;
                self.insertClick = false;
                this.addNewItem(self.getNextSequence());
            },
            onInsertItem()
            {
                const self = this;
                self.insertClick = true;
                this.addNewItem(self.getNextSequence());
            },
            onInsertItemBefore()
            {
                const self = this;
                self.insertClick = true;
                let previous = self.getNextSequence() -1 ;
                this.addNewItem(previous);
            },
            getNextSequence()
            {
                const self = this;
                let nextSeq = self.maxSequence + 1;
                if(self.$refs.dataGrid.selectedRows.length > 0)
                    nextSeq = self.$refs.dataGrid.selectedRows[0].sequence + 1;
                return nextSeq;
            },

            onEditItem(e) {
                if(e.data.isSystem) {return;}
                const self = this;
                self.selectedItem = new WorkflowTasksDto(e.data);
                self.loadItem(new WorkflowTasksDto(e.data));
            },

            async onMoveItemDown(e)
            {
                const self = this;
                self.selectedItem = new WorkflowTasksDto(e.data);
                self.item = self.selectedItem;
                self.item.sequence = self.getNextSequence();
                if(self.maxSequence < self.item.sequence)
                {
                    self.$toast.info(`Cannot move ${self.itemTypeName} to a sequence greater than ${self.maxSequence}.`)
                    return;
                }
                self.skipChangeChecking = self.returnChangeInfo("down", self.item.sequence);
                await self.save(self.item, null, false);
                self.gridInstance.deselectAll();
                self.gridInstance.refresh();
            },
            async onMoveItemUp(e)
            {
                const self = this;
                self.selectedItem = new WorkflowTasksDto(e.data);
                self.item = self.selectedItem;
                self.item.sequence = self.getNextSequence()-2;
                if(self.item.sequence < 1)
                {
                    self.$toast.info(`Cannot move ${self.itemTypeName} to a sequence less than 1.`)
                    return;
                }
                self.skipChangeChecking = self.returnChangeInfo("up", self.item.sequence);
                await self.save(self.item, null, false);
                self.gridInstance.deselectAll();
                self.gridInstance.refresh();
            },
            onMoveItemTo(e)
            {
                const self = this;
                self.selectedItem = new WorkflowTasksDto(e.data);
                self.resequenceItem(new WorkflowTasksDto(e.data));
            },
            returnChangeInfo(direction, newValue)
            {
                let oldValue = newValue -1;
                if(direction=== "up")
                    oldValue = newValue +1;
                return { "name": "sequence", "old": oldValue, "new": newValue};
            },
            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let deletedItems = [];
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let okHandler = function (args) {
                    for(let x = 0; x< items.length; x++)
                    {
                        if(self.canDelete(items[x]))
                        {
                            items[x].isDeleted = true;
                            deletedItems.push(items[x])
                        }
                    }
                    if(deletedItems.length != items.length)
                    {
                        self.$toast.error(`Error deleting ${itemLabel}, some items cannot be deleted because they are in use.`);
                        if(deletedItems.length > 0)
                            self.delete(deletedItems);
                    }
                    else
                        self.delete(deletedItems);

                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    `Are you sure you want to delete the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            canDelete(item)
            {
                let canDelete = true;
                if(item.inUseOrder)
                    canDelete = false;
                if(item.inUseFileScan)
                    canDelete = false;
                if(item.inUseTemplate)
                    canDelete = false;
                if(item.inUseStandardLng)
                    canDelete = false;

                return canDelete;
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: self.itemKey,
                            dataType: "number",
                            visible: false,
                            showInColumnChooser: false,
                        },
                        {
                            dataField: "sequence", dataType: "number", sortIndex: 0, sortOrder: "asc", visible:"false"
                        },
                        {
                            // dataField: "regionDisplay",
                            dataField: "regionID",
                            caption: "Region",
                            calculateSortValue: "regionDisplay",
                            dataType: "number",
                            lookup: {
                                dataSource: self.regionsLookup,
                                displayExpr: "displayName",
                                valueExpr: "regionID",
                            },
                        },
                        {
                            dataField: "taskName", caption: "Task Name", dataType: "string"
                        },
                        {
                            dataField: "workflowTaskBasedOnDisplay",
                            dataType: "string",
                            caption: "Based on Data Type"
                        },
                        {
                            dataField: "preceedingTaskDisplay",
                            dataType: "string",
                            caption: "Start After Task"
                        },
                        { dataField: "startLagDays", dataType: "number", visible: false},
                        { dataField: "startLagHours", dataType: "number", visible: false},
                        { dataField: "durationDays", dataType: "number"},
                        { dataField: "durationHours", dataType: "number"},
                        {
                            dataField: "departmentName", dataType: "string", caption: "Department"
                        },
                        {
                            dataField: "assignedPersonDisplay", dataType: "string", caption: "Assigned Person"
                        },
                        {
                            caption: "Notes",
                            dataField: "hasNotes",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolPopoverCellTemplate({
                                idAppend: "std-language-notes-popup-info-",
                                handlers:{
                                    mouseover(cellElement, cellInfo, e) {
                                        self.updateNotesPopover(cellInfo.data.notes, cellInfo.data.taskName, e.target);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        self.updateNotesPopover();
                                    },
                                    click(cellElement, cellInfo, e) {
                                        e.stopPropagation();
                                    },
                                }
                            }),
                        },
                        {
                            dataField: "autoEmailYN", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate , visible: false
                        },
                        {
                            dataField: "isPredefinedTask" , caption: "Predefined Task", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate , visible: false
                        },
                        {
                            dataField: "isInActive" , caption: "Inactive", dataType: "boolean",  cellTemplate: DxGridUtils.boolCellTemplate, visible: false
                        },
                        {
                            dataField: "taskClosedSystemEventDisplay" , caption: "Close Task When", dataType: "string", visible: false
                        },
                        {
                            dataField: "closeTaskUserEventDisplay" , caption: "After Close Task", dataType: "string", visible: false
                        },
                        {
                            dataField: "cmAuto" , caption: "CM Auto", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate , visible: false
                        },
                        {
                            dataField: "cmEnterpriseServiceDisplay", caption: "Enterprise Service", dataType: "string", visible: false
                        },
                        {
                            dataField: "cmOrderCategoryDisplay", caption: "File Category", dataType: "string", visible: false
                        },
                        {
                            dataField: "cmEventCodeDisplay", caption: "Event Code", dataType: "string", visible: false
                        },
                        {
                            dataField: "isSystem", caption: "Is System", dataType: "boolean", visible: true, cellTemplate: DxGridUtils.boolCellTemplate, allowEditing: false
                        }
                    ],
                };
                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData,
                };
            },
            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },
            fetchData() {
                const self = this;
                self.maxSequence = 0;
                let apiPromise = self.$api.ConfigWorkflowTasksApi.get();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let items = _.map(result.details, i => new WorkflowTasksDto(i));
                        self.items = items;
                        for(let i = 0; i< self.items.length; i++)
                        {
                            if(self.items[i].sequence > self.maxSequence)
                            {
                                self.maxSequence = self.items[i].sequence;
                            }
                        }
                        return { data: items, totalCount: items.length };
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },
            loadItem(item) {
                const self = this;
                let nextSeq = this.maxSequence;

                let onOk = (e, addAnother=false) => {
                    const dialogId = null;
                    const item = e.component.item;
                    if (!e.component.isValid()) {
                        return false;
                    }
                    return self.$rqBusy.wait(self.save(item))
                        .then(() => {
                            if (addAnother) {
                                self.selectedItem = new WorkflowTasksDto();
                                let regionID = _.get(this.currentSession, "user.regionID", 0);
                                self.selectedItem.regionID = regionID;  // Set RegionID to default (Admin Region) for New Workflow Task.
                                nextSeq++;
                                self.selectedItem.sequence = nextSeq;
                                self.$dialog.reloadComponent({ dialogId, props: { item: self.selectedItem } });
                                return false;
                            }
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                };
                let onCancel = e => {
                    self.refresh();
                };
                const buttons = _.dialogButtons({ onCancel, onOk, showAddAnother: item.isNew })
                self.$dialog.open({
                    title: `${item.isNew ? "Add": "Edit"} ${self.itemTypeName}${(item.isNew ? "" : `: ${item.taskName}`)}`,
                    width: "800",
                    height: "75%",
                    resizable: false,
                    scrollable: true,
                    adaptive: true,
                    component: WorkflowTasksForm,
                    closeOnEsc: true,
                    props: {
                        item: item,
                        itemTypeName: self.itemTypeName,
                        uniqueValidator: (item) => !self.isDuplicate(item)
                    },
                    buttons
                });

            },
            resequenceItem(item) {
                const self = this;

                self.$dialog.open({
                    title: `Edit ${self.itemTypeName}`,
                    width: "500",
                    height: "auto",
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    component: WorkflowTasksSequenceForm,
                    closeOnEsc: true,
                    props: {
                        item: item,
                        itemTypeName: self.itemTypeName,
                        maxSequence:  self.maxSequence
                    },
                    onOk(e) {
                        let form = e.component;
                        let item = form.item;
                        self.validationErrors = [];
                        self.verifiedItem = {};
                        let failsDuplicateCheck = (item.isNew) ? self.isDuplicate(item): false;
                        if (!item.isValid || failsDuplicateCheck) {
                            form.validationErrors = _.concat(item.validationErrors, self.validationErrors);
                            form.verifiedItem = _.merge(item.verifiedItem, self.verifiedItem);
                            return false;
                        }
                        self.save(item, form, false).then(() => self.refresh());
                    },
                    onCancel(e) {
                        self.refresh();
                    },
                });

            },
            delete(items) {
                const self = this;
                let data = { details: _.concat(items)};
                let apiPromise = self.$api.ConfigWorkflowTasksApi.save(data)
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        let keys = _.map(items, self.itemKey);
                        self.removeItems(keys);
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
                            : `${self.itemTypeName} was deleted.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                            self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                        } else {
                            self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                        }
                        console.error(error);
                        return error;
                    });
            },
            removeItems(keys) {
                const self = this;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                    if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                });
                self.gridInstance.refresh();
            },

            save(item){
                const self = this;

                self.$events.emit("update-config-alerts", { alerts: [] });

                let originalData = self.selectedItem.toDataObject();
                let itemData = item.toDataObject();
                let isNew = item.isNew ? true : false;
                let changes = isNew
                        ? []
                        : self.getAuditChanges(originalData, itemData);
                if(self.skipChangeChecking != null)
                {
                    changes.push(self.skipChangeChecking);
                    self.skipChangeChecking = null;
                }
                if (changes.length == 0 && !isNew) {
                    self.$toast.info("No changes detected");
                    return Promise.resolve(true);
                }
                let data = { details: _.concat(itemData)};
                return self.$api.ConfigWorkflowTasksApi.save(data, changes)
                    .then(item => {
                        let formattedItem = new WorkflowTasksDto(itemData);
                        if (isNew) {
                            self.addItem(formattedItem);
                        } else {
                            self.updateItem(formattedItem);
                        }
                        self.$toast.success({ message: `${self.itemTypeName} ${formattedItem.taskName} was saved.` });

                        if(!isNew && item.updateExistingTasks.length > 0) {
                            self.applyToExisting(item.updateExistingTasks, {});
                        }
                    });
            },
            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },
            addItem(item) {
                let self = this;
                item.regionID = self.user.regionID;
                this.items.push(new WorkflowTasksDto(item));
            },

            toggleInactiveFlag(keys) {
                _.each(keys, k => {
                    let e = _.find(this.items, [this.itemKey, k]);
                    e.isInActive = !e.isInActive;
                });
            },

            deleteItem(keys) {
                _.each(keys, k => {
                    _.remove(this.items, (i) => {return _.parseNumber(_.get(i, this.itemKey, -1), -1) == k;});
                });
            },
            updateItem(item) {
                let editIem = _.find(this.items, (i) => {
                    return _.parseNumber(_.get(i, this.itemKey, -1), -1) == _.parseNumber(_.get(item, this.itemKey, -1), -1);
                });
                _.assign(editIem, item);
            },

            isDuplicate(item){
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(item.taskName)) === _.toLower(_.trim(i.taskName)) && _.parseNumber(i[self.itemKey], -1) !== _.parseNumber(item[self.itemKey], -1);
                });

                if (dup) {
                    self.validationErrors.push(`${self.itemTypeName} [${dup.taskName}] is already in use.`);
                    return true;
                }
                return false;
            },
            applyToExisting(updateExisting) {
                const self = this;
                let apiPromise = self.$api.ConfigWorkflowTasksApi.applyToExisting(updateExisting);
                return self.$rqBusy.wait(apiPromise)
                        .then(success => {
                            if(success) {
                                self.$events.emit("update-config-alerts", {
                                    alerts: [{
                                        icon: "fas fa-exclamation-triangle",
                                        variant: "warn",
                                        message: "Depending on the number of files using this task, it may be a few minutes before you see the updates in Files.",
                                        dismissable: true
                                    }]
                                });
                            }
                            GlobalEventManager.saveCompleted({ success: success });
                        })
                        .catch(error => {
                            self.$toast.error(`Error Applying To Existing`);
                            GlobalEventManager.saveCompleted({ success: false });
                        });
            },

            onEditNote(e) {
                if(!e || !e.data) return;
                const self = this;
                self.selectedItem = new WorkflowTasksDto(e.data);
                self.showNotesDialog(e.data);
            },

            updateNotesPopover(notes=null, description=null, target=null) {
                const self = this;
                let newID = _.get(target, "id") || null;
                let lastID = _.get(self.notesPopover, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let notesPopover = _.clone(self.notesPopover);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        notesPopover.visible = false;
                    }
                    notesPopover.target = newID;
                    notesPopover.notes = notes;
                    notesPopover.description = description;
                    notesPopover.visible = true;
                } else {
                    notesPopover.visible = !notesPopover.visible;
                }
                self.$nextTick(() => {
                    self.notesPopover = notesPopover;
                });
            },

            showNotesDialog(item) {
                const self = this;
                let onOk = (e) => {
                    if (e.component.hasChanged) {
                        let updatedItem = new WorkflowTasksDto(_.assign({}, item, {notes: e.component.localNotes}));
                        self.save(updatedItem)
                            .finally(i=>{
                                self.refresh();
                            });
                    }
                    return true;
                };
                let onCancel = (e) => {
                    self.refresh();
                };
                let title = _.size(item.notes) > 0 ? 'Edit Note' : 'Add Note';
                self.$dialog.open({
                    title: title,
                    height: "300",
                    width: "600",
                    scrollable: false,
                    component: NoteDialog,
                    props: {
                        notes: item.notes
                    },
                    onOk: onOk,
                    onCancel: onCancel
                });
            },

        }
    }
</script>
<style lang="scss" scoped>
    .fontCellRequired{color:red};
</style>
