import { ref } from "vue";
import { defineStore } from "pinia";
import { useLicenseStore } from "./license";
import { useHealthStore } from "./health";
import { loadScript } from "@/load-script";
import { DateTime } from "luxon";

const PLACEHOLDER_COMPONENT_SRC = "[APP_ORIGIN]/js/hzn-integration-placeholder.js";

export const useIntegrationsStore = defineStore("integrationsStore", () => {
    const licenseStore = useLicenseStore();
    const healthStore = useHealthStore();

    /*
        Anything hardcoded below will be overrwritten by any matching options configured/returned from the server
        unless "immutable" is updated to "true" for testing purposes or other circumstances.  It'll eventually be
        removed once environment-specific configuration is fully in place.  Leaving these here for now for transparency.
        Additional integrations can also be added here when testing/troubleshooting/etc.
        e.g.
        someDevIntegration: {
            src: PLACEHOLDER_COMPONENT_SRC,
            featureFlag: "someDevIntegration",
            noCache: true,
            loaded: false,
            immutable: false
        }
    */
    const integrations = ref({
        cdfEditor: {
            src: "[APP_ORIGIN]/cdf-editor/v1/dist/cdf-editor-component.js",
            featureFlag: "cdfEditor",
            noCache: true,
            loaded: false,
            immutable: false
        },
        cssEditor: {
            src: "[APP_ORIGIN]/css-editor/v1/dist/css-editor-component.js",
            featureFlag: "cssEditor",
            noCache: true,
            loaded: false,
            immutable: false
        },
        ezJacket: {
            src: {
                default: "https://sm.uat.ortitletech.com/ezjacket/v1/script.js",
                ci: "https://sm.uat.ortitletech.com/ezjacket/v1/script.js",
                qa: "https://sm.uat.ortitletech.com/ezjacket/v1/script.js",
                staging: "https://sm.uat.ortitletech.com/ezjacket/v1/script.js",
                prod: "https://sm.prod.ortitletech.com/ezjacket/v1/script.js"
            },
            featureFlag: "ezJacket",
            noCache: true,
            loaded: false,
            immutable: false
        },
        dataConversion: {
            src: {
                default: "https://dc.qa.ortitletech.com/dc-mapper/v1/dist/dc-component.js",
                ci: "https://dc.qa.ortitletech.com/dc-mapper/v1/dist/dc-component.js",
                qa: "https://dc.qa.ortitletech.com/dc-mapper/v1/dist/dc-component.js",
                staging: "https://dc.uat.ortitletech.com/dc-mapper/v1/dist/dc-component.js",
                prod: "https://dc.ortitletech.com/dc-mapper/v1/dist/dc-component.js"
            },
            featureFlag: "dataConversion",
            noCache: true,
            loaded: false,
            immutable: true //using configured URLs here until env-specific configs can be sorted out
        },
        pavasoTagging: {
            src: {
                default: "https://fragmentstaging.pavaso.com/pvs-document-tagging.js",
                ci: "https://fragmentstaging.pavaso.com/pvs-document-tagging.js",
                qa: "https://fragmentstaging.pavaso.com/pvs-document-tagging.js",
                staging: "https://fragmentstaging.pavaso.com/pvs-document-tagging.js",
                prod: "https://fragment.pavaso.com/pvs-document-tagging.js"
            },
            featureFlag: "pavasoDigitalSignings",
            noCache: true,
            loaded: false,
            immutable: false 
        },
        workflowAutomation: {
            src: PLACEHOLDER_COMPONENT_SRC,
            featureFlag: "workflowAutomation",
            noCache: true,
            loaded: false,
            immutable: false
        },
        workdayIntegration: {
            src: "[APP_ORIGIN]/workday/v1/dist/workday-file-generation.js",
            //src: "http://localhost:4173/epicor-file-generation.js",
            featureFlag: "workdayIntegration",
            noCache: true,
            loaded: false,
            immutable: false
        },
        arcIntegration: {
            src:{
                default: "https://arc.dev.ortitletech.com/api/cs-asset/v2/FragmentJs/arc-hzn-fragments.js",
                ci: "https://arc.dev.ortitletech.com/api/cs-asset/v2/FragmentJs/arc-hzn-fragments.js",
                qa: "https://arc.qa.ortitletech.com/api/cs-asset/v2/FragmentJs/arc-hzn-fragments.js",
                staging: "https://arc.uat.ortitletech.com/api/cs-asset/v2/FragmentJs/arc-hzn-fragments.js", //staging
                prod: "https://arc.ortitletech.com/api/cs-asset/v2/FragmentJs/arc-hzn-fragments.js"
            },
            featureFlag: "arcIntegration",
            noCache: true,
            loaded: false,
            immutable: true
        },
    });

    function setIntegrations(items) {
        if(_.isEmpty(items)) return;
        _.forEach(items, item => {
            if(integrations.value?.[item.name]?.immutable) return;
            integrations.value[item.name] = {
                src: item.clientUrl,
                featureFlag: item.featureFlag,
                noCache: true,
                loaded: false
            };
        });
    }

    const unixTimestamp = DateTime.now().valueOf();
    const appendCacheBuster = url => _.endsWith(url, ".js") ? `${url}?${unixTimestamp}` : url;
    const getEnvironmentUrl = url => _.isObject(url) ? (url?.[healthStore.env] || url?.default) : url;
    const parseSourceUrl = (url, noCache) => {
        let envUrl = getEnvironmentUrl(url);
        let result = _.startsWith(envUrl, "[APP_ORIGIN]")
            ? _.replace(envUrl, "[APP_ORIGIN]", window.location.origin)
            : envUrl;
        return noCache
            ? appendCacheBuster(result)
            : result;
    };

    async function loadIntegration(name) {
        if(!_.has(integrations.value, name)) return false;
        let integration = integrations.value[name];

        let featureFlag = integration.featureFlag;
        if(!_.isEmpty(featureFlag) && !licenseStore.checkFeature(featureFlag)) return false;

        let integrationSrc = parseSourceUrl(integration.src, integration.noCache);
        await loadScript(integrationSrc, "module");
        integration.loaded = true;

        return true;
    }

    return {
        integrations,
        setIntegrations,
        loadIntegration
    };
});
