<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <rq-page-section :title="title" headerSize="md">
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div class="col col-3 form-group">
                        <label class="form-control-label" for="txt_amount">Wire Amount</label>
                        <rqInputNumber
                            automation_id="txt_amount"
                            formatType="money"
                            defaultValue="0"
                            :minValue="0"
                            decimals="2"
                            input-group
                            no-prefix
                            disabled
                            prependIcon="fas fa-dollar-sign"
                            v-model="v$.item.amount.$model" />
                    </div>
                    <div :class="{ 'col col-3 form-group':true, 'form-required': isRequired('wireNumber'), 'has-error':v$.item.wireNumber.$error }">
                        <label for="txt_wire_number">Wire Number</label>
                        <div class="input-group">
                            <span class="input-group-text">W-</span>
                            <input
                                id="txt_wire_number"
                                automation_id="txt_wire_number"
                                v-model="v$.item.wireNumber.$model"
                                :disabled="(item.isWire && !isEscrowAdmin) || useAutoGeneratedWireNumbers"
                                type="text"
                                class="form-control"
                                placeholder="Wire #"
                                maxlength="25">
                        </div>
                        <rq-validation-feedback>Wire Number is required</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-3 form-group':true, 'form-required': isExpectedWireOutDateRequired, 'has-error':v$.item.expectedWireOutDate.$error }" v-show="showExpectedWireOutDates">
                        <label for="dtp_expected_wire_out_date">Expected Wire Out Date</label>
                        <rqdx-date-box
                            id="dtp_expected_wire_out_date"
                            :disabled="disableExpectedDate"
                            v-model="v$.item.expectedWireOutDate.$model"
                        />
                        <rq-validation-feedback>{{expectedDateReqMessage}}</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-3 form-group':true, 'form-required': isCheckDateRequired, 'has-error':v$.item.checkDate.$error }">
                        <label for="dtp_wire_out_date">Wire Out Date</label>
                        <rqdx-date-box
                            id="dtp_wire_out_date"
                            v-model="v$.item.checkDate.$model"
                            :disabled-dates="getDisabledCheckDates"
                            @valueChanged="onCheckDateChanged"
                            :disabled="disableCheckDate"
                        />
                        <rq-validation-feedback>{{checkDateReqMessage}}</rq-validation-feedback>
                    </div>
                </div>
                <div class="row">
                    <div :class="{ 'col col-9 form-group':true, 'form-required': isRequired('reference'), 'has-error':v$.item.reference.$error }">
                        <label for="txt_reference">Description</label>
                        <input id="txt_reference" automation_id="txt_reference" v-model="v$.item.reference.$model" type="text" class="form-control" maxlength="500" />
                        <rq-validation-feedback>Description is required</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-3 form-group':true }">
                        <label for="txt_fed_reference_number">Fed Reference No.</label>
                        <div class="input-group">
                            <input
                                id="txt_fed_reference_number"
                                automation_id="txt_wire_number"
                                v-model="item.fedRefNumber"
                                disabled
                                type="text"
                                class="form-control"
                                maxlength="25">
                        </div>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rq-page-section title="Receiving Bank Information" headerSize="md">
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div :class="{ 'col col-4 form-group':true, 'form-required': isRequired('receivingBankName'), 'has-error':v$.item.receivingBankName.$error }">
                        <label for="txt_receiving_bank_name">Bank Name</label>
                        <input id="txt_receiving_bank_name" automation_id="txt_receiving_bank_name" v-model="v$.item.receivingBankName.$model" type="text" class="form-control" maxlength="40" />
                        <rq-validation-feedback>Bank Name is required</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-4 form-group':true, 'form-required': isRequired('receivingABA'), 'has-error':v$.item.receivingABA.$error || receivingBankAvsVerificationError }">
                        <rq-action-form-group
                            :label="routingNumberLabel"
                            labelFor="txt_receiving_aba"
                            show-action
                        >
                            <template #action>
                                <b-form-checkbox automation_id="chk_internationalTransfer" id="isInternationalTransfer" @change="resetReceivingBankAvsVerificationError" v-model="item.isInternationalTransfer">International Transfer</b-form-checkbox>
                                <span v-rq-tooltip.hover.top="{ title: receivingBankVerificationMessage  }" v-if="superMarketDataVerificationEnabled && showReceivingBankVerificationCheckmark" :class="{ 'm-auto font-lg inline': true, 'warning-check': item.receivingBankVerificationStatus != 0, 'success-check': item.receivingBankVerificationStatus == 0 }">
                                    <FontAwesomeIcon v-if="item.receivingBankVerificationStatus == 0" icon="fas fa-check-circle" />
                                    <FontAwesomeIcon v-else icon="fas fa-exclamation-triangle" />
                                </span>
                            </template>
                            <template #default>
                                <rq-masked-input v-if = "!item.isInternationalTransfer"
                                    id="txt_receiving_aba"
                                    v-model="v$.item.receivingABA.$model"
                                    custom-mask="#########"
                                    is-masked
                                    @change="resetReceivingBankAvsVerificationError"
                                    :disabled="readOnly"
                                />
                                <rq-masked-input v-else
                                    id="txt_receiving_aba"
                                    maskType="text"
                                    v-model="v$.item.receivingABA.$model"
                                    is-masked
                                    maxlength="11"
                                    @change="resetReceivingBankAvsVerificationError"
                                    :disabled="readOnly"
                                />

                            </template>
                        </rq-action-form-group>
                        <rq-validation-feedback v-if="!receivingBankAvsVerificationError"
                            :messages="{
                                        'Bank Routing Number is required': !v$.item.receivingABA.required.$invalid && !item.isInternationalTransfer,
                                        'Bank Routing Number must be 9 digits': !v$.item.receivingABA.validFormat.$invalid && !item.isInternationalTransfer,
                                        'SWIFT Code is required': !v$.item.receivingABA.required.$invalid && !!item.isInternationalTransfer,
                                        'Bank SWIFT Code must be at least 8 characters': !v$.item.receivingABA.validFormat.$invalid && !!item.isInternationalTransfer,
                                        }"
                        />
                        <rq-validation-feedback v-if="receivingBankAvsVerificationError">{{receivingBankVerificationMessage }}</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-4 form-group':true, 'form-required': isRequired('receivingAccountNumber'), 'has-error':v$.item.receivingAccountNumber.$error || receivingBankAvsVerificationError }">
                        <label for="txt_receiving_account_number" class="inline">Account #</label>
                        <span v-rq-tooltip.hover.top="{ title: receivingBankVerificationMessage  }" v-if="superMarketDataVerificationEnabled && showReceivingBankVerificationCheckmark" :class="{ 'm-auto font-lg inline': true, 'warning-check': item.receivingBankVerificationStatus != 0, 'success-check': item.receivingBankVerificationStatus == 0 }">
                            <FontAwesomeIcon v-if="item.receivingBankVerificationStatus == 0" icon="fas fa-check-circle" />
                            <FontAwesomeIcon v-else icon="fas fa-exclamation-triangle" />
                        </span>
                        <input id="txt_receiving_account_number" automation_id="txt_receiving_account_number" v-model="v$.item.receivingAccountNumber.$model" type="text" class="form-control" maxlength="40" @blur="onReceivingBankBlur" @change="resetReceivingBankAvsVerificationError()" />
                        <rq-validation-feedback v-if="!receivingBankAvsVerificationError">Account # is required</rq-validation-feedback>
                        <rq-validation-feedback v-if="receivingBankAvsVerificationError">{{receivingBankVerificationMessage }}</rq-validation-feedback>
                    </div>
                </div>
                <div class="row">
                    <div :class="{ 'col col-3 form-group':true, 'form-required': isRequired('wireBankAddress'), 'has-error':v$.item.wireBankAddress.$error }">
                        <label for="txt_wire_bank_address">Bank Address</label>
                        <input id="txt_wire_bank_address" automation_id="txt_wire_bank_address" v-model="v$.item.wireBankAddress.$model" type="text" class="form-control" maxlength="100" />
                        <rq-validation-feedback>Bank Address is required</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-3 form-group':true, 'form-required': isRequired('wireBankCity'), 'has-error':v$.item.wireBankCity.$error }">
                        <label for="txt_wire_bank_city">City</label>
                        <input id="txt_wire_bank_city" automation_id="txt_wire_bank_city" v-model="v$.item.wireBankCity.$model" type="text" class="form-control" maxlength="40" />
                        <rq-validation-feedback>City is required</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-3 form-group':true, 'form-required': isRequired('wireBankState'), 'has-error':v$.item.wireBankState.$error }">
                        <label for="drp_wire_bank_state">State</label>
                        <dx-select-box
                            :input-attr="{ id: 'drp_wire_bank_state', automation_id: 'drp_wire_bank_state' }"
                            class="form-control"
                            value-expr="id"
                            display-expr="id"
                            :items="usStatesLookup"
                            :search-enabled="true"
                            :disabled="readOnly"
                            v-model="v$.item.wireBankState.$model"
                        />
                        <rq-validation-feedback>State is required</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-3 form-group':true, 'form-required': isRequired('wireBankZip'), 'has-error':v$.item.wireBankZip.$error }">
                        <label for="txt_wire_bank_zip">Zip</label>
                        <rq-masked-input id="txt_wire_bank_zip"
                            maskType="zip"
                            cssClass="form-control"
                            v-model="v$.item.wireBankZip.$model"
                            isMasked="true"
                            grid-field="zip" />
                        <rq-validation-feedback>Zip is required</rq-validation-feedback>
                    </div>
                </div>
                <div class="row">
                    <div :class="{ 'col col-6 form-group':true, 'form-required': isRequired('receivingCustomerName'), 'has-error':v$.item.receivingCustomerName.$error || receivingBankAvsVerificationError }">
                        <label for="txt_receiving_customer_name" class="inline">Customer Name</label>
                        <span v-rq-tooltip.hover.top="{ title: receivingBankVerificationMessage  }" v-if="superMarketDataVerificationEnabled && showReceivingBankVerificationCheckmark" :class="{ 'm-auto font-lg inline': true, 'warning-check': item.receivingBankVerificationStatus != 0, 'success-check': item.receivingBankVerificationStatus == 0 }">
                            <FontAwesomeIcon v-if="item.receivingBankVerificationStatus == 0" icon="fas fa-check-circle" />
                            <FontAwesomeIcon v-else icon="fas fa-exclamation-triangle" />
                        </span>
                        <input id="txt_receiving_customer_name" automation_id="txt_receiving_customer_name" v-model="v$.item.receivingCustomerName.$model" type="text" class="form-control" maxlength="500" @change="resetReceivingBankAvsVerificationError()" @blur="onReceivingBankBlur" />
                        <rq-validation-feedback v-if="!receivingBankAvsVerificationError">Customer Name is required</rq-validation-feedback>
                        <rq-validation-feedback v-if="receivingBankAvsVerificationError">{{receivingBankVerificationMessage }}</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-6 form-group':true, 'form-required': isRequired('receivingWireInstructions'), 'has-error':v$.item.receivingWireInstructions.$error }">
                        <label for="txt_receiving_wire_instructions">Wire instructions</label>
                        <textarea id="txt_receiving_wire_instructions" automation_id="txt_receiving_wire_instructions" rows="2" v-model="v$.item.receivingWireInstructions.$model" type="text" class="form-control" maxlength="500" ></textarea>
                        <rq-validation-feedback>Wire instructions is required</rq-validation-feedback>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
        <rq-page-section title="Offline Bank Information" headerSize="md" borderless>
            <fieldset :disabled="readOnly">
                <div class="row">
                    <div :class="{ 'col col-4 form-group':true, 'form-required': isRequired('offlineBankName'), 'has-error':v$.item.offlineBankName.$error }">
                        <label for="txt_offline_bank_name">Bank Name</label>
                        <input id="txt_offline_bank_name" automation_id="txt_offline_bank_name" v-model="v$.item.offlineBankName.$model" type="text" class="form-control" maxlength="40">
                        <rq-validation-feedback>Bank Name is required</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-4 form-group':true, 'form-required': isRequired('offlineABA'), 'has-error':v$.item.offlineABA.$error || offlineBankAvsVerificationError}">
                        <label for="txt_offline_aba" class="inline">Routing Number</label>
                        <span v-rq-tooltip.hover.top="{ title: offlineBankVerificationMessage  }" v-if="superMarketDataVerificationEnabled && showOfflineBankVerificationCheckmark" :class="{ 'm-auto font-lg inline': true, 'warning-check': item.offlineBankVerificationStatus != 0, 'success-check': item.offlineBankVerificationStatus == 0 }">
                            <FontAwesomeIcon v-if="item.offlineBankVerificationStatus == 0" icon="fas fa-check-circle" />
                            <FontAwesomeIcon v-else icon="fas fa-exclamation-triangle" />
                        </span>
                        <rq-masked-input
                            id="txt_offline_aba"
                            custom-mask="#########"
                            v-model="v$.item.offlineABA.$model"
                            is-masked
                            @change="resetOfflineBankAvsVerificationError()"
                            :disabled="readOnly"/>
                        <rq-validation-feedback v-if="!offlineBankAvsVerificationError"
                            :messages="{ 'Bank Routing Number must be 9 digits': !v$.item.offlineABA.validFormat.$invalid,
                                         'Bank Routing Number is required': v$.item.offlineABA.required.$invalid,
                                          }"
                        />
                        <rq-validation-feedback v-if="offlineBankAvsVerificationError">{{ offlineBankVerificationMessage }}</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-4 form-group':true, 'form-required': isRequired('offlineAccountNumber'), 'has-error':v$.item.offlineAccountNumber.$error || offlineBankAvsVerificationError }">
                        <label for="txt_offline_account_number" class="inline">Account #</label>
                        <span v-rq-tooltip.hover.top="{ title: offlineBankVerificationMessage  }" v-if="superMarketDataVerificationEnabled && showOfflineBankVerificationCheckmark" :class="{ 'm-auto font-lg inline': true, 'warning-check': item.offlineBankVerificationStatus != 0, 'success-check': item.offlineBankVerificationStatus == 0 }">
                            <FontAwesomeIcon v-if="item.offlineBankVerificationStatus == 0" icon="fas fa-check-circle" />
                            <FontAwesomeIcon v-else icon="fas fa-exclamation-triangle" />
                        </span>
                        <input id="txt_offline_account_number" automation_id="txt_offline_account_number" v-model="v$.item.offlineAccountNumber.$model" type="text" class="form-control" maxlength="40" @change="resetOfflineBankAvsVerificationError()" @blur="onOfflineBankBlur" />
                        <rq-validation-feedback v-if="!offlineBankAvsVerificationError">Account # is required</rq-validation-feedback>
                        <rq-validation-feedback v-if="offlineBankAvsVerificationError">{{ offlineBankVerificationMessage }}</rq-validation-feedback>
                    </div>
                </div>
                <div class="row">
                    <div :class="{ 'col col-3 form-group':true, 'form-required': isRequired('offlineBankAddress'), 'has-error':v$.item.offlineBankAddress.$error }">
                        <label for="txt_offline_bank_address">Bank Address</label>
                        <input id="txt_offline_bank_address" automation_id="txt_offline_bank_address" v-model="v$.item.offlineBankAddress.$model" type="text" class="form-control" maxlength="100">
                        <rq-validation-feedback>Bank Address is required</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-3 form-group':true, 'form-required': isRequired('offlineBankCity'), 'has-error':v$.item.offlineBankCity.$error }">
                        <label for="txt_offline_bank_city">City</label>
                        <input id="txt_offline_bank_city" automation_id="txt_offline_bank_city" v-model="v$.item.offlineBankCity.$model" type="text" class="form-control" maxlength="40">
                        <rq-validation-feedback>City is required</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-3 form-group':true, 'form-required': isRequired('offlineBankState'), 'has-error':v$.item.offlineBankState.$error }">
                        <label for="drp_offline_bank_state">State</label>
                        <dx-select-box
                            :input-attr="{ id: 'drp_offline_bank_state', automation_id: 'drp_offline_bank_state' }"
                            class="form-control"
                            value-expr="id"
                            display-expr="id"
                            :items="usStatesLookup"
                            :search-enabled="true"
                            :disabled="readOnly"
                            v-model="v$.item.offlineBankState.$model"
                        />
                        <rq-validation-feedback>State is required</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-3 form-group':true, 'form-required': isRequired('offlineBankZip'), 'has-error':v$.item.offlineBankZip.$error }">
                        <label for="txt_offline_bank_zip">Zip</label>
                        <rq-masked-input id="txt_offline_bank_zip"
                            maskType="zip"
                            cssClass="form-control"
                            v-model="v$.item.offlineBankZip.$model"
                            isMasked="true"
                            grid-field="zip" />
                        <rq-validation-feedback>Zip is required</rq-validation-feedback>
                    </div>
                </div>
                <div class="row">
                    <div :class="{ 'col col-6 form-group':true, 'form-required': isRequired('offlineFurtherCredit'), 'has-error':v$.item.offlineFurtherCredit.$error }">
                        <label for="txt_offline_further_credit">Further Credit to</label>
                        <input id="txt_offline_further_credit" automation_id="txt_offline_further_credit" v-model="v$.item.offlineFurtherCredit.$model" type="text" class="form-control" maxlength="100" >
                        <rq-validation-feedback>Further Credit to is required</rq-validation-feedback>
                    </div>
                    <div :class="{ 'col col-6 form-group':true, 'form-required': isRequired('offlineNotes'), 'has-error':v$.item.offlineNotes.$error }">
                        <label for="txt_offline_notes">Notes</label>
                        <textarea id="txt_offline_notes" automation_id="txt_offline_notes" rows="2" v-model="v$.item.offlineNotes.$model" type="text" class="form-control" maxlength="500" ></textarea>
                        <rq-validation-feedback>Notes is required</rq-validation-feedback>
                    </div>
                </div>
            </fieldset>
        </rq-page-section>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { DateTime } from "luxon";
    import RegEx from "@/shared/utilities/RegEx";
    import { ChecksDto }  from "../models";
    import { WireOutStatus }  from "../enums";
    import { requiredIf } from '@vuelidate/validators';
    import { useLicenseStore } from "@/store/modules/license";
    import { useVuelidate } from "@vuelidate/core";
    import { WireOutOptions } from "@config/enums";
    import { FileScanDocumentDto }  from "../../../document-mgmt/models";
    import { DocumentFileType } from "../../../documents/enums";
    import UploadPermissibleUseDocument from "../components/UploadPermissibleUseDocument.vue";

    export default {
        name: 'CheckWritingWireTransferForm',
        props: {
            checksID: { type: Number, required: true, default: 0 },
            consolidatedCheckID: { type: Number, default: 0 }
        },

        setup: () => ({ v$: useVuelidate() }),

        data() {
            return {
                item: new ChecksDto(),
                originalItem: new ChecksDto(),
                errorMessage: "",
                requiredFieldMap: {},
                receivingBankAvsVerificationError: false,
                offlineBankAvsVerificationError: false,
                showReceivingBankVerificationCheckmark: false,
                showOfflineBankVerificationCheckmark: false,
                lastOfflineBankVerificationResult: {},
                lastReceivingBankVerificationResult: {},
                acceptedFileTypes: '',
                permissibleUseDocument: {},
                permissibleUseDocumentCategory: null,
                isPayeeIndividualForReceivingBank: false,
                isPayeeIndividualForOfflineBank: false,
                superMarketDataVerificationEnabled: false,
                receivingBankVerificationMessage: '',
                offlineBankVerificationMessage: ''
            }
        },
        watch: {
            "v$.$error":{
                handler: function(newValue, oldValue) {
                    this.$emit(`${newValue ? "disable" : "enable"}-ok`);
                },
                deep: true,
                immediate: true
            },
        },
        computed: {
            ...mapGetters([
                "usStatesLookup",
                "lookupHelpers",
                "lookupItems" ]),
            ...mapState({
                orderSummary: state => state.orders.orderSummary,
                order: state => state.orders.order,
                systemDefaults: state => state.system.systemDefaults,
            }),
            localSecurity(){ return this.securitySettings.findValues(["UseExpectedWireOutDate","CheckPreDateDays","CheckPostDateDays", "AllowFinalizedReconModifications"]); },
            wireOutSecurity() { return this.securitySettings.findValues(["WireOutRequirement",]); },
            useAutoGeneratedWireNumbers() { return _.parseBool(_.get(this, 'systemDefaults.useAutoGeneratedWireNumbers', false), false) },
            showExpectedWireOutDates() { return _.parseBool(_.get(this, 'systemDefaults.showExpectedWireOutDates', false), false) },
            permissibleUseDocumentSetting() { return _.parseBool(_.get(this, 'systemDefaults.permissibleUseDocumentRequired', false), false) },
            title() { return `Pay to the Order of ${this.item.payee}` },
            isEscrowAdmin() { return this.securitySettings.findValues(['IsEscrowAdmin']); },
            isConsolidated() { return _.gt(this.consolidatedCheckID, 0); },
            isLocked() { return !this.isConsolidated && (_.getBool(this, 'orderSummary.isLocked', false) || _.getBool(this, 'orderSummary.isEscrowLocked', false)); },
            readOnly() { return this.isLocked || _.get(this.item, 'wireOutStatus') == WireOutStatus.Processed; },
            isCheckDateRequired() {
                // Conditions for requiring this field can be observed by the following scenarios:
                // Wire Out or Expected Wire Out can possibly be required in case with "Can Use Either" setting
                // However, only one date can be applied to either fields at a time
                // Wire Out can possibly be configured to explicitly be only required field
                // Expected Wire Date can be configured to be hidden, making Wire Out Date (check) required
                let wireOutReq = this.wireOutSecurity.WireOutRequirement;
                if (this.disableCheckDate) return false;
                return (wireOutReq === WireOutOptions.CanUseEitherDate && _.isEmpty(this.item.expectedWireOutDate))
                            || wireOutReq === WireOutOptions.WireOutDate
                            || !this.showExpectedWireOutDates;
            },
            isExpectedWireOutDateRequired() {
                // Conditions for requiring this field can be observed by the following scenarios:
                // Wire Out or Expected Wire Out can possibly be required in case with "Can Use Either" setting
                // However, only one date can be applied to either fields at a time
                // Expected Wire Out can possibly be configured to explicitly be only required field
                // Expected Wire Date can be configured to be hidden which means this field will not be required
                let wireOutReq = this.wireOutSecurity.WireOutRequirement;
                if (this.disableExpectedDate) return false;
                if (!this.showExpectedWireOutDates) return false;
                return (wireOutReq === WireOutOptions.CanUseEitherDate && _.isEmpty(this.item.checkDate))
                        || wireOutReq === WireOutOptions.ExpectedWireOutDate;
            },
            checkDateReqMessage() {
                if (!this.v$.item.checkDate.isInRange && this.v$.item.checkDate.required.$invalid) return "Wire Date is not within range.";
                return this.wireOutSecurity.WireOutRequirement === WireOutOptions.CanUseEitherDate && this.showExpectedWireOutDates ? 'Wire Out Date or Expected Wire Out Date is required' : 'Wire Out Date is required';
            },
            expectedDateReqMessage() { return this.wireOutSecurity.WireOutRequirement === WireOutOptions.CanUseEitherDate && this.showExpectedWireOutDates ? 'Wire Out Date or Expected Wire Out Date is required' : 'Expected Wire Out Date is required'; },
            disableExpectedDate() {
                if (this.readOnly) return true; // respect general readOnly logic

                // Scenario where we explictly require only "Wire Out Date"
                if (this.wireOutSecurity.WireOutRequirement === WireOutOptions.WireOutDate) {
                    return true;
                }

                // Disable if Wire Out Date (checkDate) is not empty as only one date is required between Expected Wire Out Date and Wire Out Date
                // Disable if this is a wire
                return !_.isEmpty(this.item.checkDate) ? true : this.item.isWire;
            },
            disableCheckDate() {
                if (this.readOnly) return true; // respect general readOnly logic
                if (!this.showExpectedWireOutDates) return false; // Expected Wire Out is hidden so we must enable Wire Out (checkDate) field
                // Scenario where we explictly require only "Expected Wire Out Date"
                if (this.wireOutSecurity.WireOutRequirement === WireOutOptions.ExpectedWireOutDate) {
                    return this.showExpectedWireOutDates;
                }

                // Disable if Expected Wire Out Date is not empty as only one date is required between Expected Wire Out Date and Wire Out Date
                // Disable if this is a wire or not escrow admin
                return !_.isEmpty(this.item.expectedWireOutDate) ?  true : this.item.isWire && !this.isEscrowAdmin;
            },
            routingNumberLabel() { return !this.item.isInternationalTransfer ? 'Routing Number' : 'SWIFT Code'; },
            fileScanCategories() {
                let items = this.lookupHelpers.getLookupItems(this.lookupItems.FILE_SCAN_CATEGORIES);
                return items;
            },
        },
        validations() {
            const self = this;

            return {
                item: {
                    amount: {},
                    wireNumber: {
                        required: requiredIf(() => self.isRequired("wireNumber"))
                    },
                    checkDate: {
                        isInRange(val) {
                            return !(self.isCheckDateRequired && self.isCheckDateInRange(val));
                        },
                        required: requiredIf(() => self.isCheckDateRequired)
                    },
                    expectedWireOutDate: {
                        required: requiredIf(() => self.isExpectedWireOutDateRequired)
                    },
                    reference: {
                        required: requiredIf(() => self.isRequired("reference"))
                    },
                    receivingBankName: {
                        required: requiredIf(() => self.isRequired("receivingBankName"))
                    },
                    receivingABA: {
                        required: requiredIf(() => self.isRequired("receivingABA")),
                        validFormat (value) {
                            if (_.isNullOrEmpty(value)) return true;
                            return !this.item.isInternationalTransfer ?
                                    RegEx.ABA.test(value) :
                                    RegEx.SWIFT.test(value);
                        }
                    },
                    receivingAccountNumber: {
                        required: requiredIf(() => self.isRequired("receivingAccountNumber"))
                    },
                    wireBankAddress: {
                        required: requiredIf(() => self.isRequired("wireBankAddress"))
                    },
                    wireBankCity: {
                        required: requiredIf(() => self.isRequired("wireBankCity"))
                    },
                    wireBankState: {
                        required: requiredIf(() => self.isRequired("wireBankState"))
                    },
                    wireBankZip: {
                        required: requiredIf(() => self.isRequired("wireBankZip"))
                    },
                    receivingCustomerName: {
                        required: requiredIf(() => self.isRequired("receivingCustomerName"))
                    },
                    receivingWireInstructions: {
                        required: requiredIf(() => self.isRequired("receivingWireInstructions"))
                    },
                    offlineBankName: {
                        required: requiredIf(() => self.isRequired("offlineBankName"))
                    },
                    offlineABA: {
                        required: requiredIf(() => self.isRequired("offlineABA")),
                        validFormat (value) {
                            if (_.isNullOrEmpty(value)) return true;
                            return RegEx.ABA.test(value);
                        },
                    },
                    offlineAccountNumber: {
                        required: requiredIf(() => self.isRequired("offlineAccountNumber"))
                    },
                    offlineBankAddress: {
                        required: requiredIf(() => self.isRequired("offlineBankAddress"))
                    },
                    offlineBankCity: {
                        required: requiredIf(() => self.isRequired("offlineBankCity"))
                    },
                    offlineBankState: {
                        required: requiredIf(() => self.isRequired("offlineBankState"))
                    },
                    offlineBankZip: {
                        required: requiredIf(() => self.isRequired("offlineBankZip"))
                    },
                    offlineFurtherCredit: {
                        required: requiredIf(() => self.isRequired("offlineFurtherCredit"))
                    },
                    offlineNotes: {
                        required: requiredIf(() => self.isRequired("offlineNotes"))
                    },
                }
            };
        },
        created() {
            const self = this;
            const licenseStore = useLicenseStore();
            const featureFlag = 'superMarketAccountVerification';
            self.superMarketDataVerificationEnabled = licenseStore.checkFeature(featureFlag);
            self.createRequiredFieldMap();
            self.fetchData();
        },
        methods: {
            createRequiredFieldMap() {
                const self = this;
                const sysDefVal = key => _.getBool(self.systemDefaults, key);
                let fieldMap = {
                    wireNumber: "wireOutWireNumberRequired",
                    reference: "wireOutReferenceRequired",
                    receivingBankName: "wireOutReceivingBankNameRequired",
                    receivingABA: "wireOutReceivingBankABARequired",
                    receivingAccountNumber: "wireOutReceivingBankAccountNumberRequired",
                    wireBankAddress: "wireOutReceivingBankAddressRequired",
                    wireBankCity: "wireOutReceivingBankCityStateZipRequired",
                    wireBankState: "wireOutReceivingBankCityStateZipRequired",
                    wireBankZip: "wireOutReceivingBankCityStateZipRequired",
                    receivingCustomerName: "wireOutReceivingBankCustomerNameRequired",
                    receivingWireInstructions: "wireOutReceivingBankInstructionsRequired",
                    offlineBankName: "wireOutOfflineBankNameRequired",
                    offlineABA: "wireOutOfflineBankABARequired",
                    offlineAccountNumber: "wireOutOfflineBankAccountNumberRequired",
                    offlineBankAddress: "wireOutOfflineBankAddressRequired",
                    offlineBankCity: "wireOutOfflineBankCityStateZipRequired",
                    offlineBankState: "wireOutOfflineBankCityStateZipRequired",
                    offlineBankZip: "wireOutOfflineBankCityStateZipRequired",
                    offlineFurtherCredit: "wireOutOfflineBankFurtherCreditToRequired",
                    offlineNotes: "wireOutOfflineBankNotesRequired"
                };
                this.requiredFieldMap = _.mapValues(fieldMap, val => sysDefVal(val));
            },
            getDisabledCheckDates(e) {
                //note: returning true disables the date, false enables it.  Can be confusing.
                let now = DateTime.now().startOf("day");
                let reconDate = DateTime.fromISO(this?.order?.lastFinalizedReconDate).plus({days: 1}).startOf("day");

                // RQO-32615
                if (reconDate.year === 1900) {
                    reconDate = now.minus({days: Math.abs(this.localSecurity.CheckPreDateDays)});
                }

                let reconDaysDiff = reconDate.diff(now, "days").days;
                let allowFinalizedReconModifications = _.parseBool(this.localSecurity.AllowFinalizedReconModifications, false);
                let checkPreDateDaysSetting = allowFinalizedReconModifications ? this.localSecurity.CheckPreDateDays : Math.abs(reconDaysDiff);
                let checkPostDateDaysSetting = this.localSecurity.CheckPostDateDays;

                //-1 === unlimited
                if(checkPreDateDaysSetting === -1 && checkPostDateDaysSetting === -1) return false;

                let currentDate = DateTime.fromJSDate(e.date).startOf("day").isValid ? DateTime.fromJSDate(e.date).startOf("day") : DateTime.fromISO(e.date).startOf("day");
                let daysDiff = currentDate.diff(now, "days").days;

                if(daysDiff === 0) return false; //today is always valid

                //predate is unlimited but not postdate
                if(checkPreDateDaysSetting === -1){
                    if(daysDiff <= 0) return false;
                    return daysDiff > checkPostDateDaysSetting;
                }
                //postdate is unlimited but not predate
                else if(checkPostDateDaysSetting === -1){
                    if(daysDiff >= 0) return false;
                    return Math.abs(daysDiff) > checkPreDateDaysSetting;
                }
                //neither is unlimited
                return (daysDiff < 0 && Math.abs(daysDiff) > checkPreDateDaysSetting) || (daysDiff > 0 && daysDiff > checkPostDateDaysSetting);
            },

            fetchData() {
                const self = this;
                self.acceptedFileTypes = '.pdf,.png';
                let apiPromise = self.$api.CheckWritingApi.getCheck(self.checksID);
                self.permissibleUseDocumentCategory = self.fileScanCategories.find(p=>p.name == 'Permissible Use Document');
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.originalItem = new ChecksDto(result);
                        self.item = new ChecksDto(result);
                        if(_.isNil(self.item.receivingCustomerName) || self.item.receivingCustomerName.trim().length == 0) {
                            self.item.receivingCustomerName = self.item.payee;
                        }
                        if (self.useAutoGeneratedWireNumbers && _.size(self.item.wireNumber) == 0) {
                            self.item.wireNumber = `${self.item.bankCompanyID}-${self.item.checksID}`;
                        }

                        self.showOfflineBankVerificationCheckmark = !_.isNil(self.item.offlineBankVerificationStatus);
                        self.showReceivingBankVerificationCheckmark = !_.isNil(self.item.receivingBankVerificationStatus);

                        self.lastOfflineBankVerificationResult = {
                            bankABA : self.item.offlineABA,
                            bankAccountNumber: self.item.offlineAccountNumber,
                            payeeName: '',
                            verificationStatus: self.getBankVerificationStatusString(self.item.offlineBankVerificationStatus)
                        };

                        self.lastReceivingBankVerificationResult = {
                            bankABA : self.item.receivingABA,
                            bankAccountNumber: self.item.receivingAccountNumber,
                            payeeName: self.item.receivingCustomerName,
                            verificationStatus: self.getBankVerificationStatusString(self.item.receivingBankVerificationStatus)
                        };

                        self.updateIsPayeeIndividual();
                        self.fetchBankVerificationOrderNotes();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Check.` });
                    });
            },

            fetchBankVerificationOrderNotes() {
                const self = this;
                const messageDelimiter = 'Message:';
                if(!_.isNil(self.item.receivingBankVerificationOrderNoteID)) {
                    let receivingBankVerificationOrderNoteApiPromise = self.$api.OrdersApi.getOrderNoteText(self.item.ordersID, self.item.receivingBankVerificationOrderNoteID);
                    self.$rqBusy.wait(receivingBankVerificationOrderNoteApiPromise)
                        .then(result => {
                            const msgIndex = result?.indexOf(messageDelimiter) ?? -1;
                            if (msgIndex !== -1) {
                                self.receivingBankVerificationMessage = result.substring(msgIndex + messageDelimiter.length).trim();
                            }
                        });
                }

                if(!_.isNil(self.item.offlineBankVerificationOrderNoteID)) {
                    let offlineBankVerificationOrderNoteApiPromise = self.$api.OrdersApi.getOrderNoteText(self.item.ordersID, self.item.offlineBankVerificationOrderNoteID);
                    self.$rqBusy.wait(offlineBankVerificationOrderNoteApiPromise)
                        .then(result => {
                            const msgIndex = result?.indexOf(messageDelimiter) ?? -1;
                            if (msgIndex !== -1) {
                                self.offlineBankVerificationMessage = result.substring(msgIndex + messageDelimiter.length).trim();
                            }
                        });
                }
            },

            isCheckDateInRange(val){
                if (_.isNil(val)) return false;
                return this.getDisabledCheckDates({date: val});
            },

            isRequired(fieldName){
                if(!_.has(this.requiredFieldMap, fieldName)) return false;
                return _.getBool(this.requiredFieldMap, fieldName);
            },

            isValid(){
                this.errorMessage = '';
                this.v$.$touch();
                if (this.v$.$error) {
                    this.errorMessage = 'Please correct the highlighted fields.';
                    return false;
                } else {
                    return true;
                }
            },

            onCheckDateChanged(e) {
                this.v$.item.checkDate.$touch();
            },

            save(){
                const self = this;
                self.errorMessage = "";
                if (self.v$.$error) self.v$.$touch();
                if (!self.v$.$anyDirty) { return Promise.resolve(true); }
                if (self.v$.$error) return Promise.resolve(false);
                if(self.receivingBankAvsVerificationError || self.offlineBankAvsVerificationError)  return Promise.reject();
                let isVerified = self.checkIfBankAccountAlreadyVerified();

                let isPermissibleDocRequired = self.permissibleUseDocumentSetting &&
                ((self.checkIfReceivingBankNeedsVerification() && (self.isPayeeIndividualForReceivingBank || (self.item.receivingCustomerName != self.item.payee))) ||
                (self.checkIfOfflineBankNeedsVerification() && self.isPayeeIndividualForOfflineBank));

                if(isVerified || self.superMarketDataVerificationEnabled == false || isPermissibleDocRequired == false) {
                    return self.saveCheckDetails();
                }
                else {
                    return Promise.reject({
                        errorCode: 'BANKACCOUNTVALIDATIONREQUIRED'
                    });
                }
            },

            saveCheckDetails() {
                const self = this;

                let changes = self.getAuditChanges(self.originalItem.toDataObject(), self.item.toDataObject());
                if(self.item.checksID > 0 && _.isEmpty(changes)) {
                    return Promise.resolve(true);
                }

                if(_.isNil(self.item.receivingBankVerificationStatus) && _.some(changes, element => (element.name === 'receivingABA' || element.name === 'receivingAccountNumber' || element.name === 'receivingCustomerName'))){
                    let isPermissibleDocRequired = self.superMarketDataVerificationEnabled && self.permissibleUseDocumentSetting && (self.isPayeeIndividualForReceivingBank || (self.item.receivingCustomerName != self.item.payee));
                    self.item.receivingBankAvsResultDocumentId =  isPermissibleDocRequired ? self.permissibleUseDocument.fileName : '';
                    self.item.receivingBankAvsResultDocumentLink = isPermissibleDocRequired ? self.permissibleUseDocument.fileContentBase64Encoded : '';
                }

                if(_.isNil(self.item.offlineBankVerificationStatus) && _.some(changes, element => (element.name === 'offlineABA' || element.name === 'offlineAccountNumber'))){
                    let isPermissibleDocRequired = self.permissibleUseDocumentSetting && self.isPayeeIndividualForOfflineBank;
                    self.item.offlineBankAvsResultDocumentId =  isPermissibleDocRequired ? self.permissibleUseDocument.fileName : '';
                    self.item.offlineBankAvsResultDocumentLink = isPermissibleDocRequired ? self.permissibleUseDocument.fileContentBase64Encoded : '';
                }

                let data = self.item.toDataObject();

                let saveApiRequest = self.isConsolidated
                    ? self.$api.ConsolidatedChecksApi.transferToWire(self.consolidatedCheckID, { data, changes })
                    : self.$api.CheckWritingApi.saveCheckToWire(data, changes);
                return self.$rqBusy.wait(saveApiRequest).then((data) => {

                        if(self.superMarketDataVerificationEnabled && self.permissibleUseDocumentSetting && !_.isNil(self.permissibleUseDocumentCategory) &&
                        !_.isNil(self.permissibleUseDocument) && !_.isNil(self.permissibleUseDocument.originalFile)) {
                            const formData = new FormData();
                            let fileNameParts = self.permissibleUseDocument.originalFile.name.split('.');
                            let fileName = fileNameParts[0];
                            let extension = `.${fileNameParts[1]}`;
                            let fileType = DocumentFileType.fromFileExtension(extension);
                            let lastModifiedDisplayDate = self.permissibleUseDocument.originalFile.lastModifiedDate.toLocaleString('en-US', { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });

                            formData.append(`file-${self.permissibleUseDocument.originalFile.name}`, self.permissibleUseDocument.originalFile);

                            let document = new FileScanDocumentDto({
                                contentType: self.permissibleUseDocument.originalFile.type,
                                dateModifiedUtcDisplay: lastModifiedDisplayDate,
                                description: fileName,
                                documentType: 1,
                                fileScanCategoryID: self.permissibleUseDocumentCategory.id,
                                fileScanDocumentDuplicateAction: 2,
                                fileScanDocumentID: -1,
                                fileScanPageFileName: self.permissibleUseDocument.originalFile.name,
                                fileSize: self.permissibleUseDocument.originalFile.size,
                                fileType: fileType,
                                fileTypeDisplay: extension,
                                numberOfPages: 1,
                                ordersID: self.item.ordersID,
                                standardDescription: fileName,
                                isReadOnly: true
                            });

                            formData.append('data-', JSON.stringify([document]));
                            return self.$rqBusy.wait(self.$api.FileScanApi.uploadToFileScan(formData)).then((uploadResult) => {
                                return Promise.resolve(data);
                            })
                            .catch((error) => {
                                console.error(error);
                                return Promise.resolve(data);
                            });
                        }

                        return Promise.resolve(data);
                    })
                    .catch((error) => {
                        if(error.errorCode === "BANKACCOUNTVALIDATIONERROR") {

                            let errorJson = JSON.parse(error.errorMessage);
                            let offlineBankError = errorJson.find(ele => ele.message.toLowerCase().includes('offline bank'));
                            let receivingBankError = errorJson.find(ele => ele.message.toLowerCase().includes('receiving bank'));

                            if(!_.isNil(offlineBankError)) {

                                self.item.offlineBankVerificationStatus = self.getBankVerificationStatusNumber(offlineBankError.verificationStatus);
                                self.onAccountVerificationConfirmationRequired('offlinebank', offlineBankError.verificationStatus);

                                self.item.offlineBankAvsResultDocumentId = offlineBankError.avsResultDocumentId;
                                self.item.offlineBankAvsResultDocumentLink = offlineBankError.avsResultDocumentLink;
                                self.item.offlineBankVerificationOrderNoteID = offlineBankError.orderNoteID;
                                self.offlineBankVerificationMessage = offlineBankError.verificationMessage;

                                self.lastOfflineBankVerificationResult = {
                                    bankABA : self.item.offlineABA,
                                    bankAccountNumber: self.item.offlineAccountNumber,
                                    payeeName: '',
                                    verificationStatus: self.getBankVerificationStatusString(self.item.offlineBankVerificationStatus)
                                };
                            }

                            if(!_.isNil(receivingBankError)) {

                                self.item.receivingBankVerificationStatus = self.getBankVerificationStatusNumber(receivingBankError.verificationStatus);
                                self.onAccountVerificationConfirmationRequired('receivingbank', receivingBankError.verificationStatus);

                                self.item.receivingBankAvsResultDocumentId = receivingBankError.avsResultDocumentId;
                                self.item.receivingBankAvsResultDocumentLink = receivingBankError.avsResultDocumentLink;
                                self.item.receivingBankVerificationOrderNoteID = receivingBankError.orderNoteID;
                                self.receivingBankVerificationMessage = receivingBankError.verificationMessage;

                                self.lastReceivingBankVerificationResult = {
                                    bankABA : self.item.receivingABA,
                                    bankAccountNumber: self.item.receivingAccountNumber,
                                    payeeName: self.item.receivingCustomerName,
                                    verificationStatus: self.getBankVerificationStatusString(self.item.receivingBankVerificationStatus)
                                };
                            }
                        }

                        return Promise.reject(error);
                });
            },

            resetReceivingBankAvsVerificationError() {
                this.receivingBankAvsVerificationError = false;
                this.showReceivingBankVerificationCheckmark = false;
            },

            resetOfflineBankAvsVerificationError() {
                this.offlineBankAvsVerificationError = false;
                this.showOfflineBankVerificationCheckmark = false;
            },

            onReceivingBankBlur(e) {
                const self = this;
                if(!self.superMarketDataVerificationEnabled) return;
                if(e && e.relatedTarget && (e.relatedTarget.innerText == 'OK' || e.relatedTarget.innerText == 'CANCEL')) return;

                if(self.v$.item.receivingABA.$error ||
                self.v$.item.receivingAccountNumber.$error ||
                self.v$.item.receivingCustomerName.$error) {
                    return;
                }
                if(_.isNullOrEmpty(self.item.receivingABA) ||
                _.isNullOrEmpty(self.item.receivingAccountNumber) ||
                _.isNullOrEmpty(self.item.receivingCustomerName)) {
                    return;
                }
                if(!_.isNil(self.lastReceivingBankVerificationResult) &&
                self.lastReceivingBankVerificationResult.bankABA == self.item.receivingABA &&
                self.lastReceivingBankVerificationResult.bankAccountNumber == self.item.receivingAccountNumber &&
                self.lastReceivingBankVerificationResult.payeeName == self.item.receivingCustomerName) {
                    return;
                }
                if (self.item.isInternationalTransfer) return;

                if(self.permissibleUseDocumentSetting === true &&
                (self.isPayeeIndividualForReceivingBank || (self.item.receivingCustomerName != self.item.payee))) {
                    self.showUploadFileDialog('verify', 'receivingbank');
                }
                else {
                    self.verifyReceivingBank();
                }
            },

            onOfflineBankBlur(e) {
                const self = this;
                if(!self.superMarketDataVerificationEnabled) return;
                if(e && e.relatedTarget && (e.relatedTarget.innerText == 'OK' || e.relatedTarget.innerText == 'CANCEL')) return;
                if(self.v$.item.offlineABA.$error ||
                self.v$.item.offlineAccountNumber.$error) {
                    return;
                }
                if(_.isNullOrEmpty(self.item.offlineABA) ||
                _.isNullOrEmpty(self.item.offlineAccountNumber)) {
                    return;
                }

                if(!_.isNil(self.lastOfflineBankVerificationResult) &&
                self.lastOfflineBankVerificationResult.bankABA == self.item.offlineABA &&
                self.lastOfflineBankVerificationResult.bankAccountNumber == self.item.offlineAccountNumber) {
                    return;
                }

                if(self.permissibleUseDocumentSetting == true && self.isPayeeIndividualForOfflineBank) {
                    self.showUploadFileDialog('verify', 'offlinebank');
                }
                else {
                    self.verifyOfflineBank();
                }
            },

            verifyReceivingBank() {
                const self = this;
                let isPermissibleDocRequired = self.permissibleUseDocumentSetting && (self.isPayeeIndividualForReceivingBank || (self.item.receivingCustomerName != self.item.payee));
                let verificationRequest = {
                    bankABA: self.item.receivingABA,
                    bankAccountNumber: self.item.receivingAccountNumber,
                    bankType: 'receivingbank',
                    payeeName: self.item.receivingCustomerName,
                    fileName: isPermissibleDocRequired ? self.permissibleUseDocument.fileName : '',
                    fileContent: isPermissibleDocRequired ? self.permissibleUseDocument.fileContentBase64Encoded : '',
                    updateNotes: true
                };

                let apiPromise = self.$api.CheckWritingApi.verifyBankForWire(self.item.checksID, verificationRequest);
                self.$rqBusy.wait(apiPromise).then((data) => {
                    self.lastReceivingBankVerificationResult = data;
                    self.item.receivingBankAvsResultDocumentId = data.avsResultDocumentId;
                    self.item.receivingBankAvsResultDocumentLink = data.avsResultDocumentLink;
                    self.item.receivingBankVerificationOrderNoteID = data.orderNoteID;
                    self.receivingBankVerificationMessage = data.verificationMessage;
                    self.item.receivingBankVerificationStatus = self.getBankVerificationStatusNumber(data.verificationStatus);

                    if(data.verificationStatus == 'VERIFIED'){
                        self.receivingBankAvsVerificationError = false;
                        self.showReceivingBankVerificationCheckmark = true;    
                    }
                    else {
                        self.onAccountVerificationConfirmationRequired('receivingbank', data.verificationStatus);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    self.receivingBankAvsVerificationError = true;
                    self.showReceivingBankVerificationCheckmark = false;
                    self.item.receivingBankVerificationStatus = null;
                    self.item.receivingBankVerificationOrderNoteID = null;
                    self.receivingBankVerificationMessage = "The account information cannot be verified.";
                });
            },

            verifyOfflineBank() {
                const self = this;
                let isPermissibleDocRequired = self.permissibleUseDocumentSetting && self.isPayeeIndividualForOfflineBank;
                let verificationRequest = {
                    bankABA: self.item.offlineABA,
                    bankAccountNumber: self.item.offlineAccountNumber,
                    bankType: 'offlinebank',
                    payeeName: '',
                    fileName: isPermissibleDocRequired ? self.permissibleUseDocument.fileName : '',
                    fileContent: isPermissibleDocRequired ? self.permissibleUseDocument.fileContentBase64Encoded : '',
                    updateNotes: true
                };

                let apiPromise = self.$api.CheckWritingApi.verifyBankForWire(self.item.checksID, verificationRequest);
                self.$rqBusy.wait(apiPromise).then((data) => {
                    self.lastOfflineBankVerificationResult = data;
                    self.item.offlineBankAvsResultDocumentId = data.avsResultDocumentId;
                    self.item.offlineBankAvsResultDocumentLink = data.avsResultDocumentLink;
                    self.item.offlineBankVerificationOrderNoteID = data.orderNoteID;
                    self.offlineBankVerificationMessage = data.verificationMessage;
                    self.item.offlineBankVerificationStatus = self.getBankVerificationStatusNumber(data.verificationStatus);

                    if(data.verificationStatus == 'VERIFIED'){
                        self.offlineBankAvsVerificationError = false;
                        self.showOfflineBankVerificationCheckmark = true;
                    }
                    else {
                        self.onAccountVerificationConfirmationRequired('offlinebank', data.verificationStatus);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    self.offlineBankAvsVerificationError = true;
                    self.showOfflineBankVerificationCheckmark = false;
                    self.item.offlineBankVerificationStatus = null;
                    self.item.offlineBankVerificationOrderNoteID = null;
                    self.offlineBankVerificationMessage = "The account information cannot be verified.";
                });
            },

            onAccountVerificationConfirmationRequired(bankType, verificationStatus) {
                const self = this;
                let ok = function () {
                    if(bankType.includes('offlinebank')) {
                        self.offlineBankAvsVerificationError = false;
                        self.showOfflineBankVerificationCheckmark = true;
                    }

                    if(bankType.includes('receivingbank')) {
                        self.receivingBankAvsVerificationError = false;
                        self.showReceivingBankVerificationCheckmark = true;
                    }
                };

                let cancel = function () {
                    if(bankType.includes('offlinebank')) {
                        self.offlineBankAvsVerificationError = true;
                        self.showOfflineBankVerificationCheckmark = false;
                    }

                    if(bankType.includes('receivingbank')) {
                        self.receivingBankAvsVerificationError = true;
                        self.showReceivingBankVerificationCheckmark = false;
                    }
                };

                let msg = 'The Account Information has been verified but additional confirmation may be necessary. Do you want to proceed?';
                if(!_.isNil(verificationStatus) && (verificationStatus === 'WARNING' || verificationStatus === 'SERVERERROR')) {
                    msg = 'The Account Information could not be verified. Do you want to proceed?';
                }

                self.$dialog.confirm("Confirm", msg, ok, cancel, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            checkIfBankAccountAlreadyVerified() {
                const self = this;
                let result = true;
                if (_.isNil(self.lastReceivingBankVerificationResult) ||
                self.lastReceivingBankVerificationResult.bankABA != self.item.receivingABA ||
                self.lastReceivingBankVerificationResult.bankAccountNumber != self.item.receivingAccountNumber ||
                self.lastReceivingBankVerificationResult.payeeName != self.item.receivingCustomerName) {
                    self.item.receivingBankVerificationStatus = null;
                    result = _.isEmpty(self.item.receivingABA) || _.isEmpty(self.item.receivingAccountNumber);
                }

                if (_.isNil(self.lastOfflineBankVerificationResult) ||
                self.lastOfflineBankVerificationResult.bankABA != self.item.offlineABA ||
                self.lastOfflineBankVerificationResult.bankAccountNumber != self.item.offlineAccountNumber) {
                    self.item.offlineBankVerificationStatus = null;
                    result = _.isEmpty(self.item.offlineABA) || _.isEmpty(self.item.offlineAccountNumber);
                }

                return result;
            },

            checkIfReceivingBankNeedsVerification() {
                const self = this;
                let result = false;
                if (_.isNil(self.lastReceivingBankVerificationResult) ||
                self.lastReceivingBankVerificationResult.bankABA != self.item.receivingABA ||
                self.lastReceivingBankVerificationResult.bankAccountNumber != self.item.receivingAccountNumber ||
                self.lastReceivingBankVerificationResult.payeeName != self.item.receivingCustomerName) {
                    self.item.receivingBankVerificationStatus = null;
                    result = !_.isEmpty(self.item.receivingABA) && !_.isEmpty(self.item.receivingAccountNumber);
                }

                return result;
            },

            checkIfOfflineBankNeedsVerification() {
                const self = this;
                let result = false;
                if (_.isNil(self.lastOfflineBankVerificationResult) ||
                self.lastOfflineBankVerificationResult.bankABA != self.item.offlineABA ||
                self.lastOfflineBankVerificationResult.bankAccountNumber != self.item.offlineAccountNumber) {
                    self.item.offlineBankVerificationStatus = null;
                    result = !_.isEmpty(self.item.offlineABA) && !_.isEmpty(self.item.offlineAccountNumber);
                }

                return result;
            },

            getBankVerificationStatusString(verificationStatus) {
                if(verificationStatus == 0) return 'VERIFIED';
                else if(verificationStatus == 1) return 'NOTICE';
                else if(verificationStatus == 2) return 'CAUTION';
                else if(verificationStatus == 3) return 'WARNING';
                else return 'SERVERERROR';
            },

            getBankVerificationStatusNumber(verificationStatusString) {
                if(verificationStatusString == 'VERIFIED') return 0;
                else if(verificationStatusString == 'NOTICE') return 1;
                else if(verificationStatusString == 'CAUTION') return 2;
                else if(verificationStatusString == 'WARNING') return 3;
                else return null;
            },

            showUploadFileDialog(action, bankType, callback) {
                const self = this;
                let onOk = (e) => {

                    let file = e.component.uploadFile;
                    let existingDoc = e.component.selectedDocument;
                    if(!_.isNil(file)) {
                        self.readFileContentAndContinue(file, action, bankType, callback);
                    }
                    else {
                        self.readExistingDocContentAndContinue(existingDoc, action, bankType, callback);
                    }
                    return true;
                };

                self.$dialog.open({
                    title: `Upload Permissible Use Document (${self.acceptedFileTypes})`,
                    height: "auto",
                    width: "500",
                    adaptive: true,
                    closeOnEsc: true,
                    component: UploadPermissibleUseDocument,
                    props:  {
                            accepted: self.acceptedFileTypes,
                            ordersID: self.item.ordersID
                            },
                    onOk: onOk
                });
            },

            readFileContentAndContinue(file, action, bankType, callback) {
                const reader = new FileReader();
                const self = this;

                reader.onload = (event) => {

                    const fileContent = event.target.result;
                    let base64 = 'base64,';
                    let fileContentBase64 = fileContent.substring(fileContent.indexOf(base64) + base64.length);
                    self.permissibleUseDocument = {
                        fileName: file.name,
                        fileContentBase64Encoded: fileContentBase64,
                        originalFile: file
                    };

                    if(action.includes('save')) {
                        self.$dialog.close();
                        self.saveCheckDetails().then(result => {
                            if(!_.isNil(callback)){
                                callback(result);
                            }
                        });
                    }
                    else if(action.includes('verify')) {
                        if (bankType.includes('offlinebank')) {
                            self.verifyOfflineBank();
                        }

                        if (bankType.includes('receivingbank')) {
                            self.verifyReceivingBank();
                        }
                    }
                };

                // Read the content of the selected file as a data URL
                reader.readAsDataURL(file);
            },

            readExistingDocContentAndContinue(existingDoc, action, bankType, callback) {
                const self = this;
                let apiPromise = self.$api.FileScanApi.getFileScanDocument(existingDoc.fileScanDocumentID, 5);
                self.$rqBusy.wait(apiPromise).then(result => {
                    let base64 = 'base64,';
                    let fileContentBase64 = result.content.substring(result.content.indexOf(base64) + base64.length);
                    self.permissibleUseDocument = {
                        fileName: existingDoc.commonFileName,
                        fileContentBase64Encoded: fileContentBase64,
                        originalFile: null
                    };

                    if(action.includes('save')) {
                        self.$dialog.close();
                        self.saveCheckDetails().then(result => {
                            if(!_.isNil(callback)){
                                callback(result);
                            }
                        });
                    }
                    else if(action.includes('verify')) {
                        if (bankType.includes('offlinebank')) {
                            self.verifyOfflineBank();
                        }

                        if (bankType.includes('receivingbank')) {
                            self.verifyReceivingBank();
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                    self.$toast.error({ message: `Error occurred while retrieving document.` });
                });
            },

            updateIsPayeeIndividual() {
                const self = this;

                let apiPromise = self.$api.CheckWritingApi.isPayeeIndividual(self.checksID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.isPayeeIndividualForReceivingBank = result;
                        self.isPayeeIndividualForOfflineBank = result;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }

        },
    }
</script>
<style lang="scss" scoped>
    .success-check {
        color: #49B848;
        float: right;
        margin-right: 5px !important;
    }
    .warning-check {
        color: #E7B721;
        float: right;
        margin-right: 5px !important;
    }
    .inline {
        display: inline !important;
        margin-left: 5px !important;
    }
</style>