export class RoleType {
    static get None() { return 0; }
    static get Bank() { return 1; }
    static get TitleCompany() { return 2; }
    static get Underwriter() { return 3; }
    static get Attorney() { return 4; }
    static get HomeInspection() { return 5; }
    static get Lender() { return 6; }
    static get Member() { return 7; }
    static get PestInspection() { return 8; }
    static get PlaceOfClosing() { return 9; }
    static get Realtor() { return 10; }
    static get Surveyor() { return 11; }
    static get SearchProvider() { return 12; }
    static get RecordingOffice() { return 13; }
    static get MortgageBroker() { return 14; }
    static get Buyer() { return 15; }
    static get Seller() { return 16; }
    static get Notary() { return 17; }
    static get LegalPaperworkPreparedBy() { return 18; }
    static get Referral() { return 19; }
    static get AdditionalBuyerRealtor() { return 20; }
    static get AdditionalBuyerAttorney() { return 21; }
    static get AdditionalLender() { return 22; }
    static get AdditionalTitleCompany() { return 23; }
    static get Customer() { return 24; }
    static get AdditionalSellerRealtor() { return 25; }
    static get AdditionalSellerAttorney() { return 26; }
    static get SettlementAgent() { return 28; }
    static get ContactTemplate() { return 29; }
}

export class UploadCategory {
    static get TitleProduction() { return 100; }
    static get OwnerPolicy() { return 200; }
    static get LoanPolicy() { return 300; }
}

export class RateAndFeeWarningType {
    static get Smkt() { return 1; }
    static get Pull() { return 2; }
}

export class ContentDataFormat {
    static get UnencodedString() { return 1; }
    static get Base64String() { return 2; }
    static get Uint8Array() { return 3; } // binary
}

const pcAccessOptions = {
    Default: 2,
    Access: 1,
    NoAccess: 0
};
export class PCAccessOptions {
    static get Default() { return pcAccessOptions.Default; }
    static get Access() { return pcAccessOptions.Access; }
    static get NoAccess() { return pcAccessOptions.NoAccess; }

    static get lookupItems() {
        return _.map(pcAccessOptions, a => { return { id: a, name: PCAccessOptions.displayValue(a) }; });
    }

    static get lookupItemsNoDefault() {
        const result = PCAccessOptions.lookupItems;
        _.pullAt(result, [0]);
        return result;
    }

    static displayValue(id) {
        switch (id) {
            case pcAccessOptions.Default: return "Use Role Default";
            case pcAccessOptions.Access: return "Access";
            case pcAccessOptions.NoAccess: return "No Access";
        }
        return "";
    }
}

const userScreenAccessLevel = {
    None: 0,
    Read: 1,
    Full: 2
};

export class UserScreenAccessLevel {
    static get None() { return userScreenAccessLevel.None; }
    static get Read() { return userScreenAccessLevel.Read; }
    static get Full() { return userScreenAccessLevel.Full; }

    static get lookupItems() { return this.getLookupItems(); }

    static getLookupItems(valueExpr = "id", displayExpr = "name") {
        return _.map(userScreenAccessLevel, a => ({ [valueExpr]: a, [displayExpr]: UserScreenAccessLevel.displayValue(a) }));
    }

    static displayValue(id) {
        switch (id) {
            case userScreenAccessLevel.None:
                return "None";
            case userScreenAccessLevel.Read:
                return "Read Only";
            case userScreenAccessLevel.Full:
                return "Full Access";
        }
        return "";
    }
}

const userAccessLevel = {
    None: 0,
    CreatedOnly: 1,
    Any: 2
};
export class UserAccessLevel {
    static get None() { return userAccessLevel.None; }
    static get CreatedOnly() { return userAccessLevel.CreatedOnly; }
    static get Any() { return userAccessLevel.Any; }

    static get lookupItems() {
        return _.map(userAccessLevel, a => { return { id: a, name: UserAccessLevel.displayValue(a) }; });
    }

    static displayValue(id) {
        switch (id) {
            case userAccessLevel.None:
                return "None";
            case userAccessLevel.CreatedOnly:
                return "Only When Created/Owned by User";
            case userAccessLevel.Any:
                return "Any";
        }
        return "";
    }
}

const orderService = {
    ALTAOwner: 0,
    ALTATitleGuard: 1,
    ALTAMortgage: 2,
    ALTASimultaneous: 3,
    ALTAConstructionLoan: 4,
    ALTAOther: 5,
    TXOwnerResident: 6,
    TXOwnerNonResident: 7,
    TXMortgage: 8,
    TXSimultaneousResident: 9,
    TXSimultaneousNonResident: 10,
    TXBinder: 11,
    TXOther: 12
};

export class OrderServiceType {
    static get AltaOwner() { return orderService.ALTAOwner; }
    static get AltaTitleGuard() { return orderService.ALTATitleGuard; }
    static get AltaMortgage() { return orderService.ALTAMortgage; }
    static get AltaSimultaneous() { return orderService.ALTASimultaneous; }
    static get AltaConstructionLoan() { return orderService.ALTAConstructionLoan; }
    static get AltaOther() { return orderService.ALTAOther; }
    static get TxOwnerResident() { return orderService.TXOwnerResident; }
    static get TxOwnerNonResident() { return orderService.TXOwnerNonResident; }
    static get TxMortgage() { return orderService.TXMortgage; }
    static get TxSimultaneousResident() { return orderService.TXSimultaneousResident; }
    static get TxSimultaneousNonResident() { return orderService.TXSimultaneousNonResident; }
    static get TxBinder() { return orderService.TXBinder; }
    static get TxOther() { return orderService.TXOther; }

    static get ownerSpecificTypes() {
        return [
            orderService.ALTAOwner,
            orderService.ALTATitleGuard,
            orderService.TXOwnerResident,
            orderService.TXOwnerNonResident
        ];
    }

    static get loanSpecificTypes() {
        return [
            orderService.ALTAMortgage,
            orderService.ALTAConstructionLoan,
            orderService.TXMortgage,
        ];
    }

    static get simultaneousEligibleTypes() {
        return [
            orderService.ALTAOther,
            orderService.ALTASimultaneous,
            orderService.TXBinder,
            orderService.TXOther,
            orderService.TXSimultaneousResident,
            orderService.TXSimultaneousNonResident
        ];
    }

    static isOwnerSpecific(serviceType) {
        return _.includes(OrderServiceType.ownerSpecificTypes, serviceType);
    }

    static isLoanSpecific(serviceType) {
        return _.includes(OrderServiceType.loanSpecificTypes, serviceType);
    }

    static isSimultaneousEligible(serviceType) {
        return _.includes(OrderServiceType.simultaneousEligibleTypes, serviceType);
    }

    static isAlta(id) {
        return id === orderService.ALTAOwner
            || id === orderService.ALTATitleGuard
            || id === orderService.ALTAMortgage
            || id === orderService.ALTASimultaneous
            || id === orderService.ALTAConstructionLoan
            || id === orderService.ALTAOther;
    }

    static displayValue(id) {
        switch (id) {
            case 0:
                return "Owner";
            case 1:
                return "Title Guarantee";
            case 2:
                return "Loan";
            case 3:
                return "Simultaneous";
            case 4:
                return "Const Loan";
            case 5:
                return "Other";
            case 6:
                return "Owner (Res)";
            case 7:
                return "Owner (Non Res)";
            case 8:
                return "Loan";
            case 9:
                return "Simult. (Res)";
            case 10:
                return "Simult. (Non Res)";
            case 11:
                return "Binder";
            case 12:
                return "Other";
        }
        return "Unknown";
    }
}

const numberFormat = {
    None: 0,
    ArabicNumbers: 3,
    Letters: 4,
    CapitalLetters: 5,
    RomanNumbers: 6,
    SmallRomanNumbers: 7
};

export class NumberFormat {
    static get None() { return numberFormat.None; }
    static get ArabicNumbers() { return numberFormat.ArabicNumbers; }
    static get Letters() { return numberFormat.Letters; }
    static get CapitalLetters() { return numberFormat.CapitalLetters; }
    static get RomanNumbers() { return numberFormat.RomanNumbers; }
    static get SmallRomanNumbers() { return numberFormat.SmallRomanNumbers; }

    static displayValue(id) {
        switch (id) {
            case numberFormat.ArabicNumbers: return "1,2,3...";
            case numberFormat.Letters: return "a,b,c...";
            case numberFormat.CapitalLetters: return "A,B,C...";
            case numberFormat.RomanNumbers: return "I,II,III...";
            case numberFormat.SmallRomanNumbers: return "i,ii,iii...";
        }
        return "None";
    }

    static openXmlVal(id) {
        switch (id) {
            case numberFormat.ArabicNumbers: return "decimal";
            case numberFormat.Letters: return "lowerLetter";
            case numberFormat.CapitalLetters: return "upperLetter";
            case numberFormat.RomanNumbers: return "upperRoman";
            case numberFormat.SmallRomanNumbers: return "lowerRoman";
        }
        return "None";
    }

    static get lookupItems() {
        return _.map(numberFormat, nf => { return { id: nf, name: NumberFormat.displayValue(nf) }; });
    }
}

const textControlListType = {
    None: 1,
    Bulleted: 2,
    Numbered: 4,
    Structured: 8
};
export class TextControlListType {
    static get None() { return textControlListType.None; }
    static get Bulleted() { return textControlListType.Bulleted; }
    static get Numbered() { return textControlListType.Numbered; }
    static get Structured() { return textControlListType.Structured; }

    static displayValue(id) {
        switch (id) {
            case textControlListType.None:
                return "None";
            case textControlListType.Bulleted:
                return "Bulleted";
            case textControlListType.Numbered:
                return "Numbered";
            case textControlListType.Structured:
                return "Structured";
        }
        return "";
    }

    static displayIcon(id) {
        switch (id) {
            case textControlListType.Bulleted:
                return "fas fa-list-ul";
            case textControlListType.Numbered:
                return "fas fa-list-ol";
            case textControlListType.Structured:
                return "fas fa-th-list";
        }
        return "";
    }

    static get lookupItems() {
        return _.map(textControlListType, t => { return { id: t, name: TextControlListType.displayValue(t) }; });
    }
}

const listFormatCharacter = {
    None: 1,
    Tab: 9,
    Space: 32
};
export class ListFormatCharacter {
    static get None() { return listFormatCharacter.None; }
    static get Tab() { return listFormatCharacter.Tab; }
    static get Space() { return listFormatCharacter.Space; }

    static get lookupItems() {
        return _.map(listFormatCharacter, c => { return { id: c, name: ListFormatCharacter.displayValue(c) }; });
    }

    static displayValue(id) {
        switch (id) {
            case listFormatCharacter.Tab: return "Tab";
            case listFormatCharacter.Space: return "Space";
        }
        return "None";
    }
}

const auditChangeType = {
    Add: 0,
    Edit: 1,
    Delete: 2,
    Label: 3,
};

export class AuditChangeType {
    static get Add() { return auditChangeType.Add; }
    static get Edit() { return auditChangeType.Edit; }
    static get Delete() { return auditChangeType.Delete; }
    static get Label() { return auditChangeType.Label; }

    static displayValue(id) {
        switch (id) {
            case auditChangeType.Add: return "Add";
            case auditChangeType.Edit: return "Edit";
            case auditChangeType.Delete: return "Delete";
            case auditChangeType.Label: return "Version";
        }
        return "";
    }
}

const orderNoteCategory = {
    Order: 0,
    VendService: 1,
    RecordingInstrument: 2,
    TitleProduction: 3,
    CheckWriting: 4,
    Policy: 5,
    SettlementStatementHUD: 6,
    CSS: 7,
    Invoice: 8
};

export class OrderNoteCategory {
    static get Order() { return orderNoteCategory.Order; }
    static get VendService() { return orderNoteCategory.VendService; }
    static get RecordingInstrument() { return orderNoteCategory.RecordingInstrument; }
    static get TitleProduction() { return orderNoteCategory.TitleProduction; }
    static get CheckWriting() { return orderNoteCategory.CheckWriting; }
    static get Closing() { return orderNoteCategory.Closing }
    static get Policy() { return orderNoteCategory.Policy; }
    static get SettlementStatementHUD() { return orderNoteCategory.SettlementStatementHUD; }
    static get CSS() { return orderNoteCategory.CSS; }
    static get Invoice() { return orderNoteCategory.Invoice; }

    static displayValue(id) {
        switch (id) {
            case orderNoteCategory.Order: return "File";
            case orderNoteCategory.VendService: return "Vendor Service";
            case orderNoteCategory.RecordingInstrument: return "Recording Instrument";
            case orderNoteCategory.TitleProduction: return "Title Production";
            case orderNoteCategory.CheckWriting: return "Check Writing";
            case orderNoteCategory.Closing: return "Closing";
            case orderNoteCategory.Policy: return "Policy";
            case orderNoteCategory.SettlementStatementHUD: return "Settlement Statement";
            case orderNoteCategory.CSS: return "CSS";
            case orderNoteCategory.Invoice: return "Invoice";
        }
        return "";
    }

    static get lookupItems() {
        return _.map(orderNoteCategory, a => { return { id: a, name: OrderNoteCategory.displayValue(a) }; });
    }
}

const orderLockOption = {
    All: 0,
    LockOnly: 1,
    UnlockOnly: 2,
    None: 3
};

export class OrderLockOption {
    static get All() { return orderLockOption.All; }
    static get LockOnly() { return orderLockOption.LockOnly; }
    static get UnlockOnly() { return orderLockOption.UnlockOnly; }
    static get None() { return orderLockOption.None; }
}

const escrowLockOption = {
    All: 0,
    LockOnly: 1,
    UnlockOnly: 2,
    None: 3
};

export class EscrowLockOption {
    static get All() { return escrowLockOption.All; }
    static get LockOnly() { return escrowLockOption.LockOnly; }
    static get UnlockOnly() { return escrowLockOption.UnlockOnly; }
    static get None() { return escrowLockOption.None; }
}

const orderStatus = {
    New: 0, Open: 1, Closed: 2, Cancelled: 3, Template: 4, Old: 5, OnHold: 7, Legacy: 8, PolicyIssued: 9
};
export class OrderStatus {
    static get New() { return orderStatus.New; }
    static get Open() { return orderStatus.Open; }
    static get Closed() { return orderStatus.Closed; }
    static get Cancelled() { return orderStatus.Cancelled; }
    static get Template() { return orderStatus.Template; }
    static get Old() { return orderStatus.Old; }
    static get OnHold() { return orderStatus.OnHold; }
    static get Legacy() { return orderStatus.Legacy; }
    static get PolicyIssued() { return orderStatus.PolicyIssued; }


    static get lookupItems() {
        return _.map(orderStatus, c => { return { id: c, name: OrderStatus.displayValue(c) }; });
    }

    static displayValue(id) {
        switch (id) {
            case orderStatus.New:
                return "New";
            case orderStatus.Open:
                return "Open";
            case orderStatus.Closed:
                return "Closed";
            case orderStatus.Cancelled:
                return "Cancelled";
            case orderStatus.Template:
                return "Template";
            case orderStatus.Old:
                return "Old";
            case orderStatus.OnHold:
                return "On Hold";
            case orderStatus.Legacy:
                return "Legacy";
            case orderStatus.PolicyIssued:
                return "Policy Issued";
            default:
                return "";
        }
    }
}

export class AlertSeverity {
    static get Info() { return 0; }
    static get Warning() { return 1; }
    static get Error() { return 2; }
}

const workflowTaskAssignment = { Me: 1, Dept: 2, Other: 3 };
export class WorkflowTaskAssignment {
    static get Me() { return workflowTaskAssignment.Me; }
    static get Dept() { return workflowTaskAssignment.Dept; }
    static get Other() { return workflowTaskAssignment.Other; }
    static get lookupItems() { return _.map(workflowTaskStatus, (id, name) => ({ id, name })); }
    static displayValue(id) { return _.findKey(workflowTaskStatus, v => v === id); }
}

const workflowTaskStatus = { Open: 0, DueToday: 1, DueWithin7Days: 2, DueWithin30Days: 3, Complete: 4, Overdue: 5, NotApplicable: 6 };
export class WorkflowTaskStatus {
    static get Open() { return workflowTaskStatus.Open; }
    static get DueToday() { return workflowTaskStatus.DueToday; }
    static get DueWithin7Days() { return workflowTaskStatus.DueWithin7Days; }
    static get DueWithin30Days() { return workflowTaskStatus.DueWithin30Days; }
    static get Overdue() { return workflowTaskStatus.Overdue; }
    static get Complete() { return workflowTaskStatus.Complete; }
    static get NotApplicable() { return workflowTaskStatus.NotApplicable; }

    static get lookupItems() {
        return _.map(workflowTaskStatus, a => { return { id: a, name: WorkflowTaskStatus.displayValue(a) }; });
    }

    static get filterLookupItems() {
        return _.filter(this.lookupItems, item => !_.includes([0, 4, 6], item.id));
    }

    static getItemStatus(item) {
        if (_.parseBool(item.notApplicableYN || item.notApplicable)) return workflowTaskStatus.NotApplicable;
        if (_.parseBool(item.isComplete)) return workflowTaskStatus.Complete;
        if (_.parseBool(item.isOverDue || item.isOverdue)) return workflowTaskStatus.Overdue;
        if (_.parseBool(item.isDueToday)) return workflowTaskStatus.DueToday;
        if (_.parseBool(item.isDueWithin7)) return workflowTaskStatus.DueWithin7Days;
        if (_.parseBool(item.isDueWithin30)) return workflowTaskStatus.DueWithin30Days;
        return workflowTaskStatus.Open;
    }

    static displayValue(id, defaultDisplayValue) {
        switch (id) {
            case workflowTaskStatus.Open: return "Open";
            case workflowTaskStatus.DueToday: return "Due Today";
            case workflowTaskStatus.DueWithin7Days: return "Due within 7 Days";
            case workflowTaskStatus.DueWithin30Days: return "Due within 30 Days";
            case workflowTaskStatus.Overdue: return "Overdue";
            case workflowTaskStatus.Complete: return "Complete";
            case workflowTaskStatus.NotApplicable: return "Not Applicable";
            default: return defaultDisplayValue;
        }
    }
}

export class PolicyType {
    static get Owner() { return 1; }
    static get Loan() { return 0; }
    static displayValue(policyType) {
        switch (policyType) {
            case this.Owner: return "Owner";
            case this.Loan: return "Loan";
            default: return "None";
        }
    }
    static toLoanHolder(policyType) {
        switch (policyType) {
            case this.Owner: return LoanHolderType.Owner;
            case this.Loan: return LoanHolderType.Loan;
            default: return "None";
        }
    }
}

export class LoanHolderType {
    static get Loan() { return 0; }
    static get Owner() { return 1; }
    static displayValue(loanHolderType) {
        switch (loanHolderType) {
            case this.Owner: return "Owner";
            case this.Loan: return "Loan";
            default: return "None";
        }
    }
    static toPolicyType(loanHolderType) {
        switch (loanHolderType) {
            case this.Owner: return PolicyType.Owner;
            case this.Loan: return PolicyType.Loan;
            default: return "None";
        }
    }
}

const scheduleB2Items = { None: 0, SurveyComplete: 1, SurveyNeeded: 2 };
export class ScheduleB2Items {
    static get None() { return scheduleB2Items.None; }
    static get SurveyComplete() { return scheduleB2Items.SurveyComplete; }
    static get SurveyNeeded() { return scheduleB2Items.SurveyNeeded; }

    static get lookupItems() {
        return _.map(scheduleB2Items, c => { return { id: c, name: ScheduleB2Items.displayValue(c) }; });
    }

    static displayValue(id) {
        let enumKey = _.findKey(scheduleB2Items, cm => cm === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

export const companyContactRouteNames = {
    ManageCompanies: 'dashboard:mc:company-contact-list',
    ThirdPartyCompanies: 'cfg:ent:tpc:company-contact-list',
}

//Not used client-side currently; Adding it here in case it's needed in the future.
const ssDocType = {
    CDF_Combo: 1,
    CDF_Combo_P2ab: 2,
    CDF_Buyer: 3,
    CDF_Buyer_P2ab: 4,
    CDF_Seller: 5,
    CDF_Seller_P2ab: 6,
    CDF_NoSeller: 7,
    CDF_NoSeller_P2ab: 8,
    CDF_ALTA: 9,
    CDF_ALTA_Buyer: 10,
    CDF_ALTA_Seller: 11,
    CDF_ALTA_Cash: 12,
    HUD_2010: 13,
    HUD_2010_Buyer: 14,
    HUD_2010_Seller: 15,
    HUD_2010_1A: 16,
    HUD_1974: 17,
    HUD_1974_Buyer: 18,
    HUD_1974_Seller: 19,
    HUD_1974_1A: 20,
    CSS_Combo: 21,
    CSS_Buyer: 22,
    CSS_Seller: 23,
    CSS_Purchaser: 24
};

export class SettlementStatementDocumentType {
	static get CDF_Combo() { return ssDocType.CDF_Combo; }
	static get CDF_Combo_P2ab() { return ssDocType.CDF_Combo_P2ab; }
	static get CDF_Buyer() { return ssDocType.CDF_Buyer; }
	static get CDF_Buyer_P2ab() { return ssDocType.CDF_Buyer_P2ab; }
	static get CDF_Seller() { return ssDocType.CDF_Seller; }
	static get CDF_Seller_P2ab() { return ssDocType.CDF_Seller_P2ab; }
	static get CDF_NoSeller() { return ssDocType.CDF_NoSeller; }
	static get CDF_NoSeller_P2ab() { return ssDocType.CDF_NoSeller_P2ab; }
	static get CDF_ALTA() { return ssDocType.CDF_ALTA; }
	static get CDF_ALTA_Buyer() { return ssDocType.CDF_ALTA_Buyer; }
	static get CDF_ALTA_Seller() { return ssDocType.CDF_ALTA_Seller; }
	static get CDF_ALTA_Cash() { return ssDocType.CDF_ALTA_Cash; }
	static get HUD_2010() { return ssDocType.HUD_2010; }
	static get HUD_2010_Buyer() { return ssDocType.HUD_2010_Buyer; }
	static get HUD_2010_Seller() { return ssDocType.HUD_2010_Seller; }
	static get HUD_2010_1A() { return ssDocType.HUD_2010_1A; }
	static get HUD_1974() { return ssDocType.HUD_1974; }
	static get HUD_1974_Buyer() { return ssDocType.HUD_1974_Buyer; }
	static get HUD_1974_Seller() { return ssDocType.HUD_1974_Seller; }
	static get HUD_1974_1A() { return ssDocType.HUD_1974_1A; }
	static get CSS_Combo() { return ssDocType.CSS_Combo; }
	static get CSS_Buyer() { return ssDocType.CSS_Buyer; }
	static get CSS_Seller() { return ssDocType.CSS_Seller; }
	static get CSS_Purchaser() { return ssDocType.CSS_Purchaser; }
}

const logLevel = { Debug: 0, Info: 1, Error: 2, Fatal: 3 };
export class LogLevel{
    static get Debug() { return logLevel.Debug; }
    static get Info() { return logLevel.Info; }
    static get Error() { return logLevel.Error; }
    static get Fatal() { return logLevel.Fatal; }
    //aliases
    static get Critical() { return logLevel.Fatal; }
}