import { DocumentFileType }  from "@documents/enums";
import { DateTime } from "luxon";

export class FileScanDocumentDto {
	constructor(options) {
        options = options || {};
        this.fileScanDocumentID = _.parseNumber(options.fileScanDocumentID, 0);
        this.ordersID = _.parseNumber(options.ordersID, null);
        this.gfNo = options.gfNo || null;
        this.description = options.description || null;
        this.documentType = _.parseNumber(options.documentType, null);
        this.documentTypeDisplay = options.documentTypeDisplay || null;
        this.fileType = _.parseNumber(options.fileType, null);
        this.fileTypeDisplay = options.fileTypeDisplay || null;
        this.fileScanPagesID = _.parseNumber(options.fileScanPagesID, null);
        this.fileScanCategoryID = _.parseNumber(options.fileScanCategoryID, null);
        this.fileScanCategoryDisplay = options.fileScanCategoryDisplay || null;
        this.fileScanVolumeID = _.parseNumber(options.fileScanVolumeID, null);
        this.fileScanDescriptionID = _.parseNumber(options.fileScanDescriptionID, null);
        this.fileScanPageFileName = options.fileScanPageFileName || null;
        this.useDefaultDescription = _.parseBool(options.useDefaultDescription, false);
        this.documentTemplateID = _.parseNumber(options.documentTemplateID, null);
        this.numberOfPages = _.parseNumber(options.numberOfPages, null);
        this.dateCreated = options.dateCreated || null;
        this.dateModified = options.dateModified || null;
        this.isReadOnly = _.parseBool(options.isReadOnly, false);
        this.dateModifiedUtcDisplay = _.isNil(options.dateModifiedNumber) ? "" : DateTime.fromMillis(options.dateModifiedNumber, { zone: 'utc' }).toFormat('MM/dd/yyyy, hh:mm a');
        this.publishToPaperlessCloser = _.parseBool(options.publishToPaperlessCloser, false);
        this.publishedByUserID = _.parseNumber(options.publishedByUserID, null);
        this.publishedDate = options.publishedDate || null;
        this.publishedByContactID = _.parseNumber(options.publishedByContactID, null);
        this.publishedByPaperlessCloserAccessID = _.parseNumber(options.publishedByPaperlessCloserAccessID, null);
        this.hUDMainID = _.parseNumber(options.hUDMainID, null);
        this.cssMainID = _.parseNumber(options.cssMainID, null);
        this.invoiceID = _.parseNumber(options.invoiceID, null);
        this.publishedBy = options.publishedBy || null;
        this.publishedByDisplay = options.publishedByDisplay || null;
        this.standardDescription = options.standardDescription || null;
        this.cdfMainID = _.parseNumber(options.cdfMainID, null);
        this.allowAnonymous = _.parseBool(options.allowAnonymous, false);
        this.publicID = options.publicID || null;
        this.loanID = _.parseNumber(options.loanID, null);
        this.tagNames = options.tagNames || null;
        if (_.size(options.tagIDs || []) == 0 && (options.tagIDList || "").length > 0) {
            this.tagIDs = _.split(options.tagIDList || "", ',').map(Number);
        } else {
            this.tagIDs = options.tagIDs || [];
        }
        this.requiresAttention = _.parseBool(options.requiresAttention, false);
        this.fileScanDocumentDuplicateAction = _.parseNumber(options.fileScanDocumentDuplicateAction, 0);
        this.fileSize = _.parseNumber(options.fileSize, 0);
        this.content = options.content || null;
        this.commonFileName = options.commonFileName || null;
        this.contentType = _.isNil(options.contentType) ? DocumentFileType.contentType(options.fileType) : options.contentType;
        this.orderWorkflowTaskID = _.parseNumber(options.orderWorkflowTaskID, null);
        this.fileId = options.fileId || null;
        this.shareUrl = options.shareUrl || null;
        this.email = options.email || null;
        this.isReadOnly = _.parseBool(options.isReadOnly, false);
    }

    get isNew() { return this.fileScanDocumentID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isNil(this.ordersID) || _.parseNumber(this.ordersID, -1) == -1) {
            errorList.push("Order ID is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        if (_.isNil(this.fileScanCategoryID)) {
            errorList.push("Category is required.")
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class FileScanDocumentDescriptionDefaults {
    constructor(options) {
        options = options || {};
        this.defaultTags = options.defaultTags || null;
        this.defaultCategoryID = options.defaultCategoryID || null;
        this.descriptionID = options.descriptionID || null;
        if (_.size(options.tagIDs || []) == 0 && (options.defaultTags || "").length > 0) {
            this.tagIDs = _.split(options.defaultTags || "", ',').map(Number);
        } else {
            this.tagIDs = options.tagIDs;
        }
    }
    toDataObject() { return _.toPlainObject(this); }
}

export class FileScanDocumentShortDto {
	constructor(options) {
        options = options || {};
        this.fileScanCategoryID = _.parseNumber(options.fileScanCategoryID, 0);
        this.fileScanDocumentID = _.parseNumber(options.fileScanDocumentID, 0);
        this.fileType = _.parseNumber(options.fileType, null);
        this.fileTypeDisplay = options.fileTypeDisplay || null;
        this.fileScanCategoryDisplay = options.fileScanCategoryDisplay || null;
        this.fileScanPageFileName = options.fileScanPageFileName || null;
        this.dateModified = options.dateModified || null;
        this.publishedByDisplay = options.publishedByDisplay || null;
        if (!_.isNil(options.description) && _.isNil(options.commonFileName)) {
            this.description = `${options.description}`;
            this.commonFileName = `${options.description}${options.fileTypeDisplay}`;
        } else {
            this.description = options.commonFileName ? options.commonFileName.substr(0, options.commonFileName.lastIndexOf('.')) : null;
            this.commonFileName = options.commonFileName || null;
        }
        this.orderWorkflowTaskID = _.parseNumber(options.orderWorkflowTaskID, null);
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class FileScanDocumentCategoryResultDto {
	constructor(options) {
        options = options || {};
        this.fileScanDocumentID = _.parseNumber(options.fileScanDocumentID, 0);
        this.fileScanCategoryID = _.parseNumber(options.fileScanCategoryID, 0);
        this.fileScanCategoryDisplay = options.fileScanCategoryDisplay || null;
    }
}

export class FileScanDocumentAssignTagRequestDto {
	constructor(options) {
        options = options || {};
        this.tagIDs = options.tagIDs || [];
        this.fileScanDocumentIDs = options.fileScanDocumentIDs || [];
        this.assignType = options.assignType || "";
    }
}

export class FileScanDocumentAssignTagResultDto {
	constructor(options) {
        options = options || {};
        this.fileScanDocumentID = _.parseNumber(options.fileScanDocumentID, 0);
        this.tagNames = options.tagNames || null;
        if (_.size(options.tagIDs || []) == 0 && (options.tagIDList || "").length > 0) {
            this.tagIDs = _.split(options.tagIDList || "", ',').map(Number);
        } else {
            this.tagIDs = options.tagIDs;
        }
        this.dateModified = options.dateModified || new Date();
        this.inactive = _.parseBool(options.inactive, false);
    }
}

export class FileScanDocumentCopyMoveRequestDto {
	constructor(options) {
        options = options || {};
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.gfNo = options.gfNo;
        this.fileScanDocumentIDs = options.fileScanDocumentIDs;
        this.copy = _.parseBool(options.copy, false);
    }
}

export class FileScanDocumentCopyMoveResultDto {
	constructor(options) {
        options = options || {};
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.gfNo = options.gfNo;
        this.fileCount = _.parseNumber(options.fileCount, 0);
        this.copy = _.parseBool(options.copy, false);
        this.validationErrors = options.validationErrors || [];
    }
}

export class FileScanDocumentMergeRequestDto {
	constructor(options) {
        options = options || {};
        this.fileScanDocument = options.fileScanDocument || null;
        this.fileScanDocumentIDs = options.fileScanDocumentIDs || [];
    }
}
