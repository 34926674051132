<template>
    <fieldset :disabled="!hasCompanyAccess || readOnly">
        <div class="row" v-if="!configurationMode && showRoleType">
            <div class="col-3 form-group">
                <label for="drp_company_role">Role</label>
                <dx-select-box
                    :input-attr="{ id:'drp_company_role', automation_id:'drp_company_role' }"
                    :items="roles"
                    :search-enabled="true"
                    value-expr="id"
                    display-expr="name"
                    :disabled="!hasCompanyAccess || readOnly"
                    v-model="localInfo.roleTypeID"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-3 form-group">
                <label for="txt_company_id">ID:</label>
                <input id="txt_company_id"
                    automation_id="txt_company_id"
                    type="text"
                    class="form-control"
                    placeholder="Company ID"
                    title="Company ID cannot be modified"
                    v-model="localInfo.companyID"
                    readonly="true"
                />
            </div>
            <div class="col-3 form-group form-required" :class="{ 'has-error':v$.localInfo.name.$error}">
                <label for="txt_company_name">Company Name</label>
                <input id="txt_company_name"
                    automation_id="txt_company_name"
                    type="text"
                    class="form-control"
                    placeholder="Company Name"
                    title="Company Name is required"
                    v-model.trim="v$.localInfo.name.$model"
                    maxlength="150"
                />
                <rq-validation-feedback>Company Name is required</rq-validation-feedback>
            </div>
            <div class="col-3 form-group form-required" :class="{ 'has-error':v$.localInfo.regionID.$error}">
                <label for="drp_company_region">Region</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_company_region', id: 'drp_company_region' }"
                    :items="regions"
                    value-expr="regionID"
                    display-expr="displayName"
                    v-model="v$.localInfo.regionID.$model"
                    :disabled="!hasCompanyAccess || readOnly"
                    :search-enabled="false"
                />
                <rq-validation-feedback>Region is required</rq-validation-feedback>
            </div>
            <div class="col-3 form-group">
                <label for="txt_company_account_report_code">Account Report Code</label>
                <input id="txt_company_account_report_code"
                    automation_id="txt_company_account_report_code"
                    type="text"
                    class="form-control"
                    placeholder="Acct Report Code"
                    maxlength="15"
                    v-model="localInfo.accountReportCode"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-3 form-group">
                <rq-action-form-group
                    label="Address 1"
                    labelFor="txt_company_address1"
                    show-action>
                    <template #action>
                        <b-form-checkbox
                            id="chk_foreign"
                            automation_id="chk_company_foreign"
                            v-model="localInfo.isForeignAddress">Foreign Address
                        </b-form-checkbox>
                    </template>
                    <template #default>
                        <input id="txt_company_address1"
                            automation_id="txt_company_address1"
                            type="text"
                            class="form-control"
                            placeholder="Line 1"
                            v-model="localInfo.address1"
                        />
                    </template>
                </rq-action-form-group>
            </div>
            <div class="col-3 form-group">
                <label for="txt_company_address2">Address 2</label>
                <input id="txt_company_address2"
                    automation_id="txt_company_address2"
                    type="text"
                    class="form-control"
                    placeholder="Line 2"
                    v-model="localInfo.address2"
                />
            </div>
            <div class="col-3 form-group">
                <label for="txt_company_city">City</label>
                <input id="txt_company_city"
                    automation_id="txt_company_city"
                    type="text"
                    class="form-control"
                    placeholder="City"
                    v-model="localInfo.city"
                />
            </div>
            <div v-if="!localInfo.isForeignAddress" class="col-3 form-group">
                <label for="drp_company_state">State</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_company_state', id: 'drp_company_state' }"
                    :items="usStatesLookup"
                    value-expr="id"
                    display-expr="id"
                    v-model="localInfo.state"
                    :disabled="!hasCompanyAccess || readOnly"
                    :search-enabled="true"
                />
            </div>
            <div v-if="localInfo.isForeignAddress" class="col-3 form-group" >
                <label for="txt_company_regionTerritory">Region/Territory</label>
                <input id="txt_company_regionTerritory"
                    automation_id="txt_company_regionTerritory"
                    type="text"
                    class="form-control"
                    placeholder="Region/Territory"
                    maxlength="150"
                    v-model="localInfo.regionTerritory"
                >
            </div>
            <div class="col-3 form-group" :class="{'has-error' : v$.localInfo.zip.$error}">
                <label for="txt_company_zip">Zip</label>
                <input v-if="localInfo.isForeignAddress"
                    automation_id="txt_company_zip" 
                    placeholder="Zip"
                    :disabled="readOnly"
                    v-model="localInfo.zip"
                    maxlength="10"
                    class="form-control"
                />
                <rq-masked-input v-else
                    type="text"
                    automation_id="txt_company_zip"
                    placeholder="Zip"
                    :disabled="readOnly"
                    v-model="v$.localInfo.zip.$model"
                    cssClass="form-control"
                    maskType="zip"
                    is-masked
                />
                <rq-validation-feedback>Zip code format is invalid.</rq-validation-feedback>
            </div>

            <div class="col-3 form-group">
                <label for="txt_company_country">Country</label>
                <input id="txt_company_country"
                    automation_id="txt_company_country"
                    type="text"
                    class="form-control"
                    placeholder="Country"
                    v-model="localInfo.country"
                    :disabled="!localInfo.isForeignAddress"
                />
            </div>

            <div v-if="!localInfo.isForeignAddress" class="col-3 form-group">
                <label for="drp_county">County</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_county', id: 'drp_county' }"
                    :items="counties"
                    :disabled="!localInfo.state || !hasCompanyAccess || readOnly"
                    v-model="localInfo.countyID"
                    value-expr="id"
                    display-expr="name"
                    :search-enabled="true"
                />
            </div>
            <div class="col-3 form-group">
                <label for="txt_company_address_location">Address Location</label>
                <input id="txt_company_address_location"
                    automation_id="txt_company_address_location"
                    type="text"
                    class="form-control"
                    placeholder="Address Location"
                    v-model="localInfo.businessAddressLocation"
                />
            </div>
            <div class="col-3 form-group" :class="{ 'has-error':v$.localInfo.phone.$error }">
                <label for="txt_company_Phone">Phone</label>
                 <div class="input-group input-group-phone">
                    <rq-masked-input
                        id="txt_company_Phone_cc"
                        v-model="localInfo.phoneCountryCode"
                        :isMasked="true"
                        customMask="###"
                    />
                    <rq-masked-input id="txt_company_Phone"
                        placeholder="Phone"
                        maskType="phone"
                        isMasked="true"
                        v-model="v$.localInfo.phone.$model"
                    />
                </div>
                <rq-validation-feedback>Phone number format is invalid.</rq-validation-feedback>
            </div>
            <div class="col-3 form-group">
                <label for="txt_company_friendly_Name">Friendly Name</label>
                <input id="txt_company_friendly_Name"
                    automation_id="txt_company_friendly_Name"
                    type="text"
                    class="form-control"
                    placeholder="Friendly Name"
                    v-model="localInfo.friendlyName"
                    maxlength="50"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-3 form-group">
                <label for="txt_company_mailing_address">Mailing Address</label>
                <input id="txt_company_mailing_address"
                    automation_id="txt_company_mailing_address"
                    type="text"
                    class="form-control"
                    placeholder="Mailing Address"
                    v-model="localInfo.mailingAddress"
                />
            </div>
            <div class="col-3 form-group" v-if="!isConfigurationAndEscrowAccountCompany">
                <label for="txt_company_nmls_id">NMLS ID</label>
                <input id="txt_company_nmls_id"
                    automation_id="txt_company_nmls_id"
                    type="text"
                    class="form-control"
                    placeholder="NMLS ID"
                    v-model="localInfo.nmlsid"
                    maxlength="50"
                />
            </div>
            <div class="col-3 form-group" v-if="!isConfigurationAndEscrowAccountCompany">
                <label for="txt_company_license">License</label>
                <input id="txt_company_license"
                    automation_id="txt_company_license"
                    type="text"
                    placeholder="License"
                    class="form-control"
                    v-model="localInfo.stateLicenseID"
                    maxlength="50"
                />
            </div>
            <div class="col-3 form-group" v-if="!isConfigurationAndEscrowAccountCompany" :class="{ 'has-error':v$.localInfo.stateIssuedLicense.$error }">
                <label for="drp_company_state_issued">State Issued</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_company_state_issued', id: 'drp_company_state_issued' }"
                    :items="usStatesLookup"
                    value-expr="id"
                    display-expr="id"
                    v-model="v$.localInfo.stateIssuedLicense.$model"
                    :disabled="!hasCompanyAccess || readOnly"
                    :search-enabled="true"
                />
                <rq-validation-feedback>Only a single license per state is allowed</rq-validation-feedback>
            </div>
        </div>
        <div class="row">
            <div class="col-3 form-group" :class="{ 'has-error':v$.localInfo.tollFreeNumber.$error }">
                <label for="txt_company_toll_free_phone">Toll Free Phone</label>
                <div class="input-group input-group-phone">
                    <rq-masked-input
                        id="txt_company_toll_free_phone_cc"
                        v-model="localInfo.tollFreeNumberCountryCode"
                        :isMasked="true"
                        customMask="###"
                    />
                    <rq-masked-input id="txt_company_toll_free_phone"
                        placeholder="Toll Free Phone"
                        maskType="phone"
                        isMasked="true"
                        v-model="v$.localInfo.tollFreeNumber.$model"
                    />
                </div>
                <rq-validation-feedback>Toll Free Phone number format is invalid.</rq-validation-feedback>
            </div>
            <div class="col-3 form-group" :class="{ 'has-error':v$.localInfo.fax.$error }">
                <label for="txt_company_fax">Fax</label>
                 <div class="input-group input-group-phone">
                    <rq-masked-input
                        id="txt_company_fax_cc"
                        v-model="localInfo.faxCountryCode"
                        :isMasked="true"
                        customMask="###"
                    />
                    <rq-masked-input id="txt_company_fax"
                        placeholder="Fax"
                        maskType="phone"
                        isMasked="true"
                        v-model="v$.localInfo.fax.$model"
                    />
                </div>
                <rq-validation-feedback>Fax number format is invalid.</rq-validation-feedback>
            </div>
            <div class="col-3 form-group" :class="{ 'has-error':v$.localInfo.tollFreeFax.$error }">
                <label for="txt_company_toll_free_fax">Toll Free Fax</label>
                 <div class="input-group input-group-phone">
                    <rq-masked-input
                        id="txt_company_toll_free_fax_cc"
                        v-model="localInfo.tollFreeFaxCountryCode"
                        :isMasked="true"
                        customMask="###"
                    />
                    <rq-masked-input id="txt_company_toll_free_fax"
                        placeholder="Toll Free Fax"
                        isMasked="true"
                        maskType="phone"
                        v-model="v$.localInfo.tollFreeFax.$model"
                    />
                </div>
                <rq-validation-feedback>Toll Free Fax number format is invalid.</rq-validation-feedback>
            </div>
            <div class="col-3 form-group" >
                <label for="website">Website</label>
                <input id="txt_company_website"
                    automation_id="txt_company_website"
                    type="text"
                    class="form-control"
                    maxlength="1000"
                    placeholder="Website"
                    v-model="localInfo.webSite"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-3 form-group">
                <label for="txt_email_address">Email</label>
                <input id="txt_email_address"
                    automation_id="txt_email_address"
                    type="text"
                    class="form-control"
                    placeholder="Email Address"
                    v-model="localInfo.eMailAddress">
            </div>
            <div class="col-3 form-group">
                <label for="drp_company_sales_representative">Sales Representative</label>
                <dx-select-box
                    :input-attr="{ id:'drp_company_sales_representative', automation_id:'drp_company_sales_representative' }"
                    :items="salesRepsLookup"
                    :disabled="readOnly"
                    value-expr="id"
                    display-expr="name"
                    v-model="localInfo.salesRepresentativeStaffID"
                />
            </div>
            <div class="col-3 form-group" v-if="isSettlementAgent">
                <label for="txt_company_tax_id">Settlement Agent Tax ID</label>
                <input id="txt_company_tax_id"
                    automation_id="txt_company_tax_id"
                    type="text"
                    class="form-control"
                    placeholder="Tax ID"
                    maxlength="10"
                    v-model="localInfo.settlementAgentTaxID"
                />
            </div>
        </div>
    </fieldset>
</template>
<script>
    import { mapState, mapGetters } from "vuex";
    import { Company, LogoDto} from '@order-entry/contacts/models';
    import { RoleType } from "@/shared/models/enums";
    import { required } from '@vuelidate/validators';
    import { useVuelidate } from "@vuelidate/core";
    import { RegionDto }  from "../../../configuration/enterprise/models";

    //Since the phone numbers are being masked to be "(xxx) xxx-xxxx" and limiting character input, we simply have to validate the value's length (14 characters)
    const phoneNumberValid = value => _.isEmpty(value) || value.length === 14;

    export default {
        props: {
            info: { type: Object },
            locations: { type: Array },
            logo: { type: Object },
            configurationMode: { type: Boolean, default: false },
            hasCompanyAccess: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false },
            companyLicenses: { type: Array, default: () => new []() },
        },
        setup: () => ({ v$: useVuelidate() }),
        data: function() {
            return {
                localInfo: {},
                localLocations: [],
                localLogo: {},
                counties: []
            }
        },
        watch: {
            info: {
                handler: function(newValue, oldValue) {
                    if(_.isEqual(_.toPlainObject(newValue), _.toPlainObject(this.localInfo))) return;
                    this.localInfo = new Company(newValue);
                    this.getCounties();
                },
                immediate: true,
                deep: true
            },
            locations: {
                handler: function(newValue, oldValue) {
                    if(this.isListEqual(newValue, oldValue)) return;
                    this.localLocations = newValue;
                },
                deep: true
            },
            logo: {
                handler: function(newValue, oldValue) {
                    if(_.isEqual(_.toPlainObject(newValue), _.toPlainObject(oldValue))) return;
                    this.localLogo = new LogoDto(newValue);
                },
                deep: true
            },
            localInfo: {
                handler: function(newValue, oldValue) {
                    this.$emit('update:info', newValue);
                },
                deep: true
            },
            "localInfo.state": {
                handler: function(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    this.getCounties();
                }
            },
            localLocations: {
                handler: function(newValue, oldValue) {
                    this.$emit('update:locations', newValue);
                },
                deep: true
            },
            localLogo: {
                handler: function(newValue, oldValue) {
                    this.$emit('update:logo', newValue);
                },
                deep: true
            },
            "v$.localInfo.$error": {
                handler: function(newValue, oldValue){
                    this.$emit('validation-event', this.generateValidationData());
                }
            },
            "info.roleTypeID":{
                handler: function(newValue, oldValue){
                    if(this.localInfo.roleTypeID !== newValue)
                        this.localInfo.roleTypeID = newValue;
                }
            },
            "localInfo.settlementAgentTaxID":{
                handler: function(newValue, oldValue){
                    this.$emit('update:taxID', newValue);
                }
            }
        },
        validations() {
            return {
                localInfo: {
                    name: { required },
                    regionID: { required },
                    zip: {
                        zipFormatValid: value => _.isEmpty(value) || value.length === 5 || value.length === 10 || this.localInfo.isForeignAddress
                    },
                    phone: { phoneNumberValid },
                    tollFreeNumber: { phoneNumberValid },
                    fax: { phoneNumberValid },
                    tollFreeFax: { phoneNumberValid },
                    stateIssuedLicense: {
                        isValid(val) {
                            let states = [val, ...this.companyLicenses.map(cl => cl.state)].filter(state => !_.isNil(state));
                            return new Set(states).size === states.length;
                        },
                    }
                }
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "regionsLookup",
                "usStatesLookup",
                "currentSession"
            ]),
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                globalRegion: state => state.system.globalRegion
            }),
            roles() {
                return _.filter(this.lookupHelpers.getRoles([], null, null, true), item =>
                    !_.includes([RoleType.TitleCompany,RoleType.Underwriter,RoleType.Bank], item.id)
                    && (item.enabled || item.id === this.localInfo.roleTypeID)
                );
            },
            regions(){
                let regions = this.lookupHelpers.getRegions();
                if(this.localSecurity.EditCompaniesOutsideUsersRegion && !_.some(regions, r => r.regionID === this.globalRegionId)){
                    let regionsNew = [];
                    regionsNew.push(new RegionDto(this.globalRegion));
                    regions = regionsNew.concat(regions);
                }
                return regions;
            },
            salesRepsLookup(){
                return this.lookupHelpers.getOrderLookupItems(this.lookupItems.SALES_REPS, this.localInfo.salesRepresentativeStaffID);
            },
            localSecurity(){
                return this.securitySettings.findValues(["EditCompaniesOutsideUsersRegion"]);
            },
            isValid() { return !this.v$.localInfo.$error; },
            notesClass() { return this.localInfo.isPlaceOfClosing ? "col-3" : "col-9" },
            isConfigurationAndEscrowAccountCompany() { return this.configurationMode && _.get(this.localInfo, "roleTypeID", 0) === RoleType.Bank; },
            isSettlementAgent(){
                return _.get(this.localInfo, "roleTypeID", 0) === RoleType.SettlementAgent || (_.get(this.localInfo, "roleTypeID", 0) === RoleType.TitleCompany && this.localInfo.isSettlementAgent);
            },
            showRoleType() {
                let roleTypeID = _.get(this.localInfo, "roleTypeID", 0);
                return roleTypeID !== RoleType.TitleCompany && roleTypeID !== RoleType.Underwriter;
            }
        },
        created() {
            if (_.isNil(this.localInfo.regionID)) this.setDefaultRegion();
        },
        methods: {
            getCounties () {
                const self = this;
                if (!self.localInfo || !self.localInfo.state) return;
                self.counties = self.lookupHelpers.getCountiesByState(self.localInfo.state);
            },
            validate() {
                this.v$.$touch();
            },
            reset() {
                this.v$.$reset();
            },
            generateValidationData() {
                let validationErrors = [];

                return {
                    isValid: this.isValid
                };
            },
            onAddLocation(e) {
                this.localLocations.push({
                    itemID: this.generateRandomId(),
                    itemName: e.value
                });
            },
            onUpdateLocation(e) {
                e.commit(e.data);
            },
            onDeleteLocation(e) {
                e.commit();
            },
            generateRandomId() {
                return _.parseInt((_.uniqueId())) * -1;
            },
            isListEqual(list1, list2) {
                return _.isEqual(_.sortBy(list1), _.sortBy(list2))
            },
            setDefaultRegion() {
                this.localInfo.regionID = _.get(this.currentSession, "user.regionID", 0);
            }
        }
    }
</script>
