import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
getAll (buyerSellerID) {
    return HttpWrapper.get(`${endpoints.BUYER_SELLER_DOCUMENT}/${buyerSellerID}`, buyerSellerID)
},
save (buyerSellerDocument) {
    return HttpWrapper.put(`${endpoints.BUYER_SELLER_DOCUMENT}/save`, buyerSellerDocument);
},
deleteBuyerSellerDocument(buyerSellerDocumentIDs) {
    return HttpWrapper.post(`${endpoints.BUYER_SELLER_DOCUMENT}/delete`, buyerSellerDocumentIDs);              
},
getBuyerSellerDocumentFile(buyerSellerDocumentID){
    return HttpWrapper.post(`${endpoints.BUYER_SELLER_DOCUMENT}/buyerSellerDocument/${buyerSellerDocumentID}`)
},
uploadBuyerSellerDocument (formRequest) {
    return HttpWrapper.put(`${endpoints.BUYER_SELLER_DOCUMENT}/upload/`, formRequest, null);
},
}