import { defineAsyncComponent } from "vue";

const DataConversionWrapper = defineAsyncComponent(() => import(/* webpackChunkName: "data-conversion" */"./wrappers/DataConversionWrapper.vue"));
const WorkflowAutomationWrapper = defineAsyncComponent(() => import(/* webpackChunkName: "workflow-automation" */"./wrappers/WorkflowAutomationWrapper.vue"));
const CdfEditorWrapper = defineAsyncComponent(() => import(/* webpackChunkName: "cdf-editor" */"./wrappers/CdfEditorWrapper.vue"));
const CssEditorWrapper = defineAsyncComponent(() => import(/* webpackChunkName: "commercial-settlement-statement" */"./wrappers/CssEditorWrapper.vue"));
const ArcIntegrationWrapper = defineAsyncComponent(() => import(/*webpackChunkName: "arc-integration" */"./wrappers/ArcIntegrationWrapper.vue"));
const WorkDayIntegrationWrapper = defineAsyncComponent(() => import(/*webpackChunkName: "arc-integration" */"./wrappers/WorkDayIntegrationWrapper.vue"));
const PavasoTaggingWrapper = defineAsyncComponent(() => import(/*webpackChunkName: "pavaso-integration" */"./wrappers/PavasoTaggingWrapper.vue"));
/*
    Do not import from here if using as a route component.  Import the component directly as a dynamic import.
    https://router.vuejs.org/guide/advanced/lazy-loading.html
*/
export {
    DataConversionWrapper,
    WorkflowAutomationWrapper,
    CdfEditorWrapper,
    CssEditorWrapper,
    ArcIntegrationWrapper, 
    WorkDayIntegrationWrapper,
    PavasoTaggingWrapper
};