import { HttpWrapper } from '../shared/services/rest.service';
import * as endpoints from './endpoints';

export default {
getAll (regionID) {
    return HttpWrapper.get(`${endpoints.BUYER_SELLER_CONTACT}/getAll/${regionID}`, regionID)
},
getContact(id) {
    return HttpWrapper.get(`${endpoints.BUYER_SELLER_CONTACT}/${id}`, id)
},
activate (ids) {
    return HttpWrapper.post(`${endpoints.BUYER_SELLER_CONTACT}/activate`, ids);
},
saveBuyerSellerContact (buyerSellerContact) {
    return HttpWrapper.put(`${endpoints.BUYER_SELLER_CONTACT}/save`, buyerSellerContact);
},
}