
export class AddressModel {
    constructor (line1, line2, city, state, zip, country, isForeign, regionTerritory) {
        this.isDirty = false;
        this.line1 = line1;
        this.line2 = line2;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.country = country;
        this.isForeign = isForeign;
        this.regionTerritory = regionTerritory;
    }

    copy (address) {
        this.line1 = address.line1;
        this.line2 = address.line2;
        this.city = address.city;
        this.state = address.state;
        this.zip = address.zip;
        this.isForeign = address.isForeign;
        this.country = address.country;
        this.regionTerritory = address.regionTerritory;
        this.isDirty = true;
    }

    clear () {
        this.line1 = null;
        this.line2 = null;
        this.city = null;
        this.state = null;
        this.zip = null;
        this.country = null;
        this.regionTerritory = null;
        this.isForeign = false;
        this.isDirty = true;
    }
}

export class ContactModel {
    constructor (firstName, middleName, lastName, suffix, businessPhone, homePhone, mobilePhone, fax, email, gender, title, taxId, isGdpr) {
        this.isDirty = false;
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.suffix = suffix;
        this.businessPhone = businessPhone;
        this.homePhone = homePhone;
        this.mobilePhone = mobilePhone;
        this.fax = fax;
        this.email = email;
        this.gender = gender;
        this.title = title;
        this.taxId = taxId;
        this.isGdpr = isGdpr;
    }

    get fullName () {
        return _
            .chain([this.firstName, this.middleName, this.lastName, this.suffix])
            .tap(function (array) {_.remove(array, _.isEmpty);})
            .join(" ")
            .value();
    }

    clear () {
        this.firstName = null;
        this.middleName = null;
        this.lastName = null;
        this.suffix = null;
        this.gender = null;
        this.title = null;
        this.taxId = null;
        this.email = null;
        this.businessPhone = null;
        this.homePhone = null;
        this.mobilePhone = null;
        this.fax = null;
        this.isDirty = true;
    }
}

export class SignatureBlockModel {
    constructor (options) {
        options = options || {};
        this.ordersID = options.ordersID || 0;
        this.buyerSellerID = options.buyerSellerID || 0;
        this.signatureHtmlValue = options.signatureHtmlValue || "";
        this.signatureRtfValue = options.signatureRtfValue || "";
        this.signatureOpenXmlValue = options.signatureOpenXmlValue || null;
        this.signatureBlockOverride = options.signatureBlockOverride || false;
    }
}

export class BuyerSellerRelationship {
    get HusbandAndWife() { return 0; }
    get WifeAndHusband() { return 1; }
    get Partner() { return 3; }
    get MarriedCouple() { return 4; }
    get ASinglePerson() { return 5; }
    get BlankUnknown() { return 6; }
    get Partner2() { return 7; }
    get HusbandAndWife2() { return 8; }
    get HusbandAndHusband() { return 9; }
    get WifeAndWife() { return 10; }
}

export class BuyerSellerDocumentDto {
    constructor(options) {
        this.buyerSellerDocumentID = _.parseNumber(options.buyerSellerDocumentID, 0);
        this.buyerSellerID = _.parseNumber(options.buyerSellerID, 0);
        this.fileName = options.fileName || null;
        this.contentType = options.contentType || null;
        this.fileSizeBytes = _.parseNumber(options.fileSizeBytes, 0);
        this.effectiveDate = options.effectiveDate || null;
        this.modifiedDate = options.modifiedDate || null;
        this.modifiedBy = options.modifiedBy || null;
        this.modifiedByName = options.modifiedByName || null;
        this.expireOnDate = options.expireOnDate || null;
        this.fileScanDocumentDuplicateAction = _.parseNumber(options.fileScanDocumentDuplicateAction, 0);
    }
}
export class BuyerSellerDto {
	constructor(options) {
        options = options || {};
        this.ordersID = _.parseNumber(options.ordersID, 0);
		this.regionID = _.parseNumber(options.regionID, null);
		this.sequence = _.parseNumber(options.sequence, 999);

        this.attorneyID = _.parseNumber(options.attorneyID, null);
        this.attorney = options.attorney || null;
		this.attorneyContactID = _.parseNumber(options.attorneyContactID, null);
		this.attorneyContact = options.attorneyContact || null;

		this.businessName = options.businessName || null;
		this.buyerSellerID = _.parseNumber(options.buyerSellerID, null);
		this.buyerSellerRelationshipID = _.parseNumber(options.buyerSellerRelationshipID, null);
		this.buyerSellerRelationshipName = options.buyerSellerRelationshipName || null;
		this.buyerSellerType = _.parseNumber(options.buyerSellerType, null);
		this.cellPhone1 = options.cellPhone1 || null;
        this.cellPhoneCountryCode1 = options.cellPhoneCountryCode1 || null;
		this.cellPhone2 = options.cellPhone2 || null;
        this.cellPhoneCountryCode2 = options.cellPhoneCountryCode2 || null;
		this.currentAddress1 = options.currentAddress1 || null;
		this.currentAddress2 = options.currentAddress2 || null;
		this.currentCity = options.currentCity || null;
		this.currentCountry = options.currentCountry || null;
		this.currentState = options.currentState || null;
        this.currentRegionTerritory = options.currentRegionTerritory || null;
		this.currentZip = options.currentZip || null;
        this.emailAddress1 = options.emailAddress1 || null;
        this.emailAddress1Default = _.parseBool(options.emailAddress1Default, true);
		this.emailAddress2 = options.emailAddress2 || null;
		this.extension1 = options.extension1 || null;
		this.extension2 = options.extension2 || null;
		this.fax1 = options.fax1 || null;
        this.faxCountryCode1 = options.faxCountryCode1 || null;
		this.fax2 = options.fax2 || null;
        this.faxCountryCode2 = options.faxCountryCode2 || null;
		this.firstName1 = options.firstName1 || null;
		this.firstName2 = options.firstName2 || null;
		this.formalName = options.formalName || null;
		this.formalNameOverride = options.formalNameOverride || null;
		this.formalNameText = options.formalNameText || null;
		this.forwardingAddress1 = options.forwardingAddress1 || null;
		this.forwardingAddress2 = options.forwardingAddress2 || null;
		this.forwardingCity = options.forwardingCity || null;
		this.forwardingCountry = options.forwardingCountry || null;
		this.forwardingState = options.forwardingState || null;
        this.forwardingRegionTerritory = options.forwardingRegionTerritory || null;
		this.forwardingZip = options.forwardingZip || null;
		this.gFNo = options.gFNo || null;
		this.gender1 = _.parseNumber(options.gender1, null);
		this.gender1Name = options.gender1Name || null;
		this.gender2 = _.parseNumber(options.gender2, null);
		this.gender2Name = options.gender2Name || null;
		this.homePhone = options.homePhone || null;
        this.homePhoneCountryCode = options.homePhoneCountryCode || null;
		this.homePhone2 = options.homePhone2 || null;
        this.homePhoneCountryCode2 = options.homePhoneCountryCode2 || null;
        this.isInactive = _.parseBool(options.isInactive, false);
		this.isForeignAddress = _.parseBool(options.isForeignAddress, null);
		this.isForeignFowardingAddress = _.parseBool(options.isForeignFowardingAddress, null);
        this.isTransferorForeign = _.parseBool(options.isTransferorForeign, null);
		this.jobTitle1 = options.jobTitle1 || null;
		this.jobTitle2 = options.jobTitle2 || null;
		this.lastName1 = options.lastName1 || null;
		this.lastName2 = options.lastName2 || null;
		this.middleInitial1 = options.middleInitial1 || null;
		this.middleInitial2 = options.middleInitial2 || null;
		this.notaryStaffID = _.parseNumber(options.notaryStaffID, null);
		this.notaryStaffName = options.notaryStaffName || null;
		this.notes = options.notes || null;
        this.overrideAuto1099ProceedsDistribution = _.parseBool(options.overrideAuto1099ProceedsDistribution);

		this.realtorID = _.parseNumber(options.realtorID, null);
		this.realtor = options.realtor || null;
		this.realtorContactID = _.parseNumber(options.realtorContactID, null);
        this.realtorContact = options.realtorContact || null;

		this.salesPrice = _.parseNumber(options.salesPrice, null);
		this.signatureBlockOverride = options.signatureBlockOverride || null;
        this.exchangeSignatureBlockOverride = options.exchangeSignatureBlockOverride || null;
		this.socialSecurityNumber1 = options.socialSecurityNumber1 || null;
		this.socialSecurityNumber2 = options.socialSecurityNumber2 || null;
        this.taxNameText = options.taxNameText || null;
		this.ten99AllocationAmount = options.ten99AllocationAmount || null;
		this.ten99AllocationPercent = options.ten99AllocationPercent || null;
		this.ten99Box4 = options.ten99Box4 || null;
		this.ten99DoNotGenerateReport = options.ten99DoNotGenerateReport || null;
		this.ten99OverrideAutoProceedDistribution = options.ten99OverrideAutoProceedDistribution || null;
		this.useForwardingAddressfor1099 = options.useForwardingAddressfor1099 || null;
		this.vestingInformationID = _.parseNumber(options.vestingInformationID, null);
		this.vestingInformationName = options.vestingInformationName || null;
		this.workPhone1 = options.workPhone1 || null;
        this.workPhoneCountryCode1 = options.workPhoneCountryCode1 || null;
		this.workPhone2 = options.workPhone2 || null;
        this.workPhoneCountryCode2 = options.workPhoneCountryCode2 || null;
		this.isGdpr1 = _.parseBool(options.isGdpr1);
        this.isGdpr2 = _.parseBool(options.isGdpr2);
        this.taxIdVerificationDocumentId1 = options.taxIdVerificationDocumentId1 || null;
        this.taxIdVerificationDocumentId2 = options.taxIdVerificationDocumentId2 || null;
        this.taxIdVerificationStatus1 = options.taxIdVerificationStatus1 || null;
        this.taxIdVerificationStatus2 = options.taxIdVerificationStatus2 || null;
        this.tinType1 = _.parseNumber(options.tinType1,0);
        this.tinType2 = _.parseNumber(options.tinType2,0);
        this.isForeignID1 = _.parseBool(options.isForeignID1);
        this.isForeignID2 = _.parseBool(options.isForeignID2);
        this.nonResident = _.parseBool(options.nonResident);
        this.currentMilitary = _.parseBool(options.currentMilitary);
        this.forwardingMilitary = _.parseBool(options.forwardingMilitary);
        this.hasOutstandingChecks = _.parseBool(options.hasOutstandingChecks);
        this.parentBuyerSellerID = _.parseNumber(options.parentBuyerSellerID, null);
        this.updateTemplate = _.parseBool(options.updateTemplate, false);
    }

    get displayText () {
        if (this.formalName) {
            if (this.formalName.length > 30) return this.formalName.substring(0, 29); return this.formalName;
        }
        let type = this.buyerSellerType === 0 ? "Buyer" : "Seller";
        return this.formalName || `<Enter ${type} Information>`;
    }

    validate () {
        return _.isEmpty(this.businessName) && (_.isEmpty(this.firstName1) && _.isEmpty(this.lastName1))
            ? ["Entity Name or First and Last Names for Name1 is required"]
            : [];
    }

    getFullName (nameNum) {
        let fullNameParts = [this[`firstName${nameNum}`], this[`middleInitial${nameNum}`], this[`lastName${nameNum}`], this[`extension${nameNum}`]];
        return _.joinParts(fullNameParts, " ");
    }

    getDefaultFormalName(relationship, showRelation) {
        if(!_.isEmpty(this.businessName)) return this.businessName;

        if (!relationship || _.isNull(relationship.data)) {
            return _.isEmpty(this.fullName2)
                ? this.fullName1
                : `${this.fullName1} and ${this.fullName2}`;
        }
        else {
            let tags = relationship.data.split(",");
            return tags[0]
                    .replace("N1", `${this.fullName1}`)
                    .replace("N2", `${this.fullName2}`)
                    .concat(`${showRelation && tags[1] ? ",".concat(tags[1]) : ""}`);
        }
    }

    updateFormalName (relationship, showRelation) {
        if(this.formalNameOverride) return;
        this.formalName = this.getDefaultFormalName(relationship, showRelation);
    }

    get fullName1() { return this.getFullName(1); }
    get fullName2() { return this.getFullName(2); }

    get primaryAddress() {
        return new AddressModel(
            this.currentAddress1,
            this.currentAddress2,
            this.currentCity,
            this.currentState,
            this.currentZip,
            this.currentCountry,
            this.isForeignAddress,
            this.currentRegionTerritory
        );
    }
    set primaryAddress(val) {
        this.currentAddress1 = val.line1;
        this.currentAddress2 = val.line2;
        this.currentCity = val.city;
        this.currentState = val.state;
        this.currentZip = val.zip;
        this.currentCountry = val.country;
        this.isForeignAddress = val.isForeign;
        this.currentRegionTerritory = val.regionTerritory;
    }

    get forwardingAddress(){
        return new AddressModel(
            this.forwardingAddress1,
            this.forwardingAddress2,
            this.forwardingCity,
            this.forwardingState,
            this.forwardingZip,
            this.forwardingCountry,
            this.isForeignFowardingAddress,
            this.forwardingRegionTerritory
        );
    }
    set forwardingAddress(val){
        this.forwardingAddress1 = val.line1;
        this.forwardingAddress2 = val.line2;
        this.forwardingCity = val.city;
        this.forwardingState = val.state;
        this.forwardingZip = val.zip;
        this.forwardingCountry = val.country;
        this.isForeignFowardingAddress = val.isForeign;
        this.forwardingRegionTerritory = val.regionTerritory;
    }

    get attorneyCompany() {
        return {
            companyID: this.attorneyID,
            companyName: this.attorney,
            contactID: this.attorneyContactID,
            contactName: this.attorneyContact
        };
    }

    set attorneyCompany(val) {
        this.attorneyID = _.get(val, "companyID", null);
        this.attorney = _.get(val, "companyName", null);
        this.attorneyContactID = _.get(val, "contactID", null);
        this.attorneyContact = _.get(val, "contactName", null);
    }

    get realtorCompany() {
        return {
            companyID: this.realtorID,
            companyName: this.realtor,
            contactID: this.realtorContactID,
            contactName: this.realtorContact
        };
    }

    set realtorCompany(val) {
        this.realtorID = _.get(val, "companyID", null);
        this.realtor = _.get(val, "companyName", null);
        this.realtorContactID = _.get(val, "contactID", null);
        this.realtorContact = _.get(val, "contactName", null);
    }

    get isBuyer() { return this.buyerSellerType === 0; }

    get isSeller() { return this.buyerSellerType === 1; }

    toDataObject() { return _.toPlainObject(this); }
}
