<template>
    <rq-section-card title="Title Company" section-group="company-edit" collapsible>
        <fieldset :disabled="readOnly">
            <div class="row">
                <div class="col col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_alta_universal_id">ALTA Universal ID</label>
                    <input id="txt_alta_universal_id" automation_id="txt_alta_universal_id" type="text" class="form-control" placeholder="ALTA Universal ID" maxlength="7" v-model="altaID">
                </div>
                <div class="col col-12 col-md-6 col-lg-3 form-group">
                    <label for="txt_counter_signature">Authorized Signatory - Countersignature</label>
                    <input id="txt_counter_signature" automation_id="txt_counter_signature" type="text" class="form-control" placeholder="Contact Signature" maxlength="30" v-model="item.counterSignature">
                </div>
            </div>
            <rq-page-section title="1099 Information" borderless>
                <div class="row">
                    <div class="col col-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_tax_identification">Tax Identification</label>
                        <input id="txt_tax_identification" automation_id="txt_tax_identification" type="text" class="form-control" placeholder="Tax Identification" maxlength="10" v-model="item.taxIdentification">
                    </div>
                    <div class="col col-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_contact_1099">Contact 1099</label>
                        <input id="txt_contact_1099" automation_id="txt_contact_1099" type="text" class="form-control" placeholder="Contact 1099" maxlength="40" v-model="item.contact1099">
                    </div>
                    <div class="col col-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_payer_name_control">Payor Name Control</label>
                        <input id="txt_payer_name_control" automation_id="txt_payer_name_control" type="text" class="form-control" placeholder="Payor Name Control" maxlength="4" v-model="item.payorNameControl">
                    </div>
                    <div class="col col-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_transmitter_control_code">Transmitter Control Code</label>
                        <input id="txt_transmitter_control_code" automation_id="txt_transmitter_control_code" type="text" class="form-control" placeholder="TransmitterControlCode" maxlength="5" v-model="item.transmitterControlCode">
                    </div>
                </div>
                <div class="row">
                    <div class="col col-12 col-md-6 col-lg-3 offset-lg-3 form-group">
                        <label for="txt_contact_1099_email_address">Contact 1099 Email Address</label>
                        <input id="txt_contact_1099_email_address" automation_id="txt_contact_1099_email_address" type="text" class="form-control" placeholder="Contact 1099 Email Address" maxlength="150" v-model="item.contact1099EmailAddress">
                    </div>
                    <div class="col col-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_contact_1099_phone">Contact 1099 Phone</label>
                        <rq-masked-input id="txt_contact_1099_phone" placeholder="Contact 1099 Phone" maskType="phone" isMasked="true" maxlength="25" v-model="item.contact1099Phone" />
                    </div>
                    <div class="col col-12 col-md-6 col-lg-3 form-group">
                        <label for="txt_contact_1099_phone_ext">Contact 1099 Phone Ext</label>
                        <input id="txt_contact_1099_phone_ext" automation_id="txt_contact_1099_phone_ext" type="text" class="form-control" placeholder="Contact 1099 Phone Ext" maxlength="20" v-model="item.contact1099PhoneExt">
                    </div>
                </div>
            </rq-page-section>
        </fieldset>
        <schedule-d-section
            entity-name="Title Company"
            v-model:rtf="item.scheduleD"
            v-model:html="item.scheduleDHtml"
            :read-only="readOnly"
        />
    </rq-section-card>
</template>
<script>
    import { TitleCompany } from "@order-entry/contacts/models";
    import ScheduleDSection from "./ScheduleDSection";

    export default {
        name: "TitleCompanyInfo",
        props: {
            item: { type: Object, default: () => new TitleCompany() },
            altaUniversalId: {type: String, default: null},
            readOnly: { type: Boolean, default: false }
        },
        components: {
            ScheduleDSection
        },
        data: function() {
            return {
                altaID: "",
            }
        },
        watch: {
            altaID: {
                handler() {
                    this.$emit('update:alta-universal-id', this.altaID);
                },
            },
            'item.taxIdentification': {
                handler() {
                    this.$emit('update:taxID', this.item.taxIdentification);
                },
            }
        },
        created() {
            this.altaID = this.altaUniversalId;
        }
    }
</script>

