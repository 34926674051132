<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        @delete="onDelete"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        v-model:validation-errors="validationErrors"
        @rowValidating="onRowValidating"
        :strikethrough-if-true="['inactive']"
        hide-show-column-chooser
        rq-editable
        integrated-search
        focus-after-insert="none"
        rq-filters
        fixed-header
    />
</template>

<script>
    import { mapGetters} from "vuex";
    import {
        BiReportTreeItem,
        ExagoPermissionType,
        ExagoPartyType,
        ExagoContentAccessDto,
    } from "../models.js";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name: "BiReportPermissionGrid",
        mixins: [ GridInvokerMixin({ grid: "dataGrid" })],
        props: {
            modelValue: { type: Object, default: () => (new BiReportTreeItem())},
            lookupUsers: { type: Array, default: () => [] },
            lookupGroups: { type: Array, default: () => [] },
            availablePermissions: { type: Array, default: () => [] },
        },
        data () {
            return {
                validationErrors: [],
            };
        },

        computed: {
            ...mapGetters([
                "isDevelopment"
            ]),
            availableParties() {
                // Filtering for what a user SEES and is able to add/edit.
                let base = _.cloneDeep(ExagoPartyType.lookupItems);
                let allowedParties = [ExagoPartyType.User, ExagoPartyType.Group];
                if (this.isDevelopment) {
                    allowedParties.push(ExagoPartyType.Everyone);
                }

                return _.filter(base, (item) => {
                    return _.includes(allowedParties, item.id);
                });
            },
            grid() { return _.get(this, "$refs.dataGrid", null) || {}; },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            hasPermissions() {
                return _.get(this.modelValue, 'permissions.length', 0) > 0;
            },
            selectionActions() {
                const self = this;
                return [
                    {
                        name: 'delete',
                        text: 'Delete',
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        disabled: function(e) {
                            if (self.isDevelopment && self.modelValue.permissions.length == e.data.length) {
                                return 'Cannot remove all permissions.';
                            }
                            else if (_.some(e.data, (item) => item.party_type_id == ExagoPartyType.Everyone)) {
                                return 'Cannot delete the "Everyone" record.';
                            }
                            return false;
                        }
                    }
                ]
            },
            usersAndGroups() {
                return _.concat(this.lookupGroups, this.lookupUsers)
            }
        },

        watch: {
            modelValue: {
                handler(newValue, oldValue) {
                    const self = this;
                    if (newValue != oldValue) {
                        self.refresh();
                    }
                },
                immediate: true,
            },
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },

        created() {
            const self = this;
            self.initGridConfig();
        },

        methods: {

            onRowValidating(e){
                const self = this;
                if (e.isValid) {
                    self.validationErrors = [];
                }
            },

            onDelete(e) {
                const self = this;

                _.forEach(e.data, (toDelete) => {
                    _.remove(self.modelValue.permissions, (item) => item.key == toDelete.key)
                })

                self.flagChange();

                self.invokeGridMethod('refresh');
                self.invokeGridMethod('updateDimensions');
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            refresh() {
                const self = this;

                if (!_.isEmpty(self.modelValue.permissions)
                    || _.getBool(self, 'modelValue.isModified', false))
                {
                    self.invokeGridMethod('refresh');
                    return;
                }

                self.$api.ExagoApi.getPermissions(self.modelValue.contentId, self.modelValue.parentContentId)
                    .then((results) => {
                        self.modelValue.isModified = false;
                        self.modelValue.permissions = _.map(results, (item) => new ExagoContentAccessDto(item));
                    })
                    .then(() => {
                        self.invokeGridMethod('refresh');
                        self.$emit('refresh');
                    });
            },

            partyRequired(item) {
                let partyTypeId = item.data.party_type_id;
                if (_.isNil(partyTypeId) || partyTypeId === ExagoPartyType.Everyone) return true;

                let calc = (_.get(item, 'data.party_id') || '') !== ''

                return calc;
            },

            isDuplicateParty(item) {
                const self = this;

                let keyValue = _.get(item, 'data.key');
                let partyTypeId = item.data.party_type_id;

                let calc = partyTypeId === ExagoPartyType.Everyone
                    ? !self.partyTypeExists(keyValue, item.data)
                    : true;

                return calc;
            },

            isDuplicatePartyIdentifiers(item) {
                const self = this;

                let keyValue = _.get(item, 'data.key');
                let partyTypeId = item.data.party_type_id;

                let calc = _.isNil(partyTypeId) || partyTypeId === ExagoPartyType.Everyone
                    ? !self.partyTypeExists(keyValue, item.data)
                    : !self.itemExists(keyValue, item.data);

                return calc;
            },

            initGridConfig(){
                const self = this;

                self.gridConfig = {
                    focusedRowEnabled: false,
                    sorting: { mode: 'single', showSortIndexes: false },
                    columns: [
                        {
                            caption: "Role Type",
                            dataField: "party_type_id",
                            dataType: "number",
                            sortIndex: 0,
                            sortOrder: "asc",
                            lookup: {
                                dataSource: self.availableParties,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            setCellValue: function(rowData, data) {
                                if (rowData.party_type_id !== data) rowData.party_id = null;

                                rowData.party_type_id = data;
                            },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: 'custom',
                                    message: 'An item matching this permission level already exists.',
                                    validationCallback: self.isDuplicateParty,
                                },
                            ],
                        },
                        {
                            caption: "User / Group",
                            dataField: "party_id",
                            dataType: "number",
                            sortIndex: 1,
                            sortOrder: "asc",
                            rqFilter: {
                                displayExpr: "displayName",
                                valueExpr: "usersID",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.usersAndGroups;
                                    }
                                }
                            },
                            lookup: {
                                dataSource: DxGridUtils.lookupDataSource({
                                    store: self.usersAndGroups,
                                    sort: 'displayName',
                                    filter: (e, rowData, lookupItemData) => {
                                        let partyId = _.get(rowData, 'party_id');
                                        let partyTypeId = _.get(rowData, 'party_type_id');
                                        return lookupItemData.type == partyTypeId && (!lookupItemData.isInactive || lookupItemData.usersID == partyId);
                                    }
                                }),
                                displayExpr: "displayName",
                                valueExpr: "usersID",
                            },
                            ...DxGridUtils.lookupSortDisplayExpr,
                            validationRules: [
                                {
                                    type: 'custom',
                                    message: 'This value is required (except for Everyone).',
                                    validationCallback: self.partyRequired,
                                },
                                {
                                    type: 'custom',
                                    message: 'An item matching this permission level already exists.',
                                    validationCallback: self.isDuplicatePartyIdentifiers,
                                },
                            ],
                        },
                        {
                            dataField: "accessBits",
                            caption: "Permissions",
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "id",
                                filterType:"tags",
                                listOperator: "and",
                                valueOperator:"contains",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.availablePermissions;
                                    }
                                }
                            },
                            calculateSortValue: rowData => {
                                let flag = _.get(rowData, 'access_flags', 0);

                                let matches = _.map(ExagoPermissionType.toList(flag), (item) => (item.name));

                                let displayText = _.joinParts(matches, ", ");

                                return displayText;
                            },
                            // width: 200,
                            // minWidth: 100,
                            cellTemplate: function(cellElement, cellInfo) {
                                let flag = _.get(cellInfo, 'data.access_flags', 0);

                                let matches = _.map(ExagoPermissionType.toList(flag), (item) => (item.name));

                                let displayText = _.joinParts(matches, ", ");
                                let displayTitle = matches.length === 1
                                    ? displayText
                                    : `${matches.length} Permission(s) Selected`;

                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayTitle)
                                    .append(displayText)
                                    .appendTo(cellElement);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTagBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return Promise.resolve(self.availablePermissions);
                                        }
                                    },
                                    onItemClick: function(e) {
                                    },
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    multiline: false,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    searchEnabled: true,
                                    maxDisplayedTags: 100,
                                    onValueChanged: function(e) {
                                        let cellValue = _.cloneDeep(e.value);
                                        cellInfo.setValue(cellValue);
                                    }
                                }).appendTo(cellElement);
                            }
                        },
                    ],
                    onInitNewRow: e => {
                        let item = new ExagoContentAccessDto({
                            content_id: self.modelValue.contentId,
                            parent_id: self.modelValue.parentContentId,
                        })
                        item.applyTo(e.data, ['party_type_id', 'party_id']);
                    },
                    onEditorPreparing: e => {
                        if (e.parentType === "dataRow") {
                            if (e.dataField === "party_id") {
                                e.editorOptions.disabled = _.get(e, 'row.data.party_type_id', 1) === ExagoPartyType.Everyone;
                            }
                        }
                    }
                };
                self.gridDataSource = {
                    loadMode: "raw",
                    key: "key",
                    load (loadOptions) {
                        return Promise.resolve(_.get(self.modelValue, 'permissions', []));
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            partyTypeExists(key, data) {
                const self = this;

                return _.some(self.modelValue.permissions, (item) => {
                    return item.key !== key &&
                        item.party_type_id == data.party_type_id
                 });
            },

            itemExists(key, data) {
                const self = this;

                return _.some(self.modelValue.permissions, (item) => {
                    return item.key !== key &&
                        item.party_type_id == data.party_type_id &&
                        item.party_id == data.party_id;
                 });
            },

            addNewRow() {
                const self = this;
                self.$refs.dataGrid.setAddRow()
                    .then((response) => {})
            },

            save() {
                const self = this;
                self.validationErrors = [];

                if (!self.gridInstance) return Promise.resolve(true);

                if (!self.gridInstance.hasEditData()) {
                    return Promise.resolve(true);
                }

                return self.gridInstance.saveEditData()
                    .then(() => {
                        if (!self.grid.isValid) return false;
                        return true;
                    });

                //return self.$refs.dataGrid.setEditRow(0);
            },

            onGridInsert(values) {
                const self = this;

                let flags = _.cloneDeep(values.accessBits);
                let newItem = new ExagoContentAccessDto(values);

                newItem.setPermissionsByList(flags);

                self.modelValue.permissions.push(newItem);

                self.validationErrors = [];
                self.flagChange();

                return true;
            },

            flagChange() {
                const self = this;

                self.$emit('modified', { obj: self.modelValue });
            },

            onGridUpdate(key, values) {
                const self = this;

                let item = _.find(self.modelValue.permissions, c => c.key === key);
                _.assign(item, values);

                // Do this because we have been adjusting the bits so we have to derive the bitmask.
                item.setPermissionsByList(item.accessBits);

                self.validationErrors = [];
                self.flagChange();

                return true;
            },
        }
    };
</script>