<template>
    <div class="content-wrapper">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner && v$.$error"
            dismissable
        />
        <rq-banner
            message="Not authorized to edit note after 5 minutes."
            variant="warning"
            icon="fas fa-exclamation-circle"
            :visible="showRestrictedWarning"
        />
        <div class="order-note-form">
            <div class="row">
                <div class="col-1 form-group">
                    <label class="form-control-label" for="drp_priority">Priority</label>
                    <dx-select-box
                        :input-attr="{ id: 'drp_priority', automation_id: 'drp_priority' }"
                        :items="priorityItems"
                        value-expr="id"
                        display-expr="name"
                        placeholder=""
                        class="form-control"
                        :disabled="!hasEditPermission"
                        item-template="item"
                        field-template="field"
                        :search-enabled="false"
                        v-model="note.priority">
                        <template #item="{ data }">
                            <div>
                                <div :class="`note-priority-option note-priority-${data.id}`">
                                    <FontAwesomeIcon :icon="data.icon" class="note-priority-icon"/>
                                    <span class="note-priority-label">{{ data.name }}</span>
                                </div>
                            </div>
                        </template>
                        <template #field="{ data }">
                            <div>
                                <div :class="`note-priority-option note-priority-selected note-priority-${data.id}`">
                                    <FontAwesomeIcon :icon="data.icon" class="note-priority-icon"/>
                                    <span class="note-priority-label">{{ data.name }}</span>
                                </div>
                                <dx-text-box class="d-inline-block w-100" :read-only="true" />
                            </div>
                        </template>
                    </dx-select-box>
                </div>
                <div class="col-3 form-group">
                    <label for="drp_note_category">Category</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_note_category', id: 'drp_note_category' }"
                        :items="noteCategories"
                        :defaultItemEnabled="false"
                        :disabled="!hasEditPermission"
                        v-model="note.category"
                        value-expr="id"
                        display-expr="name"
                        :search-enabled="true"
                        @valueChanged="onChanged"
                    />
                </div>
                <!-- <div class="col-2 form-group">
                    <b-form-checkbox automation_id="chk_publish" class="publish-note-check" v-model="note.publish" :disabled="!hasEditPermission">Publish</b-form-checkbox>
                </div> -->
                <div v-if="note.priority == 0" class="col-2 form-group" style="margin-top:27px">
                    <b-form-checkbox v-model="note.isCriticalNote" switch :disabled="!hasEditPermission">Critical Note</b-form-checkbox>
                </div>
                <div v-if="!note.isNew" class="col-3 ms-auto form-group">
                    <label class="form-control-label" for="drp_priority">Created By</label>
                    <input type="text" class="form-control" v-model="note.fullName" readonly>
                </div>
                <div class="col-3 ms-auto form-group">
                    <label class="form-control-label" for="drp_priority">Date/Time Created</label>
                    <input type="text" class="form-control" :value="formattedCreatedDate" readonly>
                </div>
            </div>
            <div class="row note-input-row">
                <div class="col col-12 form-group form-required" :class="{'has-error' : v$.notes.$error}">
                    <label class="form-control-label" for="ta_notes">Note</label>
                    <textarea
                        automation_id="ta_notes"
                        ref="noteTextArea"
                        class="form-control note-input"
                        :disabled="!hasEditPermission"
                        v-model="notes">
                    </textarea>
                    <rq-validation-feedback
                        :container="`#${dialogId}`"
                        :boundary="`#${dialogId}`">Note text is required</rq-validation-feedback>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { useVuelidate } from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import { dateTimeCalculated } from "@/shared/utilities/VueHelpers";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { ORDER_ACTIONS } from '@/store/actions';
    import { OrderNoteCategory } from '@/shared/models/enums';
    import { PriorityType } from './enums';
    import { DateTime } from "luxon";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: "AddEditOrderNote",
        props: {
            note: { type: Object, required: true }
        },
        inject: ["dialogId"],

        setup: () => ({ v$: useVuelidate() }),

        data () {
            const self = this;
            return {
                notes: self.note.notes,
                showBanner: false,
                priorityItems: PriorityType.lookupItems
            };
        },
        watch:{
            "note.priority":{
                handler: function(newValue, oldValue) {
                    if(newValue === oldValue) return;
                    if(newValue != 0){
                        this.note.isCriticalNote = false;
                    }
                }
            },
        },

        validations: () => ({ notes: { required } }),

        computed:{
            ...mapState({
                user: state => state.authentication.session.user,
                isOrderLocked: state => state.orders.orderSummary.isLocked,
                fileInUse: state => state.orders.orderSummary.isFileInUse,
                order: state => state.orders.order
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            localSecurity(){
                let settings = this.securitySettings.findValues(["OrderNotesSecurity","Notes_ScreenAccess"]);
                let mapVals = (setting,keys) => _.zipObject(keys, _.map(keys, (val,idx) => idx === _.parseNumber(setting, 2)));
                return {
                    OrderNotesSecurity: mapVals(settings.OrderNotesSecurity, ["Limited","UserOnly","Any"]),
                    Notes_ScreenAccess: mapVals(settings.Notes_ScreenAccess, ["None","ReadOnly","Full"])
                };
            },
            readOnly() { return this.isOrderLocked || this.fileInUse || !this.localSecurity.Notes_ScreenAccess.Full; },
            isUserNote() { return this.note.usersID === this.user.usersID; },
            editWindowExpired() { return DateTimeHelper.diff(DateTime.now(), DateTimeHelper.getDateFromString(this.calcNoteDate), "minutes").minutes > 5; },
            hasEditPermission() {
                return !this.readOnly && !this.note.isSystemGen && (
                    this.note.isNew || (
                        this.localSecurity.OrderNotesSecurity.Any || (
                            this.isUserNote && (
                                this.localSecurity.OrderNotesSecurity.UserOnly || !this.editWindowExpired
                            )
                        )
                    )
                );
            },
            ...dateTimeCalculated({ field: { calcNoteDate: 'note.nDate' }}),
            formattedCreatedDate() { return DateTimeHelper.getDateFromString(this.calcNoteDate).toLocaleString(DateTime.DATETIME_SHORT); },
            showRestrictedWarning() { return this.localSecurity.OrderNotesSecurity.Limited && this.isUserNote && this.editWindowExpired && !this.note.isSystemGen; },
            noteCategories(){ return this.lookupHelpers.getLookupItems(this.lookupItems.NOTE_CATEGORIES); },
        },

        mounted() {
            _.delay(() => {
                _.invoke(this, "$refs.noteTextArea.focus");
            }, 300);
        },

        methods: {
            validate() {
                const self = this;
                self.v$.$touch();
                self.showBanner = true;
                return !self.v$.$error;
            },
            getPriorityCss(priorityId){
                return PriorityType.getCssClass(priorityId)
            },
            onChanged(e){
                e.component.option("value", e.value || OrderNoteCategory.Order);
            },
            save() {
                const self = this;
                if(!self.hasEditPermission) return Promise.resolve(true);

                if(!self.validate()) return Promise.resolve(false);
                self.note.notes = self.notes;

                let apiPromise = self.$api.OrdersApi.saveOrderNote(self.note);
                return self.$rqBusy.wait(apiPromise)
                    .then(r => {
                        self.$store.dispatch(ORDER_ACTIONS.GET_ORDER_DATA, { orderId: self.order.ordersID, refresh: true });
                        self.$store.dispatch(ORDER_ACTIONS.ADD_ORDER_CRITICAL_NOTE, self.note);
                        self.$toast.success("Note saved successfully.");
                        return true;
                    })
                    .catch(err => {
                        console.error(err);
                        self.$toast.error("Note save failed.");
                    });
            }
        }
    };
</script>
