import { RoleType } from "@/shared/models/enums";
import { ImageDataModel, ListBoxItemModel} from "@/shared/models/models";
import { PositivePay } from '../../../escrow-accounting/models';

const resolveCompanyDetail = (data, detailPropName) => {
    if(!_.has(data, detailPropName)) return data || {};

    let companyID = _.getNumberFrom(data, ["companyID", "companyInfo.companyID"], 0);
    let detail = _.get(data, detailPropName, null);
    if(_.isNil(detail)) return { companyID };

    let detailCompanyId = _.getNumber(detail, "companyID", 0);
    if(detailCompanyId > 0) return detail;

    detail.companyID = companyID;
    return detail;
};

export class OrderContact {
    constructor (options) {
        options = options || {};
        this.rolesID = options.rolesID || null;
        this.companyID = options.companyID || null;
        this.contactID = options.contactID || null;
        this.companyName = options.companyName;
        this.contactName = options.contactFullName;
        this.roleName = options.roleName;
        this.roleTypeID = options.roleTypeID || null;
        this.buyerSellerID = options.buyerSellerID || null;
        this.role = options.role || null;
        this.ordersID = options.ordersID || null;
        this.relationship = options.relationship || null;
        this.tableID = options.tableID || null;
        this.pcEnableAccess = options.pcEnableAccess || false;
        this.pcDisplayInContacts = options.pcDisplayInContacts || false;
        this.pcEmailEvents = options.pcEmailEvents || false;
        this.pcEmailDocumentNotifications = options.pcEmailDocumentNotifications || false;
        this.pcEnableOrderNotes = options.pcEnableOrderNotes || false;
        this.pcEnableWorkflow = options.pcEnableWorkflow || false;
        this.pcEnableReceiptsDisbursements = options.pcEnableReceiptsDisbursements || false;
        this.pcEnablePropertyInformation = options.pcEnablePropertyInformation || false;
        this.pcEnableDocuments = options.pcEnableDocuments || false;
        this.pcOverrideContact = options.pcOverrideContact || "";
        this.pcOverrideEmail = options.pcOverrideEmail || "";

        this.cdfRolesPCAccess = new CDFRolesPCAccess(options.cdfRolesPCAccess);
        this.cdfRolesPCAccess.rolesID = this.rolesID;

        this.company = new Company({
            companyID: options.companyID,
            roleTypeID: options.companyRoleTypeID,
            name: options.companyName,
            friendlyName: options.companyFriendlyName,
            accountReportCode: options.companyAccountReportCode,
            address1: options.companyAddress1,
            address2: options.companyAddress2,
            city: options.companyCity,
            state: options.companyState,
            zip: options.companyZip,
            countyID: options.companyCountyID,
            county: options.companyCounty,
            eMailAddress: options.companyEmailAddress,
            mailingAddress: options.companyMailingAddress,
            businessAddressLocation: options.companyBusinessAddressLocation,
            regionID: options.companyRegionID,
            region: options.companyRegion,
            phone: options.companyPhone,
            tollFreeNumber: options.companyTollFreeNumber,
            fax: options.companyFax,
            tollFreeFax: options.companyTollFreeFax,
            webSite: options.companyWebSite,
            isPlaceOfClosing: options.companyIsPlaceOfClosing,
            isOneTimeUse: options.companyIsOneTimeUse,
            isPreferred: options.companyIsPreferred,
            isInactive: options.companyIsInactive,
            isArchived: options.companyIsArchived,
            isSettlementAgent: options.companyIsSettlementAgent,
            isServiceProvider: options.companyIsServiceProvider,
            nMLSID: options.companyNMLSID,
            stateLicenseID: options.companyStateLicenseID,
            stateIssuedLicense: options.companyStateIssuedLicense,
            notes: options.companyNotes
        });

        this.contact = new Contact({
            contactID: options.contactID,
            companyID: options.companyID,
            companyName: options.companyName,
            fullName: options.contactFullName,
            firstName: options.contactFirstName,
            lastName: options.contactLastName,
            jobTitle: options.contactJobTitle,
            address1: options.contactAddress1,
            address2: options.contactAddress2,
            city: options.contactCity,
            state: options.contactState,
            zip: options.contactZip,
            emailAddress: options.contactEmailAddress,
            phone: options.contactPhone,
            phoneExtension: options.contactPhoneExtension,
            direct: options.contactDirect,
            home: options.contactHome,
            fax: options.contactFax,
            cellPhone: options.contactCellPhone,
            other: options.contactOther,
            contactNMLSID: options.contactNMLSID,
            contactStateLicenseID: options.contactStateLicenseID,
            stateIssuedContactLicense: options.stateIssuedContactLicense,
            isPreferred: options.contactIsPreferred,
            isInactive: options.contactIsInactive,
            isDefaultServiceContract: options.contactIsDefaultServiceContact,
            agentSelect: options.contactAgentSelect,
            paperlessCloserAccessID: options.contactPaperlessCloserAccessID,
            cdfPaperlessCloserAccessID: options.contactCDFPaperlessCloserAccessID,
            notes: options.companyNotes
        });
    }

    get phone () {
        if (this.contact.businessPhone) {return this.contact.businessPhone;}  return this.company.phone;
    }

    get address () {
        let address = "";
        if (this.role === "Buyer" || this.role === "Seller") {
            address = `${this.contact.address1 !== null ? this.contact.address1 : ""} ${this.contact.city !== null ? this.contact.city : ""}, ${this.contact.state !== null ? this.contact.state : ""} ${this.contact.zip !== null ? this.contact.zip : ""}`;
        } else {
            address = `${this.company.address1 !== null ? this.company.address1 : ""} ${this.company.city !== null ? this.company.city : ""}, ${this.company.state !== null ? this.company.state : ""} ${this.company.zip !== null ? this.company.zip : ""}`;
        }
        return address.trim() === "," ? "" : address;
    }

    updateCompanyInfo (obj, deep = false) {
        if (!obj) return;
        if (obj.companyID) {this.companyID = obj.companyID;}
        if (obj.name) {this.companyName = obj.name;}
        if (deep) {_.assign(this.company, obj);}
    }

    updateContactInfo (obj, deep = false) {
        if (!obj) return;
        if (obj.contactID) {this.contactID = obj.contactID;}
        if (obj.fullName) {this.contactName = obj.fullName;}
        if (deep) {_.assign(this.contact, obj);}
    }
}

export class Company {
    constructor (options) {
        options = options || {};
        this.companyID = _.parseNumber(options.companyID, 0);
        this.roleTypeID = _.parseNumber(options.roleTypeID, null);
        this.role = options.role || null;
        this.name = options.name || null;
        this.friendlyName = options.friendlyName || null;
        this.accountReportCode = options.accountReportCode || null;
        this.isForeignAddress = _.parseBool(options.isForeignAddress, false);
        this.address1 = options.address1 || null;
        this.address2 = options.address2 || null;
        this.city = options.city || null;
        this.state = options.state || null;
        this.zip = options.zip || null;
        this.countyID = _.parseNumber(options.countyID, null);
        this.county = options.county || null;
        this.regionTerritory = options.regionTerritory || null;
        this.country = options.country || null;
        this.businessAddressLocation = options.businessAddressLocation || null;
        this.eMailAddress = options.eMailAddress || null;
        this.mailingAddress = options.mailingAddress || null;
        this.nmlsid = options.nmlsid || null;
        this.salesRepresentativeStaffID = _.parseNumber(options.salesRepresentativeStaffID, null);
        this.stateLicenseID = options.stateLicenseID || null;
        this.stateIssuedLicense = options.stateIssuedLicense || null;
        this.regionID = options.regionID || null;
        this.regionDisplay = options.regionDisplay || null;
        this.phone = options.phone || null;
        this.phoneCountryCode = options.phoneCountryCode || null;
        this.tollFreeNumber = options.tollFreeNumber || null;
        this.tollFreeNumberCountryCode = options.tollFreeNumberCountryCode || null;
        this.fax = options.fax || null;
        this.faxCountryCode = options.faxCountryCode || null;
        this.settlementAgentTaxID = options.settlementAgentTaxID || null;
        this.tollFreeFax = options.tollFreeFax || null;
        this.tollFreeFaxCountryCode = options.tollFreeFaxCountryCode || null;
        this.webSite = options.webSite || null;
        this.isPlaceOfClosing = options.isPlaceOfClosing || false;
        this.isOneTimeUse = _.parseBool(options.isOneTimeUse, false);
        this.isPreferred = _.parseBool(options.isPreferred, false);
        this.isInactive = _.parseBool(options.isInactive, false);
        this.isArchived = _.parseBool(options.isArchived, false);
        this.isSettlementAgent = _.parseBool(options.isSettlementAgent, false);
        this.isServiceProvider = _.parseBool(options.isServiceProvider, false);
        this.notes = options.notes || null;
        this.altaUniversalId = options.altaUniversalId || null;
        this.logoData = new ImageDataModel(options.logoData);
        // this.placeOfClosingLocations = options.placeOfClosingLocations;
        this.friendlyName = options.friendlyName || null;
        this.isDirty = false;
    }

    get companyIDDisplay () {
        return this.companyID === 0 ? "" : this.companyID;
    }
}

export class CompanyAddress {
    constructor (options) {
        options = options || {};
        this.companyAddressID = _.parseNumber(options.companyAddressID, 0) || options.companyAddressID;
        this.companyID = _.parseNumber(options.companyID, null);
        this.address1 = options.address1 || null;
        this.address2 = options.address2 || null;
        this.addressTypeID = _.parseNumber(options.addressTypeID, null);
        this.addressTypeName = options.addressTypeName || null;
        this.city = options.city || null;
        this.state = options.state || null;
        this.zip = options.zip || null;
        this.countyID = _.parseNumber(options.countyID, null);
        this.countyName = options.countyName || null;        
        this.isVerified = _.parseBool(options.isVerified, false);
        this.isUpdated = _.parseBool(options.isUpdated || false);
        this.isDeleted = _.parseBool(options.isDeleted || false);
        this.isNew = _.parseBool(options.isNew || false);
    }

    get validationErrors() {

        let errorList = [];
        if(_.isNil(this.addressTypeID)) {
            errorList.push("Address Type is required.")
        }
        if (_.isEmpty(this.address1)) {
            errorList.push("Address1 is required.");
        }
        if(_.isEmpty(this.city)) {
            errorList.push("City is required.");
        }
        if(_.isEmpty(this.state)) {
            errorList.push("State is required.");
        }
        if(_.isEmpty(this.zip)) {
            errorList.push("Zipcode is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class CompanyLicense {
    constructor (options) {
        options = options || {};
        this.companyLicenseID = _.parseNumber(options.companyLicenseID, 0) || options.companyLicenseID;
        this.companyID = _.parseNumber(options.companyID, null);
        this.licenseNumber = options.licenseNumber || null;
        this.expirationDate = options.expirationDate || null;
        this.state = options.state || null;
        this.isUpdated = _.parseBool(options.isUpdated || false);
        this.isDeleted = _.parseBool(options.isDeleted || false);
        this.isNew = _.parseBool(options.isNew || false);
    }

    get validationErrors() {

        let errorList = [];

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class ContactLicense {
    constructor (options) {
        options = options || {};
        this.contactLicenseID = _.parseNumber(options.contactLicenseID, 0) || options.contactLicenseID;
        this.contactID = _.parseNumber(options.contactID, null);
        this.licenseNumber = options.licenseNumber || null;
        this.expirationDate = options.expirationDate || null;
        this.state = options.state || null;
        this.isUpdated = _.parseBool(options.isUpdated || false);
        this.isDeleted = _.parseBool(options.isDeleted || false);
        this.isNew = _.parseBool(options.isNew || false);
    }

    get validationErrors() {

        let errorList = [];

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class Contact {
    constructor (options) {
        options = options || {};
        this.contactID = _.parseNumber(options.contactID, 0);
        this.companyID = _.parseNumber(options.companyID, null);
        this.fullName = options.fullName || null;
        this.firstName = options.firstName || null;
        this.lastName = options.lastName || null;
        this.jobTitle = options.jobTitle || null;
        this.emailAddress = options.emailAddress || null;
        this.address1 = options.address1 || null;
        this.address2 = options.address2 || null;
        this.city = options.city || null;
        this.state = options.state || null;
        this.zip = options.zip || null;
        this.contactNMLSID = _.parseNumber(options.contactNMLSID, null);
        this.contactStateLicenseID = options.contactStateLicenseID || null;
        this.stateIssuedContactLicense = options.stateIssuedContactLicense || null;
        this.phone = options.phone || null;
        this.phoneExtension = options.phoneExtension || null;
        this.cellPhone = options.cellPhone || null;
        this.direct = options.direct || null;
        this.fax = options.fax || null;
        this.home = options.home || null;
        this.other = options.other || null;
        this.isPreferred = _.parseBool(options.isPreferred);
        this.isInactive = _.parseBool(options.isInactive);
        this.isDefaultServiceContact = _.parseBool(options.isDefaultServiceContact);
        this.agentSelect = options.agentSelect || null;
        this.paperlessCloserAccessID = _.parseNumber(options.paperlessCloserAccessID, null);
        this.cdfPaperlessCloserAccessID = _.parseNumber(options.cdfPaperlessCloserAccessID, null);
        this.notes = options.notes || null;
        this.isDirty = false;
        this.id = options.contactID || 0;
        this.companyName = options.companyName || null;
        this.salesRepresentativeStaffID = _.parseNumber(options.salesRepresentativeStaffID, null);
        this.friendlyName = options.friendlyName || null;
    }

    get businessPhone () {
        if (this.phone) {return `${this.phone}${this.phoneExtension !== null ? " x" + this.phoneExtension : ""}`;}

        return null;
    }
}

export class LogoDto {
    constructor (options) {
        options = options || {};
        this.dataUrl = options.dataUrl || null;
        this.height = options.height || 0;
        this.imageFormat = options.imageFormat || null;
        this.width = options.width || 0;
    }
}

export class TitleCompany {
    constructor (options) {
        let detail = resolveCompanyDetail(options, "titleCompanyDetail");
        this.titleCompanyID = detail.titleCompanyID  || 0;
        this.companyID = detail.companyID || null;
        this.counterSignature = detail.counterSignature || null;
        this.payorNameControl = detail.payorNameControl || null;
        this.taxIdentification = detail.taxIdentification || null;
        this.transmitterControlCode = detail.transmitterControlCode || null;
        this.contact1099EmailAddress = detail.contact1099EmailAddress || null;
        this.contact1099 = detail.contact1099 || null;
        this.contact1099Phone = detail.contact1099Phone || null;
        this.contact1099PhoneExt = detail.contact1099PhoneExt || null;
        this.settlementAgent = detail.settlementAgent || null;
        this.scheduleD = detail.scheduleD || '';
        this.scheduleDOpenXml = detail.scheduleDOpenXml || '';
        this.id = detail.titleCompanyID || 0;
        this.scheduleDHtml = detail.scheduleDHtml || null;
        this.settlementAgent = detail.settlementAgent || false;
    }
}

export class EscrowAccount {
    constructor (options) {
        let detail = resolveCompanyDetail(options, "escrowCompanyDetail");
        this.bankID = _.parseNumber(detail.bankID, 0);
        this.companyID = _.parseNumber(detail.companyID, null);
        this.companyName = detail.companyName || null;
        this.accountNumber = detail.accountNumber || null;
        this.bankInfoNumber = detail.bankInfoNumber || null;
        this.checkTemplateFile = detail.checkTemplateFile || null;
        this.depositSlipRoutingNumber = detail.depositSlipRoutingNumber || null;
        this.fractionalNumber = detail.fractionalNumber || null;
        this.wireTransferRoutingNumber = detail.wireTransferRoutingNumber || null;
        this.checkDigitsLength = _.parseNumber(detail.checkDigitsLength, null);
        this.checkLineLimit = _.parseNumber(detail.checkLineLimit, 1);
        this.checkRangeEnd = _.parseNumber(detail.checkRangeEnd, null);
        this.checkRangeStart = _.parseNumber(detail.checkRangeStart, null);
        this.bankInfo = detail.bankInfo || null;
        this.companyInfo = detail.companyInfo || null;
        this.bookBalance = _.parseNumber(detail.bookBalance, null);
        this.depositSlipTemplateFile = detail.depositSlipTemplateFile || null;
        this.depositSlipLineLimit = _.parseNumber(detail.depositSlipLineLimit, 1);
        this.reportPath = detail.reportPath || null;
    }
}

export class Underwriter {
    constructor (options) {
        let detail = resolveCompanyDetail(options, "underwriterCompanyDetail");
        this.underwriterID = detail.underwriterID  || 0;
        this.companyID = detail.companyID || null;
        this.code = detail.code || null;
        this.additionalCode = detail.additionalCode || null;
        this.reportCode = detail.reportCode || null;
        this.lockDate = detail.lockDate || null;
        this.suspendLock = detail.suspendLock || null;
        this.scheduleD = detail.scheduleD || "";
        this.scheduleDOpenXml = detail.scheduleDOpenXml || '';
        this.defaultSplitPercentage = detail.defaultSplitPercentage || null;
        this.fullName = detail.fullName || null;
        this.createCheck = detail.createCheck || null;
        this.email = detail.email || null;
        this.scheduleDHtml = detail.scheduleDHtml || null;
        this.id = detail.underwriterID || 0;
        this.remitLockDate = detail.remitLockDate || null;
        this.companyName = detail.companyName || null;
        this.underwriterAgencies = _.map(detail.underwriterAgencies, i=>new UnderwriterAgencyDto(i)) || null;
        this.underwriterPremiumSplits = _.map(detail.underwriterPremiumSplits, i=>new UnderwriterPremiumSplitDto(i)) || null;
    }
    toDataObject() { return _.toPlainObject(this); }
}

export class ContactDto extends Contact {
    constructor (options) {
        options = options || {};
        super(options);
        this.orderContactSequence = options.orderContactSequence || "3.1";
        this.paperlessCloserAccess = new PaperlessCloserAccess(options.paperlessCloserAccess);
        this.cdfPaperlessCloserAccess = new CDFPaperlessCloserAccess(options.cdfPaperlessCloserAccess);
    }
}

export class CDFRolesPCAccess {
    constructor (options) {
        options = options || {};
        this.rolesID = options.rolesID || 0;
        this.pcEnableAccessCDF = _.parseBool(options.pcEnableAccessCDF);
        this.pcEnableLoanInfo = _.parseBool(options.pcEnableLoanInfo);
        this.pcEnableLoanTerms = _.parseBool(options.pcEnableLoanTerms);
        this.pcEnableProjectPayment = _.parseBool(options.pcEnableProjectPayment);
        this.pcEnableLoanCost = _.parseBool(options.pcEnableLoanCost);
        this.pcEnableOtherCosts = _.parseBool(options.pcEnableOtherCosts);
        this.pcEnableCashToClose = _.parseBool(options.pcEnableCashToClose);
        this.pcEnableTransactions = _.parseBool(options.pcEnableTransactions);
        this.pcEnableLoanDisclosure = _.parseBool(options.pcEnableLoanDisclosure);
        this.pcEnableAPTable = _.parseBool(options.pcEnableAPTable);
        this.pcEnableAIRTable = _.parseBool(options.pcEnableAIRTable);
        this.pcEnableLoanCalculation = _.parseBool(options.pcEnableLoanCalculation);
        this.pcEnableOtherDisclosure = _.parseBool(options.pcEnableOtherDisclosure);
        this.pcEnableContactInfo = _.parseBool(options.pcEnableContactInfo);
        this.lastChangedBy = options.lastChangedBy || null;
        this.lastChanged = options.lastChanged || null;
        this.isPCUser = _.parseBool(options.isPCUser);
        this.pcEnableCreateCDF = _.parseBool(options.pcEnableCreateCDF);
        this.lastChangedByUserID = options.lastChangedByUserID || null;
    }
}

function parseWithZero (val, defVal) {
    return val == null || val === "" || isNaN(val) ? defVal : Number(val);
}

export class PaperlessCloserAccess {
    constructor (options) {
        options = options || {};
        this.paperlessCloserAccessID = options.paperlessCloserAccessID || 0;
        this.pcEnableAccess = _.parseBool(options.pcEnableAccess, true);
        this.pcEnableOrderNotes = parseWithZero(options.pcEnableOrderNotes, 2);
        this.pcEnableReceiptsDisbursements = parseWithZero(options.pcEnableReceiptsDisbursements, 2);
        this.pcEnablePropertyInformation = parseWithZero(options.pcEnablePropertyInformation, 2);
        this.pcEnableDocuments = parseWithZero(options.pcEnableDocuments, 2);
        this.pcEnableWorkflow = parseWithZero(options.pcEnableWorkflow, 2);
        this.pcEmailEvents = parseWithZero(options.pcEmailEvents, 2);
        this.pcEmailDocumentNotifications = parseWithZero(options.pcEmailDocumentNotifications, 2);
        this.pcViewAllOrders = parseWithZero(options.pcViewAllOrders, 0);
        this.pcDisplayInContacts = parseWithZero(options.pcDisplayInContacts, 2);
        this.pcUserName = options.pcUserName || "";
        this.pcPassword = options.pcPassword || "";
        this.isCMServiceAdmin = _.parseBool(options.pcEnableAccess, false);
        this.lastLoginDate = options.lastLoginDate || null;
    }
}

export class CDFPaperlessCloserAccess {
    constructor (options) {
        options = options || {};
        this.cdfPaperlessCloserAccessID = options.cdfPaperlessCloserAccessID || 0;
        this.pcEnableAccessCDF = _.parseBool(options.pcEnableAccessCDF, true);
        this.pcEnableCreateCDF = _.parseBool(options.pcEnableCreateCDF, true);
        this.pcEnableLoanInfo = parseWithZero(options.pcEnableLoanInfo, 2);
        this.pcEnableLoanTerms = parseWithZero(options.pcEnableLoanTerms, 2);
        this.pcEnableProjectPayment = parseWithZero(options.pcEnableProjectPayment, 2);
        this.pcEnableLoanCost = parseWithZero(options.pcEnableLoanCost, 2);
        this.pcEnableOtherCosts = parseWithZero(options.pcEnableOtherCosts, 2);
        this.pcEnableCashToClose = parseWithZero(options.pcEnableCashToClose, 2);
        this.pcEnableTransactions = parseWithZero(options.pcEnableTransactions, 2);
        this.pcEnableLoanDisclosure = parseWithZero(options.pcEnableLoanDisclosure, 2);
        this.pcEnableAPTable = parseWithZero(options.pcEnableAPTable, 2);
        this.pcEnableAIRTable = parseWithZero(options.pcEnableAIRTable, 2);
        this.pcEnableLoanCalculation = parseWithZero(options.pcEnableLoanCalculation, 2);
        this.pcEnableOtherDisclosure = parseWithZero(options.pcEnableOtherDisclosure, 2);
        this.pcEnableContactInfo = parseWithZero(options.pcEnableContactInfo, 2);
        this.token = options.token || null;
        this.lastChangedBy = options.lastChangedBy || null;
        this.lastChanged = options.lastChanged || null;
        this.isPCUser = options.isPCUser || null;
        this.lastChangedByUserID = options.lastChangedByUserID || null;
    }
}

export class ListCodeLookupsModel {
    constructor (options) {
        options = options || {};
        this.id = options.id || 0;
        this.text = options.name || "";
        this.canAdd = options.canAdd || null;
        this.canAssignCompany = options.canAssignCompany || null;
        this.canDelete = options.canDelete || null;
        this.canEdit = options.canEdit || null;
        this.enabled = options.enabled || null;
    }
}

export class CompanyListCode {
    constructor (options) {
        options = options || {};
        this.companyListCodeID = options.companyListCodeID || 0;
        this.companyID = options.companyID || 0;
        this.listCodeID = options.listCodeId || 0;
    }
}

export class PaperlessCloserLayoutsLookupsModel {
    constructor (options) {
        options = options || {};
        this.id = options.id || 0;
        this.text = options.name || null;
        this.canAdd = options.canAdd || null;
        this.canAssignCompany = options.canAssignCompany || null;
        this.canDelete = options.canDelete || null;
        this.canEdit = options.canEdit || null;
        this.enabled = options.enabled || null;

    }
}

export class WebOrderLayoutTemplate {
    constructor (options) {
        options = options || {};
        this.companyWebOrderLayoutTemplateID = options.companyWebOrderLayoutTemplateID || 0;
        this.companyID = options.companyID || 0;
        this.webOrderLayoutTemplateID = options.webOrderLayoutTemplateID || 0;
    }
}

export class ContactCardDto {
	constructor(options) {
        options = options || {};
		this.key = _.parseNumber(options.key, 0);
		this.role = options.role || null;
		this.roleExtension = options.roleExtension || null;
		this.rolesID = _.parseNumber(options.rolesID, null);
		this.tableID = _.parseNumber(options.tableID, null);
		this.roleTypeID = _.parseNumber(options.roleTypeID, null);
		this.buyerSellerID = _.parseNumber(options.buyerSellerID, null);
		this.companyID = _.parseNumber(options.companyID, null);
		this.contactID = _.parseNumber(options.contactID, null);
		this.regionID = _.parseNumber(options.regionID, null);
        this.isEntity = _.parseBool(options.isEntity, false);
		this.name = options.name || null;
		this.firstName = options.firstName || null;
		this.lastName = options.lastName || null;
		this.email = options.email || null;
        this.address = options.address || null;
        this.forwardingAddress = options.forwardingAddress || null;
		this.addressLine1 = options.addressLine1 || null;
		this.addressLine2 = options.addressLine2 || null;
		this.phone = options.phone || null;
		this.fax = options.fax || null;
		this.tollFreeNumber = options.tollFreeNumber || null;
		this.tollFreeFax = options.tollFreeFax || null;
		this.business = options.business || null;
		this.direct = options.direct || null;
		this.fax2 = options.fax2 || null;
		this.home = options.home || null;
		this.work = options.work || null;
        this.cell = options.cell || null;
        this.orderContactSequence = options.orderContactSequence || "";
        this.roleNumber = options.roleNumber || 0;
        this.hasOutstandingChecks = options.hasOutstandingChecks || false;
        this.stateLicenseID = options.stateLicenseID || "";
        this.nmlsid = options.nmlsid || "";
        this.stateIssuedLicense = options.stateIssuedLicense || "";
    }

    get roleDisplayValue() { return _.parseNumber(this.roleNumber, 0) <= 0 ? this.role : `${this.role} ${this.roleNumber}`; }

    get isBuyer() { return this.roleTypeID == RoleType.Buyer; }

    get isSeller() { return this.roleTypeID == RoleType.Seller; }

    get isBuyerSeller() { return this.isBuyer || this.isSeller; }

    get companyName() { return this.isBuyerSeller ? "" : this.name; }

    get contactName() { return this.isBuyerSeller ? this.name || this.fullName : this.fullName; }

    get primaryPhone(){
        return this.isBuyerSeller
            ? (this.cell || this.home) ? (this.cell || this.home) : this.phone
            : (this.business || this.cell) ? (this.business || this.cell) : this.phone;
    }

    get fullName() {
        if (!this.isEntity && !this.isBuyerSeller) {
            //controlling logic for fullName for buyer/seller on server side in SQL.
            return "";
        }
        let nameParts = [this.firstName, this.lastName];
        return _.joinParts(nameParts, " ");
    }

    get initial() {
        if(!this.contactName) return "";
        return _.upperCase(_.first(this.contactName));
    }

    get roleDescription() { return _.isEmpty(this.name) ? this.role : `${this.role} (${this.name})`; }

    get actions() {
        return [
            { key:"edit", title:"Edit Selected", icon:"fas fa-edit", disabled: false },
            { key:"adv-opts", title:"Advanced Options", icon:"fas fa-cog", disabled: false }
            ];
    }//
}

export class Location {
    constructor(options) {
        options = options || {};
        this.locationID = options.locationID || null;
        this.locationName = options.locationName || null;
        this.placeOfClosingContactID = options.placeOfClosingContactID || null;
        this.regionID = options.regionID || null;
        this.placeOfClosingCompanyID = options.placeOfClosingCompanyID || null;
    }
}

export class CompanyDetailDto {
    constructor(options) {
        options = options || {};
        this.companyInfo = new Company(options.companyInfo);
        this.companyAddresses = _.map(options.companyAddresses, (address) => new CompanyAddress(address));
        this.companyLicenses = _.map(options.companyLicenses, (license) => new CompanyLicense(license));
        this.placeOfClosingLocations = options.placeOfClosingLocations ? options.placeOfClosingLocations : [];
        this.logoData = new ImageDataModel(options.logoData);
        this.titleCompanyDetail = new TitleCompany(options.titleCompanyDetail);
        this.underwriterCompanyDetail = new Underwriter(options.underwriterCompanyDetail);
        this.escrowCompanyDetail = new EscrowAccount(options.escrowCompanyDetail);
        this.positivePayDetail = new PositivePay(options.positivePayDetail);
        this.selectedListCodes = options.selectedListCodes ? (this.convertToSimpleItemType(options.selectedListCodes) || []) : [];
        this.selectedPCL = options.selectedListCodes ? (this.convertToSimpleItemType(options.selectedPCL) || []) : [];
        this.exportFormats = options.exportFormats ? (this.convertToSimpleItemType(options.exportFormats) || []) : [];
    }
    convertToSimpleItemType(data) { return _.map(data, item => ({ id: item.id, name: item.name })); }
}

export class UnderwriterAgencyDto {
    constructor(options) {
        options = options || {};
        this.underwriterAgencyID = _.parseNumber(options.underwriterAgencyID, -1) || options.underwriterAgencyID;
        this.companyID = _.parseNumber(options.companyID, -1);
        this.agencyID = options.agencyID;
        this.stateID = _.parseNumber(options.stateID, -1);
        this.countyID = _.parseNumber(options.countyID, null);
        this.modifiedBy = _.parseNumber(options.modifiedBy, null);
        this.modifiedDate = _.parseNumber(options.modifiedDate, null);
        this.isUpdated = options.isUpdated || false;
        this.isDeleted = options.isDeleted || false;
        this.isNew = options.isNew || false;
    }
    toDataObject() { return _.toPlainObject(this); }
}

export class UnderwriterPremiumSplitDto {
    constructor(options) {
        options = options || {};
        this.underwriterPremiumSplitID = _.parseNumber(options.underwriterPremiumSplitID, -1) || options.underwriterPremiumSplitID;
        this.companyID = _.parseNumber(options.companyID, -1);
        this.percentage = options.percentage;
        this.stateID = _.parseNumber(options.stateID, -1);
        this.countyID = _.parseNumber(options.countyID, null);
        this.modifiedBy = _.parseNumber(options.modifiedBy, null);
        this.modifiedDate = _.parseNumber(options.modifiedDate, null);
        this.isUpdated = options.isUpdated || false;
        this.isDeleted = options.isDeleted || false;
        this.isNew = options.isNew || false;
    }
    toDataObject() { return _.toPlainObject(this); }
}